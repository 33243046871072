import { connect, useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  ICondosStaticState,
  IWeekState,
  weeksActions,
  weeksLocationsActions,
} from '@share/store/slices';
import { ILoginState } from '@share/store/slices';

import './style.scss';
import { FunctionalRootState, RootState } from '@share/utils';
import { InsuranceSearch } from '../insurance-search-wrapper';
import LifePreserverImg from '@assets/images/insurance/life-preserver.svg';
import ShieldCheckImg from '@assets/images/insurance/shield-check.svg';
import GearWideImg from '@assets/images/insurance/gear-wide-connected.svg';
import { FormattedMessage } from 'react-intl';
import { useMemo, useState } from 'react';
import { Moment } from 'moment';
import { IQuoteCheckoutResponse } from '../../../../store/slices/insurance-quote';
import GlobalSpinner from '../global-spinner/component';

interface IMapStateToProps {
  weeksStore: IWeekState;
  loginStore: ILoginState;
  condosStaticStore: ICondosStaticState;
}

interface IMapDispatchToProps {
  setDefaultValues: () => void;
  resetLocations: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {}

const availableStates = [
  //'AK',  el proveedor de seguros ya no lo tiene activo y da error al momento de consultarlo
  'AL',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
];

export interface InsuranceFilter {
  destination: string | null;
  travelDates: [Moment | null, Moment | null] | null;
  travelers: number | null;
  state: string | null;
  tripCost: number | null;
}

const InsuranceResultsPageComponent = (props: IProps) => {
  const { condosCountriesCode } = props.condosStaticStore;

  const [insuranceFilter, setInsuranceFilter] = useState<InsuranceFilter>({
    destination: null,
    travelDates: null,
    travelers: null,
    state: null,
    tripCost: null,
  });

  const states = useMemo(
    () => condosCountriesCode?.rciCountries?.find((c) => c.isoCode === 'US')?.states ?? [],
    [condosCountriesCode, insuranceFilter],
  );

  const filteredStates = states
    ? useMemo(() => states.filter((s: any) => availableStates.includes(s.code)), [states])
    : [];

  const insuranceQuoteStore: IQuoteCheckoutResponse = useSelector(
    (state: FunctionalRootState) => state.insuranceQuoteStore,
  );

  return (
    <section className="insurance__page">
      <header className="insurance__header">
        <section className="insurance__header-background">
          <h2>
            <FormattedMessage id="insurance.header.title" />
          </h2>
          <p>
            <FormattedMessage id="insurance.header.subtitle" />
          </p>
        </section>
        <InsuranceSearch
          setInsuranceFilter={setInsuranceFilter}
          insuranceFilter={insuranceFilter}
          states={filteredStates}
          insuranceQuoteStore={insuranceQuoteStore}
          condosCountriesCode={condosCountriesCode}
        />
      </header>

      <section className="insurance__travel">
        <section className="insurance__travel-container">
          <section className="insurance__travel-container-one">
            <h2>
              <FormattedMessage id="insurance.travel.title" />
            </h2>
          </section>
          <section className="insurance__travel-container-two">
            <h3 className="insurance__travel-container-two-title">
              <FormattedMessage id="insurance.travel.subtitle" />
            </h3>
            <section className="insurance__travel-container-two-paragraph">
              <h5>
                <FormattedMessage id="insurance.travel.ptitle1" />
              </h5>
              <p>
                <FormattedMessage id="insurance.travel.paragraph1" />
              </p>
            </section>
            <section className="insurance__travel-container-two-paragraph">
              <h5>
                <FormattedMessage id="insurance.travel.ptitle2" />
              </h5>
              <p>
                <FormattedMessage id="insurance.travel.paragraph2" />
              </p>
            </section>
            <section className="insurance__travel-container-two-paragraph">
              <h5>
                <FormattedMessage id="insurance.travel.ptitle3" />
              </h5>
              <p>
                <FormattedMessage id="insurance.travel.paragraph3" />
              </p>
            </section>
            <section className="insurance__travel-container-two-paragraph">
              <h5>
                <FormattedMessage id="insurance.travel.ptitle4" />
              </h5>
              <p>
                <FormattedMessage id="insurance.travel.paragraph4" />
              </p>
            </section>
            <section className="insurance__travel-container-two-paragraph">
              <h5>
                <FormattedMessage id="insurance.travel.ptitle5" />
              </h5>
              <p>
                <FormattedMessage id="insurance.travel.paragraph5" />
              </p>
            </section>
          </section>
        </section>
      </section>

      <section className="insurance__protection">
        <section className="insurance__protection-container">
          <section className="insurance__protection-container-text">
            <h2>
              <FormattedMessage id="insurance.protection.title1" />{' '}
              <span>
                {' '}
                <FormattedMessage id="insurance.protection.title2" />
              </span>
            </h2>
            <p>
              <FormattedMessage id="insurance.protection.subtitle" />
            </p>
          </section>
          <section className="insurance__protection-container-plans">
            <section className="insurance__protection-container-plans-one">
              <img
                src={ShieldCheckImg}
                alt="Shield Check Img"
                className="insurance__protection-container-plans-one-icons"
              />
              <h3>
                <FormattedMessage id="insurance.protection.ptitle1" />
              </h3>
              <p>
                <FormattedMessage id="insurance.protection.paragraph1" />
              </p>
            </section>
            <section className="insurance__protection-container-plans-two">
              <img
                src={LifePreserverImg}
                alt="Life preserver Img"
                className="insurance__protection-container-plans-one-icons"
              />
              <h3>
                {' '}
                <FormattedMessage id="insurance.protection.ptitle2" />
              </h3>
              <p>
                <FormattedMessage id="insurance.protection.paragraph2" />
              </p>
            </section>
            <section className="insurance__protection-container-plans-three">
              <img
                src={GearWideImg}
                alt="Gear Wide Img"
                className="insurance__protection-container-plans-one-icons"
              />
              <h3>
                {' '}
                <FormattedMessage id="insurance.protection.ptitle3" />
              </h3>
              <p>
                <FormattedMessage id="insurance.protection.paragraph3" />
              </p>
            </section>
          </section>
          {/* <button>View Our Plans</button> */}
        </section>
      </section>

      <GlobalSpinner spinning={insuranceQuoteStore.quote.loading} />
    </section>
  );
};

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    weeksStore: state.weeksStore,
    loginStore: state.loginStore,
    condosStaticStore: state.condosStaticStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setDefaultValues: weeksActions.setDefaultValues,
  resetLocations: weeksLocationsActions.resetWeeksLocations,
};

export const InsuranceResultsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(InsuranceResultsPageComponent));
