
import { get, isEmpty, isUndefined } from "lodash";
import { IAccount, IAccountOrgSiteIdProfile, INavigation, INavigationItem, IUser } from "@share/common-types";
import { Routes } from "@share/constants";
import { getLocalURI, isExternalUrl } from "./common";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { VACATIONS_PAGE } from "./routes";

export const GetAccountProfile = (profiles: IAccountOrgSiteIdProfile[], organizationId: number, siteId: number) => {
  if (profiles?.length) {
    const profile = get(profiles.filter(p => p.organizationId === organizationId), '[0].profile') || get(profiles.filter(p => p.organizationId === siteId), '[0].profile');
    if (!isEmpty(profile)) {
      return profile;
    }
  }
  return null;
}

const GetMainMenuReplaceFlights = (items: INavigation) => {
  let main = items.main ? [...items.main] : items.main;
  return main.map((m: any) => {
    if (m.title === 'More Benefits' && m.childItems?.length) {
      const childItems = m.childItems.map((c: any) => {
        if (c.title === 'Flights') {
          return { ...c, url: 'https://www.flights.com/' };
        }
        return { ...c };
      });
      return { ...m, childItems };
    }
    return { ...m };
  });
}

const GetMainMenuLevartiAgent = (items: INavigation) => {
  let main = items.main ? [...items.main] : items.main;
  const moreBenefits = main?.filter((m: any) => m.title === 'More Benefits');
  main = [
    {
      isPopup: false,
      title: 'Travel Agent',
      childItems: [
        { isPopup: false, title: 'Become Travel Agent', url: Routes.MLMBecomeAgent },
        {
          isPopup: false,
          excludeCheck: true,
          title: 'New Agents',
          url: Routes.MLMNewAgents,
        },
        {
          isPopup: false,
          excludeCheck: true,
          title: 'Experienced Agents',
          url: Routes.MLMExperiencedAgents,
        },
        {
          isPopup: false,
          excludeCheck: true,
          title: 'Exclusive access',
          url: Routes.MLMExclusiveAccess,
        },
        {
          isPopup: false,
          excludeCheck: true,
          title: 'Training',
          url: Routes.MLMTraining,
        },
        {
          isPopup: false,
          excludeCheck: true,
          title: 'Tools',
          url: Routes.MLMTechnology,
        },
      ],
    },
    { isPopup: false, title: 'Hotels', url: `/search` },
    { isPopup: false, title: 'Condos', url: `/condo/deals` },
    { isPopup: false, title: 'Cruises', url: `/cruise` },
    { isPopup: false, title: 'Car Rentals', url: `/cars/search` },
  ];
  if (moreBenefits?.length) {
    const childItems = moreBenefits[0].childItems?.filter((a: any) => a.title !== 'Car Rentals');
    const moreBenefitsFiltered = { ...moreBenefits[0], childItems };

    main.push(moreBenefitsFiltered);
  }

  return main;
}

const GetMainMenuLevartiBasic = (user: IUser, items: INavigation) => {
  return [
    { isPopup: false, title: 'my.dashboard', url: `/dashboard` },
    {
      isPopup: false,
      excludeCheck: true,
      title: 'training.material',
      url: `https://travelagents.travcoding.com/wp-admin/?access_token=${user?.accessToken}&email=${user?.email}`,
    },
    ...items.main,
    { isPopup: false, title: 'support.page', url: `/contact-us` },
  ];
}

export const GetMainMenu = (account: IAccount, user: IUser, items: INavigation) => {
  if (items.main?.length && account?.levartiOrgIds?.includes(items.organizationId)) {
    if (!items.isTravelAgent) {
      return GetMainMenuLevartiAgent(items);
    } else {
      return GetMainMenuLevartiBasic(user, items);
    }
  } else if (account?.isRSITemplate && items.main?.length) {
    return GetMainMenuReplaceFlights(items);
  }
  return items.main;
}

const HOW_TO_MENU = 'How To';
const MENU_TO_INCLUDE = ['Condos', 'Cruises', 'Getaways', 'Hotels', 'Homes', 'All Inclusives', 'Car Rentals', 'Flights', 'Daily Benefits', 'Elite Benefits', HOW_TO_MENU];

export const GetMenuListToDisplay = (main: INavigationItem[], showMyAccount: boolean) => {
  const list: INavigationItem[] = [];
  
  if (main) {
    main.forEach(m => {
      if (!m.childItems?.length) {
        if (MENU_TO_INCLUDE.includes(m.title)) {
          list.push(m);
        }
      } else {
        m.childItems.forEach(c => {
          if (MENU_TO_INCLUDE.includes(c.title)) {
            const child = { ...c };
            if (c.title === HOW_TO_MENU) {
              child.title = 'Brio & Rci';
            }
            list.push(child);
          }
        });
      }
    });
  }

  if (showMyAccount) {
    list.push({
      title: 'My Account',
      url: `${Routes.RsiAccount}`,
    });
  } else {
    list.push({
      title: 'saved.properties',
      url: `${Routes.SavedPropertiesAccount}`,
    });
  }

  list.push({
    title: 'Contact Us',
    url: `${Routes.ContactUs}`,
  });

  return list;
}
