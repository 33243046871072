
import { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Modal, Spin } from 'antd';

import { FunctionalRootState } from '@share/utils';
import { ILoginState, IMenuState } from '@share/store/slices';
import { GIFT_CARD_FIRST_LOGIN } from '@share/constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

const TIMEOUT_CLOSE = 15000;
const CONFETTI_TIMEOUT_CLOSE = 6000;

function GiftCardFirstLoginModalComponent() {

  const [visible, setVisible] = useState(false);
  const [closable, setClosable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [displayPlay, setDisplayPlay] = useState(false);
  const [displayConfetti, setDisplayConfetti] = useState(false);
  const [firstLoginStorage, setFirstLoginStorage] = useState<string | null>(null);

  const { items }: IMenuState = useSelector((state: FunctionalRootState) => state.navigationMenuStore);
  const { user }: ILoginState = useSelector((state: FunctionalRootState) => state.loginStore);

  const vidRef = useRef<any>(null);

  useEffect(() => {
    setFirstLoginStorage(localStorage.getItem(GIFT_CARD_FIRST_LOGIN));
  }, []);

  useEffect(() => {
    const check = !!user?.isFirstLogin && !!items?.isGiftCard && isEmpty(firstLoginStorage);
    setVisible(check);
  }, [user?.isFirstLogin, items?.isGiftCard, firstLoginStorage]);

  const handleClose = () => {
    setVisible(false);
  }

  const handleVideoEnded = () => {
    localStorage.setItem(GIFT_CARD_FIRST_LOGIN, 'true');
    setClosable(true);
    setDisplayPlay(true);

    setTimeout(() => {
      setVisible(false);
    }, TIMEOUT_CLOSE);
  }

  const handlePlayPress = () => {
    if (vidRef?.current) {
      setDisplayPlay(false);

      vidRef.current.pause();
      vidRef.current.currentTime = 0;
      vidRef.current.muted = false;
      vidRef.current.loop = false;
      vidRef.current.play();
    }
  };
  
  const handleCanPlay = () => {
    setTimeout(() => {
      setDisplayPlay(true);
      setDisplayConfetti(true);
      setLoading(false);

      setTimeout(() => setDisplayConfetti(false), CONFETTI_TIMEOUT_CLOSE);
    }, 500);
  }

  if (!visible) {
    return null;
  }

  return (
    <Modal
      open={visible}
      className="gift-card-first-login-modal"
      wrapClassName="gift-card-first-login-modal__wrapper"
      footer={null}
      closable={closable}
      onCancel={handleClose}
    >
      <div className="gift-card-first-login-modal__video-container">
        <video id="gift-card-video" className="gift-card-video" controls={false} onEnded={handleVideoEnded} onLoadedData={handleCanPlay} ref={vidRef}>
          <source src={'https://travcoding-hotel-resources.s3.us-east-1.amazonaws.com/videos/gift-card-video.mp4'} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {loading && (
          <div className="gift-card-first-login-modal__loading" onClick={handlePlayPress}>
            <Spin size="large" />
          </div>)}

        {displayPlay && (
          <div className="gift-card-first-login-modal__play" onClick={handlePlayPress}>
            <FontAwesomeIcon icon={faCirclePlay} />
          </div>)}
      </div>

      {!loading && (
        <label className="gift-card-first-login-modal__watch">Watch the video to be able to use your rewards</label>)}

      {displayConfetti && (
        <div className="gift-card-first-login-modal__confetti" style={{ backgroundImage: 'url(https://travcoding-hotel-resources.s3.us-east-1.amazonaws.com/front/confetti-new.gif)' }}></div>)}
    </Modal>
  );
}

export const GiftCardFirstLoginModal = connect()(GiftCardFirstLoginModalComponent);
