
//import RSIUpgradeHero from '@share/assets/images/profiles/rsi-getaways-prime.jpg';

const RSIUpgrade = 'https://travcoding-hotel-resources.s3.us-east-1.amazonaws.com/front/profiles/rsi-upgrade-prime.svg';

export const RSIUpgradePrimeProfile = {
    logo: RSIUpgrade,
    logoSkipWidth: true,

    headerFontColor: '#FFFFFF',
    headerBackgroundColor: '#1A1D6E',

    buttonColor: '#1A1D6E',
    buttonTextColor: '#FFFFFF',

    buttonColorReverse: '#FFFFFF',
    buttonTextColorReverse: '#1A1D6E',

    buttonBorderRadius: '0',

    generalBorderRadius: '0',
    generalBorderColor: '#0079C2',
    
    isTravelLeadersImage: true,
    travelLeadersImages: [
        //{ value: RSIUpgradeHero },
        { value : 'https://travcoding-hotel-resources.s3.us-east-1.amazonaws.com/front/profiles/rsi-getaways-1.jpg' },
        { value : 'https://travcoding-hotel-resources.s3.us-east-1.amazonaws.com/front/profiles/rsi-getaways-2.jpg' },
        { value : 'https://travcoding-hotel-resources.s3.us-east-1.amazonaws.com/front/profiles/rsi-getaways-3.jpg' },
        { value : 'https://travcoding-hotel-resources.s3.us-east-1.amazonaws.com/front/profiles/rsi-getaways-4.jpg' },
        { value : 'https://travcoding-hotel-resources.s3.us-east-1.amazonaws.com/front/profiles/rsi-getaways-5.jpg' },
        { value : 'https://travcoding-hotel-resources.s3.us-east-1.amazonaws.com/front/profiles/rsi-getaways-6.jpg' },
        { value : 'https://travcoding-hotel-resources.s3.us-east-1.amazonaws.com/front/profiles/rsi-getaways-7.jpg' },
        { value : 'https://travcoding-hotel-resources.s3.us-east-1.amazonaws.com/front/profiles/rsi-getaways-8.jpg' },
        { value : 'https://travcoding-hotel-resources.s3.us-east-1.amazonaws.com/front/profiles/rsi-getaways-9.jpg' },
        { value : 'https://travcoding-hotel-resources.s3.us-east-1.amazonaws.com/front/profiles/rsi-getaways-10.jpg' },
    ],
    travelLeadersImageBackground: '#1A1D6E',
    travelLeadersImageText: 'rsi.upgrade.prime.hero.text',
    travelLeadersImageTextColor: '#FFFFFF',
    travelLeadersImageTextFamily: 'liu-jian-mao-cao-font',
    travelLeadersImageTextPosition: 'bottom-under',

    bogoColor: '#323232',
    bogoButtonTextColor: '#1A1D6E',
    getawaysColor: '#1A1D6E',
    getawaysButtonTextColor: '#1A1D6E',

    buttonColorAlternative: '#CFA241',
    buttonTextColorAlternative: '#FFFFFF',

    mainBackgroundColor: '#1A1D6E',
    subHeaderColor: '#000000',
    travelLeadersFontSize: '50',

    mainBorderColor: '#6D6D6D',
    mainBorderRadius: '0',
    mainHideLinkIcon: true,

    menuItemHideBorderBottom: true
}
