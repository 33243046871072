
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { BackgroundColor } from '@constants';
import { ICompanyLogos } from '@common-types';
import { IMenuState } from '@share/store/slices';
import { RootState } from '@share/utils';

import './style.scss';

interface IMapStateToProps {
  menuStore: IMenuState;
}

interface IProps extends IMapStateToProps {
  isBackgroundColorDark?: boolean;
  logos: ICompanyLogos[];
  max?: number;
}

function CompanyLogosComponent(props: IProps) {
  const { isBackgroundColorDark = true, logos, max, menuStore } = props;
  const { items } = menuStore;

  const logosTodisplay = max ? logos.slice(0, max) : logos;
  
  if (!items) {
    return null;
  }
  
  return (
    <div
      className={`company-logos ${
        isBackgroundColorDark ? BackgroundColor.dark : BackgroundColor.light
      }`}
    >
      <div className="company-logos__wrapper">
        <p className="company-logos__title sencodary-font">
          <FormattedMessage id="company.you.keep" />
        </p>
        <div className="company-logos__logos-wrapper">
          {logosTodisplay.map((item, index) => (
            <div key={index} className="company-logos__img-wrapper">
              <img className="company-logos__img" src={item.image} alt={item.name} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
  };
};

export const CompanyLogos = connect(mapStateToProps)(CompanyLogosComponent);
