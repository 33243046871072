import './style.scss';
import { Select } from 'antd';
import { ICountriesCode, IRciCountriesCodes } from '@common-types';
import { useEffect, useState } from 'react';

interface IProps {
  placeholder: string;
  label: string;
  icon: any;
  value: ICountriesCode;
  setInsuranceFilter: React.Dispatch<React.SetStateAction<any>>;
}

export default function InsuranceCountryFilter(props: IProps) {
  const { placeholder, label, icon, value, setInsuranceFilter } = props;

  const onChange = (value: string) => {
    setInsuranceFilter((prevState: any) => ({ ...prevState, destination: value }));
  };

  const [countryOrState, setCountryOrState] = useState<any[]>([]);

  useEffect(() => {
    let isMounted = true;

    if (value?.rciCountries) {
      const mappedCountriesOrState =
        value?.rciCountries?.map((item: IRciCountriesCodes, i: number) => ({
          label: item.name,
          value: item.isoCode,
          key: i,
        })) ?? [];

      if (isMounted) {
        setCountryOrState(mappedCountriesOrState); // Solo actualizar si está montado
      }
    }

    return () => {
      isMounted = false;
    };
  }, [value]);

  return (
    <div className="insurance-filters-containers">
      <div className="insurance-filters-containers-icons">
        <img src={icon} className="search-container-icons" />
      </div>
      <div className="insurance-filters-containers-inputs">
        <div className="insurance-filters-containers-inputs-title">
          <span>{label}</span>
        </div>
        <Select
          showSearch
          placeholder={placeholder}
          optionFilterProp="children"
          popupClassName="custom-insurance-dropdown"
          className="insurance-filters-containers-inputs-controls"
          onChange={onChange}
          allowClear
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={countryOrState}
        />
      </div>
    </div>
  );
}
