
import chunk from 'lodash/chunk';
import groupBy from 'lodash/groupBy';

import { IGeoLocation } from '@share/common-types';
import { DEFAULT_MAP_ZOOM, SOLD_OUT } from '@constants';
import { Cluster, ClusterStats, MarkerClusterer, onClusterClickHandler, Renderer, SuperClusterAlgorithm } from "@googlemaps/markerclusterer";

import MapMarker from '@assets/images/map-price-icon.png';
import MapMarkerSelected from '@assets/images/map-price-selected-icon.png';
import MapMultipleDoubleMarker from '@assets/images/map-multiple-double-price-icon.png';
import MapMultipleDoubleMarkerSelected from '@assets/images/map-multiple-double-price-selected-icon.png';
import MapMultipleMarker from '@assets/images/map-multiple-price-icon.png';
import MapMultipleMarkerSelected from '@assets/images/map-multiple-price-selected-icon.png';
import MapMarkerSoldout from '@assets/images/map-price-icon-soldout.png';
import MapHomeMarker from '@assets/images/map-home-icon.svg';
import MapHomeSelectedMarker from '@assets/images/max-home-selected-icon.svg';
import MapHomeDisabledMarker from '@assets/images/map-home-disabled.svg';

import { getCurrencyText } from '@share/utils';

import { OverlappingMarkerSpiderfier } from "ts-overlapping-marker-spiderfier";

const zero = 0;

const getMarkerIcon = (
  price: number,
  id: number,
  selectedId: number,
  availability?: string,
  isExact?: boolean,
  isDisabled?: boolean,
): string => {
  let icon = MapMarker;

  if (price && !(price === 0 && availability === SOLD_OUT)) {
    if (id === selectedId || isExact) {
      icon = MapMarkerSelected;
    }
  } else {
    icon = MapHomeMarker;
    if (id === selectedId || isExact) {
      icon = MapHomeSelectedMarker;
    } else if (availability === SOLD_OUT) {
      icon = MapMarkerSoldout;
    }
  }

  if (isDisabled) {
    icon = MapHomeDisabledMarker;
  }

  return icon;
}

interface Location {
  location: IGeoLocation;
  id: number;
  availability?: string;
  pricePerNight?: number;
  isExact?: boolean;
  isDisabled?: boolean;
  label?: string | google.maps.MarkerLabel;
  isCondo?: boolean;
}

export class PriceMarker extends google.maps.Marker {
  position: google.maps.LatLng;
  containerDiv: HTMLDivElement;

  public availability: string;
  public isExact: boolean;
  public isDisabled: boolean;
  public price: number;
  public id: number;
  public selectedLocations: Location[];

  constructor(
    map: google.maps.Map,
    position: google.maps.LatLng,
    price: number,
    id: number,
    selectedId: number,
    currency: string,
    selectedLocations: Location[],
    availability?: string,
    isExact?: boolean,
    isDisabled?: boolean,
  ) {
    super({ 
      position,
      map,
      icon: getMarkerIcon(price, id, selectedId, availability, isExact, isDisabled),
      label: Map.getMarkerLabelObject(availability, currency, price, id),
      title: id?.toString()
    });

    this.position = position;
    this.availability = availability;
    this.isExact = isExact;
    this.isDisabled = isDisabled;
    this.price = price;
    this.id = id;
    this.selectedLocations = selectedLocations;
  }
}




const two = 2;
const topZIndex = '5';
const MAX_SHOW_AREA = 4000;


export class PriceMarkerCondo extends google.maps.OverlayView {
  position: google.maps.LatLng;
  containerDiv: HTMLDivElement;

  public availability: string | null | undefined;
  public isExact: boolean | null | undefined;
  public isDisabled: boolean | null | undefined;
  public price: number;
  public id: number;
  public selectedLocations: Location[];

  constructor(
    position: google.maps.LatLng,
    price: number,
    id: number,
    selectedId: number,
    selectedLocations: Location[],
    onClick: () => void,
    availability?: string,
    isExact?: boolean,
    isDisabled?: boolean,
  ) {
    super();
    this.position = position;

    this.containerDiv = document.createElement('div');
    this.containerDiv.classList.add('price-marker');
    if (selectedLocations?.length > 1) {
      this.containerDiv.classList.add('multiple-locations');
    }
    this.containerDiv.innerText = `$${price}`;
    this.containerDiv.style.background = `url(${MapMarker})`;
    this.containerDiv.setAttribute('data-item-id', `${id}`);
    this.containerDiv.setAttribute('data-item-location-id', selectedLocations.map(l => l.id).join(','));

    const backgroundImage = getMarkerIcon(price, id, selectedId, availability, isExact, isDisabled);

    this.containerDiv.style.zIndex = topZIndex;
    this.containerDiv.style.background = `url(${backgroundImage})`;

    if (isDisabled || isExact) {
      this.containerDiv.setAttribute('data-item-not-change', `${id}`);
    }

    this.availability = availability;
    this.isExact = isExact;
    this.isDisabled = isDisabled;
    this.price = price;
    this.id = id;
    this.selectedLocations = selectedLocations;

    this.containerDiv.addEventListener('click', onClick);
    PriceMarkerCondo.preventMapHitsAndGesturesFrom(this.containerDiv);
  }

  onAdd(): void {
    this.getPanes()?.floatPane.appendChild(this.containerDiv);
  }

  onRemove(): void {
    if (this.containerDiv.parentElement) {
      this.containerDiv.parentElement.removeChild(this.containerDiv);
    }
  }

  draw(): void {
    const divPosition = this.getProjection().fromLatLngToDivPixel(this.position);
    const display =
      Math.abs(divPosition.x) < MAX_SHOW_AREA && Math.abs(divPosition.y) < MAX_SHOW_AREA
        ? 'block'
        : 'none';

    if (display === 'block') {
      this.containerDiv.style.left = divPosition.x - this.containerDiv.offsetWidth / two + 'px';
      this.containerDiv.style.top = divPosition.y - this.containerDiv.offsetHeight + 'px';
    }

    if (this.containerDiv.style.display !== display) {
      this.containerDiv.style.display = display;
    }
  }

  setIcon(icon: string): void {
    this.containerDiv.style.background = `url(${icon})`;
  }

  setZIndex(zIndex: number): void {
    this.containerDiv.style.zIndex = zIndex?.toString();
  }
}

export class ClusterRenderer implements Renderer {
  render(cluster: Cluster, stats: ClusterStats): google.maps.Marker {
    // change color if this cluster has more markers than the mean cluster
    const color = '#0D99D6';
    // create svg url with fill color
    const svg = window.btoa(`
      <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
      <circle cx="120" cy="120" opacity=".6" r="70" />
      <circle cx="120" cy="120" opacity=".3" r="90" />
      <circle cx="120" cy="120" opacity=".2" r="110" />
      </svg>`);
    // create marker using svg icon
    return new google.maps.Marker({
      position: cluster.position,
        icon: {
            url: `data:image/svg+xml;base64,${svg}`,
            scaledSize: new google.maps.Size(45, 45),
        },
        label: {
            text: String(cluster.count),
            color: 'rgba(255,255,255,0.9)',
            fontSize: '12px',
        },
        title: `Cluster of ${cluster.count} markers`,
        // adjust zIndex to be above other markers
        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + cluster.count,
    });
  }
}

let markers: (google.maps.Marker | PriceMarker | PriceMarkerCondo)[] = [];
let selectedMarker: (google.maps.Marker | PriceMarker | PriceMarkerCondo | null | undefined) = null;
let selectedMarkerId: number | null | undefined = null;
let selectedMarkerSpiderOpenList: number[] = [];
let markerClusterer: MarkerClusterer | null | undefined = null;
let initialZIndex = 110;

export class Map {
  static initializeMap(
    element: HTMLElement | null | undefined,
    point: google.maps.LatLngLiteral | null | undefined,
    options: google.maps.MapOptions = {},
    zoom?: number
  ): google.maps.Map {
    return new google.maps.Map(element as HTMLElement, {
      center: point,
      zoom: zoom? zoom : DEFAULT_MAP_ZOOM,
      ...options,
    });
  }

  static addMarkers(
    map: google.maps.Map,
    locations: {
      location: IGeoLocation;
      id: number;
      availability?: string;
      pricePerNight?: number;
      isExact?: boolean;
      isDisabled?: boolean;
      label?: string | google.maps.MarkerLabel;
      isCond?: boolean;
    }[],
    icon: string | google.maps.Icon,
    currency?: string,
    selectedId = zero,
    groupMarkers = false,
    onClick?: (_id: any) => void,
  ): void {
    markers = [];

    initialZIndex = 110;

    let locationGrouped;
    if (groupMarkers) {
      const locationGroupedObject = groupBy(locations, function(l: any) {
        return `${l?.location?.latitude?.toFixed(4)}-${l?.location?.longitude?.toFixed(4)}`;
      });
      locationGrouped = Object.keys(locationGroupedObject).map(key => locationGroupedObject[key]);
    } else {
      locationGrouped = locations.map(l => [l]);
    }

    locationGrouped.forEach(locations => this.processMarker(map, locations, icon, currency, selectedId, onClick));
  }

  static async addMarkersParallel(
    map: google.maps.Map | undefined,
    locations: Location[] | null | undefined,
    icon: string | google.maps.Icon,
    currency?: string | null,
    selectedId = zero,
    onClick?: (id: number, marker?: google.maps.Marker) => void,
  ): Promise<void> {
    markers = [];

    const locationChunks = chunk(locations, 20);
    await Promise.all(
      locationChunks.map(locations => new Promise((resolve) => {
        locations.forEach(location => this.processMarker(map, [location], icon, currency, selectedId, onClick));
        resolve("OK");
      })))
  }

  static getMarkerLabelObject(availability: string, currency: string, pricePerNight: number, id?: number) {
    return {
      text: availability === SOLD_OUT ? SOLD_OUT : `${getCurrencyText(currency)} ${pricePerNight.toFixed(2)}`,
      className: `price-marker-map${id ? ` marker_${id}` : ''}`,
    };
  }

  static processMarker(
    map: google.maps.Map | undefined,
    selectedLocations: Location[],
    icon: string | google.maps.Icon,
    currency?: string,
    selectedId = zero,
    onClick?: (id: number) => void,
  ): void {
    let marker: google.maps.Marker | PriceMarker | PriceMarkerCondo;

    const isMultipleLocationSamePlace = selectedLocations?.length > 1;
    let omsObject: any;
    if (isMultipleLocationSamePlace) {
      omsObject = new OverlappingMarkerSpiderfier(map, { 
        markersWontMove: true,   // we promise not to move any markers, allowing optimizations
        markersWontHide: true,   // we promise not to change visibility of any markers, allowing optimizations
        basicFormatEvents: true,  // allow the library to skip calculating advanced formatting information

        circleStartAngle: Math.PI / 3,
        circleFootSeparation : 60,
        spiralFootSeparation: 60, // (default: 26)
        spiralLengthStart: 25, // (default: 11)
        spiralLengthFactor: 20, // (default: 4)
      });

      omsObject.addListener('format', function(marker: any, status: any) {
        const hasSelected = selectedLocations.some(l => l.id === selectedMarkerId);

        let markerIcon;
        if (icon) {
          markerIcon = icon;
        } else {
          const hasMoreThanTwo = selectedLocations.length > 2;

          const { price, id, availability, isExact, isDisabled } = marker as PriceMarker | PriceMarkerCondo;
          let markerLabel;

          markerIcon = MapMarker;
          if (status === OverlappingMarkerSpiderfier.markerStatus.UNSPIDERFIED ) {
            markerIcon = (hasSelected) ? hasMoreThanTwo ? MapMultipleMarkerSelected : MapMultipleDoubleMarkerSelected : hasMoreThanTwo ? MapMultipleMarker : MapMultipleDoubleMarker;
            if (hasSelected) {
              const locationSelected = selectedLocations.find(l => l.id === selectedMarkerId);
              const { pricePerNight = zero, availability } = locationSelected;
              if (id === selectedMarkerId) {
                selectedMarkerSpiderOpenList = selectedMarkerSpiderOpenList.filter(m => m !== selectedMarkerId);
                markerLabel = Map.getMarkerLabelObject(availability, currency, pricePerNight, id);
              }
            } else {
              const locationSelected = selectedLocations[0];
              selectedMarkerSpiderOpenList = selectedMarkerSpiderOpenList.filter(m => m !== selectedMarkerId);
              markerLabel = Map.getMarkerLabelObject(locationSelected.availability, currency, locationSelected.pricePerNight, id);
            }
          } else {
            selectedMarkerSpiderOpenList.push(selectedMarkerId);
            markerIcon = getMarkerIcon(price, id, selectedMarkerId, availability, isExact, isDisabled);
            markerLabel = Map.getMarkerLabelObject(availability, currency, price, id);
          }
          if (marker instanceof PriceMarker || marker instanceof google.maps.Marker) {
            marker.setLabel(markerLabel);
          }
        }

        marker.setIcon(markerIcon);
      });
    }

    selectedLocations.forEach(selectedLocation => {
      const { location, id, pricePerNight = zero, availability, label, isExact = false, isDisabled = false } = selectedLocation;

      if (icon) {
        marker = new google.maps.Marker({
          position: Map.getGoogleLocation(location),
          map,
          icon,
          label,
          title: id?.toString(),
        });
  
        marker.set('availability', availability);

        if (onClick) {
          google.maps.event.addListener(marker, 'click', () => {
            selectedMarker = marker;
            selectedMarkerId = id;

            onClick(id);
          });
        }
      } else {
        if (selectedLocation.isCondo) {
          marker = new PriceMarkerCondo(
            new google.maps.LatLng(Map.getGoogleLocation(location)),
            pricePerNight,
            id,
            selectedId,
            selectedLocations, () => {
              const selectedMarkerObject = selectedMarker as PriceMarker | PriceMarkerCondo;
              const isMultiple = selectedMarkerObject?.selectedLocations?.length > 1;
              
              if (selectedMarker) {
                if (isMultiple && !selectedMarkerSpiderOpenList.includes(selectedMarkerObject?.id)) {
                  selectedMarker.setIcon(selectedMarkerObject?.selectedLocations?.length === 2 ? MapMultipleDoubleMarker : MapMultipleMarker);
                } else {
                  selectedMarker.setIcon(getMarkerIcon(pricePerNight, id, selectedMarkerId, availability, isExact, isDisabled));
                }
              }
              marker.setIcon(getMarkerIcon(pricePerNight, id, id, availability, isExact, isDisabled));
    
              selectedMarker = marker;
              selectedMarkerId = id;
  
              onClick(id);
            },
            availability,
            isExact,
            isDisabled,
          );  
        } else {
          marker = new PriceMarker(
            map,
            new google.maps.LatLng(Map.getGoogleLocation(location)),
            pricePerNight,
            id,
            selectedId,
            currency,
            selectedLocations,
            availability,
            isExact,
            isDisabled,
          );  
        }
        marker.setMap(map);
  
        if (availability !== SOLD_OUT) {
          google.maps.event.addListener(marker, 'click', () => {
            const selectedMarkerObject = selectedMarker as PriceMarker | PriceMarkerCondo;
            const isMultiple = selectedMarkerObject?.selectedLocations?.length > 1;
            
            if (selectedMarker) {
              if (isMultiple && !selectedMarkerSpiderOpenList.includes(selectedMarkerObject?.id)) {
                selectedMarker.setIcon(selectedMarkerObject?.selectedLocations?.length === 2 ? MapMultipleDoubleMarker : MapMultipleMarker);
              } else {
                selectedMarker.setIcon(getMarkerIcon(pricePerNight, id, selectedMarkerId, availability, isExact, isDisabled));
              }
            }
            marker.setIcon(getMarkerIcon(pricePerNight, id, id, availability, isExact, isDisabled));
  
            selectedMarker = marker;
            selectedMarkerId = id;

            onClick(id);
          });
        }
      }

      marker.setZIndex(initialZIndex++);

      markers.push(marker);

      if (isMultipleLocationSamePlace) {
        (function() {  // make a closure over the marker and marker data
          omsObject.addMarker(marker);  // adds the marker to the spiderfier _and_ the map
        })();
      }
    });
  }

  static removeAllMarkers(): void {
    markers.forEach((marker) => {
      marker.setMap(null);
    });
    selectedMarker = null;
    selectedMarkerId = null;
  }

  static refreshMarkers(selectedId: number | null | undefined, onlyUpdateIcon?: boolean): void {
    if (!onlyUpdateIcon) {
      selectedMarker = null;
      selectedMarkerId = null;
    }

    markers.forEach((marker) => {
      if (marker instanceof PriceMarker || marker instanceof PriceMarkerCondo) {
        const { price, id, availability, isExact, isDisabled, selectedLocations } = marker as PriceMarker | PriceMarkerCondo;
        const isMultiple = selectedLocations?.length > 1;
        const isSpidefierOpen = selectedMarkerSpiderOpenList.includes(selectedId) || selectedLocations.some(l => selectedMarkerSpiderOpenList.includes(l.id));
        if (isMultiple && !isSpidefierOpen) {
          const isSelected: boolean = id === selectedId || selectedLocations.map(l => l.id).includes(selectedId);
          marker.setIcon(selectedLocations?.length === 2 ? isSelected ? MapMultipleDoubleMarkerSelected : MapMultipleDoubleMarker : isSelected ? MapMultipleMarkerSelected : MapMultipleMarker);
        } else {
          marker.setIcon(getMarkerIcon(price, id, selectedId, availability, isExact, isDisabled));
        }
        if (!onlyUpdateIcon && id === selectedId) {
          selectedMarker = marker;
          selectedMarkerId = selectedId;      
        }

        if (selectedId) {
          if (id === selectedId) {
            marker.setZIndex(initialZIndex++);
          }
        }
      }
    });
  }

  static addCluster(map: google.maps.Map | undefined, clusterRenderer?: Renderer): void {
    if (!!markerClusterer) {
      markerClusterer.clearMarkers();
    }

    const renderer = clusterRenderer ? clusterRenderer : new ClusterRenderer();
    const algorithm = new SuperClusterAlgorithm({ minPoints: 3, maxZoom: 10 });
    const markersFiltered = markers.filter(marker => !(marker instanceof PriceMarkerCondo)) as (PriceMarker | google.maps.Marker)[];
    markerClusterer = new MarkerClusterer({ markers: markersFiltered, map, algorithm, renderer });
  }

  static getGoogleLocation(location: IGeoLocation | null | undefined): google.maps.LatLngLiteral {
    return {
      lat: location?.latitude ? location?.latitude : 0,
      lng: location?.longitude ? location?.longitude : 0,
    };
  }
}
