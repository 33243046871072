import React from 'react';

import { Modal } from 'antd';
import { FormattedMessage } from 'react-intl';

import { RootState, splitText } from '@share/utils';
import { ILoginState } from '@share/store/slices';

import './style.scss';
import { connect } from 'react-redux';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps {
  cancellationPoliciesText: string | undefined;
  refundabilityText?: string;
  visible: boolean;
  isForCondo?: boolean;
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>, isScrollToRooms?: boolean) => void;
}

class ModalCancellationPoliciesComponent extends React.Component<IProps> {
  getCancellationPoliciesSplitText = (): React.ReactNode => {
    const arrayTexts = this.props.cancellationPoliciesText?.split(splitText);

    return (
      <ul className="modal-cancellation-policies__description">
        {arrayTexts?.map((item, index) => (
          <li className="modal-cancellation-policies__description-row" key={index}>
            {item}
          </li>
        ))}
      </ul>
    );
  };

  render(): React.ReactNode {
    const {
      refundabilityText,
      visible,
      onCancel,
      cancellationPoliciesText,
      isForCondo = false,
      loginStore
    } = this.props;
    const { account } = loginStore;

    const fontFamily = account?.fontFamily;
    const secondaryFontFamily = account?.secondaryFontFamily;

    return (
      <Modal
        className={`modal-cancellation-policies ${fontFamily ? `${fontFamily}-font`: ''} ${secondaryFontFamily ? `secondary-${secondaryFontFamily}-font`: ''}`}
        open={visible}
        footer={null}
        onCancel={onCancel}
        wrapClassName="modal-cancellation-policies__wrapper"
      >
        <div>
          <h4 className="modal-cancellation-policies__title secondary-font">
            <FormattedMessage id="cancellation.policies" />
          </h4>
          {isForCondo ? (
            <div>{cancellationPoliciesText}</div>
          ) : (
            this.getCancellationPoliciesSplitText()
          )}
          {!!refundabilityText && (
            <div className="modal-cancellation-policies__refundability">
              <p className="modal-cancellation-policies__refundability-title">
                <FormattedMessage id="refundability" />:
              </p>
              <p className="modal-cancellation-policies__refundability-description">
                {refundabilityText}
              </p>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
  };
};

export const ModalCancellationPolicies = connect(mapStateToProps)(ModalCancellationPoliciesComponent);
