import React from 'react';
import differenceInDays from 'date-fns/differenceInDays';
import ReactGA from 'react-ga4';

import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, } from '@fortawesome/free-solid-svg-icons';

import { getDateCheckInOutISOString, RootState } from '@share/utils';
import { IHotelInfo, IRoomsContent } from '@common-types';
import { IAccount, IGuest, IRoom, ISessionKey } from '@share/common-types';
import { EmptySearchMessage } from '@components';
import { BlueButton } from '@share/components';
import { roomsSearchActions, IRoomsSearchState, getRoomsDetails } from '@store/slices';
import { InfoGraySvg, CalendarNotificationSvg } from '@assets';
import { UpdateSvg, CloseSvg } from '@share/assets';
import { D_UPDATE_BUTTON, D_VIEW_ALL_ROOMS } from '@share/constants';
import { DatePickerComponent } from '@share/components';
import { RoomPickerComponent } from '@share/components';
import { IHotelsState, ILoginState, IMarginatorState } from '@share/store/slices';

import { RoomsSkeleton } from '../rooms-skeleton';
import { RoomVacation } from '../room-vacation';
import { Room } from '../room';

import './style.scss';

interface IMapStateToProps {
  roomsSearchStore: IRoomsSearchState;
  hotelsStore: IHotelsState;
  loginStore: ILoginState;
  marginatorStore: IMarginatorState;
}

interface IMapDispatchToProps {
  setDates: (dates: { startDate: Date; endDate: Date }) => void;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  setKey: (key: string) => void;
  clearError: () => void;
  addEmptyRoom: () => void;
  updateAdultsCount: (roomId: string, count: number) => void;
  addKid: (roomId: string, kid: IGuest) => void;
  updateKidAge: (roomId: string, kidId: string, age: number) => void;
  removeRoom: (roomId: string) => void;
  removeKid: (roomId: string, kidId: string) => void;
  setRooms: (roms: IRoom[]) => void;
  setDatesString: (dates: { startDate: string; endDate: string }) => void;
  getRoomsDetails: (hotelId: number, sessionKey: ISessionKey) => void;
  resetState: () => void;
  setShowUpdatedBanner: (show: boolean) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {
  hotel: IHotelInfo;
  refAnchor: React.RefObject<HTMLDivElement>;
  matches?: boolean;
  isVacationRentals?: boolean;
  isFocused?: boolean;
  isStaticDetail?: boolean;
}

interface IState {
  isFirstRequestExecuted: boolean;
  showMoreRooms: boolean;

  isModalCancellationPoliciesVisible: boolean;
  cancellationPoliciesText: string;
  refundabilityText: string;
  isChangedCancellationPolicy: boolean;
}

const zero = 0;

class RoomsSearchComponent extends React.Component<IProps, IState> {
  state: IState = {
    isFirstRequestExecuted: true,
    showMoreRooms: false,

    isModalCancellationPoliciesVisible: false,
    cancellationPoliciesText: '',
    refundabilityText: '',
    isChangedCancellationPolicy: false,
  };

  constructor(props: IProps) {
    super(props);

    this.state.isFirstRequestExecuted = true;
  }

  updateSearch = () => {
    const { getRoomsDetails, hotel, hotelsStore } = this.props;

    getRoomsDetails(hotel.hotelDetails.id, hotelsStore?.sessionKey as ISessionKey);

    const { loginStore } = this.props;
    const { account } = loginStore;

    ReactGA.event({
      category: account?.name as string,
      action: `${D_UPDATE_BUTTON}_${account?.name.toUpperCase()}`,
      label: `User clicked update button on detail`,
      nonInteraction: false,
    });
  };

  componentWillUnmount() {
    const { roomsSearchStore } = this.props;
    const { timer } = roomsSearchStore;

    if (timer) {
      clearTimeout(timer);
    }
  }

  getHotelInfo = (): IHotelInfo => {
    const { hotel, roomsSearchStore } = this.props;

    return roomsSearchStore.hotel ? roomsSearchStore.hotel : hotel;
  };

  handleViewMOreRooms = () => {
    this.setState({ showMoreRooms: !this.state.showMoreRooms });

    const { loginStore } = this.props;
    const { account } = loginStore;
    ReactGA.event({
      category: account?.name as string,
      action: `${D_VIEW_ALL_ROOMS}_${account?.name.toUpperCase()}`,
      label: `User clicked view more/less rooms on detail`,
      nonInteraction: false,
    });
  }

  getRoomContent = (room: string): IRoomsContent => {
    const { roomsContent = [] } = this.getHotelInfo();

    const content = roomsContent.find(({ roomKey }) => {
      return roomKey === room;
    });

    return content
      ? content
      : {
          amenities: [],
          descriptions: [],
          images: [],
          roomKey: room,
        };
  };

  render(): React.ReactNode {
    const {
      updateAdultsCount,
      removeRoom,
      removeKid,
      addKid,
      addEmptyRoom,
      updateKidAge,
      clearError,
      setDates,
      setKey,
      roomsSearchStore,
      refAnchor,
      matches,
      hotel,
      setShowUpdatedBanner,
      marginatorStore,
      hotelsStore,
      loginStore,
      isVacationRentals,
      isStaticDetail
    } = this.props;
    const { rooms, endDate, error, key, startDate, isRoomsLoading, showUpdatedBanner } = roomsSearchStore;
    const { selectedHotel } = hotelsStore;
    const { account } = loginStore;
    const { adjustedPackageGroups } = this.getHotelInfo();
    const nights = differenceInDays(new Date(endDate), new Date(startDate));

    const groupPackages = adjustedPackageGroups?.map(({ groupedPackages }) => get(groupedPackages, `[${zero}]`, null)).filter(p => !!p);

    const cheapestRoom = groupPackages.reduce((prev: any, current: any) => (+current?.pricePerNight >= +prev?.pricePerNight)? prev :  current);
    const preferredRoomPackageId = get(cheapestRoom, `packageId`, null);
    const preferredRoomSavingsAmount = get(cheapestRoom, `savings`, 0);
    const preferredRoomPrice = get(cheapestRoom, `price`, 1);
    const preferredRoomSavingPct = preferredRoomSavingsAmount / (preferredRoomPrice + preferredRoomSavingsAmount) * 100;

    const isVacationRental = hotel.hotelDetails.isVacationRental;

    return (
      <div className="rooms-search" ref={refAnchor}>
        <div className="rooms-search__header secondary-font">
          <FormattedMessage id={isVacationRental ? "property.availability" : "rooms.availability"} />
        </div>

        <div className="rooms-search__search">
          <DatePickerComponent
            clearError={clearError}
            datesStore={{
              startDateSelected: startDate,
              endDateSelected: endDate,
              endDate,
              startDate,
              error,
              key,
              isFocused: this.props.isFocused
            }}
            loginStore={{ ...loginStore }}
            setDatesSelected={setDates}
            setKey={setKey}
            isRoomsSearch={matches}
            onSelect={this.updateSearch}
            isUpdateSearch={true}
          />
          <RoomPickerComponent
            updateAdultsCount={updateAdultsCount}
            removeKid={removeKid}
            addKid={addKid}
            addEmptyRoom={addEmptyRoom}
            removeRoom={removeRoom}
            roomsStore={{ 
              rooms,
              error,
              roomsSelected: rooms
            }}
            loginStore={{ ...loginStore }}
            updateKidAge={updateKidAge}
            onSelect={this.updateSearch}
            isUpdateSearch={true}
          />
          <div className="rooms-search__button">
            <BlueButton disabled={!startDate || !endDate || isRoomsLoading} onClick={this.updateSearch}>
              <UpdateSvg />
              <FormattedMessage id="update.search.your.hotel" />
            </BlueButton>
          </div>
        </div>

        {error && (
          <div className="date-picker__error" title={error} style={{ marginTop: '5px' }}>
            {error}
          </div>)}

        <div className="rooms-search__list">
          {isRoomsLoading ? (
            <div className="rooms-search__list-skeleton">
              <RoomsSkeleton />
              <RoomsSkeleton />
              <RoomsSkeleton />
            </div>
          ) : (
            <>
              {showUpdatedBanner && adjustedPackageGroups.length ? (
                <div className="rooms-search__updated-banner">
                  <div className="rooms-search__banner-icon">
                    <InfoGraySvg />
                  </div>
                  <div className="rooms-search__updated-text-wrapper">
                    <div className="rooms-search__updated-header">
                      <FormattedMessage id="rooms.updated" />
                    </div>
                    <div className="rooms-search__updated-text">
                      <FormattedMessage id="last.session.expired" />
                    </div>
                  </div>
                  <div
                    className="rooms-search__updated-close"
                    onClick={() => setShowUpdatedBanner(false)}
                  >
                    <CloseSvg />
                  </div>
                </div>
              ) : null}
              {!this.state.isFirstRequestExecuted && (
                <div className="rooms-search__specify-travel-banner">
                  <div className="rooms-search__banner-icon">
                    <CalendarNotificationSvg />
                  </div>
                  <div className="rooms-search__specify-travel-text-wrapper">
                    <div className="rooms-search__specify-travel-text">
                      <FormattedMessage id="specify.travel.dates" />
                    </div>
                  </div>
                </div>
              )}

              {adjustedPackageGroups.length && (<div className={`rooms-search__list-text secondary-font ${isVacationRental ? 'vacation' : ''}`}>
                {
                  isVacationRental
                  ? <div className="rooms-search__header secondary-font" style={{ fontSize: '24px' }}>
                      {isVacationRental ? (<FormattedMessage id="units" />) : (<FormattedMessage id="rooms" />)}
                    </div>
                  : <span className="list-count"><FormattedMessage id="room.search.room_availability" /> {adjustedPackageGroups.length}</span>
                }
                {adjustedPackageGroups.length > 6 && !this.state.showMoreRooms && (<span className="view-all" onClick={this.handleViewMOreRooms}><FormattedMessage id="room.search.view_all" /></span>)}
                {adjustedPackageGroups.length > 6 && this.state.showMoreRooms && (<span className="view-all" onClick={this.handleViewMOreRooms}><FormattedMessage id="room.search.view_less" /></span>)}
              </div>)}

              <div className="rooms-search__list-wrapper">
                {adjustedPackageGroups.length ? (
                  adjustedPackageGroups.slice(0, (!this.state.showMoreRooms? 6 : adjustedPackageGroups.length)).map(({ groupedPackages }) => {
                    const currentPackage = groupedPackages[zero];
                    const possibleStays = currentPackage.possibleStays;

                    if (!currentPackage || !this.state.isFirstRequestExecuted) {
                      return null;
                    }

                    if (isVacationRental) {
                      return (
                        <RoomVacation
                          account={account as IAccount}
                          key={currentPackage.packageId}
                          nights={!possibleStays?.length ? nights : possibleStays[0].days}
                          hotelId={hotel.hotelDetails.id}
                          groupedPackages={groupedPackages}
                          getRoomContent={this.getRoomContent}
                          isPreferred={preferredRoomPackageId === currentPackage.packageId}
                          isStaticDetail={isStaticDetail}
                          preferredRoomSavingPct={preferredRoomSavingPct}
                          marginator={marginatorStore}
                          hotelDetail={hotel.hotelDetails}
                          isVacationRentals={isVacationRentals}
                        />
                      );
                    }
                    else {
                      return (
                        <Room
                          account={account as IAccount}
                          hotel={selectedHotel}
                          key={currentPackage.packageId}
                          nights={nights}
                          hotelId={hotel.hotelDetails.id}
                          groupedPackages={groupedPackages}
                          getRoomContent={this.getRoomContent}
                          isPreferred={preferredRoomPackageId === currentPackage.packageId}
                          isStaticDetail={isStaticDetail}
                          preferredRoomSavingPct={preferredRoomSavingPct}
                          marginator={marginatorStore}
                        />
                      );
                    }
                  })
                ) : (
                  <div className="rooms-search__list-wrapper-no-rooms">
                    <EmptySearchMessage account={account as IAccount} label={"no.available.rooms"}  isVacationRentals={isVacationRentals} />
                  </div>
                )}
              </div>

              {adjustedPackageGroups.length && adjustedPackageGroups.length > 6 && (
                <div className="rooms-search__list-show-more secondary-font" onClick={this.handleViewMOreRooms}>
                  {!this.state.showMoreRooms && (<><span><FormattedMessage id="room.search.view_all_rooms" /></span><FontAwesomeIcon icon={faArrowDown}/></>)}
                  {this.state.showMoreRooms && (<><span><FormattedMessage id="room.search.view_less_rooms" /></span><FontAwesomeIcon icon={faArrowUp}/></>)}
                </div>)}

            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    roomsSearchStore: state.roomsSearchStore,
    hotelsStore: state.hotelsStore,
    marginatorStore: state.marginatorStore,
    loginStore: state.loginStore,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  setDates: (dates: { startDate: Date; endDate: Date }) => {
    dispatch(
      roomsSearchActions.setDates({
        startDate: getDateCheckInOutISOString(dates.startDate),
        endDate: getDateCheckInOutISOString(dates.endDate),
      }),
    );
  },
  setDatesString: (dates: { startDate: string; endDate: string }) => {
    dispatch(
      roomsSearchActions.setDates({
        startDate: dates.startDate,
        endDate: dates.endDate,
      }),
    );
  },
  setStartDate: (date: Date) => {
    dispatch(roomsSearchActions.setStartDate(getDateCheckInOutISOString(date)));
  },
  setEndDate: (date: Date) => {
    dispatch(roomsSearchActions.setEndDate(getDateCheckInOutISOString(date)));
  },
  setKey: (key: string) => {
    dispatch(roomsSearchActions.setKey(key));
  },
  clearError: () => {
    dispatch(roomsSearchActions.setError(''));
  },
  addEmptyRoom: () => {
    dispatch(roomsSearchActions.addEmptyRoom());
  },
  updateAdultsCount: (roomId: string, count: number) => {
    dispatch(roomsSearchActions.updateAdultsCount({ roomId, count }));
  },
  addKid: (roomId: string, kid: IGuest) => {
    dispatch(roomsSearchActions.addKid({ roomId, kid }));
  },
  updateKidAge: (roomId: string, kidId: string, age: number) => {
    dispatch(roomsSearchActions.updateKidAge({ roomId, kidId, age }));
  },
  removeRoom: (roomId: string) => {
    dispatch(roomsSearchActions.removeRoom(roomId));
  },
  removeKid: (roomId: string, kidId: string) => {
    dispatch(roomsSearchActions.removeKid({ roomId, kidId }));
  },
  setRooms: (rooms: IRoom[]) => {
    dispatch(roomsSearchActions.setRooms(rooms));
  },
  getRoomsDetails: (hotelId: number, sessionKey: ISessionKey) => {
    dispatch(getRoomsDetails(hotelId, sessionKey));
  },
  setShowUpdatedBanner: (show: boolean) => {
    dispatch(roomsSearchActions.setShowUpdatedBanner(show));
  },
  resetState: () => {
    dispatch(roomsSearchActions.resetState());
  },
});

export const RoomsSearch = connect(mapStateToProps, mapDispatchToProps)(withRouter(RoomsSearchComponent));

