import { ILoginState } from '@share/store/slices';
import { RootState } from '@share/utils';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './style.scss';


interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps {
  isCondoPage?: boolean;
}

class SubmitRequestHeadingComponent extends Component<IProps> {
  render(): React.ReactNode {
    const { isCondoPage, loginStore } = this.props;
    const { account } = loginStore;

    return (
      <div className="submit-request-heading-wrapper">
        <div className="submit-request-heading-wrapper__wrapper">
          <Link
            className={`submit-request-heading-wrapper__link ${isCondoPage ? 'condo' : ''}`}
            to={isCondoPage ? '/condo/deals' : `/${account?.name}/all-inclusives`}
          >
            <FormattedMessage id="back" />
          </Link>
          <h1 className={`submit-request-heading-wrapper__heading secondary-font ${isCondoPage ? 'condo' : ''}`}>
            <FormattedMessage id="submit.request" />
          </h1>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
  };
};

export const SubmitRequestHeading = connect(mapStateToProps)(SubmitRequestHeadingComponent);
