
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Routes } from '@share/constants';
import { BlueButton } from '@share/components';
import { RootState } from '@share/utils';
import { ILoginState, IMenuState } from '@share/store/slices';

import ConciergeTeamImageLarge from '@assets/images/concierge-team-large.png';

import './style.scss';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IMapStateToProps {
  menuStore: IMenuState;
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps {}

function ConciergeServicesComponent(props: IProps) {
  const { loginStore, menuStore } = props;
  const { account } = loginStore;
  const { loading, items } = menuStore;

  if (!items || loading) {
    return null;
  }

  return (
    <div className="concierge-services">
      <div className="concierge-services__wrapper">
        <div className="concierge-services__info-wrapper">
          <div className="concierge-services__text-wrapper">
            <p className="concierge-services__title">
              <FormattedMessage id="concierge.services" />
            </p>
            <p className="concierge-services__description">
              <FormattedMessage id="you.are.now.member" />
            </p>
          </div>
          <Link to={`/${account?.name}${Routes.ConciergeTeam}`} className="concierge-services__btn-wrapper">
            <BlueButton alternative>
              <FormattedMessage id="talk.to.our.team" />
              <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: '6px' }} />
            </BlueButton>
          </Link>
        </div>
        <div className="concierge-services__img-wrapper">
          <img src={ConciergeTeamImageLarge} alt="Concierge team" />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore,
  };
};

export const ConciergeServices = connect(mapStateToProps)(ConciergeServicesComponent);
