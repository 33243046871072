import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, Toaster } from '@share/utils';
import { axiosInstanceB2B } from '@share/utils';
import { ISavedProperties, ISavedPropertiesProcess } from '@share/common-types';
import { NULL_VALUE } from '@constants';
import { updateSavedProperty } from './hotels-search';
import { updateSavedPropertyDetails } from '@store/slices';
import { updateSavedPropertyCondos } from './condo-search';
import { updateSavedPropertyDetailsCondos } from './condo-details';

export interface ISavedPropertiesState {
    properties: ISavedProperties[];
    loading: boolean;
    process: ISavedPropertiesProcess;
    error: string;
}

const initialState: ISavedPropertiesState = {
    properties: [],
    loading: false,
    process: NULL_VALUE,
    error: '',
};

const savedPropertiesSlice = createSlice({
    name: 'savedProperties',
    initialState,
    reducers: {
        setLoading: (state: ISavedPropertiesState, { payload }: PayloadAction<boolean>) => {
            state.loading = payload;
        },
        setProcess: (state: ISavedPropertiesState, { payload }: PayloadAction<ISavedPropertiesProcess>) => {
            state.process = payload;
        },
        setError: (state: ISavedPropertiesState, { payload }: PayloadAction<string>) => {
            state.error = payload;
        },
        setProperties: (state: ISavedPropertiesState, { payload }: PayloadAction<ISavedProperties[]>) => {
            state.properties = payload;
        },
    },
});

export const savedPropertiesActions = savedPropertiesSlice.actions;
export const savedPropertiesReducer = savedPropertiesSlice.reducer;

export const getSavedProperties = (filter: string): AppThunk => {
    return async (dispatch) => {
        dispatch(savedPropertiesActions.setLoading(true));

        try {
            const response = await axiosInstanceB2B.get(`/identity/users/saved-property?orderBy=${filter}&sortOrder=desc`);
            dispatch(savedPropertiesActions.setProperties(response.data.result));
            dispatch(savedPropertiesActions.setLoading(false));
        } catch (error: any) {
            dispatch(savedPropertiesActions.setError(error.toString()));
            dispatch(savedPropertiesActions.setLoading(false));
        }
    };
};

export const handleSavedProperties = (property: ISavedProperties): AppThunk => {
    return async (dispatch) => {
        dispatch(savedPropertiesActions.setLoading(true));

        try {
            const response = await axiosInstanceB2B.post(`/identity/users/saved-property`, property);
            if (response.data.success) {
                const added = response.data.result.some((x: any) => x.propertyId === property.propertyId);
                dispatch(savedPropertiesActions.setProcess({ id: property.propertyId, added: added }));
                dispatch(savedPropertiesActions.setProperties(response.data.result));

                if (property.type === 'hotel') {
                    dispatch(updateSavedProperty(property.propertyId, added));
                    dispatch(updateSavedPropertyDetails(property.propertyId, added));
                }
                
                if (property.type === 'condo') {
                    dispatch(updateSavedPropertyCondos(property.propertyId, added));
                    dispatch(updateSavedPropertyDetailsCondos(property.propertyId, added));
                }
                
                Toaster.success(added ? 'Property saved successfully.' : 'The property was removed.');
            } else {
                Toaster.error(`An error occurred. If the problem persists, please contact the system administrator.`);
            }
            dispatch(savedPropertiesActions.setLoading(false));

        } catch (error: any) {
            dispatch(savedPropertiesActions.setError(error.toString()));
            dispatch(savedPropertiesActions.setLoading(false));
            Toaster.error(`An error occurred. If the problem persists, please contact the system administrator.`);
        }
    };
};

export const deleteSavedProperties = (propertyId: number): AppThunk => {
    return async (dispatch) => {
        dispatch(savedPropertiesActions.setLoading(true));

        try {
            const response = await axiosInstanceB2B.post(`/identity/users/saved-property`, { propertyId });
            dispatch(savedPropertiesActions.setProperties(response.data.result));
            dispatch(savedPropertiesActions.setLoading(false));
            Toaster.success('The property was removed.');
        } catch (error: any) {
            dispatch(savedPropertiesActions.setError(error.toString()));
            dispatch(savedPropertiesActions.setLoading(false));
            Toaster.error(`An error occurred. If the problem persists, please contact the system administrator.`);
        }
    };
};

export const removeSavedProperties = (): AppThunk => {
    return async (dispatch) => {
        dispatch(savedPropertiesActions.setLoading(true));

        try {
            const response = await axiosInstanceB2B.delete(`/identity/users/saved-property`, {});
            if (response.data.success) {
                dispatch(savedPropertiesActions.setProperties(response.data.result));
                Toaster.success('All saved properties have been deleted.');
            }
            dispatch(savedPropertiesActions.setLoading(false));
        } catch (error: any) {
            dispatch(savedPropertiesActions.setError(error.toString()));
            dispatch(savedPropertiesActions.setLoading(false));
            Toaster.error(`An error occurred. If the problem persists, please contact the system administrator.`);
        }
    };
};
