import React from 'react';

import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { get, isEmpty } from 'lodash';

import { FilterCheckbox, FilterRadio } from '@components';
import { BlueButton } from '@share/components';
import { IFilterOption } from '@common-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

export enum Aligment {
  Right,
  Left,
}

type OnChangeFunction = (value: string[]) => void;
type EmptyFunction = () => void;

interface IProps extends WrappedComponentProps {
  display: boolean;
  title: string;
  dropdownDisable: boolean;
  dropdownTitle: string | null | undefined;

  values: string[];
  counters: any[] | null | undefined;

  aligment?: Aligment;

  singleValue?: boolean;
  displayZero?: boolean;
  inputFilter?: boolean;

  counterConverter: (counter: any) => IFilterOption;
  onChange: OnChangeFunction | null | undefined;
  onDoneClick: EmptyFunction | null | undefined;
}

interface IState {
  isFilterOpen: boolean;
  valuesOriginal: string[] | null | undefined;
}

class CondoResultFilterComponent extends React.Component<IProps, IState> {
  state: IState = { isFilterOpen: false, valuesOriginal: null };
  filterWrapperRef: React.RefObject<HTMLDivElement> = React.createRef();

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  toggleFilter = () => {
    this.setState((prevState: IState) => ({
      isFilterOpen: !prevState.isFilterOpen,
      valuesOriginal: !prevState.isFilterOpen ? [...this.props.values] : null,
    }));
  };

  handleClickOutside = (event: MouseEvent): void => {
    if (
      this.state.isFilterOpen &&
      this.filterWrapperRef &&
      !this.filterWrapperRef.current.contains(event.target as Node)
    ) {
      const refresh = this.state.isFilterOpen
        ? JSON.stringify(this.props.values) !== JSON.stringify(this.state.valuesOriginal)
        : false;
      const valuesOriginal = refresh ? [...this.state.valuesOriginal] : null;
      this.toggleFilter();
      if (refresh) {
        this.props.onChange(valuesOriginal);
      }
    }
  };

  render(): React.ReactNode {
    const { isFilterOpen } = this.state;
    const {
      singleValue,
      aligment,
      display,
      title,
      dropdownTitle,
      dropdownDisable,
      values,
      counters,
      displayZero,
      inputFilter,      
      onDoneClick,
    } = this.props;

    if (!display) {
      return null;
    }

    return (
      <div
        className={`condo-result-filter__fast-filter-wrapper ${
          aligment === Aligment.Right ? 'right-aligment' : ''
        }`}
      >
        <div
          className={`condo-result-filter__fast-filter-top ${isFilterOpen ? 'open' : ''} ${
            values?.length ? 'selected' : ''
          }`}
          onClick={this.toggleFilter}
        >
          <div
            className={`condo-result-filter__fast-filter-top-tittle ${
              values?.length ? 'selected' : ''
            }`}
          >
            <FormattedMessage id={title} />
          </div>
          {isFilterOpen ? (
            <FontAwesomeIcon icon={faChevronUp} />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} />
          )}
        </div>
        <div
          ref={this.filterWrapperRef}
          className={`condo-result-filter__fast-filter-dropdown ${isFilterOpen ? 'open' : ''}`}
        >
          {(onDoneClick || !isEmpty(title)) ? (
            <div className="condo-result-filter__fast-filter-dropdown-bottom">
              <p className="filter__title secondary-font">
                {!isEmpty(title) && (<FormattedMessage id={title} />)}
              </p>

              {onDoneClick && (
                <BlueButton
                  onClick={() => {
                    this.toggleFilter();
                    onDoneClick();
                  }}
                >
                  <FormattedMessage id="done" />
                </BlueButton>)}
            </div>) : null}

          <div className="condo-result-filter__fast-filter-dropdown-top">
            {!singleValue ? (
              <FilterCheckbox
                disabled={dropdownDisable}
                onChange={this.props.onChange}
                values={values}
                options={counters?.map((counter: any) => this.props.counterConverter(counter))}
                displayZero={displayZero}
                inputFilter={inputFilter}
              />
            ) : (
              <FilterRadio
                disabled={dropdownDisable}
                onChange={this.props.onChange}
                value={get(values, '[0]')}
                options={counters?.map((counter: any) => this.props.counterConverter(counter))}
                displayZero={displayZero}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export const CondoResultFilter = connect()(injectIntl(CondoResultFilterComponent));
