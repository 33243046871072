import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { FormattedMessage, WrappedComponentProps, injectIntl, useIntl } from 'react-intl';

import { PaymentMethodBase, WhiteButton } from '@components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { BlueButton } from '@share/components';

import './style.scss';
import { isEmpty } from 'lodash';
import { FormInstance } from 'antd/lib/form';
import { ICardBooking, IFormErrors } from '@common-types';
import { InsuranceQuoteConfirmed } from './insurance-quote-confirmed';
import {
  insuranceQuoteActions,
  IQuoteCheckoutResponse,
  PostInsuranceCheckout,
} from '../../../../store/slices/insurance-quote';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '@share/utils';
import {
  IHotelsState,
  ILoginState,
  IWeeksDatesState,
  IWeeksLocationsState,
  IWeekState,
} from '@share/store/slices';
import { UserForm } from './user-form';
import GlobalSpinner from '../global-spinner/component';
import { toast } from 'react-toastify';
import { InsuranceConfirmedModal } from './insurance-confirmed-modal';
import { PaymentMethodBaseInsurance } from './payment-method-insurance';

export interface ICard {
  addressLine: string;
  cvv: string;
  cardNumber: string;
  cardType: string;
  city: string;
  type: string;
  country: string; // Por defecto 'US'
  state: string;
  expireDate: string;
  holderName: string;
  phone: string;
  zipCode: string;
  addPaymentMethod: boolean;
}

export interface IUser {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  street: string;
  city: string;
  postalCode: string;
  email: string;
  phone: string;
}

const INITIAL_USER: IUser = {
  id: crypto.randomUUID(), // Genera un ID único al crear un nuevo usuario,
  firstName: '',
  middleName: '',
  lastName: '',
  street: '',
  city: '',
  postalCode: '',
  email: '',
  phone: '',
};

const INITIAL_CARD: ICard = {
  addressLine: '',
  cvv: '',
  cardNumber: '',
  cardType: '',
  city: '',
  type: '',
  country: 'US',
  state: '',
  expireDate: '',
  holderName: '',
  phone: '',
  zipCode: '',
  addPaymentMethod: false,
};

interface IMapStateToProps {
  hotelsStore: IHotelsState;

  weeksLocationsStore: IWeeksLocationsState;
  weeksDatesStore: IWeeksDatesState;
  weeksStore: IWeekState;

  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  postInsuranceCheckout: (quoteId: string, policyBooking: string, users: any[], card: any) => void;
}

interface IProps extends WrappedComponentProps, IMapDispatchToProps {
  visible: boolean;
  loading: boolean;
  error: string;
  insuranceQuoteStore: IQuoteCheckoutResponse;
  onCancel: () => void;
}

const modalWidth = 800;

function InsuranceQuoteModalComponent(props: IProps) {
  const [card, setCard] = useState(INITIAL_CARD);
  const [users, setUsers] = useState<IUser[]>([]);
  const [policyBooking, setPolicyBooking] = useState<string>('');
  const [inputs, setInputs] = useState(INITIAL_USER);

  const { visible, loading, error, onCancel, insuranceQuoteStore, postInsuranceCheckout } = props;

  const formPaymentRef = React.createRef<FormInstance>();
  const formInsuranceRef = React.createRef<FormInstance>();
  const [modalZIndex, setModalZIndex] = useState(9999);

  const intl = useIntl();
  const dispatch = useDispatch();

  const reset = () => {
    setCard(INITIAL_CARD);
    formPaymentRef.current?.resetFields();
    formInsuranceRef.current?.resetFields();
  };

  const handleAddInsurance = () => {
    if (!loading && !error) {
      formPaymentRef?.current?.setFieldsValue({ address: card.addressLine });
      formPaymentRef?.current?.setFieldsValue({ city: card.city });
      formPaymentRef?.current?.setFieldsValue({ type: card.type });
      formPaymentRef?.current?.setFieldsValue({ state: card.state });
      formPaymentRef?.current?.setFieldsValue({ 'postal-code': card.zipCode });

      formInsuranceRef?.current?.setFieldsValue({
        firstName: inputs.firstName,
        middleName: inputs.middleName,
        lastName: inputs.lastName,
        street: inputs.street,
        city: inputs.city,
        postalCode: inputs.postalCode,
        email: inputs.email,
        phone: inputs.phone,
      });

      const formRefPay = formPaymentRef?.current?.validateFields();
      const formRefIns = formInsuranceRef?.current?.validateFields();

      Promise.all([formRefPay, formRefIns])
        .then(() => {
          postInsuranceCheckout(insuranceQuoteStore.quote.quoteId, policyBooking, users, card);
        })
        .catch((error: IFormErrors) => {
          const { errorFields } = error;
          console.error(errorFields);
        });
    }
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOk = () => {
    reset();
    onCancel();
    setUsers([]);
    setPolicyBooking('');
    setIsModalVisible(false); // Closed insurance main modal
  };

  const handleCancel = () => {
    setIsModalVisible(false); // closed confirmation
  };

  const onCancelStates = () => {
    setModalZIndex(modalZIndex + 1);
    setIsModalVisible(true); // open confirmation
  };

  const notifyError = (message: string) =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });


  useEffect(() => {
    if (insuranceQuoteStore.checkout.error) {
      notifyError(insuranceQuoteStore.checkout.error);
      dispatch(insuranceQuoteActions.clearCheckoutError());
    }
  }, [insuranceQuoteStore.checkout]);

  return (
    <div className="insurance-modal">
      <Modal
        className="insurance-search-modal-wrapper"
        wrapClassName="insurance-modal-wrapper__wrap"
        open={visible}
        maskClosable={false}
        footer={null}
        onCancel={() => {
          if (!loading) {
            onCancelStates(); 
          }
        }}
        closable={true}
        destroyOnClose={true}
        closeIcon={<FontAwesomeIcon icon={faTimes} />}
        width={modalWidth}
        title={' '}
        zIndex={modalZIndex - 1}
      >
        <InsuranceQuoteConfirmed insuranceQuoteStore={insuranceQuoteStore} />

        <div className="insurance-modal-wrapper__body">
          {!isEmpty(insuranceQuoteStore?.checkout?.policyNumber) && (
            <InsuranceConfirmedModal
              insuranceQuoteStore={insuranceQuoteStore}
              card={card}
              users={users}
            />
          )}

          {isEmpty(insuranceQuoteStore.checkout.policyNumber) && (
            <>
              <UserForm
                form={null}
                formRef={formInsuranceRef}
                users={users}
                setUsers={setUsers}
                policyBooking={policyBooking}
                setPolicyBooking={setPolicyBooking}
                inputs={inputs}
                setInputs={setInputs}
                USERS={INITIAL_USER}
              />
              <PaymentMethodBaseInsurance
                form={null}
                formRef={formPaymentRef}
                onDataRefreshed={() => this.setState({ refreshPayment: false })}
                card={card}
                errorsField={null}
                loading={loading}
                setCard={(c: ICardBooking) => setCard(c)}
                display
                isRequired
                skipStorage
                disableCountrySelection
                residency={true}
              />
            </>
          )}
        </div>
        {isEmpty(insuranceQuoteStore.checkout.policyNumber) && (
          <div className="insurance-modal-wrapper__footer">
            <WhiteButton
              disabled={loading || !isEmpty(error)}
              onClick={() => {
                onCancelStates();
              }}
            >
              <FormattedMessage id="cancel" />
            </WhiteButton>
            <BlueButton disabled={loading || !isEmpty(error)} onClick={handleAddInsurance}>
              <FormattedMessage id="insurance.policy.add_protection" />
            </BlueButton>
          </div>
        )}

        {!isEmpty(insuranceQuoteStore.checkout.policyNumber) && (
          <div className="insurance-modal-wrapper__footer">
            <BlueButton onClick={() => onCancelStates()}>
              <FormattedMessage id="insurance.exit" />
            </BlueButton>
          </div>
        )}

        <GlobalSpinner spinning={insuranceQuoteStore.checkout.loading} />
      </Modal>
      <Modal
        title={intl.formatMessage({ id: 'insurance.modal.exit' })}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Yes"
        cancelText="No"
        closable={false}
        style={{ padding: 0 }} 
        bodyStyle={{ display: 'none' }} 
        zIndex={modalZIndex}
        destroyOnClose={true}
      />
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    hotelsStore: state.hotelsStore,

    weeksLocationsStore: state.weeksLocationsStore,
    weeksDatesStore: state.weeksDatesStore,
    weeksStore: state.weeksStore,

    loginStore: state.loginStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  postInsuranceCheckout: (quoteId: string, policyBooking: string, users: any[], card: any) =>
    PostInsuranceCheckout(quoteId, policyBooking, users, card),
};

export const InsuranceQuoteModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(InsuranceQuoteModalComponent));
