
import { useCallback, useEffect, useState } from 'react';

import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, Tabs, Spin, TabsProps } from 'antd';

import { RENEWAL_AGENCY_EXCLUDE_RENEWAL, RENEWAL_AGENCY_INCLUDE_RENEWAL, Routes } from '@share/constants';
import { getMyProfile, IMyProfileState, IMenuState } from '@share/store/slices';
import { RootState, LoginType } from '@share/utils';
import { ILoginState } from '@share/store/slices';
import { Loading } from '@components';
import { LoadingSise } from '@common-types';

import { AddPayment } from './add-payment';
import { AuthorizedUsers } from './authorized-users';
import { UpdateProfile } from './update-profile';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
  myProfileStore: IMyProfileState;
  menuStore: IMenuState;
}

interface IMapDispatchToProps {
  get: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps, WrappedComponentProps { }

function MyProfileComponent(props: IProps) {

  const [ loadingDelete, setLoadingDelete ] = useState(false);
  const [activeKey, setActiveKey] = useState('1');

  const { myProfileStore, history, loginStore, menuStore } = props;
  const { loading, result, loadingCards } = myProfileStore;
  const { user, account } = loginStore;

  const renewalAgency = menuStore?.items?.renewalAgency as string;
  const isRenewalMember = RENEWAL_AGENCY_INCLUDE_RENEWAL.includes(renewalAgency) && !RENEWAL_AGENCY_EXCLUDE_RENEWAL.includes(renewalAgency);

  const isRemoveAuthorizedUsers = menuStore?.items?.isRemoveAuthorizedUsers;

  useEffect(() => {
    if (!loginStore.loading) {
      props.get();
    }
  }, [loginStore.loading])

  if (!account || account.type !== LoginType.PrivateWithToken) {
    history.push(Routes.NotAuthorized);
  }

  const handleTab = (key: string) => {
    setActiveKey(key);
  }

  const getItems = useCallback(() => {
    const tabsItems: TabsProps['items'] = [{ key: '1', label: <FormattedMessage id="primary" />, children: activeKey === '1' ? <UpdateProfile /> : null }];

    if (!isRemoveAuthorizedUsers) {
      tabsItems.push({
        key: '2',
        label: <FormattedMessage id={!menuStore?.items?.isMLM ? 'authorized.users' : 'partner.spouse'} />,
        children: activeKey === '2' ? <AuthorizedUsers onLoadingDelete={setLoadingDelete} /> : null
      });
    }

    tabsItems.push({ key: '3', label: <FormattedMessage id="add.payment.method" />, children: activeKey === '3' ? <AddPayment /> : null });

    return tabsItems;
  }, [menuStore?.items, activeKey]);

  return (
    <div className="my-profile-wrapper">
      <div className={`my-profile-wrapper__header ${isRenewalMember ? '' : 'isno-renewal-member'}`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-6 buttons" style={{ paddingRight: '0', position: 'relative' }}>
              <div className="my-profile-wrapper__title">
                <h1><FormattedMessage id="my.profile" /></h1>
                <span style={{ marginTop: '6px' }}>MEMBER SINCE: {result?.profile?.memberSince}</span>
              </div>
            </div>
            {!menuStore?.items?.isMLM ? (
              <div className="col-md-6 col-sm-6 col-xs-6 renewal" style={{ paddingRight: '0', position: 'relative' }}>
                <div className="my-profile-wrapper__pay-div">
                  <span className={`renew-due-by-mobile`}>RENEWAL DUE BY: {result?.profile?.expirationDate}</span>
                  {isRenewalMember ? (<Button disabled={loading} size='large' type='primary' target='blank' href={`https://www.myvcaccount.com/Account/tokenpass/${user?.keyid}?token=r3iwH708P0d6VCMwvheoDheq0ZoRD0V8`} className='btn-pay-color'>PAY YOUR RENEWAL</Button>) : null}
                  <span className={`renew-due-by ${isRenewalMember ? '' : 'isno-renewal-member'}`}>RENEWAL DUE BY: {result?.profile?.expirationDate}</span>
                </div>
              </div>) : null}
          </div>
        </div>
      </div>

      <Spin spinning={loading || loadingDelete || loadingCards} indicator={<Loading size={LoadingSise.Small} />} >
        <Tabs defaultActiveKey="1" id="tab-internal" items={getItems()} activeKey={activeKey} onTabClick={handleTab}></Tabs>
      </Spin>
    </div >
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    myProfileStore: state.myProfileStore,
    menuStore: state.navigationMenuStore,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  get: () => {
    dispatch(getMyProfile());
  }
});

const ComponentIntl = injectIntl(MyProfileComponent);

export const MyProfile = connect(mapStateToProps, mapDispatchToProps)(withRouter(ComponentIntl));
