import React from 'react';

import { isUndefined } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import NoCondoResutls from '@assets/images/no_condos_results.png';

import './style.scss';

interface IProps {
  baseUrl: string | null | undefined;
  resortsCount: number;
  optionsCount: number;
  isSpecificCondo: boolean;
}

const zeroCount = 0;

class NoAvailableCondosNotificationComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const { resortsCount, optionsCount, isSpecificCondo } = this.props;

    return (
      <div className="no-available-condos">
        <img src={NoCondoResutls} alt="" />
        <div className="no-available-condos__wrapper">
          <p className="no-available-condos__title">
          </p>
          <div className="no-available-condos__description">
            <div className="no-available-condos__support-description-wrapper">
              <p className="no-available-condos__support-description">
                <FormattedMessage id="demand.is.quite.high.for.this.area" />
              </p>
            </div>
          </div>
          {!isUndefined(resortsCount) && resortsCount !== zeroCount ? (
            <p className="no-available-condos__count">
              {!isSpecificCondo ? (
                <FormattedMessage
                  values={{
                    resortsCount: resortsCount, optionsCount: optionsCount
                  }}
                  id="alternative.condo.title.without.below"
                />
              ) : null}
            </p>
          ) : null}
        </div>
      </div>
    );
  }
}

export const NoAvailableCondosNotification = connect()(NoAvailableCondosNotificationComponent);

