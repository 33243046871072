
import { useSelector } from 'react-redux';

import BrioRciMassage from '@assets/images/brio-rci-massage.jpg';
import BrioRciCrown from '@assets/images/rci-gold-crown.png';

import { FormattedMessage } from 'react-intl';

import { RSI_UPGRADE_PRIME_PROFILE } from '@share/constants';
import { FunctionalRootState } from '@share/utils';
import { IMenuState } from '@share/store/slices';

import './style.scss';

export function EliteRciHowTo() {

  const { items }: IMenuState = useSelector((state: FunctionalRootState) => state.navigationMenuStore);

  const profile = items?.profile;
  const isRsiUpgradePrimeProfile = profile === RSI_UPGRADE_PRIME_PROFILE;

  return (
    <div className="elite-rci-how-to">
      <section className="image-bg overlay parallax pt-xs-40 pb-xs-40">
        <div className="background-image-holder" style={{ backgroundImage: `url(${BrioRciMassage})` }}>
          <img alt="image" className="background-image" src={BrioRciMassage} />
        </div>
        <div className="container">
          <div className="row mb-xs-24">
            <div className="col-md-12 col-md-offset-3 col-sm-10 col-sm-offset-1 mb16 text-center title secondary-font">
              <FormattedMessage id={isRsiUpgradePrimeProfile ? 'elite.prime.rci.main.title' : 'elite.rci.main.title'} />
            </div>
          </div>
          <div className="row mb-xs-24" style={{ padding: '10px' }}>
            <h4 className="lead text-center mb0 secondary-font"><FormattedMessage id={isRsiUpgradePrimeProfile ? 'elite.prime.rci.main.message' : 'elite.rci.main.message'} /></h4>
          </div>
        </div>
      </section>
      <section className="pt-xs-32 pb-xs-24 pt32">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 hidden-md text-center mb32">
              <img className="" src={BrioRciCrown} style={{ width: '70%' }} />
            </div>
            <div className="col-lg-8 divider">
              <p className="h4">
                <FormattedMessage id={'elite.rci.message.1'} />
              </p>
              <p className="h4">
                <FormattedMessage id={isRsiUpgradePrimeProfile ? 'elite.prime.rci.message.2' : 'elite.rci.message.2'} values={{ strong: (message: string) => (<strong>{message}</strong>) }} />
              </p>
              <p className="h4">
                <FormattedMessage id={isRsiUpgradePrimeProfile ? 'elite.prime.rci.message.3' :'elite.rci.message.3'} values={{ strong: (message: string) => (<strong>{message}</strong>) }} />
              </p>
              {!isRsiUpgradePrimeProfile && (
                <p className="h4">
                  <FormattedMessage id={'elite.rci.message.4'} />
                </p>)}
              <p className="h4">
                <FormattedMessage id={isRsiUpgradePrimeProfile ? 'elite.prime.rci.message.5' :'elite.rci.message.5'} />
              </p>
              <p className="h4">
                <FormattedMessage id={isRsiUpgradePrimeProfile ? 'elite.prime.rci.message.6' :'elite.rci.message.6'} values={{ strong: (message: string) => (<strong>{message}</strong>), strongRed: (message: string) => (<strong style={{ color: 'red' }}>{message}</strong>) }} />
              </p>
              {!isRsiUpgradePrimeProfile && (
                <p className="h4">
                  <FormattedMessage id={'elite.rci.message.7'} />
                </p>)}
            </div>
          </div>
        </div>

      </section>
    </div>
  );
}
