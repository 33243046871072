
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IHotelInfo } from '@common-types';
import { BookingErrorsEnum, ISessionKey, ITrustYouReview } from '@share/common-types';
import {
  SESSION_SEARCH_EXPIRED_MESSAGE,
  SESSION_EXPIRED_STATUS,
  Urls,
  Routes,
  SESSION_KEY_LABEL_ROOMS,
  SESSION_KEY_LABEL,
} from '@share/constants';
import { SERVER_ERROR_STATUSES } from '@share/constants';
import { getHeaders, axiosInstance, AppThunk, getHotelsRequest, UrlUtils, getSessionObj, delay, getSelectedCurrency } from '@share/utils';
import { datesActions, locationActions, roomsActions, trustYouReviewActions } from '@share/store/slices';
import { NULL_VALUE } from '@constants';

import { getRoomsDetails } from './rooms-search';
import { roomsSearchActions } from '../slices';

export interface IHotelDetailsState {
  hotel: IHotelInfo | null | undefined;
  loading: boolean;
  isServerError: boolean;
  error: string;
}

const initialState: IHotelDetailsState = {
  hotel: null,
  loading: true,
  error: '',
  isServerError: false
};

const zero = 0;

const hotelDetailsSlice = createSlice({
  name: 'hotel-details',
  initialState,
  reducers: {
    setLoading: (state: IHotelDetailsState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state: IHotelDetailsState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setHotelDetails: (state: IHotelDetailsState, { payload }: PayloadAction<IHotelInfo | null | undefined>) => {
      state.hotel = payload;
    },
    setIsServerError: (state: IHotelDetailsState, { payload }: PayloadAction<boolean>) => {
      state.isServerError = payload;
    },
  },
});

export const hotelDetailsActions = hotelDetailsSlice.actions;
export const { setLoading, setError, setHotelDetails, setIsServerError } = hotelDetailsSlice.actions;

export const hotelDetailsReducer = hotelDetailsSlice.reducer;

const getHotel = async (hotelId: number, sessionKey: ISessionKey, dealId: number, quote: string, getState: any) => {
  const { locationsStore, datesStore, roomsStore, loginStore } = getState();
  const { selectedLocation } = locationsStore;
  const { startDate, endDate } = datesStore;
  const { rooms } = roomsStore;
  const { lifeStyle } = loginStore;

  return await axiosInstance.post(
    Urls.HotelDetails,
    {
      marginator: {percentage: 0},
      sessionKey,
      hotelRequest: {
        ...getHotelsRequest(rooms, startDate, endDate, selectedLocation?.code),
        hotelId,
      },
      currency: getSelectedCurrency(loginStore.account),
      dealId,
      quote,
      lifeStyle
    },
    { ...getHeaders() },
  );
}

export const updateSavedPropertyDetails = (propertyId: number, added: boolean): AppThunk => {
  return async (dispatch, getState) => {

    const { hotelDetailsStore } = getState();
    const { hotel } = hotelDetailsStore;
     
    if (hotel) {
      const cloned = JSON.parse(JSON.stringify(hotel));
      cloned.hotelDetails.savedProperty = cloned.hotelDetails.id == propertyId ? added : cloned.savedProperty;
      dispatch(setHotelDetails(cloned));
    }
  };
};

const getHotelRetry = async (hotelId: number, sessionKey: ISessionKey, dealId: number, quote: string, getState: any
  ) => {
  try {
    return await getHotel(hotelId, sessionKey, dealId, quote, getState);
  } catch (error) {
    delay(300);
    return await getHotel(hotelId, sessionKey, dealId, quote, getState);
  }
}

export const getHotelDetails = (
  hotelId: number,
  sessionKey: ISessionKey,
  dealId?: number,
  quote?: string
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch(setError(''));
    
    dispatch(setIsServerError(false));

    dispatch(datesActions.resetSelectedDates());
    dispatch(roomsActions.resetSelectedRooms());
    dispatch(locationActions.resetSelectedLocation());

    const { loginStore } = getState();
    const { account } = loginStore;

    try {
      const res: any = await getHotelRetry(hotelId, sessionKey, dealId as number, quote as string, getState);

      dispatch(setHotelDetails(res.data));
      dispatch(
        trustYouReviewActions.setReviewModule(
          res.data?.trustYouData?.metaReview?.response as ITrustYouReview,
        ),
      );

      if (res.data?.sessionKey?.isInvalid) {
        dispatch(getRoomsDetails(hotelId, sessionKey));
      }
      else {
        const values = UrlUtils.getValues();
        const hasSessionKey = getSessionObj(values[SESSION_KEY_LABEL_ROOMS] as ISessionKey, values[SESSION_KEY_LABEL] as ISessionKey);
        
        if (isUndefined(hasSessionKey)) {
          dispatch(roomsSearchActions.setSessionKey(res.data.sessionKey));
        }
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      console.error(error);
      dispatch(setError(!isEmpty(error?.code) ? error?.code?.toString() : 'GENERIC'));
      dispatch(setLoading(false));

      if (
        error?.response?.status === SESSION_EXPIRED_STATUS &&
        error?.response?.data &&
        error?.response?.data[zero] === SESSION_SEARCH_EXPIRED_MESSAGE
      ) {
        history.replaceState(null, NULL_VALUE, `/${account.name}${Routes.Search}${location.search}`);
        location.reload();
      } else if (SERVER_ERROR_STATUSES.includes(error?.response?.status)) {
        dispatch(setIsServerError(true));
      } else if ([BookingErrorsEnum.RoomsUnavailable].includes(error?.response?.data?.code)) {
        dispatch(setError(BookingErrorsEnum.RoomsUnavailable));
      }
    }
  };
};

