
import { connect } from 'react-redux';

import LoadingImage from '@share/assets/images/loading.gif';

import './style.scss';

export enum LoadingSise {
  XSmall = 'xsmall',
  Small = 'small',
  Normal = 'normal',
  Medium = 'medium',
  Large = 'large',
  XLarge = 'xlarge'
}

interface IProps {
  size: LoadingSise;
  className?: string;
}

function LoadingComponent(props: IProps) {
  const className = `loading-image-container ${props.size !== LoadingSise.Normal ? props.size : ''}`;
  return (
    <img src={LoadingImage} className={className} alt={'loading...'} />
  );
}

export const Loading = connect()(LoadingComponent);

