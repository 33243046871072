export const TOKEN_HEADER_IS_WIDGET = 'is-widget';
export const SESSION_KEY_TOKEN_NAME_PARAM = 'key';
export const SESSION_KEY_TOKEN_NAME = 'sessionKey';
export const LIFE_STYLE_NAME_PARAM = 'lifeStyle';
export const COOKIE_TOKEN_NAME = 'mbr_Token';
export const COOKIE1_TOKEN_NAME = 'mbr_Token1';
export const UNAUTHORIZED_STATUS = 401;
export const SESSION_EXPIRATION_DELAY = 60000;
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_CONDO_SIZE = 100;
export const DEFAULT_RESIDENCY = 'US';
export const USER_BASE_URL = 'usr_base_url';
export const GIFT_CARD_FIRST_LOGIN = 'gift_card_first_login';

export const AVAILABILITY_SOLDOUT = 'SoldOut';

export const DATE_FORMAT_BASE = 'yyyy-MM-DD';
export const DATE_FORMAT_ISO = 'yyyy-MM-DDTHH:mm:ss';
export const DATE_FORMAT_UNIT_ROW = 'ddd, D MMMM, yyyy';
export const DATE_FORMAT_UNIT_ROW_SHORT = 'ddd, D MMM, yyyy';

export const BOOKING_TABLE_CURRENT_TAB = 'bookingTableCurrentTab';
export const BOOKING_TABLE_DATE_BOOKED_ON_RANGE = 'bookingTableDateBookedOnRange';
export const BOOKING_TABLE_MATCHED_BY = 'bookingTableMatchedBy';

// Getaway query keys
export const GETAWAY_LOCATION = 'getawayLocation';
export const GETAWAY_SORT_ORDER = 'getawaySortOrder';

export const GETAWAYS_LOCATION = 'getawaysLocation';
export const GETAWAYS_FILTERS = 'getawaysFilters';
export const GETAWAYS_SORT_ORDER = 'getawaysSortOrder';
export const GETAWAYS_PROMOID = 'getawaysPromoId';

export const BOOKING_SUPPLIER_TYPES_LABEL = 'bookingSupplierTypes';
export const BOOKING_SUB_STATUSES_LABEL = 'bookingSubStatuses';
export const BOOKING_TABLE_SEARCH = 'bookingTableSearch';
export const BOOKING_TABLE_SORT_ORDER = 'bookingTableSortOrder';
export const BOOKING_TABLE_DATE_CHECK_OUT_RANGE = 'bookingTableDateCheckOutRange';
export const BOOKING_TABLE_DATE_CHECK_IN_RANGE = 'bookingTableCheckInDateRange';
export const BOOKING_TABLE_PAGINATION = 'bookingTablePagination';
export const BOOKING_STATUS_LABEL = 'bookingStatus';
export const CONDO_FILTERS_LABEL = 'condoFilters';
export const CONDO_SEARCH_BOUNDS = 'condoSearchBounds';
export const CONDO_UNITS_SESSION_KEY_LABEL = 'condoUnitsSessionKeyLabel';
export const CONDO_SESSION_KEY = 'condoSessionKey';
export const CARS_SESSION_KEY_LABEL = 'carsSessionKey';
export const CONDO_UNITS_SEARCH_LABEL = 'unitSearch';
export const CONDO_GUESTS_LABEL = 'condoGuests';
export const CONDO_LOCATIONS_OBJECT_LABEL = 'condoLocationObject';
export const CONDO_LOCATIONS_LABEL = 'condoLocationLabel';
export const CONDO_IS_FLEXIBLE_LABEL = 'condoIsFlexible';
export const CONDO_DATES_LABEL = 'condoDates';
export const CONDO_DATES_FLEXIBLE_LABEL = 'condoFlexibleDates';
export const SESSION_KEY_LABEL_ROOMS = 'keySessionRooms';
export const ROOMS_SEARCH_LABEL = 'roomSearch';
export const SESSION_KEY_LABEL = 'sessionKey';
export const FILTERS_LABEL = 'filters';
export const DEFAULT_KEY_VALUE_SEPARATOR = '=';
export const ROOMS_LABEL = 'rooms';
export const LOCATION_OBJECT_LABEL = 'locationObject';
export const LOCATION_LABEL = 'locationLabel';
export const DEFAULT_SEPARATOR = '&';
export const DATES_LABEL = 'dates';
export const CARS_DATES_LABEL = 'carsDates';
export const CARS_DRIVER_LABEL = 'carsDriver';
export const CARS_FILTERS_QUICK_LABEL = 'carsFiltersQuick';
export const DEAL_HOME_LABEL = 'dealHome';
export const DEALID_LABEL = 'dealId';
export const QUOTE_LABEL = 'quote';
export const LANGUAGE_LABEL = 'lang';
export const CLIENT_CASH_LABEL = 'clientCash';
export const CLIENT_CASH_CONDO_LABEL = 'clientCashCondo';
export const CLIENT_CASH_CARS_LABEL = 'clientCashCars';
export const CLIENT_CASH_WEEKS_LABEL = 'clientCashWeeks';

export const DETAIL_TYPE_PARAM_LABEL = 'detail_type';
export const DETAIL_TYPE_STATIC = 'static';

export const CARS_PICKUP_LOCATION_OBJECT_LABEL = 'carsPickUpLocationObject';
export const CARS_PICKUP_LOCATION_LABEL = 'carsPickUpLocationLabel';
export const CARS_DROPOFF_LOCATION_OBJECT_LABEL = 'carsDropOffLocationObject';
export const CARS_DROPOFF_LOCATION_LABEL = 'carsDropOffLocationLabel';
export const CARS_FILTERS_LABEL = 'carsFilters';

export const WEEKS_FILTERS_LABEL = 'weeksFilters';
export const WEEKS_DATES_LABEL = 'weeksDates';
export const WEEKS_LOCATION_OBJECT_LABEL = 'weeksLocationObject';
export const WEEKS_LOCATION_LABEL = 'weeksLocationLabel';
export const WEEKS_SESSION_KEY_LABEL = 'weeksSessionKey';
export const WEEKS_UNITS_SEARCH_LABEL = 'weeksUnitSearch';
export const WEEKS_UNITS_SESSION_KEY_LABEL = 'weeksUnitsSessionKeyLabel';

export const CHECKIN_LABEL = 'checkin';
export const CHECKOUT_LABEL = 'checkout';
export const LATITUDE_LABEL = 'latitude';
export const LONGITUDE_LABEL = 'longitude';
export const ADULTS_LABEL = 'adults';
export const CHILDS_LABEL = 'childs';
export const REFERENCE_NUMBER_LABEL = 'referenceNumber';
export const AGENCY_NUMBER_LABEL = 'agencyNumber';
export const ACCESS_TOKEN_LABEL = 'accessToken';
export const ACCESS_TOKEN_EXPIRATION_LABEL = 'accessTokenExpiration';

export const ACCESS_TOKEN_MAX_LENGTH = 50;

export const DATE_FORMAT = 'dd MMM yyyy';
export const ENTER_KEY = 'Enter';

export const ALL_INCLUSIVE_KEY = 'all_inclusive';
export const ALL_INCLUSIVE_VALUE = 'All Inclusive';
export const GETAWAYS_KEY = 'GETAWAYS';


export const SESSION_SEARCH_EXPIRED_MESSAGE = 'SearchSession';
export const SESSION_EXPIRED_STATUS = 404;
export const SESSION_EXPIRED_MESSAGE = 'SearchResult';

export const DEFAULT_MAP_LIMIT = 10;
export const DEFAULT_MAP_LIMIT_CONDO = 100;
export const DEFAULT_PAGE_NUMBER = 1;

export const CONDO_GUESTS_ACTIONS_LABEL = 'condo-guests';
export const MEDIA_POINT = 1200;
export const DEFAULT_MEDIA_POINT = 1020;
export const MOBILE_MEDIA_POINT = 798;

export const HOTEL_RECENT_SEARCHES_LABEL = 'HOTEL_RECENT_SEARCHES_LABEL';
export const CONDO_RECENT_SEARCHES_LABEL = 'CONDO_RECENT_SEARCHES_LABEL';
export const CAR_RECENT_SEARCHES_LABEL = 'CAR_RECENT_SEARCHES_LABEL';
export const WEEKS_RECENT_SEARCHES_LABEL = 'WEEKS_RECENT_SEARCHES_LABEL';

export const DATE_FORMAT_TABLE_FILTER = 'YYYY/MM/DD';
export const DATE_FORMAT_FILTER = 'MM/DD/YYYY';

export const CHANGE_SELECTED_CURRENCY = 'mod_currency';
export const SELECTED_CURRENCY = 'selected_currency';

export const SELECTED_LANGUAGE_COUNRTY_LABEL = 'SELECTED_LANGUAGE_COUNRTY_LABEL';
export const SELECTED_LANGUAGE_LABEL = 'SELECTED_LANGUAGE_LABEL';


export const LOGGED_USER_LABEL = 'LOGGED_USER_LABEL';
export const WALLET_LOGGED_USER_LABEL = 'WALLET_LOGGED_USER_LABEL';

export const MAIN_BLUE_COLOR = '#0081FE';

export const RENEWAL_AGENCY_ASHEVILLE = 'ASHEVILLE';
export const RENEWAL_AGENCY_CLUB = 'CLUB';
export const RENEWAL_AGENCY_EXCLUDE_RENEWAL = [RENEWAL_AGENCY_CLUB];
export const RENEWAL_AGENCY_INCLUDE_RENEWAL = [RENEWAL_AGENCY_ASHEVILLE];

export const BOOKING_ID_MATCHEDBY = 'BookingId';
export const PROPERTY_NAME_MATCHEDBY = 'PropertyName';
export const LEAD_NAME_MATCHEDBY = 'LeadName';

export const LS_ANYTIME_WARNING_DISPLAYED_KEY = 'LS_ANYTIME_WARNING_DISPLAYED_KEY';

export const SERVER_ERROR_STATUSES = [500, 501, 502, 503, 504];

export const CONDOS_SELECTION_TYPE = 'Condos';
export const HOTELS_SELECTION_TYPE = 'Hotels';
export const VACATIONS_SELECTION_TYPE = 'Vacations';
export const CARS_SELECTION_TYPE = 'Cars';

export const DATE_ONLY_MONTH_FORMAT = 'dd MMM';


export const AMERICAN_EXPRESS_TYPE = "american-express";
export const DINNERS_TYPE = "diners-club";
export const DISCOVER_TYPE = "discover";
export const JCB_TYPE = "jcb";
export const MAESTRO_TYPE = "maestro";
export const MASTERCARD_TYPE = "mastercard";
export const VISA_TYPE = "visa";

export const MAX_AMOUNT_PERCENT = 100;
export const MAX_PRICE = 10000000;
export const MIN_AMOUNT = 0;
export const PERCENT_TYPE = 'Percentage';
