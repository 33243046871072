
import { useEffect } from 'react';

import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch, useLocation, withRouter } from 'react-router-dom';

import { isHomeFromPath, RootState, VACATIONS_PAGE } from '@share/utils';
import { MarkDownEnum, UserAccountEnum } from '@common-types';
import {
  ChangePassword,
  CondoWeeksWrapper,
  Dashboard,
  ErrorPage,
  ErrorPanel,
  ForgotPassword,
  GenericNotFoundPage,
  IframeBackoffice,
  Markdown,
  PrivateRoute,
  ResetPassword,
  SavedProperty,
  SendVerification,
  SignIn,
  SignUp,
  VerifyUser,
} from '@components';
import { CondoWorldMap } from '@share/components';
import { IMenuState, refreshProfile } from '@share/store/slices';
import { IPageType, Routes } from '@share/constants';
import { NULL_VALUE } from '@constants';

import { SearchPage } from '../search';
import { MemberVideosPage } from '../member-videos';
import { MemberStoryPage } from '../member-story';
import { CondoPage } from '../condo';
import { PersonalInfoPage } from '../personal-info';
import { PromotionDetailsPage } from '../promotion-details';
import { BrioRciHowToPage } from '../brio-rci-how-to';
import { EliteRciHowToPage } from '../elite-rci-how-to';
import { WebinarsPage } from '../webinars';
import { ReservationsPage } from '../reservations';
import { AccountPage } from '../account';
import { NewMembersPage } from '../new-members';
import { ExperiencesPage } from '../experiences';
import { SidekickPage } from '../sidekicks';
import { SidekickTermsPage } from '../sidekicks-terms';
import { SidekickPrivacyPage } from '../sidekicks-privacy';
import { ThreeDS } from '../three-ds';
import { CarsPage } from '../cars';

import { HotelDetailPage } from '../hotel-details';
import { GetawayPage } from '../getaway';
import { GetawayListPage } from '../getaway-list';
import { CondoDetailPage } from '../condo-details';
import { SubmitRequestPage } from '../all-inclusives/submit-request';
import { GetawayDetailsPage } from '../getaway-details';
import { CondoReviewBookPage } from '../condo-review-book';
import { ReservationCancellation } from '../reservation-cancellation';
import { HomePage } from '../home-page';
import { HomesPage } from '../homes';
import { Condominiums } from '../../components/condo/condominiums';
import { CondoSpecialsPage } from '../../components/condo/condo-specials';
import { GetawayBookingPage } from '../getaway-booking';
import { AmericanLifestylePage } from '../american-lifestyle';
import { ConciergeTravelTeamPage } from '../concierge';
import { ResourcesPage } from '../resources';
import { CruisesPage } from '../cruises';
import { DailyBenefitsPage } from '../daily-benefits';
import { LuxuryCruisesPage } from '../luxury-cruises';
import { ContactUsPage } from '../contact-us';
import { MasterClassesRegisterPage } from '../master-classes-register';
import { CarsSearchPage } from '../cars-search';
import { CarsDetailPage } from '../cars-details';

import { WeeksDetailsPage, WeeksSearchPage } from '../weeks';
import { WeeksReviewBookPage } from '../weeks/weeks-review-book';
import { AllGetawayPage } from '../all-getaway';

import GetawayCondoPage from '../getaway-condo/component';
import AllInclusivesPage from '../all-inclusives/component';
import ReviewBookPage from '../review-book/component';
import MLMBecomeTravelAgent from '../mlm/become-travel-agent/component';
import MLMNewAgents from '../mlm/new-agents/component';
import MLMExperiencedAgents from '../mlm/experienced-agents/component';
import MLMExclusiveAccess from '../mlm/exclusive-access/component';
import MLMTraining from '../mlm/training/component';
import MLMTechnology from '../mlm/technology/component';
import { StaticDetailPage } from '../static-details';
import { InsuranceSearchPage } from '../insurance';

interface IMapStateToProps {
  menuStore: IMenuState;
}

interface IMapDispatchToProps {
  refreshProfile: (pageType: IPageType) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {}

function ApplicationRoutesComponent(props: IProps) {

  const { menuStore, history, refreshProfile } = props;
  const { items } = menuStore;
  
  const location = useLocation();
  
  const loactionPath = location?.pathname;

  const refresh = () => {
    if (!!items) {
      refreshProfile(isHomeFromPath(history) ? IPageType.Home : NULL_VALUE);
    }
  }

  useEffect(() => refresh(), [loactionPath]);
  useEffect(() => refresh(), [items?.profile]);

  return (
    <Switch>
      <Route path={`/:accountUsername${Routes.Home}`} exact={true}>
        <HomePage />
      </Route>

      <Route path={`/:accountUsername${Routes.Homes}`}>
        <HomesPage />
      </Route>
      <Route path={`/:accountUsername${Routes.Experiences}`}>
        <ExperiencesPage />
      </Route>
      <Route path={`/:accountUsername${Routes.Cruises}`} exact={true}>
        <CruisesPage />
      </Route>
      <Route path={`/:accountUsername${Routes.Cars}`} exact={true}>
        <CarsPage />
      </Route>
      <Route path={`/:accountUsername${Routes.Cruises}/luxury`}>
        <LuxuryCruisesPage />
      </Route>
      {/*Old Getaways*/}
      <Route path={`/:accountUsername${Routes.Getaway}`} exact={true}>
        <GetawayPage />
      </Route>
      {/*New Getaways*/}
      <Route path={`/:accountUsername${Routes.Getaways}`} exact={true}>
        <AllGetawayPage />
      </Route>
      <Route path={`/:accountUsername${Routes.GetawayDetails}/:id`}>
        <GetawayDetailsPage />
      </Route>
      <Route path={`/:accountUsername${Routes.GetawayCondo}`} exact={true}>
        <GetawayCondoPage isInternal={false} />
      </Route>
      <Route path={`/:accountUsername${Routes.GetawayCondo}/:id`}>
        <GetawayListPage isInternal={false} />
      </Route>

      <Route path={`/:accountUsername${Routes.GetawayCondos}`} exact={true}>
        <GetawayCondoPage isInternal={true} />
      </Route>
      <Route path={`/:accountUsername${Routes.GetawayCondos}/:id`}>
        <GetawayListPage isInternal={true} />
      </Route>

      <Route path={`/:accountUsername${Routes.Getaway}/:id`}>
        <GetawayListPage isInternal={false} />
      </Route>
      <Route path={`/:accountUsername${Routes.GetawayBookingPage}`}>
        <GetawayBookingPage />
      </Route>
      <Route path={`/:accountUsername${Routes.AllInclusive}`} exact>
        <AllInclusivesPage />
      </Route>
      <Route path={`/:accountUsername${Routes.AllInclusive}/submit-request`}>
        <SubmitRequestPage />
      </Route>
      <Route path={`/:accountUsername${Routes.ReservationCancellation}`}>
        <ReservationCancellation />
      </Route>
      <Route path={`/:accountUsername${Routes.RequestCancelReservation}`}>
        <ReservationCancellation />
      </Route>
      <Route path={`/:accountUsername${Routes.AmericanLifestyle}`}>
        <AmericanLifestylePage />
      </Route>
      <Route path={`/:accountUsername${Routes.ConciergeTeam}`}>
        <ConciergeTravelTeamPage />
      </Route>
      <Route path={`/:accountUsername${Routes.Resources}`}>
        <ResourcesPage />
      </Route>
      <Route path={`/:accountUsername${Routes.DailyBenefits}`}>
        <DailyBenefitsPage />
      </Route>
      <Route path={`/:accountUsername${Routes.ContactUs}`}>
        <ContactUsPage />
      </Route>
      <Route path={`/:accountUsername${Routes.MasterClassesRegister}`}>
        <MasterClassesRegisterPage />
      </Route>
      <Route path={`/:accountUsername${Routes.Error}`} exact={true}>
        <ErrorPage />
      </Route>
      <Route path={`/:accountUsername${Routes.MemberStories}/:id`} exact={true}>
        <MemberStoryPage />
      </Route>
      <Route path={`/:accountUsername${Routes.MemberVideos}`} exact={true}>
        <MemberVideosPage />
      </Route>
      <Route path={`/:accountUsername${Routes.Condo}`} exact={true}>
        <Condominiums />
      </Route>
      <Route path={`/:accountUsername${Routes.Condo}/specials`}>
        <CondoSpecialsPage />
      </Route>
      <Route path={`/:accountUsername${Routes.BrioRciHowTo}`}>
        <BrioRciHowToPage />
      </Route>
      <Route path={`/:accountUsername${Routes.NewMembers}`}>
        <NewMembersPage />
      </Route>
      <Route path={`/:accountUsername${Routes.EliteRciHowTo}`}>
        <EliteRciHowToPage />
      </Route>
      <Route path={`/:accountUsername${Routes.Webinars}`}>
        <WebinarsPage />
      </Route>

      <Route path={`/:accountUsername${Routes.PromotionDetails}`}>
        <PromotionDetailsPage />
      </Route>
      <Route path={`/:accountUsername${Routes.PersonalInfo}`} exact={true}>
        <PersonalInfoPage activeKey={UserAccountEnum.PersonalInfo} />
      </Route>
      <Route path={`/:accountUsername${Routes.Security}`} exact={true}>
        <PersonalInfoPage activeKey={UserAccountEnum.Security} />
      </Route>
      <Route path={`/:accountUsername${Routes.PrivacyPolicy}`} exact={true}>
        <Markdown messageId={MarkDownEnum.PrivacyPolicy} />
      </Route>
      <Route path={`/:accountUsername${Routes.PriceMatch}`}>
        <Markdown messageId={MarkDownEnum.PriceMatch} />
      </Route>
      <Route path={`/:accountUsername${Routes.Faq}`}>
        <Markdown messageId={MarkDownEnum.Faq} />
      </Route>

      <PrivateRoute path={`/:accountUsername${Routes.Reservations}`} exact={true}>
        <ReservationsPage />
      </PrivateRoute>
      <PrivateRoute path={`/:accountUsername${Routes.RsiAccount}`}>
        <AccountPage key="account" />
      </PrivateRoute>
      <PrivateRoute path={`/:accountUsername${Routes.AccountReservations}`}>
        <AccountPage key="account_reservation" />
      </PrivateRoute>
      <PrivateRoute path={`/:accountUsername${Routes.RsiTestDriveManager}`}>
        <AccountPage key="account_test_drive" />
      </PrivateRoute>
      <PrivateRoute path={`/:accountUsername${Routes.RsiQuotes}/:id`}>
        <AccountPage key="account_my_qoutes" />
      </PrivateRoute>
      <PrivateRoute path={`/:accountUsername${Routes.SavedProperties}`}>
        <AccountPage key="account_saved_properties" />
      </PrivateRoute>

      <Route path={`/:accountUsername${Routes.Backoffice}`} exact={true}>
        <IframeBackoffice />
      </Route>

      <PrivateRoute path={`/:accountUsername${Routes.CondoSearch}`}>
        <CondoPage />
      </PrivateRoute>
      <PrivateRoute path={`/:accountUsername${Routes.Condo}/:id`} exact={true}>
        <CondoDetailPage />
      </PrivateRoute>
      <PrivateRoute path={`/:accountUsername${Routes.Condo}/:id/:name`} exact={true}>
        <StaticDetailPage key="static-condo" type='condo' />
      </PrivateRoute>
      <PrivateRoute path={`/:accountUsername${Routes.UnitBook}/:id`}>
        <CondoReviewBookPage />
      </PrivateRoute>
      <PrivateRoute path={`/:accountUsername${Routes.CondoSearch}/submit-request`}>
        <SubmitRequestPage isCondoPage />
      </PrivateRoute>

      <PrivateRoute path={`/:accountUsername${Routes.Search}`} exact={true}>
        <SearchPage key="hotels" />
      </PrivateRoute>
      <PrivateRoute path={`/:accountUsername${Routes.Hotel}/:id`} exact={true}>
        <HotelDetailPage key="hotels" />
      </PrivateRoute>
      <PrivateRoute path={`/:accountUsername${Routes.Hotel}/:id/:name`} exact={true}>
        <StaticDetailPage key="static-hotel" type='hotel' />
      </PrivateRoute>
      <PrivateRoute path={`/:accountUsername${Routes.Book}/:id/:packageId`} exact={true}>
        <ReviewBookPage key="hotels" />
      </PrivateRoute>
      <PrivateRoute path={`/:accountUsername${Routes.RequestCancelReservation}/:id`}>
        <ReservationCancellation />
      </PrivateRoute>
      <PrivateRoute path={`/:accountUsername${Routes.ReservationCancellation}/:id`}>
        <ReservationCancellation />
      </PrivateRoute>

      <PrivateRoute path={`/:accountUsername${Routes.CarsSearch}`}>
        <CarsSearchPage />
      </PrivateRoute>
      <PrivateRoute path={`/:accountUsername${Routes.CarsDetails}/:id`}>
        <CarsDetailPage />
      </PrivateRoute>

      <PrivateRoute path={`/:accountUsername${Routes.Search}/${VACATIONS_PAGE}`} exact={true}>
        <SearchPage isVacationRentals={true} key="vacations" />
      </PrivateRoute>
      <PrivateRoute path={`/:accountUsername${Routes.Hotel}/:id/${VACATIONS_PAGE}`} exact={true}>
        <HotelDetailPage isVacationRentals={true} key="vacations" />
      </PrivateRoute>
      <PrivateRoute
        path={`/:accountUsername${Routes.Book}/:id/:packageId/${VACATIONS_PAGE}`}
        exact={true}
      >
        <ReviewBookPage isVacationRentals={true} key="vacations" />
      </PrivateRoute>
      <PrivateRoute path={`/:accountUsername${Routes.SidekickRewards}`} exact={true}>
        <SidekickPage />
      </PrivateRoute>
      <PrivateRoute path={`/:accountUsername${Routes.SidekickRewardsTerms}`} exact={true}>
        <SidekickTermsPage />
      </PrivateRoute>
      <PrivateRoute path={`/:accountUsername${Routes.SidekickRewardsPrivacy}`} exact={true}>
        <SidekickPrivacyPage />
      </PrivateRoute>

      <Route path={`/:accountUsername${Routes.ThreeDS}`} exact={true}>
        <ThreeDS />
      </Route>

      <Route path={`/:accountUsername${Routes.Login}`} exact={true}>
        <SignIn />
      </Route>
      <Route path={`/:accountUsername${Routes.Login}/:bookingID`} exact={true}>
        <SignIn />
      </Route>
      <Route path={`/:accountUsername${Routes.SignUp}`} exact={true}>
        <SignUp />
      </Route>
      <Route path={`/:accountUsername${Routes.SignUp}/:bookingID`} exact={true}>
        <SignUp />
      </Route>

      <Route path={`/:accountUsername${Routes.VerifyUser}/:token`} exact={true}>
        <VerifyUser />
      </Route>

      <Route path={`/:accountUsername${Routes.SendVerifyEmail}`} exact={true}>
        <SendVerification />
      </Route>
      <Route path={`/:accountUsername${Routes.ForgotPassword}`} exact={true}>
        <ForgotPassword />
      </Route>
      <Route path={`/:accountUsername${Routes.ResetPassword}/:token`} exact={true}>
        <ResetPassword />
      </Route>
      <Route path={`/:accountUsername${Routes.ChangePassword}`} exact={true}>
        <ChangePassword />
      </Route>
      <Route path={`/:accountUsername${Routes.SavedPropertiesAccount}`} exact={true}>
        <SavedProperty />
      </Route>

      <Route path={`${Routes.CondosMap}`} exact={true}>
        <CondoWorldMap />
      </Route>
      <Route path={`${Routes.CondosWeeks}`} exact={true}>
        <CondoWeeksWrapper />
      </Route>

      <PrivateRoute path={`/:accountUsername${Routes.WeeksSearch}`}>
        <WeeksSearchPage />
      </PrivateRoute>
      <PrivateRoute path={`/:accountUsername${Routes.WeeksDetails}/:id`}>
        <WeeksDetailsPage />
      </PrivateRoute>
      <PrivateRoute path={`/:accountUsername${Routes.WeeksBook}/:id/:packageId`}>
        <WeeksReviewBookPage />
      </PrivateRoute>

      <PrivateRoute path={`/:accountUsername${Routes.MLMBecomeAgent}`}>
        <MLMBecomeTravelAgent />
      </PrivateRoute>
      <PrivateRoute path={`/:accountUsername${Routes.MLMNewAgents}`}>
        <MLMNewAgents />
      </PrivateRoute>
      <PrivateRoute path={`/:accountUsername${Routes.MLMExperiencedAgents}`}>
        <MLMExperiencedAgents />
      </PrivateRoute>
      <PrivateRoute path={`/:accountUsername${Routes.MLMExclusiveAccess}`}>
        <MLMExclusiveAccess />
      </PrivateRoute>
      <PrivateRoute path={`/:accountUsername${Routes.MLMTraining}`}>
        <MLMTraining />
      </PrivateRoute>
      <PrivateRoute path={`/:accountUsername${Routes.MLMTechnology}`}>
        <MLMTechnology />
      </PrivateRoute>
      <PrivateRoute path={`/:accountUsername${Routes.Dashboard}`}>
        <Dashboard />
      </PrivateRoute>

      <PrivateRoute path={`/:accountUsername${Routes.InsuranceSearch}`}>
        <InsuranceSearchPage />
      </PrivateRoute>

      <Route path={Routes.Error}>
        <ErrorPanel />
      </Route>

      <Route path="*">
        <GenericNotFoundPage />
      </Route>
    </Switch>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  refreshProfile
};

const ApplicationComponentWithRouter = withRouter(ApplicationRoutesComponent);

export const ApplicationRoutes = connect(mapStateToProps, mapDispatchToProps)(ApplicationComponentWithRouter);
