import moment from 'moment';

import { connect, useSelector } from 'react-redux';

import AIGLogo from '@assets/images/aig-logo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane, faQuestion, faSuitcase, faUsd, faUser } from '@fortawesome/free-solid-svg-icons';

import { FormattedMessage } from 'react-intl';

import { IInsuranceComplete } from '@common-types';

import './style.scss';
import { IQuoteCheckoutResponse } from '../../../../../store/slices/insurance-quote';
import { FunctionalRootState } from '@share/utils';
import { isEmpty } from 'lodash';

interface IProps {
  insurance?: IInsuranceComplete;
  insuranceQuoteStore: IQuoteCheckoutResponse;
}

function InsuranceQuoteConfirmedComponent(props: IProps) {
  const { insuranceQuoteStore } = props;

  const insuranceStore = useSelector((state: FunctionalRootState) => state.insuranceStore);
  const { insurance, travelersRequest } = insuranceStore;
  const COUNTRY_CODE = 'COUNTRY_CODE';
  const STATE_CODE = 'STATE_CODE';
  const PRODUCT_CODE = 'PRODUCT_CODE';
  const POLICY_INSURANCE_URL = `https://webservices.travelguard.com/Product/FileRetrieval.aspx?CountryCode=${COUNTRY_CODE}&StateCode=${STATE_CODE}&ProductCode=${PRODUCT_CODE}&PlanCode=NW21&FileType=PROD_PLAN_GM`;
  const DISCALIMER_URL = 'https://www.travelguard.com/legal/disclaimer';
  const policyInsuranceUrl = POLICY_INSURANCE_URL.replace(
    COUNTRY_CODE,
    travelersRequest?.residency?.isoCountry as string,
  )
    .replace(STATE_CODE, travelersRequest?.residency?.isoStateOrProvince as string)
    .replace(PRODUCT_CODE, insurance?.productDetails?.productCode as string);

  return (
    <div className="insurance-container" style={{ marginTop: '5px' }}>
      <>
        <div className="insurance-container__header">
          <div className="header-title">
            Travel Guard &#174; <FormattedMessage id="insurance.travel.insurance" />
          </div>
          <div className="header-logo">
            <img src={AIGLogo} width="60" alt="aig_logo" />
          </div>
        </div>
        <div className="insurance-container__content">
          <div className="title">
            <FormattedMessage id="insurance.cover.your.trip" />
          </div>
          <div className="sub-title">
            <FormattedMessage id="insurance.ignore.unexpected" />
          </div>
          <div className="container-fluid" style={{ marginBottom: '10px' }}>
            <div className="row">
              <div className="col-md-6 insurance-option">
                <div className="icon-insurance">
                  <FontAwesomeIcon icon={faUser} />
                </div>
                <FormattedMessage id="insurance.family.member" />
              </div>
              <div className="col-md-6 insurance-option">
                <div className="icon-insurance">
                  <FontAwesomeIcon icon={faPlane} />
                </div>
                <FormattedMessage id="insurance.miss.connection" />
              </div>
              <div className="col-md-6 insurance-option">
                <div className="icon-insurance">
                  <FontAwesomeIcon icon={faQuestion} />
                </div>
                <FormattedMessage id="insurance.lose.passport" />
              </div>
              <div className="col-md-6 insurance-option">
                <div className="icon-insurance">
                  <FontAwesomeIcon icon={faPlane} />
                </div>
                <FormattedMessage id="insurance.trip.delayed" />
              </div>
              <div className="col-md-6 insurance-option">
                <div className="icon-insurance">
                  <FontAwesomeIcon icon={faSuitcase} />
                </div>
                <FormattedMessage id="insurance.baggage.lost" />.
              </div>
              <div className="col-md-6 insurance-option">
                <div className="icon-insurance">
                  <FontAwesomeIcon icon={faUsd} />
                </div>
                <FormattedMessage id="insurance.last.minute.accomodation" />
              </div>
            </div>
          </div>
          {isEmpty(insuranceQuoteStore?.checkout?.policyNumber) ? (
            <>
              <div style={{ marginBottom: '20px' }}>
                <FormattedMessage id="insurance.message" />{' '}
                <a href={policyInsuranceUrl} target="_blank">
                  <FormattedMessage id="insurance.message.click" />
                </a>
              </div>
              <div className="yes-selection text-only">
                <label className="selection-text">
                  Your premiun price: ${insuranceQuoteStore.quote?.productDetails?.price}{' '}
                  <small>
                    (<FormattedMessage id="insurance.only.us" />)
                  </small>
                </label>
              </div>
              <div className="yes-selection-text text-only mt-2">
                <FormattedMessage
                  id="insurance.acknowledge"
                  values={{
                    ap: (msg: string) => {
                      return (
                        <a target="_blank" href={policyInsuranceUrl}>
                          <FormattedMessage id="insurance.policy.insurance" />
                        </a>
                      );
                    },
                    ai: (msg: string) => {
                      return (
                        <a target="_blank" href={DISCALIMER_URL}>
                          <FormattedMessage id="insurance.important.disclousures" />
                        </a>
                      );
                    },
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <div className="content-line">
                <label>
                  <FormattedMessage id="insurance.policy.number" />:
                </label>
                <strong>{insuranceQuoteStore.checkout?.policyNumber}</strong>
              </div>
              <div className="content-line">
                <label>
                  <FormattedMessage id="insurance.policy.price" />:
                </label>
                $ {insuranceQuoteStore.checkout?.totalPrice?.toFixed(2)}
              </div>
              <div className="content-line">
                <label>
                  <FormattedMessage id="insurance.policy.effective.date" />:
                </label>
                {moment(insuranceQuoteStore.checkout?.effectiveDate, 'yyyy-MM-DD').format(
                  'MM-DD-yyyy',
                )}
              </div>
              <div className="content-line">
                <label>
                  <FormattedMessage id="insurance.policy.product.detail" />:
                </label>
                {insuranceQuoteStore.checkout?.productDetail?.productName}
              </div>
              <div className="content-line">
                <label>
                  <FormattedMessage id="insurance.policy.policy.holder" />:
                </label>
                {`${insuranceQuoteStore.checkout?.policyHolder?.firstName} ${insuranceQuoteStore.checkout?.policyHolder?.lastName}`}
              </div>
              <div className="content-line">
                <label>
                  <FormattedMessage id="insurance.policy.policy.holder.email" />:
                </label>
                {insuranceQuoteStore.checkout?.policyHolder?.email}
              </div>
              <div style={{ marginTop: '15px' }}>
                <FormattedMessage id="insurance.policy.to_donload" />{' '}
                <a href={insuranceQuoteStore.checkout?.policyLookup} target="_blank">
                  <FormattedMessage id="insurance.message.click" />
                </a>
              </div>
              <div style={{ marginTop: '15px' }}>
                <FormattedMessage id="insurance.policy.disclaimer" />
              </div>
            </>
          )}
          {isEmpty(insuranceQuoteStore?.checkout?.policyNumber) && (
            <div className="coverage-message">
              <FormattedMessage id="insurance.coverage" />{' '}
              <a href={DISCALIMER_URL} target="_blank">
                <FormattedMessage id="insurance.coverage.click" />
              </a>
            </div>
          )}
        </div>
      </>
    </div>
  );
}

export const InsuranceQuoteConfirmed = connect()(InsuranceQuoteConfirmedComponent);
