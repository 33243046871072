import { HomesTypes } from "./homes";
import { SortTypes } from "./sort";

export const R_CLICK_HOTEL_BUTTON = 'R_CLICK_HOTEL_BUTTON';
export const R_CLICK_VAC_RENTAL_BUTTON = 'R_CLICK_VAC_RENTAL_BUTTON';
export const H_CLICK_RECENT_SEARCH = 'H_CLICK_RECENT_SEARCH';
export const H_CLICK_TOP_DEAL = 'H_CLICK_TOP_DEAL';
export const H_CLICK_LANGUAGE = 'H_CLICK_LANGUAGE';
export const H_CLICK_CURRENCY = 'H_CLICK_CURRENCY';

export const R_SORT_POPULAR = 'R_SORT_POPULAR';
export const R_SORT_SAVINGS_PER = 'R_SORT_SAVINGS_PER';
export const R_SORT_SAVINGS_AMOUNT = 'R_SORT_SAVINGS_AMOUNT';
export const R_SORT_LOWEST_PRICE = 'R_SORT_LOWEST_PRICE';
export const R_SORT_GUEST_RAT = 'R_SORT_GUEST_RAT';
export const R_SORT_HIGHEST_PRICE = 'R_SORT_HIGHEST_PRICE';
export const R_SORT_LOWEST_DISTANCE = 'R_SORT_LOWEST_DISTANCE';


export const R_VIEW_MAP = 'R_VIEW_MAP';
export const R_FILTER_CONDO = 'R_FILTER_CONDO';
export const R_FILTER_REF = 'R_FILTER_REF';
export const R_FILTER_NONREF = 'R_FILTER_NONREF';
export const R_FILTER_NAME = 'R_FILTER_NAME';
export const R_FILTER_STARS = 'R_FILTER_STARS';
export const R_FILTER_BUDGET = 'R_FILTER_BUDGET';
export const R_SELECT_ROOM = 'R_SELECT_ROOM';
export const R_CLICK_LANGUAGE = 'R_CLICK_LANGUAGE';
export const R_CLICK_CURRENCY = 'R_CLICK_CURRENCY';

export const C_R_FILTER_ALL_INCLUSIVE = 'C_R_FILTER_ALL_INCLUSIVE';
export const C_R_FILTER_KITCHEN = 'C_R_FILTER_KITCHEN';
export const C_R_FILTER_MONTH = 'C_R_FILTER_MONTH';
export const C_R_FILTER_DAY = 'C_R_FILTER_DAY';
export const C_R_FILTER_BATHROOM = 'C_R_FILTER_BATHROOM';
export const C_R_FILTER_ACCESSIBILITIES = 'C_R_FILTER_ACCESSIBILITIES';
export const C_R_FILTER_SUPPLIER = 'C_R_FILTER_SUPPLIER';
export const C_R_FILTER_CONDO_TYPE = 'C_R_FILTER_CONDO_TYPE';
export const C_R_FILTER_ACTIVITIES = 'C_R_FILTER_ACTIVITIES';
export const C_R_FILTER_FACILITIES = 'C_R_FILTER_FACILITIES';
export const C_R_FILTER_AMENITIES = 'C_R_FILTER_AMENITIES';
export const C_R_FILTER_PLACES = 'C_R_FILTER_PLACES';
export const C_R_FILTER_NEIGHBORHOODS = 'C_R_FILTER_NEIGHBORHOODS';
export const C_R_FILTER_GUEST_RATING = 'C_R_FILTER_GUEST_RATING';
export const C_R_FILTER_DISNTACE = 'C_R_FILTER_DISNTACE';
export const C_R_FILTER_BUDGET = 'C_R_FILTER_BUDGET';
export const C_R_FILTER_CITIES = 'C_R_FILTER_CITIES';

export const C_R_SELECT_ROOM = 'C_R_SELECT_ROOM';
export const C_R_SORT_POPULAR = 'C_R_SORT_POPULAR';
export const C_R_SORT_SAVINGS_PER = 'C_R_SORT_SAVINGS_PER';
export const C_R_SORT_SAVINGS_AMOUNT = 'C_R_SORT_SAVINGS_AMOUNT';
export const C_R_SORT_GUEST_RAT = 'C_R_SORT_GUEST_RAT';
export const C_R_SORT_LOWEST_PRICE = 'C_R_SORT_LOWEST_PRICE';
export const C_R_SORT_HIGHEST_PRICE = 'C_R_SORT_HIGHEST_PRICE';
export const C_R_SORT_LOWEST_DISTANCE = 'C_R_SORT_LOWEST_DISTANCE';

export const C_H_CLICK_RECENT_SEARCH = 'C_H_CLICK_RECENT_SEARCH';
export const C_H_CLICK_SEE_ALL_DESTINATIONS = 'C_H_CLICK_SEE_ALL_DESTINATIONS';

export const C_D_CLICK_IMAGES = 'C_D_CLICK_IMAGES';
export const C_D_RESERVE_BUTTON = 'C_D_RESERVE_BUTTON';
export const C_D_UPDATE_BUTTON = 'C_D_UPDATE_BUTTON';
export const C_D_VIEW_MAP_TEXT = 'C_D_VIEW_MAP_TEXT';

export const C_C_BOOKING_CONFIRMED = 'C_C_BOOKING_CONFIRMED';
export const C_C_COMPLETE_BOOKING = 'C_C_COMPLETE_BOOKING';

export const D_VIEW_MAP_TEXT = 'D_VIEW_MAP_TEXT';
export const D_UPDATE_BUTTON = 'D_UPDATE_BUTTON';
export const D_CLICK_IMAGES = 'D_CLICK_IMAGES';
export const D_VIEW_MAP_BIG = 'D_VIEW_MAP_BIG';
export const D_VIEW_ALL_ROOMS = 'D_VIEW_ALL_ROOMS';
export const D_RESERVE_BUTTON = 'D_RESERVE_BUTTON';
export const D_ACCEPT_NEW_PRICE = 'D_ACCEPT_NEW_PRICE';
export const D_DECLINE_NEW_PRICE = 'D_DECLINE_NEW_PRICE';
export const D_CLICK_LANGUAGE = 'D_CLICK_LANGUAGE';
export const D_CLICK_CURRENCY = 'D_CLICK_CURRENCY';

export const STAYC_VIEWD = 'STAYC_VIEWD';
export const STAYC_BOOKN = 'STAYC_BOOKN';

export const TA_R_CLICK_GEAR = 'TA_R_CLICK_GEAR';
export const TA_R_CLICK_SLIDER = 'TA_R_CLICK_SLIDER';
export const TA_R_CLICK_SHOW_COMM = 'TA_R_CLICK_SHOW_COMM';
export const TA_R_CLICK_SHOW_SUPPLIER = 'TA_R_CLICK_SHOW_SUPPLIER';
export const TA_R_CLICK_APPLY = 'TA_R_CLICK_APPLY';
export const TA_R_CLICK_CANCEL = 'TA_R_CLICK_CANCEL';
export const TA_D_CLICK_GEAR = 'TA_D_CLICK_GEAR';
export const TA_D_CLICK_SLIDER = 'TA_D_CLICK_SLIDER';
export const TA_D_CLICK_SHOW_COMM = 'TA_D_CLICK_SHOW_COMM';
export const TA_D_CLICK_SHOW_SUPPLIER = 'TA_D_CLICK_SHOW_SUPPLIER';
export const TA_D_CLICK_APPLY = 'TA_D_CLICK_APPLY';
export const TA_D_CLICK_CANCEL = 'TA_D_CLICK_CANCEL';


export const C_ADD_ALL_GUESTS = 'C_ADD_ALL_GUESTS';
export const C_PP = 'C_PP';
export const C_TERMS = 'C_TERMS';
export const C_COMPLETE_BOOKING = 'C_COMPLETE_BOOKING';
export const C_BOOKING_CONFIRMED = 'C_BOOKING_CONFIRMED';
export const C_ACCEPT_NEW_PRICE = 'C_ACCEPT_NEW_PRICE';
export const C_DECLINE_NEW_PRICE = 'C_DECLINE_NEW_PRICE';


export const getRefundabilityCodeByValue = (value: string[]) => {
    if (!value?.length) {
        return null;
    }

    return value[0] === 'Non-Refundable' ?
        R_FILTER_NONREF : 
        value[0] === 'Refundable' ? R_FILTER_REF : null;
}

export const getPropertyTypeCodeByValue = (value: string) => {
    return value === HomesTypes.HotelOnly ?
        R_CLICK_HOTEL_BUTTON : 
        value === HomesTypes.VacationRentalOnly ? R_CLICK_VAC_RENTAL_BUTTON : null;
}

export const getSortByCodeByValue = (value: string) => {
    return value === SortTypes.MostBooked ? 
        R_SORT_POPULAR :
        value === SortTypes.BiggestSavingsPercentage ? 
        R_SORT_SAVINGS_PER :
        value === SortTypes.BiggestSavings ? 
            R_SORT_SAVINGS_AMOUNT :
            value === SortTypes.GuestRating ?
            R_SORT_GUEST_RAT :
            value === SortTypes.PriceAsc ? 
                R_SORT_LOWEST_PRICE :
                value === SortTypes.PriceDesc? 
                    R_SORT_HIGHEST_PRICE :
                    value === SortTypes.DistanceAsc? R_SORT_LOWEST_DISTANCE : null;
}

export const getCondoSortByCodeByValue = (value: string) => {
    return value === SortTypes.MostBooked ? 
        C_R_SORT_POPULAR :
        value === SortTypes.BiggestSavingsPercentage ? 
        C_R_SORT_SAVINGS_PER :
        value === SortTypes.BiggestSavings ? 
            C_R_SORT_SAVINGS_AMOUNT :
            value === SortTypes.GuestRating ?
            C_R_SORT_GUEST_RAT :
            value === SortTypes.PriceAsc ? 
                C_R_SORT_LOWEST_PRICE :
                value === SortTypes.PriceDesc? 
                    C_R_SORT_HIGHEST_PRICE :
                    value === SortTypes.DistanceAsc? C_R_SORT_LOWEST_DISTANCE : null;
}