
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, Input, List, Select, Spin } from "antd";
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { ISavedPropertiesState, getSavedProperties, removeSavedProperties, deleteSavedProperties, ILoginState } from '@share/store/slices';
import { getAccountUsernameFromPath, RootState } from '@share/utils';
import { HotelStars, ModalConfirmation } from '@components';
import { Routes } from '@share/constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faLocation, faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import './style.scss';
import { BlueButton } from '@share/components';

const { Search } = Input;

interface IMapStateToProps {
  savedPropertiesStore: ISavedPropertiesState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  getSavedProperties: (filter: string) => void;
  removeSavedProperties: () => void;
  deleteSavedProperties: (propertyId: number) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps, WrappedComponentProps { }

function SavedPropertyComponent(props: IProps) {

  const [textSearch, setTextSearch] = useState('');
  const [filter, setFilter] = useState('created');
  const [showConfirm, setShowConfirm] = useState(false);
  const [propertySelected, setPropertySelected] = useState<number | null>(null);

  useEffect(() => {
    props.getSavedProperties(filter);
  }, [filter]);

  const handleFilterChange = (value: string) => {
    setFilter(value);
  };

  const handleRemoveAll = (e: any) => {
    e.preventDefault();
    
    setPropertySelected(null);
    setShowConfirm(true);
  };

  const handleDelete = (e: React.MouseEvent<HTMLElement, MouseEvent>, propertyId: number) => {
    e.preventDefault();

    setPropertySelected(propertyId);
    setShowConfirm(true);
  };

  const handleAction  = () => {
    if (propertySelected) {
      props.deleteSavedProperties(propertySelected);
    } else {
      props.removeSavedProperties();
    }

    setPropertySelected(null);
    setShowConfirm(false);
  }

  const HandleDetail = (e: any, type: string, id: number, name: string) => {
    e.preventDefault();
    const accountName = getAccountUsernameFromPath(props.history);
    name = name.replace(/\s+/g, '-').toLowerCase().replace(/\.|\,/g, '');
    const path = `/${accountName}${type === 'hotel' ? Routes.Hotel : Routes.Condo}/${id}/${name}`;
    window.open(path, '_blank');
  }

  const onRefresh = () => {
    props.getSavedProperties(filter);
  }

  const { intl, savedPropertiesStore, loginStore } = props;
  const { properties, loading } = savedPropertiesStore;
  const { account } = loginStore;

  const propertiesFiltered = useMemo(() => !isEmpty(textSearch) ? properties.filter(p => p.name.toLowerCase().includes(textSearch.toLowerCase())) : properties,[properties, textSearch]);

  const styleButton = useMemo(() => {
    const buttonTextColor = account?.buttonTextColor;
    const buttonBorder = account?.buttonBorder;
    const buttonBorderRadius = account?.buttonBorderRadius;
    const buttonColor = account?.buttonColor;

    const styleButtonBorderRadius = !isEmpty(buttonBorderRadius) ? { borderRadius: `${buttonBorderRadius}px` } : {};  
    const styleButtonBorder = !isEmpty(buttonBorder)? { ...styleButtonBorderRadius, border: buttonBorder } : { ...styleButtonBorderRadius };  
    const styleButtonColor = !isEmpty(buttonTextColor)? { ...styleButtonBorder, color: buttonTextColor } : { ...styleButtonBorder };  
    return !isEmpty(buttonColor)? { ...styleButtonColor, background: buttonColor } : { ...styleButtonColor };  
  }, [account]);

  const styleMainBorder = useMemo(() => {
    const mainBorderRadius = account?.mainBorderRadius;
    return !isEmpty(mainBorderRadius)? { borderRadius: mainBorderRadius } : {};  
  }, [account]);

  const styleButtonBorder = useMemo(() => {
    const buttonBorderRadius = account?.buttonBorderRadius;
    return !isEmpty(buttonBorderRadius)? { borderRadius: buttonBorderRadius } : {};  
  }, [account]);

  return (
    <section className="saved-properties">
      <div className="search-table">
        <div className="search-table__wrapper">
          <div className="search-table__header">
            <div className="search-table__actions">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-1 buttons" style={{ paddingRight: '0px', position: 'relative' }}>
                    <div className="search-table__title">
                      <h1><FormattedMessage id="saved.properties" /></h1>
                    </div>
                  </div>
                  <div className="col-md-12 buttons" style={{ paddingRight: '0px', position: 'relative' }}>
                    <div className="search-table__refresh" onClick={() => onRefresh()}>
                      <div className="action-button" style={styleButton}>
                        <FontAwesomeIcon icon={faSyncAlt} size="1x"/>
                        <span>Refresh</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ paddingTop: '16px' }}>
          <Spin spinning={loading}>
            <div className="card-body vstack gap-4">
              {!!propertiesFiltered?.length && (
                <div className="saved-properties__filters">
                  <Select
                    placeholder={'Sort by'}
                    size="large"
                    value={filter}
                    onChange={(value) => handleFilterChange(value)}
                  >
                    <Select.Option value={'created'}>Recently added</Select.Option>
                    <Select.Option value={'reviewsCount'}>Most popular</Select.Option>
                    <Select.Option value={'rating'}>Top rated</Select.Option>
                  </Select>

                  <Search value={textSearch} onChange={e => setTextSearch(e.target.value)} placeholder={intl.formatMessage({ id: 'map.search.placeholder' })} allowClear style={styleMainBorder} />

                  <Button onClick={handleRemoveAll} danger size="large" style={styleButtonBorder}>Remove all</Button>
                </div>)}

              <List
                itemLayout="vertical"
                size="large"
                pagination={{
                  pageSize: 10,
                }}
                grid={{
                  xs: 1,
                  sm: 1,
                  md: 1,
                  lg: 2,
                  xl: 2,
                  xxl: 2
                }}
                dataSource={propertiesFiltered}
                renderItem={item => (
                  <List.Item key={item.propertyId}>
                    <div className="shadow p-2 saved-properties__card" style={styleMainBorder}>
                      <div className="saved-properties__image" style={{ backgroundImage: `url(${item.imgSrc})` }}></div>

                      <div className="saved-properties__content">
                        <h5 className="card-title mb-1">{item.name}</h5>

                        <small><FontAwesomeIcon icon={faLocation} />{item.address}</small>

                        {item.rating >= 0 ? (
                          <HotelStars stars={item.rating} />) : null}

                        <div className="saved-properties__rating">
                          <h4 className="rating">{item.rating?.toFixed(1)}</h4>
                          <span className="public_price">({item.reviewsCount} reviews)</span>
                        </div>

                        <div className="saved-properties__heart" onClick={(e) => handleDelete(e, item.propertyId)}>
                          <FontAwesomeIcon icon={faHeart} color='red' size="1x" />
                        </div>

                        <div className="saved-properties__button">
                          <BlueButton onClick={(e) => HandleDetail(e, item.type, item.propertyId, item.name)}>View hotel</BlueButton>
                        </div>
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          </Spin>
        </div>

        <ModalConfirmation
          message={intl.formatMessage({ id: propertySelected ? 'delete.property.message' : 'delete.properties.message' })}
          title={intl.formatMessage({ id: propertySelected ? 'delete.property' : 'delete.properties' })}
          yesMessage={intl.formatMessage({ id: 'confirm.button' })}
          noMessage={intl.formatMessage({ id: 'cancel.button' })}
          visible={showConfirm}
          onCancel={() => setShowConfirm(false)}
          onConfirm={handleAction}
        />

      </div>
    </section>
  );
};

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    savedPropertiesStore: state.savedPropertiesStore,
    loginStore: state.loginStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getSavedProperties,
  removeSavedProperties,
  deleteSavedProperties
};

const ComponentIntl = injectIntl(SavedPropertyComponent);
export const SavedProperty = connect(mapStateToProps, mapDispatchToProps)(withRouter(ComponentIntl));