import { useMemo } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { ILoginState, IMenuState } from '@share/store/slices';
import { RootState } from '@share/utils';
import { BlueButton } from '@share/components';
import { TRAVEL_LEADERS_DOMAIN } from '@constants';
import { ITravelLeader } from '@common-types';
import { Routes } from '@share/constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
  menuStore: IMenuState;
}

interface IProps extends IMapStateToProps, WrappedComponentProps {
  item: ITravelLeader;
  index: number;
}

function TravelLeaderNormalComponent(props: IProps) {

  const { item, index, menuStore, loginStore } = props;
  const { items } = menuStore;
  const { account } = loginStore;

  const siteId = items?.siteId;
  const organizationId = items?.organizationId;

  const displayTravelLeaderBackgroundRsi = account?.displayTravelLeaderBackgroundRsi;
  const displayTravelLeaderBackgroundRsiOrgidList = account?.displayTravelLeaderBackgroundRsiOrgidList;
  const displayTravelLeaderBackgroundRsiSiteidList = account?.displayTravelLeaderBackgroundRsiSiteidList;

  const displayTravelLeadersBackground = useMemo(() => displayTravelLeaderBackgroundRsi && ((!displayTravelLeaderBackgroundRsiOrgidList?.length && !displayTravelLeaderBackgroundRsiSiteidList?.length) || 
                                                                                              displayTravelLeaderBackgroundRsiOrgidList?.includes(organizationId?.toString() as string) || 
                                                                                              displayTravelLeaderBackgroundRsiSiteidList?.includes(siteId?.toString() as string)),[account, items]);

  const stylesFontFamily: any = account?.fontFamily ? { fontFamily: account?.fontFamily } : {};
  const styles = account?.travelLeadersFontSize ? { ...stylesFontFamily, fontSize: `${account?.travelLeadersFontSize}px`, lineHeight: `${+account?.travelLeadersFontSize + 6}px` } : { ...stylesFontFamily };
  
  const image = `${TRAVEL_LEADERS_DOMAIN}${item.fileName}`;
  const backgroundImage = displayTravelLeadersBackground ? `url(${image})` : `linear-gradient(rgba(32, 39, 77, 0.3), rgba(32, 39, 77, 0.3)),url(${image})`;

  return (
    <div className="travel-leader__carousel-wrapper" key={index + item.promotionId}>
      <div
        className="travel-leader__image-wrapper"
        style={{ backgroundImage }}
      ></div>
      <div
        className="travel-leader__info-wrapper"
        style={{ background: 'transparent', width: `${window.innerWidth}px` }}
      >
        <div
          className={`travel-leader__info-title secondary-font ${displayTravelLeadersBackground ? 'background-filter' : ''}`}
          dangerouslySetInnerHTML={{ __html: item.headline }}
          style={styles}
        />
        <Link
          to={`/${account?.name}${Routes.PromotionDetails}/${item?.promotionId}`}
          className="travel-leader__info-link"
        >
          <BlueButton reverse>
            <FormattedMessage id="view.details" />
            <FontAwesomeIcon icon={faArrowRight} />
          </BlueButton>
        </Link>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore,
  };
};

export const TravelLeaderNormal = connect(mapStateToProps)(injectIntl(TravelLeaderNormalComponent));
