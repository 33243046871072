import React, { Dispatch, SetStateAction } from 'react';
import debounce from 'lodash/debounce';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { LabeledValue } from 'antd/lib/select';
import { ThunkDispatch } from 'redux-thunk';
import { FormattedMessage } from 'react-intl';
import { RootState } from '@share/utils';
import { GetawayItem, IGetawayFilters } from '@components';
import { GetGetawaysLocations, getGetawaySortOptions } from '@utils';
import {
  getGetaways,
  getGetawaysLocations,
  getawaysActions,
  IGetawaysState,
  SubCategory,
  resetGetaway,
} from '@store/slices';
import { IMenuState } from '@share/store/slices';
import {
  GetGetawaysArgs,
  IGetawaysLocations,
  defaultLocationValue,
  GetawaysLocationEnum,
  defaultLocationCountriesValue,
  GetawaysCondoSubCategoryEnum,
} from '@share/common-types';
import { DEFAULT_PAGE_SIZE, GETAWAYS_FILTERS, GETAWAYS_LOCATION, GETAWAYS_SORT_ORDER, SortTypes } from '@share/constants';
import { APP_SELECTOR } from '@constants';
import { ILoginState } from '@share/store/slices';

import { GetawayListCard } from '../getaway-list-card';
import { GetawayListSkeleton } from '../getaway-list-skeleton';
import { GetawayListCardSkeleton } from '../getaway-list-card-skeleton';
import NotGetawayToShow from '../not-getaways-to-show/component';

import { isEmpty } from 'lodash';

import './style.scss';

const ZERO = 0;
const ONE = 1;
const DEBOUNCE_TIME = 300;
const DEBOUNCE_TIME_SEARCH = 500;
const SCROLL_PIXELS = 620;

interface IMapStateToProps {
  getawaysStore: IGetawaysState;
  menuStore: IMenuState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  getGetaways: GetGetawaysArgs;
  resetGetaway: (getaway: string, subCategory: SubCategory, isLoadMore: boolean) => void;
  getGetawaysLocations: (getaway: string, subCategory: SubCategory) => void;
  setSortType: (sort: SortTypes, subCategory: SubCategory) => void;
  setFiltrationType: (type: IGetawaysLocations, subCategory: SubCategory) => void;
  setFiltrationTypeCountry: (country: IGetawaysLocations, subCategory: SubCategory) => void;
  setPageNumber: (page: number, subCategory: SubCategory) => void;
  setPromoId: (promoId: string, subCategory: SubCategory) => void;
  setGetawaysListPage: (listPage: boolean, subCategory: SubCategory) => void;
  setDefaultValues: (subCategory: SubCategory) => void;
  resetFilters: () => void;
  setActiveSubCategory: (subCategory: SubCategory) => void;
  setRequestWithData: (isRequestPopulated: boolean, subCategory: SubCategory) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  isInternal: boolean;
  condoSubcategory: string;

  isFromProperty?: boolean;
  getaway: string;

  isHome?: boolean;
  isSlider?: boolean;


  getawaysFilter: GetawayItem[];
  setGetawaysFilters: Dispatch<SetStateAction<any>>;
  sortGetawayFilters: IGetawayFilters;
  setSortGetawayFilters: Dispatch<SetStateAction<IGetawayFilters>>;
}

interface IState {
  isCondo: boolean;
  activeGetaway: string | null;
  activeCondoSubcategory: SubCategory | null;
}

class AllGetawayComponent extends React.Component<IProps, IState> {
  root: HTMLDivElement | null = document.querySelector(APP_SELECTOR);
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  prevWrapperRef: any = null;

  state: IState = {
    isCondo: false,
    activeGetaway: null,
    activeCondoSubcategory: null,
  };

  onScroll = debounce(() => {
    const { setPageNumber, getawaysStore, getGetaways, condoSubcategory, getaway } = this.props;
    const { getaways, loadingGetaways, getawaysTotalCount, loadingMoreGetaways } = getawaysStore[
      getaway === GetawaysLocationEnum.Condos || getaway === GetawaysLocationEnum.Condo
        ? (condoSubcategory as SubCategory)
        : (getaway as SubCategory)
    ];
    const category =
      getaway === GetawaysLocationEnum.Condos || getaway === GetawaysLocationEnum.Condo
        ? (condoSubcategory as SubCategory)
        : (getaway as SubCategory);

    const rootHeight = this.root?.scrollHeight ? this.root.scrollHeight : this.root?.offsetHeight;

    if (
      !loadingGetaways &&
      !loadingMoreGetaways &&
      getaways.length &&
      getaways.length !== getawaysTotalCount &&
      getaways.length % DEFAULT_PAGE_SIZE === ZERO &&
      this.root &&
      window.innerHeight + window.scrollY + SCROLL_PIXELS >= rootHeight
    ) {
      setPageNumber(undefined, category);
      getGetaways(getaway, condoSubcategory as SubCategory, true);
    }
  }, DEBOUNCE_TIME);

  componentDidMount() {
    const {
      getaway,
      condoSubcategory,
      loginStore,
      menuStore,
      setSortType,
      setFiltrationType,
      setFiltrationTypeCountry,
      setGetawaysListPage,
      getGetawaysLocations,
      setActiveSubCategory,
      setGetawaysFilters,
      setSortGetawayFilters,
    } = this.props;
    const { user, account } = loginStore;
    const { items } = menuStore;
    const queryGetawayLocatioStateStr = localStorage.getItem(GETAWAYS_LOCATION);
    const queryGetawaysFiltersStr = localStorage.getItem(GETAWAYS_FILTERS);

    const queryGetawayLocatioState = !isEmpty(queryGetawayLocatioStateStr) ? JSON.parse(queryGetawayLocatioStateStr as string) : undefined;
    const queryGetawaysFilters = !isEmpty(queryGetawaysFiltersStr) ? JSON.parse(queryGetawaysFiltersStr as string) : undefined;
    const queryGetawaySortOrder = localStorage.getItem(GETAWAYS_SORT_ORDER);

    const activeGetaway = getaway;
    const activeCondoSubcategory = condoSubcategory as SubCategory;
    const isCondo =
      activeGetaway === GetawaysLocationEnum.Condo.toLowerCase() ||
      activeGetaway === GetawaysLocationEnum.Condos.toLowerCase();
    const isRSITemplate = account?.isRSITemplate;
    const defaultGetaway = activeCondoSubcategory ?? (activeGetaway as SubCategory); //Revisar esto
    setGetawaysListPage(true, defaultGetaway);
    this.setState({ isCondo, activeGetaway, activeCondoSubcategory });

    setActiveSubCategory(activeCondoSubcategory);

    setFiltrationTypeCountry(undefined, defaultGetaway);

    if (queryGetawayLocatioState || queryGetawaySortOrder) {
      let sortGetawayFilters= {};
      if (queryGetawayLocatioState) {
        const location = queryGetawayLocatioState as IGetawaysLocations;
        setFiltrationType(location, defaultGetaway);

        const finalLocation = `${location?.city ? location.city : ''} ${location?.country ? location?.country : ''}${location?.state ? ',' : ''} ${location?.state ? location.state : ''}`;
        const finalLocationState = {
          city: location?.city ? location.city : '',
          state: location?.country ? location?.country : '',
          country: location?.state ? location.state : '',
          getaway: location?.getaway ? location?.getaway : getaway,
        };

        sortGetawayFilters = { location: finalLocation, locationState: finalLocationState };
      }

      if (queryGetawaySortOrder) {
        const sort = queryGetawaySortOrder as SortTypes;
        setSortType(sort, defaultGetaway);
        sortGetawayFilters = { ...sortGetawayFilters, sort };
      }

      setSortGetawayFilters((prevState) => ({ ...prevState, ...sortGetawayFilters }));
    }

    if (queryGetawaysFilters) {
      setGetawaysFilters(queryGetawaysFilters);
    }

    window.addEventListener('scroll', this.onScroll);

    if (user) {
      if (!isRSITemplate) {
        //Revisar esto
        getGetawaysLocations(activeGetaway, activeCondoSubcategory);
        this.handleGetaways(activeGetaway, activeCondoSubcategory);
      } else if (items) {
        getGetawaysLocations(activeGetaway, activeCondoSubcategory);
        this.handleGetaways(activeGetaway, activeCondoSubcategory);
      }
    }
  }

  handleGetaways = (
    getaway: string,
    subCategory: SubCategory,
    isLoadMore?: boolean,
    isSearch?: boolean,
  ) => {
    this.props.getGetaways(getaway, subCategory, isLoadMore, isSearch);
  };

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    const { activeGetaway, activeCondoSubcategory } = this.state;
    const {
      loginStore,
      menuStore,
      getGetawaysLocations,
      setActiveSubCategory,
      sortGetawayFilters,
      setRequestWithData,
    } = this.props;
    const { user, account } = loginStore;

    if (user) {
      const { items } = menuStore;
      const defaultGetaway = activeCondoSubcategory ?? (activeGetaway as SubCategory);
      const { sort, location, locationState, promoId } = sortGetawayFilters;
  
      const isRSITemplate = account?.isRSITemplate;
  
      if (!prevProps?.loginStore?.user && user && !isRSITemplate) {
        getGetawaysLocations(activeGetaway, activeCondoSubcategory); //Revisar esto
        this.handleGetaways(activeGetaway, activeCondoSubcategory);
        setActiveSubCategory(defaultGetaway);
      }
  
      if (!prevProps?.menuStore?.items && items && isRSITemplate) {
        getGetawaysLocations(activeGetaway, activeCondoSubcategory);
        this.handleGetaways(activeGetaway, activeCondoSubcategory);
        setActiveSubCategory(defaultGetaway);
      }
  
      // Check if the filter Sort Changes
      if (prevProps.sortGetawayFilters.sort !== sort) {
        this.onSortChange(sort as SortTypes);
      }
  
      // Check if the filter Search Changes
      if (prevProps.sortGetawayFilters.promoId !== promoId) {
        this.onSearchHandler(promoId);
  
        if (
          !isEmpty(promoId) &&
          (defaultGetaway === GetawaysLocationEnum.Cruise ||
            defaultGetaway === GetawaysLocationEnum.Fantasy ||
            defaultGetaway === GetawaysLocationEnum.NewCondos ||
            defaultGetaway === GetawaysLocationEnum.Staycation)
        ) {
          setRequestWithData(false, defaultGetaway);
        }
      }
  
      const store = this.getStore();
      if (prevProps.sortGetawayFilters.location !== location && store?.getawaysLocations?.length) {
        this.onFilterChange(locationState.city, locationState.getaway as string);
      }
  
      this.prevWrapperRef = this.wrapperRef.current;
    }
  }

  componentWillUnmount() {
    const { activeCondoSubcategory, activeGetaway } = this.state;
    const { setGetawaysListPage, setDefaultValues } = this.props;
    const defaultGetaway = activeCondoSubcategory ?? (activeGetaway as SubCategory);
    setGetawaysListPage(false, defaultGetaway as SubCategory);
    setDefaultValues(defaultGetaway as SubCategory);
    window.removeEventListener('scroll', this.onScroll);
  }

  onSearchHandler = debounce((value) => {
    const { setPromoId, setPageNumber } = this.props;
    const { activeGetaway, activeCondoSubcategory } = this.state;
    const defaultGetaway = activeCondoSubcategory ?? (activeGetaway as SubCategory);

    setPromoId(value, defaultGetaway);

    setPageNumber(ONE, defaultGetaway);
    if (!isEmpty(value) && activeGetaway !== GetawaysLocationEnum.Staycation) {
      this.handleGetaways(activeGetaway as string, activeCondoSubcategory as SubCategory, false, true);
    } else if (isEmpty(value)) {
      this.handleGetaways(activeGetaway as string, activeCondoSubcategory as SubCategory, false, true);
    } else {
      this.props.resetGetaway(activeGetaway as string, activeCondoSubcategory as SubCategory, false);
    }
  }, DEBOUNCE_TIME_SEARCH);

  getStore = () => {
    const {
      getawaysStore,
      condoSubcategory,
      getaway,
    } = this.props;
    return getawaysStore[
      getaway === GetawaysLocationEnum.Condos || getaway === GetawaysLocationEnum.Condo
        ? (condoSubcategory as SubCategory)
        : (getaway as SubCategory)
    ]
  }
  onFilterChange = (value: string, gtw: string): void => {
    const { setFiltrationType, setPageNumber } = this.props;
    const { activeGetaway, activeCondoSubcategory } = this.state;
    const defaultGetaway = activeCondoSubcategory ?? (activeGetaway as SubCategory);
    const activeFilterOption = this.getStore().getawaysLocations.find((item) => item.city.trim().includes(value.trim()));

    let selectedFiltrationType: IGetawaysLocations | null = { city: '', state: '', country: '' };

    if (activeFilterOption) {
      selectedFiltrationType = activeFilterOption.city === defaultLocationValue ? null : activeFilterOption;
    } else {
      selectedFiltrationType = null;
    }

    setFiltrationType(selectedFiltrationType as IGetawaysLocations, defaultGetaway);

    setPageNumber(ONE, defaultGetaway);

    if (!gtw || gtw === activeGetaway || gtw === 'all') {
      this.handleGetaways(activeGetaway as string, activeCondoSubcategory as SubCategory);
    } else {
      this.props.resetGetaway(activeGetaway as string, activeCondoSubcategory as SubCategory, false);
    }
  };

  onCountryFilterChange = (value: string): void => {
    const {
      getawaysStore,
      setFiltrationTypeCountry,
      setFiltrationType,
      setPageNumber,
      condoSubcategory,
      getaway,
    } = this.props;
    const { activeGetaway, activeCondoSubcategory } = this.state;
    const defaultGetaway = activeCondoSubcategory ?? (activeGetaway as SubCategory);
    const activeFilterOption = getawaysStore[
      getaway === GetawaysLocationEnum.Condos || getaway === GetawaysLocationEnum.Condo
        ? (condoSubcategory as SubCategory)
        : (getaway as SubCategory)
    ].getawaysLocationsCountries.find((item) => item.isoCountry === value);

    const selectedFiltrationType =
      activeFilterOption?.city === defaultLocationCountriesValue ? undefined : activeFilterOption;

    const getawaysLocations = GetGetawaysLocations(
      selectedFiltrationType as IGetawaysLocations,
      getawaysStore[
        getaway === GetawaysLocationEnum.Condos || getaway === GetawaysLocationEnum.Condo
          ? (condoSubcategory as SubCategory)
          : (getaway as SubCategory)
      ].getawaysLocationsOriginal,
    );
    const filtrationType = getawaysLocations?.length === 1 ? getawaysLocations[0] : undefined;

    setFiltrationTypeCountry(selectedFiltrationType as IGetawaysLocations, defaultGetaway);
    setFiltrationType(filtrationType as IGetawaysLocations, defaultGetaway);

    setPageNumber(ONE, defaultGetaway);
    this.handleGetaways(activeGetaway as string, activeCondoSubcategory as SubCategory);
  };

  onSortChange = (value: SortTypes): void => {
    const { setSortType, setPageNumber } = this.props;
    const { activeGetaway, activeCondoSubcategory } = this.state;
    const defaultGetaway = activeCondoSubcategory ?? (activeGetaway as SubCategory);
    setSortType(value, defaultGetaway);
    setPageNumber(ONE, defaultGetaway);
    this.handleGetaways(activeGetaway as string, activeCondoSubcategory as SubCategory);
  };

  getSortOptions = (): LabeledValue[] => {
    return getGetawaySortOptions();
  };

  handleScrollLeft = () => {
    const windowWidth = window.innerWidth;
    const padding = windowWidth > 580 ? 550 : windowWidth - 10;
    this.wrapperRef.current?.scrollTo({
      top: 0,
      left: this.wrapperRef.current.scrollLeft - padding,
      behavior: 'smooth',
    });
  };
  handleScrollRight = () => {
    const windowWidth = window.innerWidth;
    const padding = windowWidth > 580 ? 550 : windowWidth - 10;
    this.wrapperRef.current?.scrollTo({
      top: 0,
      left: this.wrapperRef.current.scrollLeft + padding,
      behavior: 'smooth',
    });
  };

  renderTitle = (
    getaway: string,
    activeCondoSubcategory: SubCategory,
    isHome: boolean,
    getawaysTotalCount: number,
  ) => {
    return (
      <div className={`getaway__container ${isHome ? 'home-page' : ''}`}>
        <div className="getaway__list-count_wrapper">
          <section className="getaway__list-divider-container">
            <hr />
            <section className="getaway__list-title-container">
              {' '}
              <span className="getaway-total-title">
                {activeCondoSubcategory === GetawaysCondoSubCategoryEnum.PremierEscapes ? (
                  <FormattedMessage id="getaways.condo.premium.title" />
                ) : activeCondoSubcategory === GetawaysCondoSubCategoryEnum.ValueGetaways ? (
                  <FormattedMessage id="getaways.condo.value.title" />
                ) : getaway === GetawaysLocationEnum.NewCondos ? (
                  <FormattedMessage id="condos.title" />
                ) : getaway === GetawaysLocationEnum.Staycation ? (
                  <FormattedMessage id="getaway.short.stays.title" />
                ) : getaway === GetawaysLocationEnum.Cruise ? (
                  <FormattedMessage id="getaway.cruise.title" />
                ) : getaway === GetawaysLocationEnum.Fantasy ? (
                  <FormattedMessage id="getaway.fantasy.title" />
                ) : null}
              </span>
              <span className="getaway-total-count">{` (${getawaysTotalCount} Getaways)`}</span>
            </section>
          </section>
        </div>
      </div>
    );
  };

  render(): React.ReactNode {
    const { getawaysStore, isSlider, isHome, getaway, condoSubcategory, loginStore } = this.props;
    const { activeGetaway, activeCondoSubcategory } = this.state;
    const {
      promoId,
      getaways,

      loadingGetaways,
      getawaysTotalCount,
      loadingMoreGetaways,
    } = getawaysStore[
      getaway === GetawaysLocationEnum.Condos || getaway === GetawaysLocationEnum.Condo
        ? (condoSubcategory as SubCategory)
        : (getaway as SubCategory)
    ];
    const { loading } = loginStore;

    const style: any = {};
    if (isSlider) {
      style.flexWrap = 'unset';
      style.flexDirection = 'row';
      style.overflowX = 'scroll';
    }

    const showWhenHavePromo = () => {
      if (!isEmpty(promoId)) {
        if (getaway === GetawaysLocationEnum.Staycation) {
          return false;
        }
        return true;
      }
      return true;
    };

    const { sortGetawayFilters } = this.props;
    const { locationState } = sortGetawayFilters;

    if (!isEmpty(locationState?.getaway) && !loadingGetaways && !loading) {
      if (locationState?.getaway !== activeGetaway) {
        return null;
      }

      if (getawaysTotalCount === 0) {
        return (
          <div className={isHome ? 'getaway__list-home-page' : ''}>
            <div className="getaway__list first">
              {this.renderTitle(getaway, activeCondoSubcategory as SubCategory, isHome as boolean, getawaysTotalCount)}
            </div>
            <NotGetawayToShow title="There are no getaways to display" />
          </div>
        );
      }
    }

    if (loadingGetaways || loading) {
      return (
        <div className={isHome ? 'getaway__list-home-page' : ''}>
          <div className="getaway__list first">
            <GetawayListSkeleton isSlider={isSlider} />
          </div>
        </div>
      );
    }

    return (
      <>
        {!loadingGetaways && !loading && showWhenHavePromo() && getawaysTotalCount > 0 && (
          <div className={isHome ? 'getaway__list-home-page' : ''}>
            <div className="getaway__list first">
              {this.renderTitle(getaway, activeCondoSubcategory as SubCategory, isHome as boolean, getawaysTotalCount)}
            </div>
            <div className="getaway__list last">
              <div className={`getaway__container ${isHome ? 'home-page' : ''}`}>
                <div
                  className={`getaway__list-wrapper ${isSlider ? 'is-slider' : ''}`}
                  style={style}
                  ref={this.wrapperRef}
                >
                  {getaways.map((geta, index) => (
                    <GetawayListCard
                      key={`${geta.title}-${!!geta.promoId ? geta.promoId : index}`}
                      data={geta}
                      condoSubcategory={activeCondoSubcategory}
                      activeGetaway={activeGetaway}
                      isSlider={isSlider}
                      isAllGetaway={true}
                    />
                  ))}
                  {loadingMoreGetaways && (
                    <GetawayListCardSkeleton blocks={1} isSlider={isSlider} noMarginTop />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    getawaysStore: state.getawaysStore,
    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  getGetaways: (getaway, subCategory, isLoadMore, isSearch) => {
    dispatch(getGetaways(getaway, subCategory, isLoadMore, isSearch));
  },
  resetGetaway: (getaway, subCategory, isLoadMore) => {
    dispatch(resetGetaway(getaway, subCategory, isLoadMore));
  },
  getGetawaysLocations: (getaway, subCategory) => {
    dispatch(getGetawaysLocations(getaway, subCategory));
  },
  setSortType: (sort, subCategory) => {
    dispatch(getawaysActions.setSortType({ sort, subCategory }));
  },
  setFiltrationType: (type, subCategory) => {
    dispatch(getawaysActions.setFiltrationType({ type, subCategory }));
  },
  setFiltrationTypeCountry: (country, subCategory) => {
    dispatch(getawaysActions.setFiltrationTypeCountry({ country, subCategory }));
  },
  setPageNumber: (page, subCategory) => {
    dispatch(getawaysActions.setPageNumber({ page, subCategory }));
  },
  setPromoId: (promo, subCategory) => {
    dispatch(getawaysActions.setPromoId({ promo, subCategory }));
  },
  setDefaultValues: (subCategory) => {
    dispatch(getawaysActions.setDefaultValues({ subCategory }));
  },
  setGetawaysListPage: (listPage, subCategory) => {
    dispatch(getawaysActions.setGetawaysListPage({ listPage, subCategory }));
  },
  resetFilters: () => {
    dispatch(getawaysActions.resetFilters());
  },
  setActiveSubCategory: (subCategory) => {
    dispatch(getawaysActions.setActiveGetawaySubCategory({ subCategory }));
  },
  setRequestWithData: (isRequestPopulated, subCategory) => {
    dispatch(getawaysActions.setRequestWithData({ isRequestPopulated, subCategory }));
  },
});

export const AllGetaway = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AllGetawayComponent));
