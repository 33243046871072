
import RSIUpgrade from '@share/assets/images/profiles/footer-rsi-upgrade.png';

export const RSIUpgradeEliteProfile = {
    logo: RSIUpgrade,
    logoSkipWidth: true,

    headerFontColor: '#FFFFFF',
    headerBackgroundColor: '#7A061F',

    buttonColor: '#B0231C',
    buttonTextColor: '#FFFFFF',

    buttonColorReverse: '#FFFFFF',
    buttonTextColorReverse: '#B0231C',

    buttonBorderRadius: '0',

    generalBorderRadius: '0',
    generalBorderColor: '#0079C2',
    
    isTravelLeadersImage: true,
    travelLeadersImages: [
        //{ value : 'https://travcoding-hotel-resources.s3.us-east-1.amazonaws.com/front/profiles/rsi-getaways-elite.jpg' },
        { value : 'https://travcoding-hotel-resources.s3.us-east-1.amazonaws.com/front/profiles/rsi-getaways-1.jpg' },
        { value : 'https://travcoding-hotel-resources.s3.us-east-1.amazonaws.com/front/profiles/rsi-getaways-2.jpg' },
        { value : 'https://travcoding-hotel-resources.s3.us-east-1.amazonaws.com/front/profiles/rsi-getaways-3.jpg' },
        { value : 'https://travcoding-hotel-resources.s3.us-east-1.amazonaws.com/front/profiles/rsi-getaways-4.jpg' },
        { value : 'https://travcoding-hotel-resources.s3.us-east-1.amazonaws.com/front/profiles/rsi-getaways-5.jpg' },
        { value : 'https://travcoding-hotel-resources.s3.us-east-1.amazonaws.com/front/profiles/rsi-getaways-6.jpg' },
        { value : 'https://travcoding-hotel-resources.s3.us-east-1.amazonaws.com/front/profiles/rsi-getaways-7.jpg' },
        { value : 'https://travcoding-hotel-resources.s3.us-east-1.amazonaws.com/front/profiles/rsi-getaways-8.jpg' },
        { value : 'https://travcoding-hotel-resources.s3.us-east-1.amazonaws.com/front/profiles/rsi-getaways-9.jpg' },
        { value : 'https://travcoding-hotel-resources.s3.us-east-1.amazonaws.com/front/profiles/rsi-getaways-10.jpg' },
    ],
    travelLeadersImageBackground: '#7A061F',
    travelLeadersImageTextHide: true,
    travelLeadersImageTextColor: '#FFFFFF',
    //travelLeadersImageTextPosition: 'bottom-under',
    //travelLeadersImageTextPosition: 'bottom',
    //travelLeadersImageTextPosition: 'top',
    //travelLeadersImageTextPosition: 'center',
    //travelLeadersImageText: 'rsi.upgrade.prime.hero.text',

    bogoColor: '#323232',
    bogoButtonTextColor: '#7A061F',
    getawaysColor: '#7A061F',
    getawaysButtonTextColor: '#7A061F',

    buttonColorAlternative: '#CFA241',
    buttonTextColorAlternative: '#FFFFFF',

    mainBackgroundColor: '#7A061F',
    subHeaderColor: '#000000',
    travelLeadersFontSize: '50',

    mainBorderColor: '#6D6D6D',
    mainBorderRadius: '0',
    mainHideLinkIcon: true,

    menuItemHideBorderBottom: true
}
