import './style.scss';
import { Input, Select } from 'antd';
import { ICondoStates } from '@common-types';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { InsuranceFilter } from '../../insurance-results-page';

interface IProps {
  placeholder: string;
  label: string;
  icon: any;
  value: ICondoStates[];
  setInsuranceFilter: React.Dispatch<React.SetStateAction<any>>;
  insuranceFilter: InsuranceFilter;
}

export default function InsuranceStateFilter(props: IProps) {
  const { placeholder, label, icon, value, insuranceFilter, setInsuranceFilter } = props;

  const intl = useIntl();

  const onChange = (value: string) => {
    setInsuranceFilter((prevState: any) => ({ ...prevState, state: value }));
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInsuranceFilter((prevState: any) => ({ ...prevState, state: e.target.value }));
  };

  const [state, setState] = useState<any[]>([]);

  useEffect(() => {
    let isMounted = true;

    if (value) {
      const mappedCountriesOrState =
        value?.map((item: ICondoStates, i: number) => ({
          label: item.name,
          value: item.code,
          key: i,
        })) ?? [];

      if (isMounted) {
        setState(mappedCountriesOrState); // Solo actualizar si está montado
      }
    }

    return () => {
      isMounted = false;
    };
  }, [value]);

  return (
    <div className="insurance-filters-containers">
      <div className="insurance-filters-containers-icons">
        <img src={icon} className="search-container-icons" />
      </div>
      <div className="insurance-filters-containers-inputs">
        <div className="insurance-filters-containers-inputs-title">
          <span>{label}</span>
        </div>
        <Select
          showSearch
          placeholder={placeholder}
          optionFilterProp="children"
          popupClassName="custom-insurance-dropdown"
          className="insurance-filters-containers-inputs-controls"
          onChange={onChange}
          value={insuranceFilter?.state}
          allowClear
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={state}
          style={{ display: state.length > 0 ? '' : 'none' }}
        />
        <Input
          placeholder={intl.formatMessage({ id: 'insurance.residence.input.placeholder' })}
          style={{ display: state.length === 0 ? '' : 'none' }}
          className="insurance-filters-containers-inputs-controls"
          value={insuranceFilter?.state ?? ''}
          onChange={onChangeInput}
        />
      </div>
    </div>
  );
}
