
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { Carousel } from 'antd';
import { connect } from 'react-redux';

import { ILoginState, IMenuState } from '@share/store/slices';
import { RootState } from '@share/utils';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
  menuStore: IMenuState;
}

interface IProps extends IMapStateToProps, WrappedComponentProps {
}

const SPEED = 1500;
const SLIDES_TO_SHOW = 1;
const SLIDES_TO_SCROLL = 1;
const DefaultHeroImage = 'https://travcoding-hotel-resources.s3.us-east-1.amazonaws.com/front/profiles/rsi-upgrade-travel-leader-hero.png';

const propsCarousel = {
  dots: false,
  infinite: true,
  arrows: true,
  speed: SPEED,
  slidesToShow: SLIDES_TO_SHOW,
  slidesToScroll: SLIDES_TO_SCROLL,
  autoplay: true,
};

function TravelLeaderFixedImagesComponent(props: IProps) {

  const { loginStore } = props;
  const { account } = loginStore;

  const travelLeadersImages = account?.travelLeadersImages;
  const travelLeadersImageBackground = account?.travelLeadersImageBackground;
  const travelLeadersImageText = account?.travelLeadersImageText;
  const travelLeadersImageTextColor = account?.travelLeadersImageTextColor;
  const travelLeadersImageTextFamily = account?.travelLeadersImageTextFamily;
  const travelLeadersImageTextPosition = account?.travelLeadersImageTextPosition;
  const travelLeadersImageTextHide = account?.travelLeadersImageTextHide;
  
  const styles: any = !isEmpty(travelLeadersImageBackground) ? { backgroundColor: travelLeadersImageBackground } : {};
  const styleFont: any = !isEmpty(travelLeadersImageTextColor) ? { color: travelLeadersImageTextColor } : { color: '#000000' };

  const images = travelLeadersImages?.length ? travelLeadersImages : [{ value: DefaultHeroImage, text: null }];

  const renderText = (text?: string | null) => {
    if (travelLeadersImageTextHide) {
      return null;
    }

    const centered = travelLeadersImageTextPosition === 'center';
    const top = travelLeadersImageTextPosition === 'top';
    const bottom = travelLeadersImageTextPosition === 'bottom';
    const bottomUnder = travelLeadersImageTextPosition === 'bottom-under';
    
    return (
      <label className={`travel-leader-fixed-image__font ${centered ? 'center' : top ? 'top' : bottom ? 'bottom' : bottomUnder ? 'bottom-under' : ''} ${!isEmpty(travelLeadersImageTextFamily) ? travelLeadersImageTextFamily : ''}`} style={styleFont}>
        <FormattedMessage id={(text && !isEmpty(text)) ? text : (travelLeadersImageText && !isEmpty(travelLeadersImageText)) ? travelLeadersImageText : 'rsi.upgrade.hero.text'} />
      </label>);
  }

  return (
    <div className="travel-leader-fixed-image"  style={styles}>
      <div className="travel-leader-fixed-image__content">
        {images.map((item, index) => <img key={`image_${index}`} src={item?.value} style={{ display: 'none' }} />)}
        
        <Carousel {...propsCarousel} className="images-carousel" lazyLoad="ondemand">
          {images.map((item, index) => {
            return (
              <div key={`travel_leader_images_${index}`} className="travel-leader-fixed-image__image carousel">
                {renderText(item?.text)}
                <img src={item?.value} />
              </div>);
          })}
        </Carousel>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore,
  };
};

export const TravelLeaderFixedImages = connect(mapStateToProps)(injectIntl(TravelLeaderFixedImagesComponent));
