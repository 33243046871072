import { connect } from 'react-redux';

import './style.scss';
import { Form, FormInstance, Input, List, Modal, Select } from 'antd';
import { RefObject, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { WhiteButton } from '@components';
import { BlueButton } from '@share/components';
import { isEmpty } from 'lodash';
import { CloseOutlined } from '@ant-design/icons';
import { IUser } from '../component';
import { setNumberPhone } from '@utils';

interface IProps {
  form: FormInstance;
  formRef: RefObject<FormInstance>;
  users: IUser[];
  setUsers: React.Dispatch<React.SetStateAction<any[]>>;
  policyBooking: string;
  setDisabledPolicy: React.Dispatch<React.SetStateAction<any[]>>;
  setPolicyBooking: React.Dispatch<React.SetStateAction<any>>;
  inputs: IUser;
  setInputs: React.Dispatch<React.SetStateAction<IUser>>;
  USERS: IUser;
}

function UserFormComponent(props: IProps) {
  const {
    form,
    formRef,
    users,
    setUsers,
    policyBooking,
    setPolicyBooking,
    inputs,
    setInputs,
    USERS,
  } = props;

  const [disabledPolicy, setDisabledPolicy] = useState<boolean>(false);

  const { firstName, middleName, lastName, street, city, postalCode, email, phone } = inputs;

  const intl = useIntl();
  const { Option } = Select;

  const onChangeFilters = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'phone') {
      setInputs((prevState) => ({
        ...prevState,
        [name]: setNumberPhone(value),
      }));
    } else {
      setInputs((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
const validateUserFields = (user: IUser) => {
  const filteredEntries = Object.entries(user).filter(([key]) => key !== 'middleName'); // Ignore middleName (optional)

  return filteredEntries.every(([key, value]) => {
    if (key === 'email') {
      // Verify if is an email
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(value);
    }
    // Verify al the fields aren't empty
    return !isEmpty(value) && !isEmpty(policyBooking);
  });
};
  const addUser = () => {
    setUsers((prevState) => [
      ...prevState,
      { ...inputs, phone: inputs.phone.split('-').join(''), id: crypto.randomUUID() },
    ]);
    setInputs(USERS);
    setDisabledPolicy(true);
  };

  const listUsers = users.map((u: any) =>
    `${u.firstName} ${u.middleName || ''} ${u.lastName}`.trim(),
  );

  const removeUserList = (id: string) => {
    const newUsers = users.filter((u: any) => u.id !== id);
    setUsers(newUsers);
  };

  
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUserIndex, setisUserIndex] = useState<number | undefined>(undefined);

  const handleOk = () => {
    if (isUserIndex === 0 || isUserIndex) {
      removeUserList(users[isUserIndex].id); // Usa el ID para eliminar}
    }
    setIsModalVisible(false); // Closed insurance main modal
    setisUserIndex(undefined); // Resetea el índice
  };

  const handleCancel = () => {
    setIsModalVisible(false); // closed confirmation
  };

  const showDeleteModal = (index:number) => {
    setisUserIndex(index);
    setIsModalVisible(true); // open confirmation
  };

  return (
    <div className={`insurance-user pt-2`}>
      <div className="insurance-user__header pb-2">
        <h4 className="insurance-user__title">User Insurance Information</h4>
      </div>

      <div>
        <Form
          name="insurance_user_rule"
          className={`insurance-user__rule full-width`}
          form={form}
          ref={formRef}
        >
          <Form.Item
            name="policyBooking"
            rules={[
              {
                required: users.length === 0,
                message: intl.formatMessage({ id: 'insurance.error.reservation.number' }),
              },
            ]}
          >
            <div className={`insurance-user__input-wrapper rounded-border`}>
              <span className="insurance-user__input-label">
                {intl.formatMessage({ id: 'insurance.reservation.number' })}
              </span>

              <div className="insurance-user__input">
                <Input
                  placeholder={intl.formatMessage({ id: 'insurance.reservation.number' })}
                  maxLength={30}
                  name="policyBooking"
                  value={policyBooking}
                  onChange={(e) => setPolicyBooking(e.target.value)}
                  disabled={disabledPolicy && users.length > 0 ? true : false}
                  autoComplete="off"
                />
              </div>
            </div>
          </Form.Item>
          <div className="insurance-user__three-columns">
            <Form.Item
              name="firstName"
              rules={[
                {
                  required: users.length === 0,
                  message: intl.formatMessage({ id: 'insurance.error.firstname' }),
                },
              ]}
            >
              <div className={`insurance-user__input-wrapper rounded-border`}>
                <span className="insurance-user__input-label">
                  {' '}
                  {intl.formatMessage({ id: 'first.name' })}
                </span>

                <div className="insurance-user__input">
                  <Input
                    placeholder={intl.formatMessage({ id: 'first.name' })}
                    name="firstName"
                    value={firstName}
                    onChange={onChangeFilters}
                    autoComplete="off"
                  />
                </div>
              </div>
            </Form.Item>

            <Form.Item name="middleName">
              <div className={`insurance-user__input-wrapper rounded-border`}>
                <span className="insurance-user__input-label">
                  {intl.formatMessage({ id: 'insurance.middle.name' })}
                </span>

                <div className="insurance-user__input">
                  <Input
                    placeholder={intl.formatMessage({ id: 'insurance.middle.name' })}
                    name="middleName"
                    value={middleName}
                    onChange={onChangeFilters}
                    autoComplete="off"
                  />
                </div>
              </div>
            </Form.Item>
            <Form.Item
              name="lastName"
              rules={[
                {
                  required: users.length === 0,
                  message: intl.formatMessage({ id: 'insurance.error.last.name' }),
                },
              ]}
            >
              <div className={`insurance-user__input-wrapper rounded-border`}>
                <span className="insurance-user__input-label">
                  {intl.formatMessage({ id: 'last.name' })}
                </span>

                <div className="insurance-user__input">
                  <Input
                    placeholder={intl.formatMessage({ id: 'last.name' })}
                    name="lastName"
                    value={lastName}
                    onChange={onChangeFilters}
                    autoComplete="off"
                  />
                </div>
              </div>
            </Form.Item>
          </div>
          <p className="insurance-user__address-title">
            <FormattedMessage id="address" />:
          </p>
          <div className="insurance-user__three-columns">
            <Form.Item
              name="street"
              rules={[
                {
                  required: users.length === 0,
                  message: intl.formatMessage({ id: 'insurance.error.street' }),
                },
              ]}
            >
              <div className={`insurance-user__input-wrapper rounded-border`}>
                <span className="insurance-user__input-label">
                  {' '}
                  <FormattedMessage id="insurance.street" />
                </span>

                <div className="insurance-user__input">
                  <Input
                    placeholder={intl.formatMessage({ id: 'insurance.street' })}
                    name="street"
                    value={street}
                    onChange={onChangeFilters}
                    autoComplete="off"
                  />
                </div>
              </div>
            </Form.Item>
            <Form.Item
              name="city"
              rules={[
                {
                  required: users.length === 0,
                  message: intl.formatMessage({ id: 'insurance.error.city' }),
                },
              ]}
            >
              <div className={`insurance-user__input-wrapper rounded-border`}>
                <span className="insurance-user__input-label">
                  {intl.formatMessage({ id: 'city' })}
                </span>

                <div className="insurance-user__input">
                  <Input
                    placeholder={intl.formatMessage({ id: 'city' })}
                    name="city"
                    value={city}
                    onChange={onChangeFilters}
                    autoComplete="off"
                  />
                </div>
              </div>
            </Form.Item>

            <Form.Item
              name="postalCode"
              className={`billing-address`}
              rules={[
                {
                  required: users.length === 0,
                  message: intl.formatMessage({ id: 'error.message.postal.code' }),
                },
              ]}
            >
              <div className={`insurance-user__input-wrapper shrink-input rounded-border`}>
                <span className="insurance-user__input-label">
                  <FormattedMessage id="postal-code" />
                </span>

                <div className="insurance-user__input">
                  <Input
                    placeholder={intl.formatMessage({ id: 'postal.code.label' })}
                    maxLength={50}
                    name="postalCode"
                    onChange={onChangeFilters}
                    value={postalCode}
                    autoComplete="off"
                  />
                </div>
              </div>
            </Form.Item>
          </div>
          <p className="insurance-user__address-title">
            {intl.formatMessage({ id: 'insurance.aditional.information' })}:
          </p>
          <div className="insurance-user__duo-columns">
            <Form.Item
              name="email"
              rules={[
                {
                  required: users.length === 0,
                  message: intl.formatMessage({ id: 'insurance.error.email' }),
                },
                {
                  type: 'email',
                  message: intl.formatMessage({ id: 'insurance.error.email' }),
                },
              ]}
            >
              <div className={`insurance-user__input-wrapper rounded-border`}>
                <span className="insurance-user__input-label">
                  {intl.formatMessage({ id: 'email' })}
                </span>

                <div className="insurance-user__input">
                  <Input
                    placeholder={intl.formatMessage({ id: 'email' })}
                    name="email"
                    value={email}
                    onChange={onChangeFilters}
                    type="email"
                    autoComplete="off"
                  />
                </div>
              </div>
            </Form.Item>

            <Form.Item
              name="phone"
              className="phone-number"
              rules={[
                {
                  required: users.length === 0,
                  message: intl.formatMessage({ id: 'insurance.error.phone' }),
                },
              ]}
            >
              <div className={`insurance-user__input-wrapper rounded-border`}>
                <span className="insurance-user__input-label">
                  <FormattedMessage id="phone" />
                </span>

                <div className="insurance-user__input">
                  <Input
                    placeholder={intl.formatMessage({ id: 'phone' })}
                    name="phone"
                    value={phone}
                    onChange={onChangeFilters}
                    autoComplete="off"
                    type="tel"
                    maxLength={12}
                    minLength={12}
                  />
                </div>
              </div>
            </Form.Item>
          </div>
          <div className="insurance-user__btns">
            <WhiteButton
              onClick={() => setInputs(USERS)}
              className="insurance-user__btns-white-button"
              style={{ border: '1px solid #0081fe' }}
            >
              <FormattedMessage id="reset" />
            </WhiteButton>
            <BlueButton
              className="insurance-user__btns-blue-button"
              onClick={addUser}
              disabled={!validateUserFields(inputs)}
            >
              <FormattedMessage id="insurance.add.user" />
            </BlueButton>
          </div>
          {users.length > 0 && (
            <>
              <p className="insurance-user__address-title">
                {intl.formatMessage({ id: 'insurance.added.users' })}:
              </p>
              <List
                size="small"
                bordered
                dataSource={listUsers}
                className="mb-4"
                renderItem={(item, index) => (
                  <List.Item
                    actions={[
                      <CloseOutlined
                        onClick={() => showDeleteModal(index)}
                        style={{ cursor: 'pointer' }}
                      />,
                    ]}
                  >
                    {item}
                  </List.Item>
                )}
              />
            </>
          )}
        </Form>
        <Modal
          title={
            isUserIndex !== undefined
              ? `${intl.formatMessage({ id: 'insurance.delete.user' })}: ${
                  users[isUserIndex]?.firstName
                } ${users[isUserIndex]?.lastName}?`
              : intl.formatMessage({ id: 'insurance.delete.user' })
          }
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Yes"
          cancelText="No"
          destroyOnClose={true}
          closable={false} 
          style={{ padding: 0 }} 
          bodyStyle={{ display: 'none' }}
        />
      </div>
    </div>
  );
}

export const UserForm = connect()(UserFormComponent);
