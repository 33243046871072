
import { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Carousel } from 'antd';
import { connect, useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { ITravelLeaderState, getTravelLeader } from '@store/slices';
import { ILoginState, IMenuState } from '@share/store/slices';
import { FunctionalRootState } from '@share/utils';
import { ITravelLeader } from '@common-types';

import { TravelLeaderNormal } from './travel-leader-normal';
import { TravelLeaderFixedImages } from './travel-leader-fixed-image';

import './style.scss';

const SPEED = 1000;
const SLIDES_TO_SHOW = 1;
const SLIDES_TO_SCROLL = 1;

function TravelLeaderComponent() {

  const dispatch = useDispatch();

  const { account }: ILoginState = useSelector((state: FunctionalRootState) => state.loginStore);
  const { travelLeader, loading }: ITravelLeaderState = useSelector((state: FunctionalRootState) => state.travelLeaderStore);
  
  const menuStore: IMenuState = useSelector((state: FunctionalRootState) => state.navigationMenuStore);

  const propsCarousel = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: SPEED,
    slidesToShow: SLIDES_TO_SHOW,
    slidesToScroll: SLIDES_TO_SCROLL,
    autoplay: true,
  };

  const isTravelLeadersImage = account?.isTravelLeadersImage;
  const travelLeadersImageTextPosition = account?.travelLeadersImageTextPosition;

  const isDataAvailable = isTravelLeadersImage || (travelLeader && travelLeader.length > 0);
  const isBottomUnderTextPosition = !isEmpty(travelLeadersImageTextPosition) && travelLeadersImageTextPosition === 'bottom-under';

  useEffect(() => {
    dispatch(getTravelLeader());
  }, []);
  
  return isTravelLeadersImage || (isDataAvailable && !loading && !menuStore?.loading) ? (
    <div className={`travel-leader ${isTravelLeadersImage ? 'fixed-image' : ''} ${!isBottomUnderTextPosition ? 'text-centered' : ''}`}>
      {isTravelLeadersImage ? (
        <TravelLeaderFixedImages />) : (
        <Carousel {...propsCarousel} lazyLoad="ondemand">
          {travelLeader.map((item: ITravelLeader, i: number) => {
            return <TravelLeaderNormal item={item} index={i} />;
          })}
        </Carousel>)}
    </div>
  ) : null;
}

export const TravelLeader = connect()(injectIntl(TravelLeaderComponent));
