import { useEffect, useRef, useState } from 'react';

import { Modal, Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';


import { RootState } from '@share/utils';
import { ILocationsState, ILoginState } from '@share/store/slices';
import { ILocation } from '@share/common-types';
import { USA } from '@share/constants';
import { NULL_VALUE } from '@constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

const MODAL_WIDTH = 800;

interface IMapStateToProps {
  loginStore: ILoginState;
  locationsStore: ILocationsState;
}

interface IProps extends IMapStateToProps {
  visible: boolean;

  onSelect: (location: ILocation) => void;
  onQuick: () => void;
  onCancel: () => void;
}

function ModalAllInclusiveDestinationsComponent(props: IProps) {

  const [countrySelected, setCountrySelected] = useState('');
  const [stateSelected, setStateSelected] = useState('');

  const countriesWrapperRef = useRef<HTMLDivElement>(null);
  const statesWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setCountrySelected(NULL_VALUE);
  }, [])

  const handleSelectCountryChange = (countrySelected: string) => {
    setStateSelected(NULL_VALUE);
    setCountrySelected(countrySelected);
  }

  const handleSelectStateChange = (stateSelected: string) => {
    setStateSelected(stateSelected);
  }

  const handleQuick = (location: ILocation) => {
    props.onSelect(location);
    props.onCancel();
    setStateSelected(NULL_VALUE);
    setCountrySelected(NULL_VALUE);
  }

  const getPopupContainer = () => {
    const element = countriesWrapperRef ? countriesWrapperRef.current : document.body;
    return element as HTMLElement;
  };

  const getPopupContainerStates = () => {
    const element = statesWrapperRef ? statesWrapperRef.current : document.body;
    return element as HTMLElement;
  };


  const { visible, locationsStore, loginStore, onCancel } = props;
  const { allInclusiveDestinations, allInclusiveDestinationCountries, allInclusiveDestinationCountryStates } = locationsStore;

  const { account } = loginStore;

  let allDestinationsFiltered = [...allInclusiveDestinations];
  let allDestinationsCountries = allInclusiveDestinationCountries;
  let allDestinationsCountryStates = allInclusiveDestinationCountryStates

  if (!isEmpty(countrySelected)) {
    allDestinationsFiltered = allDestinationsFiltered.filter(d => d.country?.toLowerCase().includes(countrySelected.toLowerCase()));
  }

  if (!isEmpty(stateSelected)) {
    allDestinationsFiltered = allDestinationsFiltered.filter(d => d.state?.toLowerCase().includes(stateSelected.toLowerCase()));
  }

  allDestinationsFiltered.sort((a, b) => a.name?.localeCompare(b.name));

  const displayState = [USA].includes(countrySelected);
  const displayList = !isEmpty(countrySelected) && (!displayState || !isEmpty(stateSelected));
  const displayListMessage = isEmpty(countrySelected) ? 'see.all.destinations.select_country' : (displayState && isEmpty(stateSelected)) ? 'see.all.destinations.select_state' : '';
  const states = displayState ? allDestinationsCountryStates[countrySelected] : null;

  const fontFamily = account?.fontFamily;
  const secondaryFontFamily = account?.secondaryFontFamily;

  return (
    <Modal
      className={`modal-allinclusive-destinations ${fontFamily ? `${fontFamily}-font`: ''} ${secondaryFontFamily ? `secondary-${secondaryFontFamily}-font`: ''}`}
      open={visible}
      footer={null}
      onCancel={onCancel}
      width={MODAL_WIDTH}
      wrapClassName="modal-allinclusive-destinations__wrapper"
    >
      <div>
      <h4 className="modal-allinclusive-destinations__title secondary-font">
          <FormattedMessage id="see.all.destinations.title" />
        </h4>
        <h4 className="modal-allinclusive-destinations__sub-title secondary-font">
          <FormattedMessage id="see.all.destinations.destination.by.country" />
        </h4>
        <div className="modal-allinclusive-destinations__container">
          <div className="modal-allinclusive-destinations__filters" ref={countriesWrapperRef}>
            <Select value={countrySelected} onChange={handleSelectCountryChange} placeholder={<FormattedMessage id="select.country" />} getPopupContainer={getPopupContainer}>
              {allDestinationsCountries?.map((country:any, index:any) => <Select.Option key={`all_destinations_country_${index}`} value={country}>{country}</Select.Option>)}
            </Select>

            {isEmpty(countrySelected) ?
                null : (
                <FontAwesomeIcon icon={faTimesCircle} className="filters-search-icon filters-search-icon__clear" onClick={() => {setStateSelected(NULL_VALUE), setCountrySelected(NULL_VALUE)}} />)}
          </div>

          {displayState && (
            <div className="modal-allinclusive-destinations__filters" ref={statesWrapperRef}>
              <Select value={stateSelected} onChange={handleSelectStateChange} placeholder={<FormattedMessage id="select.by.state" />} getPopupContainer={getPopupContainerStates}>
                {states?.map((state: string, index: number) => <Select.Option key={`all_destinations_state_${index}`} value={state}>{state}</Select.Option>)}
              </Select>

              {isEmpty(stateSelected) ?
                null : (
                <FontAwesomeIcon icon={faTimesCircle} className="filters-search-icon filters-search-icon__clear" onClick={() => setStateSelected(NULL_VALUE)} />)}
            </div>)}
        </div>

        <div className="modal-allinclusive-destinations__destinations">
          <div className="container-fluid">
            <div className="row">
              {displayList ?
                allDestinationsFiltered.map((dest, index) => (
                  <div key={`destinations-group_${index}`} className="col-lg-4">
                    <label style={{ display: 'flex', position: 'relative' }}>
                      <div>
                        <span className="modal-allinclusive-destinations__destination-item" onClick={() => handleQuick(dest.location)}>{dest.name}</span>
                      </div>
                    </label>
                  </div>)) : <FormattedMessage id={displayListMessage} />}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    locationsStore: state.locationsStore
  };
};

export const ModalAllInclusiveDestinations = connect(mapStateToProps)(ModalAllInclusiveDestinationsComponent);
