import React from 'react';
import upperCase from 'lodash/upperCase';
import Cookies from 'js-cookie';

import { Drawer, Menu, Button, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import {
  RootState,
  isExternalUrl,
  getPreferenceFromAccount,
  getLocalURI,
  isAnyReservationCancelFromPath,
  IsImpersonating,
  isBookFromPath,
  DecodeTokenData,
  isSignInFromPath,
  isSignUpFromPath,
  VACATIONS_PAGE,
  GetHomeParams,
  GetWalletNumber,
  isCondoEngineFromPath,
  GetDisableNewTabOpen,
} from '@share/utils';
import { ILoginState, logout, setLoginRedirect } from '@share/store/slices';
import { getPreferenceFileUrlFromAccount, LoginType } from '@share/utils';
import {
  HOME_PAGE_URL_FIELD_KEY,
  IAccount,
  ISideKickStatus,
  LOGOUT_FIELD_KEY,
  LOGO_FIELD_KEY,
} from '@share/common-types';
import { COOKIE1_TOKEN_NAME, COOKIE_TOKEN_NAME, Routes, USER_BASE_URL } from '@share/constants';
import { NavigationMenu, WhiteButton, SkeletonHeader, ModalInternalization } from '@components';
import { PointsLocationsEnum } from '@common-types';

import { UserSvg } from '@assets';
import { CloseSvg } from '@share/assets';

import Logo from '@assets/images/logo_black.png';

import { Marginator } from '../../marginator';

import {
  getNavigationMenu,
  IMenuState,
  setVisible,
  setIsUpdateMenu,
  getBenefits,
  IBenefitsState,
  resetHotelsFull,
  endImpersonation,
} from '@share/store/slices';
import { ICondoReviewBookState, IReviewBookState } from '@store/slices';
import { resetCondosFull } from '@share/store/slices';
import { BlueButton } from '@share/components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faCrown } from '@fortawesome/free-solid-svg-icons';

import './style.scss';
import { GiftCard } from '../gift-card';
import { GiftCardFirstLoginModal } from '../gift-card-first-login-modal';

interface IMapStateToProps {
  menuStore: IMenuState;
  loginStore: ILoginState;
  benefitsStore: IBenefitsState;
  reviewBookStore: IReviewBookState;
  condoReviewBookStore: ICondoReviewBookState;
}

interface IMapDispatchToProps {
  setVisible: (visible: boolean) => void;
  setIsUpdateMenu: (value: boolean) => void;
  setLoginRedirect: (redirect: string) => void;

  getNavigationMenu: () => void;
  resetHotelsFull: () => void;
  resetCondosFull: () => void;
  logout: () => void;
  endImpersonation: () => void;
  getBenefits: (userId: number) => void;
}

interface IProps
  extends IMapStateToProps,
    IMapDispatchToProps,
    RouteComponentProps,
    WrappedComponentProps {
  matches?: boolean;
  isError?: boolean;
  isVacationRentals?: boolean;
}

const ONE = 1;
const TWO = 2;
const THREE = 3;

interface IState {
  showDrawerUserInfo: boolean;
  isShowCredits: boolean;
}

class HeaderComponent extends React.Component<IProps, IState> {
  state: IState = {
    showDrawerUserInfo: false,
    isShowCredits: false,
  };

  showDrawer = () => {
    this.props.setVisible(!this.props.menuStore.visible);
  };

  onClose = () => {
    this.props.setVisible(false);
  };

  showUserLinks = () => {
    this.setState({ showDrawerUserInfo: true });
  };

  onCloseUserLinks = () => {
    this.setState({ showDrawerUserInfo: false });
  };

  onCondoOrHotelPage = (): void => {
    if (this.props.menuStore?.items) {
      const { pointsSettings } = this.props.menuStore?.items;
      const isCondoPage =
        this.props.location.pathname.split('/')[TWO] === PointsLocationsEnum.Condo;
      const isCondoBookingPage =
        this.props.location.pathname.split('/')[TWO] === PointsLocationsEnum.CondoBooking;
      const isHotelSearchPage =
        this.props.location.pathname.split('/')[TWO] === PointsLocationsEnum.Search;
      const isHotelPage =
        this.props.location.pathname.split('/')[TWO] === PointsLocationsEnum.Hotel;
      const isHotelBookingPage =
        this.props.location.pathname.split('/')[TWO] === PointsLocationsEnum.HotelBooking;
      const isCarsPages =
        this.props.location.pathname.split('/')[TWO] === PointsLocationsEnum.Cars || this.props.location.pathname.split('/')[THREE] === PointsLocationsEnum.CarsBooking;
      const isCondo =
        (isCondoPage || isCondoBookingPage) &&
        pointsSettings?.condo?.isPointsControlEnabled &&
        !this.props.menuStore?.items?.promo;
      const isHotel =
        (isHotelSearchPage || isHotelPage || isHotelBookingPage) &&
        pointsSettings?.hotel?.isPointsControlEnabled &&
        !this.props.menuStore?.items?.promo;
      const isCars = (isCarsPages) && pointsSettings?.car?.isPointsControlEnabled && !this.props.menuStore?.items?.promo;

      if (isCondo || isHotel || isCars) {
        this.setState({ isShowCredits: true });
      } else {
        this.setState({ isShowCredits: false });
      }
    }
  };

  componentDidMount() {
    const { user, account } = this.props.loginStore;
    if (user) {
      this.props.getNavigationMenu();
      const isRSITemplate = account?.isRSITemplate;
      if (isRSITemplate) {
        this.props.getBenefits(user.keyid as number);
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (this.props.menuStore.isUpdateMenu) {
      this.props.getNavigationMenu();
    }

    if (
      prevProps.menuStore.loading !== this.props.menuStore.loading &&
      !this.props.menuStore.loading
    ) {
      this.onCondoOrHotelPage();
    }

    if (prevProps.location !== this.props.location) {
      this.onCondoOrHotelPage();
    }
  }

  renderPhoneInformation = () => {
    const { menuStore } = this.props;
    const { items } = menuStore;
    if (items?.phone && items?.showContactInfo) {
      return (
        <div className="header__info-call">
          <p className="header__call-text">
            <FormattedMessage id="call.gent.now" />
          </p>
          <p className="header__call-text mobile">
            <FormattedMessage id="talk.to.our.team" />:
          </p>
          <p className="header__call-text mobile-small">
            <FormattedMessage id="concierge.contact.button" />:
          </p>
          <a href={`tel:${items?.phone}`} className="header__call-number">
            {items?.phone}
          </a>
          {items?.phone2 ? ' ' : ''}
          {items?.phone2 ? (
            <a href={`tel:${items?.phone2}`} className="header__call-number">
              {items?.phone2}
            </a>
          ) : null}
        </div>
      );
    }
    return null;
  };

  renderBoucherLink = () => {
    const { matches, menuStore, loginStore } = this.props;
    const { items } = menuStore;
    const { account } = loginStore;

    if (items?.brochureUrl && items?.showBrochure) {
      const disableNewTabOpen = GetDisableNewTabOpen(account, items);
      return (
        <a
          href={items?.brochureUrl}
          target={disableNewTabOpen ? '_self' : '_blank'}
          rel="noreferrer"
          className="header__link-member"
        >
          {matches ? (
            <FormattedMessage id="member.brochure" />
          ) : (
            <FormattedMessage id="member.brochure.short" />
          )}
        </a>
      );
    }
    return null;
  };

  handleLogout = () => {
    this.props.logout();

    const { loginStore } = this.props;
    const { account } = loginStore;

    const isPublicAccountType = account?.type === LoginType.Public;
    const hasClientCash = account?.hasClientCash;

    if (!isPublicAccountType || !hasClientCash) {
      this.hangleGoToLogin();
    }
  };

  hangleGoToLogin = () => {
    const { loginStore, history } = this.props;
    const { account } = loginStore;

    const { pathname, search } = history.location;
    this.props.setLoginRedirect(`${pathname}${isEmpty(search) ? '' : search}`);

    this.props.history.push(`/${account?.name}${Routes.Login}`);
  };

  hangleGoToSignUp = () => {
    const { loginStore, history } = this.props;
    const { account } = loginStore;

    const { pathname, search } = history.location;
    this.props.setLoginRedirect(`${pathname}${isEmpty(search) ? '' : search}`);

    this.props.history.push(`/${account?.name}${Routes.SignUp}`);
  };

  handleLogoutToken = () => {
    Cookies.remove(COOKIE_TOKEN_NAME);
    Cookies.remove(COOKIE1_TOKEN_NAME);
  };

  renderNavidationMenu = () => {
    const { SubMenu } = Menu;
    const { matches, menuStore, loginStore, history, intl, isVacationRentals } = this.props;
    const { visible, items, menuList } = menuStore;
    const { account, user, userWallet, userWalletData } = loginStore;

    const isCancelRoute = isAnyReservationCancelFromPath(history);
    if (isCancelRoute) {
      return null;
    }

    const headerSubMenuWhiteBackground = account?.headerSubMenuWhiteBackground;
    const isRSITemplate = account?.isRSITemplate;
    const hasClientCash = account?.hasClientCash;
    const headerMainMenu = account?.headerMainMenu;
    const fontFamily = account?.fontFamily;

    const isPublicAccountType = account?.type === LoginType.Public;
    const isPrivateWithLoginAccountType = account?.type === LoginType.PrivateWithLogin;
    const isPrivateWithTokenAccountType = account?.type === LoginType.PrivateWithToken;

    const userNavigation = items?.menu ? items?.menu[items.menu?.length - ONE] : null;

    const styleBackground: any = this.getHeaderColorStyle();
    const styleBackgroundSubMenu = headerSubMenuWhiteBackground ? { ...styleBackground, color: '#000000' } : { ...styleBackground };

    const logoutUrlStorage = localStorage.getItem(USER_BASE_URL);
    const logoutUrlToken = get(DecodeTokenData(), 'logoutUrl', null);
    const logoutPref = getPreferenceFromAccount(account as IAccount, LOGOUT_FIELD_KEY);
    const logoutUrl = items?.logoutUrl ?
                          items.logoutUrl : 
                          !isEmpty(logoutUrlStorage) ? 
                              logoutUrlStorage : 
                              !isEmpty(logoutUrlToken) ? 
                                  logoutUrlToken : 
                                  logoutPref;

                                  
    const headerFontColor = !isEmpty(styleBackground?.color) ? styleBackground?.color : '#FFFFFF';
    
    const isWhiteBackgroundColor = !styleBackground?.backgroundColor || styleBackground?.backgroundColor?.toUpperCase() === '#FFFFFF';
    const isWhiteHeaderFontColor = account?.headerFontColor?.toUpperCase() === '#FFFFFF';
    
    const isSignInRoute = isSignInFromPath(history);
    const isSignUpRoute = isSignUpFromPath(history);

    const isLogged = !!userWallet;
    const loggedUser = !hasClientCash ? user : userWallet;
    const homeParams = GetHomeParams(account as IAccount);

    const isRsi = isRSITemplate && account?.isPrivateTokenAccountType;
    const menuItemHideBorderBottom = account?.menuItemHideBorderBottom;

    return (
      <div className={`header__content-navigation ${isRSITemplate ? 'rsi-home' : ''} ${menuItemHideBorderBottom ? 'hide-border' : ''}`}>
        <div
          className={`header__hamburger-btn ${visible ? 'header__show-hamburger-btn' : ''}`}
          onClick={this.showDrawer}
        >
          <span
            className={`header__hamburger-item ${(isWhiteBackgroundColor && !isWhiteHeaderFontColor) ? 'white' : ''}`}
            style={{ background: headerFontColor }}
          />
          <span
            className={`header__hamburger-item ${(isWhiteBackgroundColor && !isWhiteHeaderFontColor) ? 'white' : ''}`}
            style={{ background: headerFontColor }}
          />
          <span
            className={`header__hamburger-item ${(isWhiteBackgroundColor && !isWhiteHeaderFontColor) ? 'white' : ''}`}
            style={{ background: headerFontColor }}
          />
        </div>
          
        {matches ? (
          <NavigationMenu
            matches={matches}
            menuStore={menuStore}
            isVacationRentals={isVacationRentals}
          />
        ) : (
          <Drawer
            className={`header__navigation ${visible ? 'visible' : ''} ${(isWhiteBackgroundColor) ? 'white' : ''}`}
            width={'100%'}
            closable={true}
            onClose={this.onClose}
            open={visible}
          >
            <div className="header__navigation-actions">
              <ModalInternalization onOpen={this.onClose} isDrawer />
              <Marginator onOpen={this.onClose} />
            </div>

            <NavigationMenu
              matches={matches as boolean}
              menuStore={menuStore}
              isVacationRentals={isVacationRentals}
              isDrawer
            />

            {(!isPublicAccountType || hasClientCash) && (
              <div
                className="header__user-info drawer"
                style={{ ...styleBackground, backgroundColor: '#FFFFFF', marginLeft: '10px' }}
              >
                {hasClientCash && isLogged ? (
                  <>
                    <p className="header__user-drawer-name">
                      {!loggedUser ? items?.firstName : loggedUser?.firstName}{' '}
                      {!loggedUser ? items?.lastName[0] : loggedUser?.lastName}
                    </p>

                    <p className="header__user-drawer-member">
                      <FormattedMessage
                        id="wallet.menu.active_cash"
                        values={{
                          clientCash: isEmpty(account?.walletClientCashName)
                            ? 'Client Cash'
                            : account?.walletClientCashName,
                        }}
                      />
                      {': '}
                      {GetWalletNumber(userWalletData?.balance as number, account)}{' '}
                      <Tooltip
                        title={intl.formatMessage(
                          { id: 'wallet.menu.active_cash.tooltip' },
                          {
                            clientCash: isEmpty(account?.walletClientCashName)
                              ? 'Client Cash'
                              : account?.walletClientCashName,
                          },
                        )}
                      >
                        <FontAwesomeIcon icon={faCircleInfo} />
                      </Tooltip>
                    </p>

                    {!account?.walletIsExternal ? (
                      <p className="header__user-drawer-member" style={{ marginBottom: '20px' }}>
                        <FormattedMessage
                          id="wallet.menu.pending_cash"
                          values={{
                            clientCash: isEmpty(account?.walletClientCashName)
                              ? 'Client Cash'
                              : account?.walletClientCashName,
                          }}
                        />
                        {': '}
                        {GetWalletNumber(userWalletData?.pendingBalance as number, account)}{' '}
                        <Tooltip
                          title={intl.formatMessage(
                            { id: 'wallet.menu.pending_cash.tooltip' },
                            {
                              clientCash: isEmpty(account?.walletClientCashName)
                                ? 'Client Cash'
                                : account?.walletClientCashName,
                            },
                          )}
                        >
                          <FontAwesomeIcon icon={faCircleInfo} />
                        </Tooltip>
                      </p>
                    ) : null}
                  </>) : null}

                <Menu mode="inline">
                  {isPrivateWithTokenAccountType &&
                  !account?.isCapitalVacationsCondoRes &&
                  ((!isRSITemplate || items?.showMyAccount) && !((headerMainMenu && menuList?.length))) ? (
                    <Menu.Item className={`header__item-menu ${menuItemHideBorderBottom ? 'hide-border' : ''} ${fontFamily ? `${fontFamily}-font`: ''}`}>
                      <Link
                        className="header__user-drawer-link"
                        to={`/${account?.name}${Routes.RsiAccount}${homeParams}`}
                        onClick={this.onClose}
                      >
                        <FormattedMessage id={'my.account.title'} />
                      </Link>
                    </Menu.Item>) : null}

                  {isPrivateWithTokenAccountType && account?.isCapitalVacations ? (
                    <Menu.Item className={`header__item-menu ${menuItemHideBorderBottom ? 'hide-border' : ''} ${fontFamily ? `${fontFamily}-font`: ''}`}>
                      <Link
                        className="header__user-drawer-link"
                        to={`/${account?.name}${Routes.RsiAccount}/reservations${homeParams}`}
                        onClick={this.onClose}
                      >
                        <FormattedMessage id={'reservation.search.title'} />
                      </Link>
                    </Menu.Item>) : null}

                  {isPrivateWithLoginAccountType ||
                  (isPrivateWithTokenAccountType && !account?.isCapitalVacations && !items?.showMyAccount) ||
                  (hasClientCash && isLogged && !account?.isCapitalVacations) ? (
                    <Menu.Item className={`header__item-menu ${menuItemHideBorderBottom ? 'hide-border' : ''} ${fontFamily ? `${fontFamily}-font`: ''}`}>
                      <Link
                        className="header__user-drawer-link"
                        to={`/${account?.name}${Routes.Reservations}${homeParams}`}
                        onClick={this.onClose}
                      >
                        <FormattedMessage id="reservation.search.title" />
                      </Link>
                    </Menu.Item>) : null}


                  {!items?.isMLM && !account?.isCapitalVacations && (
                    <Menu.Item className={`header__item-menu ${menuItemHideBorderBottom ? 'hide-border' : ''} ${fontFamily ? `${fontFamily}-font`: ''}`} style={{ ...styleBackground, backgroundColor: '#FFFFFF' }}>
                      <Link
                        className="header__user-drawer-link"
                        to={`/${account?.name}${Routes.SavedPropertiesAccount}${homeParams}`}
                        onClick={this.onClose}
                      >
                        <FormattedMessage id="saved.properties" />
                      </Link>
                    </Menu.Item>)}

                  {!items?.isMLM && !account?.isCapitalVacations && (
                    <Menu.Item className={`header__item-menu ${menuItemHideBorderBottom ? 'hide-border' : ''} ${fontFamily ? `${fontFamily}-font`: ''}`} style={{ ...styleBackground, backgroundColor: '#FFFFFF' }}>
                      <Link
                        className="header__user-drawer-link"
                        to={`/${account?.name}${Routes.ChangePassword}${homeParams}`}
                        onClick={this.onClose}
                      >
                        <FormattedMessage id="change.password" />
                      </Link>
                    </Menu.Item>)}

                  {userNavigation && !userNavigation.childItems ? (
                    <Menu.Item className={`header__item-menu ${menuItemHideBorderBottom ? 'hide-border' : ''} ${fontFamily ? `${fontFamily}-font`: ''}`}>
                      {userNavigation?.url === 'disabled' ? (
                        isRsi ? (
                          <Tooltip
                            title={intl.formatMessage({ id: '"menu.link.disable' })}
                            placement="top"
                          >
                            <div
                              style={{ width: '100%' }}
                              className="header__link-nav ant-menu-item-disabled"
                            >
                              <FormattedMessage id={upperCase(userNavigation.title)} />
                            </div>
                          </Tooltip>
                        ) : (
                          upperCase(userNavigation.title)
                        )
                      ) : (
                        <a
                          className="header__link-nav"
                          href={userNavigation.url}
                          onClick={this.onClose}
                        >
                          {isRsi ? (
                            <FormattedMessage id={upperCase(userNavigation.title)} />
                          ) : (
                            upperCase(userNavigation.title)
                          )}
                        </a>
                      )}
                    </Menu.Item>) : null}

                  {userNavigation && userNavigation.childItems ? (
                    <SubMenu
                      title={<FormattedMessage id={upperCase(userNavigation?.title)} />}
                      className={`header__sub-menu ${menuItemHideBorderBottom ? 'hide-border' : ''}`}
                      popupClassName="welcome-menu-submenu-popup"
                    >
                      {userNavigation.childItems?.map((childItem, index) => (
                        <Menu.Item
                          key={`${childItem.title}-${index}`}
                          className={`header__item-menu ${menuItemHideBorderBottom ? 'hide-border' : ''} ${fontFamily ? `${fontFamily}-font`: ''}`}
                        >
                          {childItem?.url === 'disabled' ? (
                            <Tooltip
                              title={intl.formatMessage({ id: '"menu.link.disable' })}
                              placement="top"
                            >
                              <div
                                style={{ width: '100%' }}
                                className="header__link-nav-child ant-menu-item-disabled"
                              >
                                <FormattedMessage id={upperCase(childItem.title)} />
                              </div>
                            </Tooltip>
                          ) : (
                            <a
                              className="header__link-nav-child"
                              href={childItem.url}
                              onClick={this.onClose}
                            >
                              <FormattedMessage id={upperCase(childItem.title)} />
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </SubMenu>) : null}

                  {isPrivateWithTokenAccountType && !account?.isCapitalVacations ? (
                    <Menu.Item
                      key={`logout-199`}
                      className="header__user-info-navigation"
                      style={{ marginBottom: '15px' }}
                    >
                      <a
                        href={logoutUrl}
                        className="header__user-drawer-btn-wrapper"
                        style={{ padding: '0px' }}
                        onClick={this.onClose}
                      >
                        <WhiteButton>
                          <FormattedMessage id="log.out" />
                        </WhiteButton>
                      </a>
                    </Menu.Item>) : null}

                  {isPrivateWithLoginAccountType ||
                  (hasClientCash && isLogged && !account?.isCapitalVacations) ? (
                    <Menu.Item
                      key={`logout-200`}
                      className="header__user-info-navigation"
                      style={{ marginBottom: '15px' }}
                    >
                      <div
                        className="header__link-nav-child"
                        style={{ padding: '0px' }}
                        onClick={this.handleLogout}
                      >
                        <FormattedMessage id="log.out" />
                      </div>
                    </Menu.Item>) : null}

                  {hasClientCash && !isLogged && !isSignInRoute && !isSignUpRoute ? (
                    <Menu.Item
                      key={`logout-201`}
                      className="header__user-info-navigation"
                      style={{ marginBottom: '15px' }}
                    >
                      <div className="header__link-nav-child">
                        <div className="header__link-nav-child-link" onClick={this.hangleGoToLogin}>
                          <FormattedMessage id="sign.in" />
                        </div>{' '}
                        <FormattedMessage id="or" />{' '}
                        <div
                          className="header__link-nav-child-link"
                          style={{ marginLeft: '6px' }}
                          onClick={this.hangleGoToSignUp}
                        >
                          <FormattedMessage id="sign.up" />
                        </div>
                      </div>
                    </Menu.Item>) : null}
                </Menu>
              </div>
            )}
          </Drawer>
        )}
      </div>
    );
  };

  renderWelcome = () => {
    const { matches, menuStore, loginStore, history, intl } = this.props;
    const { items, menuList } = menuStore;
    const { account, user, userWallet, userWalletData } = loginStore;
    const { showDrawerUserInfo, isShowCredits } = this.state;

    const isPublicAccountType = account?.type === LoginType.Public;
    const isPrivateLoginAccountType = account?.type === LoginType.PrivateWithLogin;
    const isPrivateTokenAccountType = account?.type === LoginType.PrivateWithToken;
    const isCancelRoute = isAnyReservationCancelFromPath(history);
    const isImpersonating = IsImpersonating();

    const hasClientCash = account?.hasClientCash;
    const isRSITemplate = account?.isRSITemplate;
    const isLogged = !!userWallet;

    const loggedUser = !hasClientCash ? user : userWallet;

    if (
      matches &&
      (!isPublicAccountType || (hasClientCash && isLogged)) &&
      !!loggedUser &&
      !isCancelRoute
    ) {
      const isCapitalVacations = account?.isCapitalVacations;
      const isCapitalVacationsCondoRes = account?.isCapitalVacationsCondoRes;

      const fontFamily = account?.fontFamily;
      const secondaryFontFamily = account?.secondaryFontFamily;
      const headerFontColor = account?.headerFontColor;
      const headerHideMemberId = account?.headerHideMemberId;
      const headerMainMenu = account?.headerMainMenu;
      const headerHideMemberUserIdDrawer = account?.headerHideMemberUserIdDrawer;
      const headerTextUpperCase = account?.headerTextUpperCase;
      const headerFullDrawer = account?.headerFullDrawer;
      
      const homeParams = GetHomeParams(account as IAccount);

      const isParentSidekick = items?.sideKickStatus === ISideKickStatus.PARENT;

      const style = !isEmpty(headerFontColor) ? { color: headerFontColor } : {};
      const styleBurger = !isEmpty(headerFontColor) ? { background: headerFontColor } : {};
      const styleMainMenuDrawer: any = headerTextUpperCase ? { textTransform: 'uppercase' } : {};

      if (!items?.isTravelAgent && account?.levartiOrgIds?.includes(items?.organizationId as number)) {
        return (
          <div className="header__user-info">
            <a
              href="https://team.golevarti.com/Cart/ShoppingCart/product/PASSPRO-US-ACT-EXM"
              target="_blank"
            >
              <BlueButton style={{ lineHeight: '40px' }}>
                <FormattedMessage id="become.travel.agent" />
              </BlueButton>
            </a>
          </div>
        );
      }

      return (
        <div className="header__user-info">
          <p className="header__user-info-item" style={style}>
            {isImpersonating && account?.isTravelAgentPro && 'You are signed in as '}
            {!loggedUser ? items?.firstName : loggedUser?.firstName}{' '}
            {!loggedUser ? items?.lastName[0] : loggedUser?.lastName}
            {(isImpersonating && account?.isTravelAgentPro) && (
              <Button onClick={this.props.endImpersonation} type="text" danger size={'small'}>
                End impersonation
              </Button>)}
            {(!headerHideMemberId && items?.rsiId && !(hasClientCash && isLogged) && !items?.isMLM) ? (
              <span style={{ fontSize: '12px' }}>
                {'\n'}
                <FormattedMessage id="keyid" />
                {': '}
                {items?.rsiId}
              </span>) : null}
            {(!headerHideMemberId && items?.email && items?.isMLM) ? (
              <span style={{ fontSize: '12px' }}>
                {'\n'}
                {items?.email}
              </span>) : null}
          </p>

          {items?.isPromoSite || (!!isShowCredits && !account?.isMLM) ? (
            <p className="header__user-info-credits secondary-font">{items?.leisureCredits}</p>
          ) : null}

          <div onClick={this.showUserLinks} className="header__user-hamburger-btn">
            {matches ? (
              <>
                <span className="header__user-hamburger-item" style={styleBurger} />
                <span className="header__user-hamburger-item" style={styleBurger} />
                <span className="header__user-hamburger-item" style={styleBurger} />
              </>
            ) : (
              <UserSvg />
            )}
          </div>

          <Drawer
            className={`header__user-drawer ${headerFullDrawer ? 'full-drawer' : ''} ${!showDrawerUserInfo ? 'hide' : ''} ${fontFamily ? `${fontFamily}-font`: ''} ${secondaryFontFamily ? `secondary-${secondaryFontFamily}-font`: ''}`}
            placement="right"
            closable={true}
            onClose={this.onCloseUserLinks}
            headerStyle={{ display: 'none' }}
            open={showDrawerUserInfo}
            closeIcon={<CloseSvg />}
          >
            {(!headerHideMemberUserIdDrawer || 
             (!headerHideMemberId && items?.isMLM) || 
             (!headerHideMemberId && !(hasClientCash && isLogged) && !items?.isMLM) || 
             (hasClientCash && isLogged) || 
             (!!isShowCredits && !account?.isMLM)) && (
                <div className="header__user-drawer-wrapper">
                  <div className="header__user-drawer-info">
                    {!headerHideMemberUserIdDrawer && (
                      <p className="header__user-drawer-name secondary-font">
                        {!loggedUser ? items?.firstName : loggedUser?.firstName}{' '}
                        {!loggedUser ? items?.lastName[0] : loggedUser?.lastName}
                      </p>)}

                    {(!headerHideMemberId && items?.isMLM) ? <p className="header__user-drawer-member">{items?.email}</p> : null}

                    {(!headerHideMemberId && !(hasClientCash && isLogged) && !items?.isMLM) ? (
                      <p className="header__user-drawer-member">
                        <FormattedMessage
                          id={
                            isCapitalVacations ? 
                                isCapitalVacationsCondoRes ? 
                                    'condores.ID' : 
                                    'owner.ID' : 
                                'member.ID'
                          }
                        />
                        {': '}
                        {items?.rsiId}
                      </p>) : null}

                    {hasClientCash && isLogged ? (
                      <>
                        <p className="header__user-drawer-member">
                          <FormattedMessage
                            id="wallet.menu.active_cash"
                            values={{
                              clientCash: isEmpty(account?.walletClientCashName)
                                ? 'Client Cash'
                                : account?.walletClientCashName,
                            }}
                          />
                          {': '}
                          {GetWalletNumber(userWalletData?.balance as number, account)}{' '}
                          <Tooltip
                            title={intl.formatMessage(
                              { id: 'wallet.menu.active_cash.tooltip' },
                              {
                                clientCash: isEmpty(account?.walletClientCashName)
                                  ? 'Client Cash'
                                  : account?.walletClientCashName,
                              },
                            )}
                          >
                            <FontAwesomeIcon icon={faCircleInfo} />
                          </Tooltip>
                        </p>
                        {!account?.walletIsExternal ? (
                          <p className="header__user-drawer-member">
                            <FormattedMessage
                              id="wallet.menu.pending_cash"
                              values={{
                                clientCash: isEmpty(account?.walletClientCashName)
                                  ? 'Client Cash'
                                  : account?.walletClientCashName,
                              }}
                            />
                            {': '}
                            {GetWalletNumber(userWalletData?.pendingBalance as number, account)}{' '}
                            <Tooltip
                              title={intl.formatMessage(
                                { id: 'wallet.menu.pending_cash.tooltip' },
                                {
                                  clientCash: isEmpty(account?.walletClientCashName)
                                    ? 'Client Cash'
                                    : account?.walletClientCashName,
                                },
                              )}
                            >
                              <FontAwesomeIcon icon={faCircleInfo} />
                            </Tooltip>
                          </p>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                  {!!isShowCredits && !account?.isMLM ? (
                    <p className="header__user-drawer-credits secondary-font">{items?.leisureCredits}</p>
                  ) : null}
                </div>)}

            {(headerMainMenu && menuList?.length) && (
              <div className="header__user-drawer-links">
                {menuList.map((item, index) =>
                  isExternalUrl(item.url as string) ? (
                    <a
                      key={`${item.title}${index}`}
                      className="header__user-drawer-link"
                      href={item.url}
                      onClick={this.onCloseUserLinks}
                      style={styleMainMenuDrawer}
                    >
                      <FormattedMessage id={item.title} />
                    </a>
                  ) : (
                    <Link
                      key={`${item.title}${index}`}
                      className="header__user-drawer-link"
                      to={`/${account?.name}${getLocalURI(item.url as string)}`}
                      onClick={this.onCloseUserLinks}
                      style={styleMainMenuDrawer}
                    >
                      <FormattedMessage id={item.title} />
                    </Link>
                  ),
                )}
              </div>)}

            {items?.user ? (
              <div className="header__user-drawer-links">
                {items?.user.map((item, index) =>
                  isExternalUrl(item.url as string) ? (
                    <a
                      key={`${item.title}${index}`}
                      className="header__user-drawer-link"
                      href={item.url}
                      onClick={this.onCloseUserLinks}
                    >
                      <FormattedMessage id={item.title} />
                    </a>
                  ) : (
                    <Link
                      key={`${item.title}${index}`}
                      className="header__user-drawer-link"
                      to={`/${account?.name}${getLocalURI(item.url as string)}`}
                      onClick={this.onCloseUserLinks}
                    >
                      <FormattedMessage id={item.title} />
                    </Link>
                  ),
                )}
              </div>
            ) : null}

            {isPrivateTokenAccountType &&
            !account?.isCapitalVacationsCondoRes &&
            ((!isRSITemplate || items?.showMyAccount) && !((headerMainMenu && menuList?.length))) ? (
              <div className="header__user-drawer-links">
                <Link
                  className="header__user-drawer-link"
                  to={`/${account?.name}${Routes.RsiAccount}${homeParams}`}
                  onClick={this.onCloseUserLinks}
                >
                  <FormattedMessage id={'my.account.title'} />
                </Link>
              </div>
            ) : null}

            {isPrivateTokenAccountType && account?.isCapitalVacations ? (
              <div className="header__user-drawer-links">
                <Link
                  className="header__user-drawer-link"
                  to={`/${account?.name}${Routes.RsiAccount}/reservations${homeParams}`}
                  onClick={this.onCloseUserLinks}
                >
                  <FormattedMessage id={'reservation.search.title'} />
                </Link>
              </div>
            ) : null}

            {isPrivateLoginAccountType ||
            (isPrivateTokenAccountType && !account?.isCapitalVacations && !items?.showMyAccount) ||
            (hasClientCash && isLogged && !account?.isCapitalVacations) ? (
              <div className="header__user-drawer-links">
                <Link
                  className="header__user-drawer-link"
                  to={`/${account?.name}${Routes.Reservations}${homeParams}`}
                  onClick={this.onCloseUserLinks}
                >
                  <FormattedMessage id="reservation.search.title" />
                </Link>

                {!items?.isMLM && !account?.isCapitalVacations && (
                  <Link
                    className="header__user-drawer-link"
                    to={`/${account?.name}${Routes.SavedPropertiesAccount}`}
                    onClick={this.onCloseUserLinks}
                  >
                    <FormattedMessage id="saved.properties" />
                  </Link>)}

                {!items?.isMLM && !account?.isCapitalVacations && (
                  <Link
                    className="header__user-drawer-link"
                    to={`/${account?.name}${Routes.ChangePassword}`}
                    onClick={this.onCloseUserLinks}
                  >
                    <FormattedMessage id="change.password" />
                  </Link>)}

                {items?.isMLM && isParentSidekick ? (
                  <Link
                    className="header__user-drawer-link"
                    to={`/${account?.name}${Routes.RsiTestDriveManager}`}
                    onClick={this.onCloseUserLinks}
                  >
                    {!isEmpty(items?.sideKickShortName) ? (
                      items?.sideKickShortName
                    ) : (
                      <FormattedMessage id="test.drive.manager" />
                    )}
                  </Link>
                ) : null}
              </div>
            ) : null}

            <GiftCard isDrawer />

            {this.renderLogout()}
          </Drawer>
        </div>
      );
    }

    return null;
  };

  renderLogout = () => {
    const { menuStore, loginStore } = this.props;
    const { items } = menuStore;
    const { account, userWallet } = loginStore;

    const hasClientCash = account?.hasClientCash;
    const isCapitalVacations = account?.isCapitalVacations;
    const isRSITemplate = account?.isRSITemplate;

    if (isCapitalVacations && !account?.isCapitalVacationsAgent) {
      return <div className="header__user-drawer-btn-wrapper" style={{ padding: '10px' }}></div>;
    }

    const isPrivateLoginAccountType = account?.type === LoginType.PrivateWithLogin;
    const isLogged = !!userWallet;

    if (!isPrivateLoginAccountType && !(!isRSITemplate && hasClientCash && isLogged)) {
      const logoutURLPref = getPreferenceFromAccount(account as IAccount, LOGOUT_FIELD_KEY);
      const logoutUrl =
        account?.type === LoginType.PrivateWithToken
          ? !isEmpty(items?.logoutUrl)
            ? items?.logoutUrl
            : !isEmpty(logoutURLPref)
            ? logoutURLPref
            : null
          : null;

      return (
        <a
          href={logoutUrl as string}
          onClick={this.handleLogoutToken}
          className="header__user-drawer-btn-wrapper"
        >
          <WhiteButton>
            <FormattedMessage id="log.out" />
          </WhiteButton>
        </a>
      );
    } else {

      return (
        <div onClick={this.handleLogout} className="header__user-drawer-btn-wrapper">
          <WhiteButton>
            <FormattedMessage id="log.out" />
          </WhiteButton>
        </div>
      );
    }
  };

  getHeaderColorStyle = () => {
    const { loginStore, menuStore } = this.props;
    const { account } = loginStore;
    const { visible } = menuStore;

    const styleBackground: any = {};

    const headerFontColor = account?.headerFontColor;
    const headerBackgroundColorDB = account?.headerBackgroundColor;

    if (visible) {
      if (!isEmpty(headerBackgroundColorDB)) {
        styleBackground.backgroundColor = headerBackgroundColorDB;
        document.documentElement.style.setProperty(
          '--header-background-color',
          headerBackgroundColorDB as string,
        );
      } else {
        styleBackground.backgroundColor = '#FFFFFF';
        document.documentElement.style.setProperty('--header-background-color', '#FFFFFF');
      }
    }

    if (!isEmpty(headerFontColor)) {
      styleBackground.color = headerFontColor;
      document.documentElement.style.setProperty('--header-font-color', headerFontColor as string);
    }
    return styleBackground;
  };

  goHome = () => {
    const { loginStore, menuStore, history, isVacationRentals } = this.props;
    const { account } = loginStore;
    const { items } = menuStore;

    const homeParams = GetHomeParams(account as IAccount);

    const isRSITemplate = account?.isRSITemplate;

    if (!isAnyReservationCancelFromPath(history)) {
      const homePageDB = getPreferenceFromAccount(account as IAccount, HOME_PAGE_URL_FIELD_KEY);
      const homePageMenu = items?.homeUrl;
      const redirectPage = !isEmpty(homePageMenu) ? homePageMenu : homePageDB;

      if (!isEmpty(redirectPage)) {
        this.props.history.push(`/${account?.name}${getLocalURI(redirectPage as string)}`);
      } else {
        if (isRSITemplate) {
          this.props.resetCondosFull();
          this.props.resetHotelsFull();
          this.props.history.push(`/${account?.name}${Routes.Home}${homeParams}`);
        } else  if (isCondoEngineFromPath(history)) {
          this.props.resetCondosFull();
          this.props.history.push(`/${account?.name}${Routes.CondoSearch}${homeParams}`);
        } else {
          this.props.resetHotelsFull();
          if (isVacationRentals) {
            this.props.history.push(
              `/${account?.name}${Routes.Search}/${VACATIONS_PAGE}${homeParams}`,
            );
          } else {
            this.props.history.push(`/${account?.name}${Routes.Search}${homeParams}`);
          }
        }
      }
    }
  };

  renderInformation = () => {
    const { menuStore, loginStore, history, intl } = this.props;
    const { items } = menuStore;
    const { account, user } = loginStore;

    const isPublicAccountType = account?.type === LoginType.Public;
    const isPrivateTokenAccountType = account?.type === LoginType.PrivateWithToken;
    const isCancelRoute = isAnyReservationCancelFromPath(history);

    const isBookRoute = isBookFromPath(history);
    const homeParams = GetHomeParams(account as IAccount);

    if (!isCancelRoute && !isPublicAccountType && !!user) {
      const isRSITemplate = account?.isRSITemplate;

      const displayMenu = !!items && !!items?.header && !items?.isMLM;
      const displayQuotes = isRSITemplate && !items?.isMLM;
      const displayMyAccount =
        isPrivateTokenAccountType &&
        !account?.isCapitalVacationsCondoRes &&
        (!isRSITemplate ||
          (items?.showMyAccount &&
            !(items.isTravelAgent && account?.levartiOrgIds?.includes(items.organizationId))));

      const hasAnotherOption = displayMenu || displayQuotes || displayMyAccount;

      const displayPhoneInformation = items?.phone && items?.showContactInfo;
      const displayBoucherLink = items?.brochureUrl && items?.showBrochure;
      const diplayInfo = displayPhoneInformation || displayBoucherLink;

      const styles = account?.subHeaderColor ? { background: account?.subHeaderColor } : {};
      
      const headerHideSubHeader = account?.headerHideSubHeader;
      if (headerHideSubHeader) {
        return null;
      }

      const disableNewTabOpen = GetDisableNewTabOpen(account, items);

      return (
        <div
          className={`header__info ${items?.isMLM ? 'is-mlm' : ''} ${!diplayInfo ? 'no-info' : ''}`}
          style={styles}
        >
          <div className="header__info-item">
            {this.renderPhoneInformation()}
            {this.renderBoucherLink()}
          </div>
          <div className="header__items">
            {!isBookRoute ? (
              <div
                className={`header__user-info information ${!hasAnotherOption ? 'no-padding' : ''}`}
              >
                <ModalInternalization onOpen={this.onClose} forceWhite />
              </div>
            ) : null}

            {displayMenu
              ? items.header.map((item, index) =>
                  item?.url === 'disabled' ? (
                    <label style={{ cursor: 'not-allowed' }}>
                      <Tooltip
                        title={intl.formatMessage({ id: '"menu.link.disable' })}
                        placement="top"
                      >
                        <FormattedMessage id={item.title} />
                      </Tooltip>
                    </label>
                  ) : isExternalUrl(item.url as string) ? (
                    <a
                      key={`${index}${item.title}`}
                      href={item.url}
                      target={disableNewTabOpen ? '_self' : '_blank'}
                      rel="noreferrer"
                      className="header__item-navigation"
                    >
                      <FormattedMessage id={item.title} />
                    </a>
                  ) : (
                    <Link
                      key={`${index}${item.title}`}
                      to={`/${account?.name}${getLocalURI(item.url as string)}`}
                      className="header__item-navigation"
                    >
                      <FormattedMessage id={item.title} />
                    </Link>
                  ),
                )
              : null}

            {displayQuotes && (!isRSITemplate || items?.showMyAccount) ? (
              <Link
                key={`0myquotes`}
                to={`/${account?.name}${Routes.RsiQuotes}`}
                className="header__item-navigation"
              >
                <FormattedMessage id="my.quotes" />
              </Link>
            ) : null}

            {displayMyAccount ? (
              <Link
                key={`0account`}
                to={`/${account?.name}${getLocalURI('/account')}${homeParams}`}
                className="header__item-navigation"
              >
                <FormattedMessage id="my.account.title" />
              </Link>
            ) : null}
          </div>
        </div>
      );
    }
    return null;
  };

  render(): React.ReactNode {
    const { loginStore, menuStore, history, isError } = this.props;
    const { account, user, userWallet } = loginStore;
    const { loading, items } = menuStore;

    const logoDB = getPreferenceFileUrlFromAccount(account as IAccount, LOGO_FIELD_KEY);
    const homePageDB = getPreferenceFromAccount(account as IAccount, HOME_PAGE_URL_FIELD_KEY);
    const homePageMenu = items?.homeUrl;
    const headerBackgroundColorDB = account?.headerBackgroundColor;
    const isDisableHomes = account?.isDisableHomes;
    const disableHomesRedirect = account?.disableHomesRedirect;
    const headerTextUpperCase = account?.headerTextUpperCase;

    const logo = !isEmpty(items?.logoUrl) ? items?.logoUrl : !isEmpty(logoDB) ? logoDB : Logo;
    const isPublicAccountType = account?.type === LoginType.Public;

    const redirectPage = !isEmpty(homePageMenu) ? homePageMenu : homePageDB;
    const isCustomRedirect = !isEmpty(redirectPage) && isExternalUrl(redirectPage as string);
    const isCancelRoute = isAnyReservationCancelFromPath(history);
    const isBookRoute = isBookFromPath(history);
    const isSignInRoute = isSignInFromPath(history);
    const isSignUpRoute = isSignUpFromPath(history);

    const style: any = { backgroundColor: !isEmpty(headerBackgroundColorDB) ? headerBackgroundColorDB : '#FFFFFF' };

    const hasRenderInformation = !isCancelRoute && !isPublicAccountType && !!user;

    const hasClientCash = account?.hasClientCash;
    const isRSITemplate = account?.isRSITemplate;
    const headerFloating = account?.headerFloating;
    const logoSkipWidth = account?.logoSkipWidth;
    
    const isLogged = !!userWallet;

    if (account?.forceIframe) {
      return null;
    }

    const homeLogoRsi = !isRSITemplate || items?.showHomeUrl;
    
    const disableNewTabOpen = GetDisableNewTabOpen(account, items);

    return (
      <>
        {!loading && !isError ? (
          <div className={`header ${headerTextUpperCase ? 'upper-case' : ''} ${headerFloating ? 'floating-header' : ''}`} style={style}>
            {this.renderInformation()}
            <div
              className={`header__content ${isRSITemplate && account?.name === 'rsi' ? 'rsi-template' : ''} ${logoSkipWidth ? 'skip-width' : ''}`}
            >
              {!isCustomRedirect && !isDisableHomes && homeLogoRsi && (
                <div
                  className={`header__logo ${isCancelRoute ? '' : 'go-home'}`}
                  onClick={this.goHome}
                >
                  <img className="logo-icon" src={logo} alt={'logo'} />
                </div>
              )}

              {isCustomRedirect && !isDisableHomes && homeLogoRsi ? (
                items?.isMLM ? (
                  <Link className="header__logo" to={`/${account?.name}/home`} rel="noreferrer">
                    <img className="logo-icon" src={logo} alt={'logo'} />
                  </Link>
                ) : (
                  <a
                    href={redirectPage}
                    className="header__logo"
                    target={disableNewTabOpen ? '_self' : '_blank'}
                    rel="noreferrer"
                  >
                    <img className="logo-icon" src={logo} alt={'logo'} />
                  </a>
                )
              ) : null}

              {((isDisableHomes && isEmpty(disableHomesRedirect)) || !homeLogoRsi) && (
                <div className="header__logo">
                  <img className="logo-icon" src={logo} alt={'logo'} />
                </div>
              )}

              {isDisableHomes && !isEmpty(disableHomesRedirect) && homeLogoRsi && (
                <a
                  href={disableHomesRedirect}
                  className="header__logo"
                  target={disableNewTabOpen ? '_self' : '_blank'}
                  rel="noreferrer"
                >
                  <img className="logo-icon" src={logo} alt={'logo'} />
                </a>
              )}

              {this.renderNavidationMenu()}

              {!hasRenderInformation && !isBookRoute ? (
                <div className="header__user-info desktop">
                  <ModalInternalization onOpen={this.onClose} />
                </div>
              ) : null}

              <GiftCard />

              {this.renderWelcome()}

              {hasClientCash && !isLogged && !isSignInRoute && !isSignUpRoute && (
                <div className="header__user-info desktop">
                  <div
                    className="header__link-nav-child-link"
                    style={{ marginRight: '6px' }}
                    onClick={this.hangleGoToLogin}
                  >
                    <FormattedMessage id="sign.in" />
                  </div>{' '}
                  <FormattedMessage id="or" />{' '}
                  <div
                    className="header__link-nav-child-link"
                    style={{ marginLeft: '6px' }}
                    onClick={this.hangleGoToSignUp}
                  >
                    <FormattedMessage id="sign.up" />
                  </div>
                </div>
              )}
            </div>

            <GiftCardFirstLoginModal />
          </div>
        ) : null}

        {loading || isError ? <SkeletonHeader active={!isError} /> : null}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    condoReviewBookStore: state.condoReviewBookStore,
    reviewBookStore: state.reviewBookStore,
    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore,
    benefitsStore: state.benefitsStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getNavigationMenu,
  setLoginRedirect,
  setVisible,
  setIsUpdateMenu,
  logout,
  endImpersonation,
  getBenefits,
  resetHotelsFull,
  resetCondosFull,
};

export const Header = connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(HeaderComponent)));
