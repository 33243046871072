
import { isEmpty, toNumber } from 'lodash';

import { getNormalizedRooms, UrlUtils } from '@share/utils';
import { ACCESS_TOKEN_LABEL, ADULTS_LABEL, CHECKIN_LABEL, CHECKOUT_LABEL, CHILDS_LABEL, DATES_LABEL, LATITUDE_LABEL, LOCATION_LABEL, LOCATION_OBJECT_LABEL, LONGITUDE_LABEL, REFERENCE_NUMBER_LABEL, ROOMS_LABEL, SESSION_KEY_LABEL, AGENCY_NUMBER_LABEL, FILTERS_LABEL, CONDO_FILTERS_LABEL, CLIENT_CASH_LABEL, CLIENT_CASH_CONDO_LABEL, LIFE_STYLE_NAME_PARAM, CLIENT_CASH_CARS_LABEL, CARS_PICKUP_LOCATION_OBJECT_LABEL, CARS_PICKUP_LOCATION_LABEL, CARS_DROPOFF_LOCATION_OBJECT_LABEL, CARS_DROPOFF_LOCATION_LABEL, CARS_SESSION_KEY_LABEL, CARS_DATES_LABEL, CARS_DRIVER_LABEL, CARS_FILTERS_QUICK_LABEL, CLIENT_CASH_WEEKS_LABEL, WEEKS_FILTERS_LABEL, WEEKS_DATES_LABEL, WEEKS_LOCATION_LABEL, WEEKS_LOCATION_OBJECT_LABEL } from '@share/constants';
import { locationActions, datesActions, roomsActions, hotelsActions, loginActions, IFiltersState, ICondoFiltersState, filtersActions, condoFiltersActions, condosActions, carsActions, carsLocationsActions, carsDatesActions, carsDriverActions, carsFiltersActions, IWeeksFiltersState, weeksFiltersActions, weeksDatesActions, weeksLocationsActions, weeksActions } from '@share/store/slices';
import { setSelectedCarsReviewClientCashStr, setSelectedCondoReviewClientCash, setSelectedCondoReviewClientCashStr, setSelectedHotelReviewClientCash, setSelectedHotelReviewClientCashStr, weeksReviewBookAction } from '@store/slices';
import { IClientCash, ICondoLocation, IGuest, ILocation, ISessionKey, IUrlRoom } from '@share/common-types';
import { getLocationByGeo } from '@share/services';
import { NULL_VALUE } from '@constants';
  
const ProcessCarData = async (dispatch: any, values: { [key: string]: unknown }) => {

  if (values[CLIENT_CASH_CARS_LABEL]) {
    const clientCash = values[CLIENT_CASH_CARS_LABEL] as IClientCash;
    await dispatch(carsActions.setSelectedCarsSearchClientCash(clientCash));
    await dispatch(setSelectedHotelReviewClientCash(clientCash?.selectedPropertyReviewClientCash as number));
    await dispatch(setSelectedCarsReviewClientCashStr(clientCash?.selectedPropertyReviewClientCash?.toString() as string));
  }

  const pickUpValue = values[CARS_PICKUP_LOCATION_OBJECT_LABEL];
  const isPickUp = pickUpValue && Object.keys(pickUpValue).length > 0;
  if (isPickUp) {
    dispatch(carsLocationsActions.setSelectPickUpLocation(values[CARS_PICKUP_LOCATION_OBJECT_LABEL] as ILocation));
    dispatch(carsLocationsActions.applyLocation());
  }

  if (values[CARS_PICKUP_LOCATION_LABEL] && isPickUp) {
    dispatch(carsLocationsActions.setSelectPickUpLocationLabel(values[CARS_PICKUP_LOCATION_LABEL] as string));
    dispatch(carsLocationsActions.applyLocation());
  }

  const dropOffValue = values[CARS_DROPOFF_LOCATION_OBJECT_LABEL];
  const isDropOff = dropOffValue && Object.keys(dropOffValue).length > 0;
  if (isDropOff) {
    dispatch(carsLocationsActions.setSelectDropOffLocation(values[CARS_DROPOFF_LOCATION_OBJECT_LABEL] as ILocation));
    dispatch(carsLocationsActions.applyLocation());
  }

  if (values[CARS_DROPOFF_LOCATION_LABEL] && isDropOff) {
    dispatch(carsLocationsActions.setSelectDropOffLocationLabel(values[CARS_DROPOFF_LOCATION_LABEL] as string));
    dispatch(carsLocationsActions.applyLocation());
  }

  if (values[CARS_SESSION_KEY_LABEL]) {
    dispatch(carsActions.setCarsSessionKey(values[CARS_SESSION_KEY_LABEL] as ISessionKey));
  }

  if (values[CARS_DATES_LABEL]) {
    const carsDates = values[CARS_DATES_LABEL] as Record<string, string>;
    dispatch(carsDatesActions.setDatesSelected({ startDate: carsDates.startDate, endDate: carsDates.endDate }));
    dispatch(carsDatesActions.setTimesSelected({ startDateTime: carsDates.startDateTime, endDateTime: carsDates.endDateTime }));
    dispatch(carsDatesActions.applyCarsDates());
  }

  if (values[CARS_DRIVER_LABEL]) {
    const carsDriver = values[CARS_DRIVER_LABEL] as Record<string, string>;
    dispatch(carsDriverActions.setDriverAgeSelected(carsDriver?.driverAge));
    dispatch(carsDriverActions.setDriverCountrySelected(carsDriver?.driverCountry));
    dispatch(carsDriverActions.applyCarsDriver());
  }


  if (values[CARS_FILTERS_QUICK_LABEL]) {
    const carsFiltersQuick = values[CARS_FILTERS_QUICK_LABEL] as Record<string, string[]>;
    dispatch(carsFiltersActions.setSimpleTypeQuick(carsFiltersQuick?.simpleTypeQuick));
    dispatch(carsFiltersActions.setRateDistanceQuick(carsFiltersQuick?.rateDistanceQuick));
    dispatch(carsFiltersActions.setRentalCompanyQuick(carsFiltersQuick?.rentalCompanyQuick));
    dispatch(carsFiltersActions.setFuelQuick(carsFiltersQuick?.fuelQuick));
    dispatch(carsFiltersActions.applyCarsFiltersQuicks());
  }
}

const ProcessHotelsData = async (dispatch: any, values: { [key: string]: unknown }, hasLatitudeLongitude: boolean) => {
  if (values[REFERENCE_NUMBER_LABEL]) {
    dispatch(hotelsActions.setReferenceNumber(values[REFERENCE_NUMBER_LABEL] as string));
  }
  
  if (values[AGENCY_NUMBER_LABEL]) {
    dispatch(hotelsActions.setAgencyNumber(values[AGENCY_NUMBER_LABEL] as string));
  }

  if (values[DATES_LABEL]) {
    dispatch(datesActions.setDatesSelected(values[DATES_LABEL] as { startDate: string; endDate: string }));
    dispatch(datesActions.applyDates());
  } else if (!isEmpty(values[CHECKIN_LABEL]) || !isEmpty(values[CHECKOUT_LABEL])) {
    dispatch(datesActions.setDatesSelected({
      startDate: !isEmpty(values[CHECKIN_LABEL]) ? values[CHECKIN_LABEL] as string : NULL_VALUE,
      endDate: !isEmpty(values[CHECKOUT_LABEL]) ? values[CHECKOUT_LABEL] as string : NULL_VALUE
    }));

    if (!isEmpty(values[CHECKIN_LABEL]) && !isEmpty(values[CHECKOUT_LABEL])) {
      dispatch(datesActions.applyDates());
    }

    UrlUtils.removeFromUrl(CHECKIN_LABEL);
    UrlUtils.removeFromUrl(CHECKOUT_LABEL);
  }

  if (!hasLatitudeLongitude && values[LOCATION_LABEL]) {
    dispatch(locationActions.setSelectLocationLabel(values[LOCATION_LABEL] as string));
    dispatch(locationActions.applyLocation());
  }

  if (values[SESSION_KEY_LABEL]) {
    dispatch(hotelsActions.setSessionKey(values[SESSION_KEY_LABEL] as ISessionKey));
  }

  if (values[CLIENT_CASH_LABEL]) {
    const clientCash = values[CLIENT_CASH_LABEL] as IClientCash;
    await dispatch(hotelsActions.setSelectedHotelSearchClientCash(clientCash));
    await dispatch(setSelectedHotelReviewClientCash(clientCash?.selectedPropertyReviewClientCash as number));
    await dispatch(setSelectedHotelReviewClientCashStr(clientCash?.selectedPropertyReviewClientCash ? clientCash?.selectedPropertyReviewClientCash.toString() : ''));
  }

  if (values[LOCATION_OBJECT_LABEL]) {
    dispatch(locationActions.setSelectLocation(values[LOCATION_OBJECT_LABEL] as ILocation));
    dispatch(locationActions.applyLocation());
  } else if (hasLatitudeLongitude) {
    const latitude = toNumber(values[LATITUDE_LABEL] as string);
    const longitude = toNumber(values[LONGITUDE_LABEL] as string);
    try {
      const location = await getLocationByGeo(latitude, longitude, true);

      dispatch(locationActions.setSelectLocationLabel(location?.data?.name));
      dispatch(locationActions.setSelectLocation(location?.data));
      dispatch(locationActions.applyLocation());

      UrlUtils.removeFromUrl(LATITUDE_LABEL);
      UrlUtils.removeFromUrl(LONGITUDE_LABEL);
    } catch(e) {
      console.error(e);
    }
  }

  if (values[ROOMS_LABEL]) {
    dispatch(roomsActions.setRooms(getNormalizedRooms(values[ROOMS_LABEL] as Array<IUrlRoom>)));
    dispatch(roomsActions.applyRooms());
  } else if (!isEmpty(values[ADULTS_LABEL]) || !isEmpty(values[CHILDS_LABEL])) {
    dispatch(roomsActions.setRooms([{
      adultsCount: values[ADULTS_LABEL] ? toNumber(values[ADULTS_LABEL] as string) : 2,
      kids: Array(values[CHILDS_LABEL] ? toNumber(values[CHILDS_LABEL] as string) : 0).fill(0) as IGuest[]
    }]));
    dispatch(roomsActions.applyRooms());

    UrlUtils.removeFromUrl(ADULTS_LABEL);
    UrlUtils.removeFromUrl(CHILDS_LABEL);
  }

  if (values[FILTERS_LABEL]) {
    dispatch(filtersActions.setFilters(values[FILTERS_LABEL] as IFiltersState));
  }
}

const ProcessWeeksData = async (dispatch: any, values: { [key: string]: unknown }, hasLatitudeLongitude: boolean) => {
  if (values[CLIENT_CASH_WEEKS_LABEL]) {
    const clientCash = values[CLIENT_CASH_WEEKS_LABEL] as IClientCash;
    await dispatch(weeksActions.setSelectedWeeksSearchClientCash(clientCash));
    await dispatch(weeksReviewBookAction.setSelectedWeeksReviewClientCash(clientCash?.selectedPropertyReviewClientCash as number));
    await dispatch(weeksReviewBookAction.setSelectedWeeksReviewClientCashStr(clientCash?.selectedPropertyReviewClientCash?.toString() as string));
  }

  if (values[WEEKS_FILTERS_LABEL]) {
    dispatch(weeksFiltersActions.setFilters(values[WEEKS_FILTERS_LABEL] as IWeeksFiltersState));
  }

  if (values[WEEKS_DATES_LABEL]) {
    const dates = values[WEEKS_DATES_LABEL] as Record<string, string>;

    dispatch(weeksDatesActions.setDateSelected(dates?.date));
    dispatch(weeksDatesActions.setPeriodSelected(dates?.period));
    dispatch(weeksDatesActions.applyWeeksDates());
  }

  if (!hasLatitudeLongitude && values[WEEKS_LOCATION_LABEL]) {
    dispatch(weeksLocationsActions.setSelectLocationLabel(values[WEEKS_LOCATION_LABEL] as string));
    dispatch(weeksLocationsActions.applyLocation());
  }

  if (values[WEEKS_LOCATION_OBJECT_LABEL]) {
    dispatch(weeksLocationsActions.setSelectLocation(values[WEEKS_LOCATION_OBJECT_LABEL] as ICondoLocation));
    dispatch(locationActions.applyLocation());
  } else if (hasLatitudeLongitude) {
    const latitude = toNumber(values[LATITUDE_LABEL] as string);
    const longitude = toNumber(values[LONGITUDE_LABEL] as string);
    try {
      const location = await getLocationByGeo(latitude, longitude, true);

      dispatch(weeksLocationsActions.setSelectLocationLabel(location?.data?.name));
      dispatch(weeksLocationsActions.setSelectLocation(location?.data));
      dispatch(weeksLocationsActions.applyLocation());

      UrlUtils.removeFromUrl(LATITUDE_LABEL);
      UrlUtils.removeFromUrl(LONGITUDE_LABEL);
    } catch(e) {
      console.error(e);
    }
  }
}

const ProcessCondosData = async (dispatch: any, values: { [key: string]: unknown }) => {
  if (values[CLIENT_CASH_CONDO_LABEL]) {
    const clientCash = values[CLIENT_CASH_CONDO_LABEL] as IClientCash;
    await dispatch(condosActions.setSelectedCondoSearchClientCash(clientCash));
    await dispatch(setSelectedCondoReviewClientCash(clientCash?.selectedPropertyReviewClientCash as number));
    await dispatch(setSelectedCondoReviewClientCashStr(clientCash?.selectedPropertyReviewClientCash ? clientCash?.selectedPropertyReviewClientCash.toString() : ''));
  }

  if (values[CONDO_FILTERS_LABEL]) {
    dispatch(condoFiltersActions.setFilters(values[CONDO_FILTERS_LABEL] as ICondoFiltersState));
  }
}

const ProcessLoginData = async (dispatch: any, values: { [key: string]: unknown }) => {
  if (values[LIFE_STYLE_NAME_PARAM]) {
    await dispatch(loginActions.setLifeStyle(values[LIFE_STYLE_NAME_PARAM] as string));
  }

  if (values[ACCESS_TOKEN_LABEL]) {
    dispatch(loginActions.setAccessToken(values[ACCESS_TOKEN_LABEL] as string));
    localStorage.setItem(ACCESS_TOKEN_LABEL, values[ACCESS_TOKEN_LABEL] as string);
    UrlUtils.removeFromUrl(ACCESS_TOKEN_LABEL);
  } else {
    const accessTokenStorage = localStorage.getItem(ACCESS_TOKEN_LABEL);
    if (!isEmpty(accessTokenStorage)) {
      dispatch(loginActions.setAccessToken(accessTokenStorage as string));
    }
  }
}

export const ProcessData = async (dispatch: any) => {

  const values = UrlUtils.getValues();

  const hasLatitudeLongitude = !isEmpty(values[LATITUDE_LABEL]) && !isEmpty(values[LONGITUDE_LABEL]);
  
  ProcessHotelsData(dispatch, values, hasLatitudeLongitude);

  ProcessWeeksData(dispatch, values, hasLatitudeLongitude);

  ProcessCondosData(dispatch, values);
  
  ProcessCarData(dispatch, values);

  ProcessLoginData(dispatch, values);
}
