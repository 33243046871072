import React from 'react';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import isArray from 'lodash/isArray';
import parse from 'html-react-parser';
import ReactGA from 'react-ga4';

import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

import { IMedia } from '@share/common-types';
import { isVideo } from '@utils';
import { C_D_CLICK_IMAGES } from '@share/constants';
import { IAccount } from '@share/common-types';

import { CondoImagesSlider } from '../condo-image-slider';

import { FullScreenSvg, ImagesQuantitySvg } from '@assets';

import './style.scss';

interface IProps {
  account: IAccount;
  media: IMedia[];
  condoName: string;
  condoDescription: string[];
  highlights: string[];
  isAllInclusive: boolean;
  
  onClickScrollToUnits?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

interface IState {
  isModalVisible: boolean;
  startIndex: number;
  isEllipsis: boolean;
  isVideoError: boolean;
  showMoreLink: boolean;
}

const FIRST_ELEMENT_ARRAY = 0;
const MIN_IMAGE = 0;
const MAX_IMAGE = 4;
const ONE_IMAGES = 1;
const TWO_IMAGES = 2;
const THREE_IMAGES = 3;
const IMAGE = 'image';
const FOUR_ROW = 4;
const ONE_SECOND = 1000;

const { Paragraph } = Typography;

export class CondoImagesDescription extends React.Component<IProps, IState> {
  state: IState = {
    isModalVisible: false,
    startIndex: 0,
    isEllipsis: true,
    isVideoError: false,
    showMoreLink: false,
  };

  showSlider = (index: number): void => {
    this.setState({ isModalVisible: true });
    this.setState({ startIndex: index });

    const { account } = this.props;
    ReactGA.event({
      category: account.name,
      action: `${C_D_CLICK_IMAGES}_${account.name.toUpperCase()}`,
      label: `User clicked an image on condo detail`,
      nonInteraction: false,
    });
  };

  handleCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>, isScrollToRooms?: boolean): void => {
    if (isScrollToRooms && this.props.onClickScrollToUnits) {
      this.props.onClickScrollToUnits(e);
    }

    this.setState({ isModalVisible: false });
  };

  showMore = (): void => {
    this.setState({ isEllipsis: !this.state.isEllipsis });
  };

  getOtherImages = this.props.media.map((img: IMedia, i: number) => {
    if (i === MIN_IMAGE || i > MAX_IMAGE) {
      return;
    }
    if (i !== MIN_IMAGE && this.props.media.length === TWO_IMAGES) {
      return (
        <>
          <img className="condo-images-description__image" src={img.url} alt={img.description} />
          <div className="condo-images-description__middle">
            <div
              className="condo-images-description__btn-hover-wrapper"
              onClick={() => this.showSlider(Number(i))}
            >
              <FullScreenSvg />
              <span className="condo-images-description__btn-hover-text">
                <FormattedMessage id="full.view" />
              </span>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div
          key={i}
          className={`condo-images-description__other-image-wrapper ${
            this.props.media.length === THREE_IMAGES ? 'three-images' : ''
          }`}
        >
          <img className="condo-images-description__image" src={img.url} alt={img.description} />
          <div className="condo-images-description__middle">
            <div
              className="condo-images-description__btn-hover-wrapper"
              onClick={() => this.showSlider(Number(i))}
            >
              <FullScreenSvg />
              <span className="condo-images-description__btn-hover-text">
                <FormattedMessage id="full.view" />
              </span>
            </div>
          </div>
        </div>
      );
    }
  });

  getVideo = (): IMedia => {
    return (this.props.media.find((item) => isVideo(item.url))) as IMedia;
  };

  getImages = (): IMedia[] => {
    return this.props.media.filter((item) => item.type === IMAGE);
  };

  onError = (event: React.SyntheticEvent<HTMLVideoElement, Event>): void => {
    if (event) {
      this.setState({ isVideoError: !this.state.isVideoError });
    }
  };

  getHighlights = (): string[] => {
    const { highlights } = this.props;
    return isArray(highlights) ? highlights : [];
  };

  componentDidMount(): void {
    setTimeout(() => {
      const moreLink: HTMLLinkElement = document.querySelector(
        '.condo-images-description__condo-text .ant-typography-expand',
      ) as HTMLLinkElement;

      if (moreLink) {
        this.setState({ showMoreLink: true });
      }
    }, ONE_SECOND);
  }

  render(): React.ReactNode {
    const { media = [], condoName, condoDescription = [], isAllInclusive } = this.props;
    const { isModalVisible, startIndex, isEllipsis, isVideoError, showMoreLink } = this.state;

    return (
      <div className={`condo-images-description ${media.length === ONE_IMAGES ? 'one-image' : ''}`}>
        {!isEmpty(media) && (
          <div className={media.length === ONE_IMAGES ? 'condo-images-description__one-image' : ''}>
            <div className="condo-images-description__images-quantity">
              <ImagesQuantitySvg />
              <span className="condo-images-description__images-quantity-text">
                {media.length}+
              </span>
            </div>
            <div className="condo-images-description__wrapper">
              <div
                className={`condo-images-description__main-image-wrapper ${
                  media.length === ONE_IMAGES ? 'one-image' : ''
                }`}
              >
                {isEmpty(this.getVideo()) ? (
                  <>
                    <img
                      className="condo-images-description__image"
                      src={media[FIRST_ELEMENT_ARRAY].url}
                      alt={media[FIRST_ELEMENT_ARRAY].description}
                    />
                    <div className="condo-images-description__middle">
                      <div
                        className="condo-images-description__btn-hover-wrapper"
                        onClick={() => this.showSlider(FIRST_ELEMENT_ARRAY)}
                      >
                        <FullScreenSvg />
                        <span className="condo-images-description__btn-hover-text">
                          <FormattedMessage id="full.view" />
                        </span>
                      </div>
                    </div>
                  </>
                ) : !isVideoError ? (
                  <video
                    width="100%"
                    height="100%"
                    controls
                    loop
                    onError={(event) => this.onError(event)}
                  >
                    <source src={this.getVideo().url} />
                  </video>
                ) : (
                  <>
                    <img
                      className="condo-images-description__image"
                      src={this.getImages()[FIRST_ELEMENT_ARRAY].url}
                      alt={this.getImages()[FIRST_ELEMENT_ARRAY].description}
                    />
                    <div className="condo-images-description__middle">
                      <div
                        className="condo-images-description__btn-hover-wrapper"
                        onClick={() => this.showSlider(FIRST_ELEMENT_ARRAY)}
                      >
                        <FullScreenSvg />
                        <span className="condo-images-description__btn-hover-text">
                          <FormattedMessage id="full.view" />
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div
                className={`condo-images-description__other-images-wrapper ${
                  media.length === TWO_IMAGES ? 'two-images' : ''
                }`}
              >
                {this.getOtherImages}
              </div>
            </div>
            <CondoImagesSlider
              media={media}
              onCancel={this.handleCancel}
              visible={isModalVisible}
              title={condoName}
              startIndex={startIndex}
              isVideoError={!isUndefined(this.getVideo()) ? isVideoError : !isVideoError}
            />
          </div>
        )}
        <div
          className={`condo-images-description__condo-info ${media.length === ONE_IMAGES ? 'one-image' : ''}`}
        >
          {!!condoDescription.length && (
            <div className="condo-images-description__condo-description">
              <div className={`condo-images-description__condo-text ${!(isAllInclusive || this.getHighlights()?.length) ? 'no-highlights' : ''}`}>
                <Paragraph ellipsis={isEllipsis ? { rows: FOUR_ROW, expandable: true } : false}>
                  {parse(condoDescription.join(' '))}
                </Paragraph>

                {showMoreLink && (
                  <p className="condo-images-description__read-more-button" onClick={this.showMore}>
                    {isEllipsis ? (
                      <FormattedMessage id="show.more" />
                    ) : (
                      <FormattedMessage id="show.less" />
                    )}
                  </p>
                )}
              </div>
              {(isAllInclusive || this.getHighlights()?.length) ? (
                <div className="condo-images-description__highlights">
                  {isAllInclusive ? (
                    <p className="condo-images-description__highlight"><FormattedMessage id="all.inclusive" />{' '}</p>
                  ) : null}
                  {this.getHighlights().map((item, index) => (
                    <p key={index} className="condo-images-description__highlight">{item}</p>
                  ))}
                </div>) : null
              }
            </div>)}
        </div>
      </div>
    );
  }
}
