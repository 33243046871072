
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Button, Spin, Tooltip } from 'antd';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { HeartFilled } from '@ant-design/icons';
import { Action } from '@reduxjs/toolkit';

import { RootState } from '@share/utils';
import { handleSavedProperties, ILoginState, IMenuState, ISavedPropertiesState } from '@share/store/slices';
import { ISavedProperties } from '@share/common-types';

import './style.scss';

export enum SavePropertyTypes {
  Hotel = 'hotel',
  Condo = 'condo',
}

interface IMapStateToProps {
  loginStore: ILoginState;
  menuStore: IMenuState;
  savedPropertiesStore: ISavedPropertiesState;
}

interface IMapDispatchToProps {
  handleSavedProperty: (prop: ISavedProperties) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  isSaved: boolean;

  id: number;
  title: string;
  thumb?: any;
  images: any[];
  starRating: number;
  reviewsCount: number;
  fullAddress: string;
  type: SavePropertyTypes;

  className?: string;
}

function SavePropertyComponent(props: IProps) {
  const { isSaved, type, fullAddress, thumb, images, id, title, starRating, reviewsCount, className, savedPropertiesStore, loginStore, menuStore, intl } = props;
  const { loading } = savedPropertiesStore;
  const { account } = loginStore;
  const { items } = menuStore;

  const [displayLoading, setDisplayLoading] = useState(false);

  const organizationId = items?.organizationId;
  const siteId = items?.siteId;
  
  const isRSITemplate = account?.isRSITemplate;
  const isPublicAccountType = account?.isPublicAccountType;
  const displaySavedProperties = account?.displaySavedProperties;
  const displaySavedPropertiesRsiSiteidList = account?.displaySavedPropertiesRsiSiteidList;
  const displaySavedPropertiesRsiOrgidList = account?.displaySavedPropertiesRsiOrgidList;
  
  const emptyOrgIdList = !!displaySavedPropertiesRsiOrgidList?.length;
  const emptySiteIdList = !!displaySavedPropertiesRsiSiteidList?.length;

  const isOrgId = displaySavedPropertiesRsiOrgidList?.includes(organizationId?.toString() as string);
  const isSiteId = displaySavedPropertiesRsiSiteidList?.includes(siteId?.toString() as string);
  
  const displayRsi = isRSITemplate && ((!emptyOrgIdList && !emptySiteIdList) || isOrgId || isSiteId)
  const displayRest = !isRSITemplate;

  if (isPublicAccountType || !(displaySavedProperties && (displayRsi || displayRest))) {
    return null;
  }

  useEffect(() => {
    if (!loading) {
      setDisplayLoading(false);
    }
  }, [loading]);

  const handleSavedProperty = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    const prop: ISavedProperties = {
      propertyId: id,
      imgSrc: thumb || images[0].url,
      name: title,
      address: fullAddress,
      rating: starRating,
      reviewsCount: reviewsCount,
      type
    }

    setDisplayLoading(true);

    props.handleSavedProperty(prop);
  };
  
  return (
    <div className={`favorite-property-wrapper ${className ? className  : ''}`}>
      <Tooltip title={intl.formatMessage({ id: isSaved ? 'saved.property' : 'save.property' })}>
        <Button onClick={e => handleSavedProperty(e)} shape="circle" icon={displayLoading ? <Spin /> : <HeartFilled style={{ color: isSaved ? "#f40b0b" : "#c2c2c2" }} />} />
      </Tooltip>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore,
    savedPropertiesStore: state.savedPropertiesStore,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  handleSavedProperty: (prop: ISavedProperties) => {
    dispatch(handleSavedProperties(prop));
  }
});

export const SaveProperty = connect(mapStateToProps, mapDispatchToProps)(injectIntl(SavePropertyComponent));
