export enum SortTypes {
  MostBooked = 'MostBooked',
  BiggestSavings = 'BiggestSavings',
  BiggestSavingsPercentage = 'BiggestSavingsPercentage',
  GuestRating = 'GuestRating',
  BrandNames = 'BrandNames',
  PriceAsc = 'PriceAsc',
  PriceDesc = 'PriceDesc',
  DistanceAsc = 'DistanceAsc',
  DistanceDesc = 'DistanceDesc',
  Popularity = 'Popularity',
  Nearest = 'Nearest',
  MostPopular = 'MostPopular',
  ClosestDateRange = 'ClosestDateRange',
  FarthestDateRange = 'FarthestDateRange',
  SavedProperties = 'SavedProperties'
}
