import React from 'react';

export const MapSearchSvg: React.FunctionComponent = () => (
  <svg width="24" height="24" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.8333 21.0003L10.5 19.8337L3.5 23.3337V8.16699L10.5 4.66699L17.5 8.16699L24.5 4.66699V16.3337" stroke="white" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.5 4.66699V19.8337" stroke="white" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17.5 8.16699V14.0003" stroke="white" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M19.2497 23.3333C20.8605 23.3333 22.1663 22.0275 22.1663 20.4167C22.1663 18.8058 20.8605 17.5 19.2497 17.5C17.6388 17.5 16.333 18.8058 16.333 20.4167C16.333 22.0275 17.6388 23.3333 19.2497 23.3333Z" stroke="white" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M21.583 22.75L24.4997 25.6667" stroke="white" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
