import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ICondoDetails } from '@share/common-types';
import { getCondoTextRating, getFullAddress } from '@utils';
import { MINIMUM_RATING_TO_SHOW } from '@constants';
import { getRankingClass, renderNumber } from '@share/utils';
import { HotelStars, SaveProperty, SavePropertyTypes } from '@components';

import './style.scss';

interface IProps {
  condo: ICondoDetails;
  onClickScrollToReviews?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const ZERO = 0;
const ONE = 1;

export function CondoInfo(props: IProps) {

  const { condo, onClickScrollToReviews } = props;
  const { id, title, rating, savedProperty, address, city, country, thumb, media } = condo.condoDetails;

  return (
    <div className="condo-info">
      <div className="condo-info__item">
        <h1 className="condo-info__title secondary-font">{title} </h1>
        <div className="condo-info__link-reviews">
          {rating && rating.value > ZERO ? (
            <p className="condo-info__trust-you-text bold">
              <HotelStars stars={1} />
              <span className={`condo-info__bold-text ${getRankingClass(rating.value)}`} style={{ marginLeft: '5px' }}>
                {renderNumber(rating.value, ONE)}
              </span>
            </p>
          ) : null}
          {rating.reviewsCount ? (
            <p className="condo-info__trust-you-text">
              ({renderNumber(rating.reviewsCount)} <FormattedMessage id="reviews" />)
            </p>
          ) : rating && rating.value > ZERO && rating.value >= MINIMUM_RATING_TO_SHOW ? (
            <p className="condo-info__trust-you-text">
              <FormattedMessage id={getCondoTextRating(Number(renderNumber(rating.value, ONE)))} />
            </p>
          ) : null}
          {rating.trustYouId && (
            <span className="condo-info__link-reviews-text" onClick={onClickScrollToReviews}>
              <FormattedMessage id="view.all" />
            </span>
          )}
        </div>

      </div>

      <SaveProperty
        isSaved={savedProperty}
        id={id}
        title={title}
        thumb={thumb}
        images={media}
        starRating={rating.value}
        reviewsCount={rating.reviewsCount}
        fullAddress={getFullAddress(address, city, '', '', country).join(', ')}
        type={SavePropertyTypes.Condo}
      />
    </div>
  );
}
