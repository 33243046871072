import React from 'react';

import { connect } from 'react-redux';
import { Responsive } from '@share/utils';

import './style.scss';
import { InsuranceResultsPage } from './insurance-results-page';

const InsuranceSearchPageComponent: React.FC = () => {
  return (
    <div className="weeks-results-page">
      <Responsive>
        <InsuranceResultsPage />
      </Responsive>
    </div>
  );
};

export const InsuranceSearchPage = connect()(InsuranceSearchPageComponent);
