import { get, isEmpty } from "lodash";

import * as H from 'history';

import { NULL_VALUE } from "@constants";
import { Routes, ALL_INCLUSIVE_KEY, DEAL_HOME_LABEL, DETAIL_TYPE_PARAM_LABEL, DETAIL_TYPE_STATIC } from "@share/constants";
import { UrlUtils } from "./urls";

const FIRST_ELEMENT = 1;
const SECOND_ELEMENT = 2;
const THIRD_ELEMENT = 3;
const FOURTH_ELEMENT = 4;

export const ERROR_PAGE = 'error';
export const VACATIONS_PAGE = 'vacations';
export const SIGNIN_PAGE = 'signin';
export const SIGNUP_PAGE = 'signup';
export const SIGNOUT_PAGE = 'signout';
export const WIDGET_PAGE = 'widget';
export const BACKOFFICE_PAGE = 'backoffice';
export const RESERVATIONS_PAGE = 'reservations';
export const HOME_PAGE = 'home';
export const GETAWAYS_PAGE = 'getaways';
export const HOMES_PAGE = 'homes';
export const CRUISES_PAGE = 'cruise';
export const GETAWAY_PAGE = 'getaway';
export const MEMBER_PAGE = 'member';
export const ALL_INCLUSIVE_PAGE = 'all-inclusives';
export const CONTACT_US_PAGE = 'contact-us';
export const DAILY_BENEFITS_PAGE = 'daily-benefits';
export const RESOURCES_PAGE = 'travel-resources';
export const MASTER_CLASSES_PAGE = 'master-classes-register';
export const CONSIERGE_PAGE = 'concierge-travel-team';
export const MAGAZINE_PAGE = 'magazine';
export const RESERVATION_CANCEL_PAGE = 'reservation-cancellation';
export const RESERVATION_CANCEL_REQUEST_PAGE = 'request-cancel-reservation';
export const SEARCH = 'search';
export const HOTEL = 'hotel';
export const BOOK = 'book';
export const CONDO = 'condo';
export const DEALS = 'deals';
export const CONDOBOOK = 'unit-book';
export const PASSWORD = 'password';
export const CHANGE = 'change';
export const QUOTES = 'quotes';
export const SAVED_PROPERTY = 'saved-properties';
export const THREE_DS = 'three-ds';
export const RESERVATIONS = 'reservations';
export const TEST_DRIVE_MANAGE = 'test-drive-manage';

export const CONDOS = 'condos';
export const WORLD_MAP = 'world-map';
export const WEEKS = 'weeks';

export const REQUEST_CANCEL_RESERVATION = 'request-cancel-reservation';
export const RESERVATION_CANCELLATION = 'reservation-cancellation';

export const isHomeFromPath = (history: H.History<H.LocationState>): boolean => {
    return history.location.pathname.split('/')[SECOND_ELEMENT] === HOME_PAGE;
}

export const isGetawaysFromPath = (history: H.History<H.LocationState>): boolean => {
    return history.location.pathname.split('/')[SECOND_ELEMENT] === GETAWAYS_PAGE;
}

export const isCondoFromPath = (history: H.History<H.LocationState>): boolean => {
    const historySplit = history.location.pathname.split('/');
    const path = historySplit[SECOND_ELEMENT];
    return path === CONDO || path === CONDOBOOK;
}

export const isCondoEngineFromPath = (history: H.History<H.LocationState>): boolean => {
    const historySplit = history.location.pathname.split('/');
    const path = historySplit[SECOND_ELEMENT];
    return (path === CONDO || path === CONDOBOOK) && historySplit?.length > 3;
}

export const isReservationCancellationFromPath = (history: H.History<H.LocationState>): boolean => {
    const historySplit = history.location.pathname.split('/');
    return historySplit[FIRST_ELEMENT] === REQUEST_CANCEL_RESERVATION || historySplit[FIRST_ELEMENT] === RESERVATION_CANCELLATION;
}

export const isCondoBookFromPath = (history: H.History<H.LocationState>): boolean => {
    const path = history.location.pathname.split('/')[SECOND_ELEMENT];
    return path === CONDOBOOK;
}

export const isCondoDealsFromPath = (history: H.History<H.LocationState>): boolean => {
    const pathSplit = history.location.pathname.split('/');
    const condoPath = pathSplit[SECOND_ELEMENT];
    const dealsPath = pathSplit[THIRD_ELEMENT];
    return condoPath === CONDO && dealsPath === DEALS;
}

export const isReservationCancelFromPath = (history: H.History<H.LocationState>): boolean => {
    return history.location.pathname.split('/')[SECOND_ELEMENT] === RESERVATION_CANCEL_PAGE;
}

export const isReservationCancelRequestFromPath = (history: H.History<H.LocationState>): boolean => {
    return history.location.pathname.split('/')[SECOND_ELEMENT] === RESERVATION_CANCEL_REQUEST_PAGE;
}

export const isAnyReservationCancelFromPath = (history: H.History<H.LocationState>): boolean => {
    return isReservationCancelFromPath(history) || isReservationCancelRequestFromPath(history);
}

export const isThreeDSFromPath = (history: H.History<H.LocationState>): boolean => {
    return history.location.pathname.split('/')[SECOND_ELEMENT] === THREE_DS;
}

export const isMagazineFromPath = (history: H.History<H.LocationState>): boolean => {
    return history.location.pathname.split('/')[SECOND_ELEMENT] === MAGAZINE_PAGE;
}

export const isDailyBenefitsFromPath = (history: H.History<H.LocationState>): boolean => {
    return history.location.pathname.split('/')[SECOND_ELEMENT] === DAILY_BENEFITS_PAGE;
}

export const isTravelResourcesFromPath = (history: H.History<H.LocationState>): boolean => {
    return history.location.pathname.split('/')[SECOND_ELEMENT] === RESOURCES_PAGE;
}

export const isMasterClassesRegisterFromPath = (history: H.History<H.LocationState>): boolean => {
    return history.location.pathname.split('/')[SECOND_ELEMENT] === MASTER_CLASSES_PAGE;
}
export const isConsiergeTravelTeamFromPath = (history: H.History<H.LocationState>): boolean => {
    return history.location.pathname.split('/')[SECOND_ELEMENT] === CONSIERGE_PAGE;
}

export const isMemberFromPath = (history: H.History<H.LocationState>): boolean => {
    return history.location.pathname.split('/')[SECOND_ELEMENT] === MEMBER_PAGE;
}

export const isAllInclusiveFromPath = (history: H.History<H.LocationState>): boolean => {
    return history.location.pathname.split('/')[SECOND_ELEMENT] === ALL_INCLUSIVE_PAGE;
}

export const isAllInclusiveFromQuery = (): boolean => {
    const values = UrlUtils.getValues();
    return (values[DEAL_HOME_LABEL] && (values[DEAL_HOME_LABEL] as string) === ALL_INCLUSIVE_KEY) as boolean;
}

export const isContactUsFromPath = (history: H.History<H.LocationState>): boolean => {
    return history.location.pathname.split('/')[SECOND_ELEMENT] === ALL_INCLUSIVE_PAGE;
}

export const isGatewayFromPath = (history: H.History<H.LocationState>): boolean => {
    return history.location.pathname.split('/')[SECOND_ELEMENT] === GETAWAY_PAGE;
}

export const isHomesFromPath = (history: H.History<H.LocationState>): boolean => {
    return history.location.pathname.split('/')[SECOND_ELEMENT] === HOMES_PAGE;
}

export const isCruisesFromPath = (history: H.History<H.LocationState>): boolean => {
    return history.location.pathname.split('/')[SECOND_ELEMENT] === CRUISES_PAGE;
}

export const isWidgetFromPath = (history: H.History<H.LocationState>): boolean => {
    return history.location.pathname.split('/')[SECOND_ELEMENT] === WIDGET_PAGE;
}

export const isCondoWorldFromPath = (history: H.History<H.LocationState>): boolean => {
    const pathSplit = history.location.pathname.split('/');
    const isCondoWorldMap =
      pathSplit[FIRST_ELEMENT] === CONDOS &&
      pathSplit[SECOND_ELEMENT] === WORLD_MAP;

    return isCondoWorldMap;
}

export const isCondoWeeksFromPath = (history: H.History<H.LocationState>): boolean => {
    const pathSplit = history.location.pathname.split('/');
    const isCondoWorldMap =
      pathSplit[FIRST_ELEMENT] === CONDOS &&
      pathSplit[SECOND_ELEMENT] === WEEKS;

    return isCondoWorldMap;
}

export const isBackofficeFromPath = (history: H.History<H.LocationState>): boolean => {
    return history.location.pathname.split('/')[SECOND_ELEMENT] === BACKOFFICE_PAGE;
}

export const isReservationFromPath = (history: H.History<H.LocationState>): boolean => {
    return history.location.pathname.split('/')[SECOND_ELEMENT] === RESERVATIONS_PAGE;
}

export const isErrorFromPath = (history: H.History<H.LocationState>): boolean => {
    return history.location.pathname.split('/')[FIRST_ELEMENT] === ERROR_PAGE;
}

export const isVacationRentalsFromPath = (history: H.History<H.LocationState>): boolean => {
    const paths = history.location.pathname.split('/');
    return get(paths, `[${paths.length -1}]`) === VACATIONS_PAGE;
}

export const isSignInFromPath = (history: H.History<H.LocationState>): boolean => {
    return history.location.pathname.split('/')[SECOND_ELEMENT] === SIGNIN_PAGE;
}

export const isSignUpFromPath = (history: H.History<H.LocationState>): boolean => {
    return history.location.pathname.split('/')[SECOND_ELEMENT] === SIGNUP_PAGE;
}

export const isSignOutFromPath = (history: H.History<H.LocationState>): boolean => {
    return history.location.pathname.split('/')[SECOND_ELEMENT] === SIGNOUT_PAGE;
}

export const isResultsFromPath = (history: H.History<H.LocationState>): boolean => {
    return history.location.pathname.split('/')[SECOND_ELEMENT] === SEARCH;
}
export const isDetailFromPath = (history: H.History<H.LocationState>): boolean => {
    return history.location.pathname.split('/')[SECOND_ELEMENT] === HOTEL;
}
export const isBookFromPath = (history: H.History<H.LocationState>): boolean => {
    return history.location.pathname.split('/')[SECOND_ELEMENT] === BOOK;
}

export const isChangePasswordFromPath = (history: H.History<H.LocationState>): boolean => {
    return history.location.pathname.split('/')[SECOND_ELEMENT] === PASSWORD && history.location.pathname.split('/')[THIRD_ELEMENT] === CHANGE;
}

export const isQuotesFromPath = (history: H.History<H.LocationState>): boolean => {
    return history.location.pathname.split('/')[THIRD_ELEMENT] === QUOTES;
}

export const isSavedPropertyFromPath = (history: H.History<H.LocationState>): boolean => {
    return history.location.pathname.split('/')[THIRD_ELEMENT] === SAVED_PROPERTY;
}

export const isReservationsFromPath = (history: H.History<H.LocationState>): boolean => {
    return history.location.pathname.split('/')[THIRD_ELEMENT] === RESERVATIONS;
}

export const isTestDriveManagerFromPath = (history: H.History<H.LocationState>): boolean => {
    return history.location.pathname.split('/')[THIRD_ELEMENT] === TEST_DRIVE_MANAGE;
}

export const getAccountUsernameFromPath = (history: H.History<H.LocationState>): string => {
    const paths = history.location.pathname.split('/');
    const path = get(paths, '[2]', NULL_VALUE);
    if (`/${path}` === Routes.Widget && paths.length === 5) {
        return get(paths, '[4]', NULL_VALUE);
    }
    return get(paths, '[1]', NULL_VALUE);
}

export const getKeyCancellationFromPath = () => {    
    const paths = window.location.pathname.split('/');
    const tempKey = get(paths, '[3]', NULL_VALUE);

    return tempKey;
}

export const getCancelIdCancellationFromPath = (history: H.History<H.LocationState>) => {    
    if (isReservationCancelRequestFromPath(history)) {
        return getKeyCancellationFromPath();
    }
    return NULL_VALUE;
}

export const getTempKeyCancellationFromPath = (history: H.History<H.LocationState>) => {    
    if (isReservationCancelFromPath(history)) {
        return getKeyCancellationFromPath();
    }
    return NULL_VALUE;
}

export const getKeyQuotesFromPath = (): string => {    
    const paths = window.location.pathname.split('/');
    const tempKey = get(paths, '[4]', NULL_VALUE);

    return tempKey;
}

export const getHotelIdFromPath = (history: H.History<H.LocationState>): number => {
    const accountUsername = getAccountUsernameFromPath(history);
    const position = isEmpty(accountUsername)? SECOND_ELEMENT : THIRD_ELEMENT;      
    return Number(history.location.pathname.split('/')[position]);
}

export const getCarIdFromPath = (history: H.History<H.LocationState>): string => {
    const accountUsername = getAccountUsernameFromPath(history);
    const position = isEmpty(accountUsername)? THIRD_ELEMENT : FOURTH_ELEMENT;   
    
    return history.location.pathname.split('/')[position];
}

export const getPackageIdFromPath = (history: H.History<H.LocationState>): string => {
    const accountUsername = getAccountUsernameFromPath(history);
    const position = isEmpty(accountUsername)? THIRD_ELEMENT : FOURTH_ELEMENT;      
    return history.location.pathname.split('/')[position];
}

const getAccountNumberPath = (history: H.History<H.LocationState>): string => {
    const accountUsername = getAccountUsernameFromPath(history);
    return isEmpty(accountUsername) ? '' : `/${accountUsername}`; 
}

export const getHotelRoutePath = (hotelId: number, history: H.History<H.LocationState>, isVacationRentals?: boolean | null | undefined, isStaticDetail?: boolean, hotelName?: string): string => {
    const accountPath = getAccountNumberPath(history);

    if (isVacationRentals) {
        return `${accountPath}${Routes.Hotel}/${hotelId}/${VACATIONS_PAGE}${location.search}`;
    }

    if (isStaticDetail && hotelName) {
        const name = hotelName.replace(/\s+/g, '-').toLowerCase().replace(/\.|\,/g, '');
        return `${accountPath}${Routes.Hotel}/${hotelId}/${name}${location.search}`;
    }
    return `${accountPath}${Routes.Hotel}/${hotelId}${location.search}`;
}

export const getCarRoutePath = (carId: string, history: H.History<H.LocationState>): string => {
    const accountPath = getAccountNumberPath(history);
    return `${accountPath}${Routes.CarsDetails}/${carId}${location.search}`;
}

export const getCondoRoutePath = (condoId: number, history: H.History<H.LocationState>): string => {
    const accountPath = getAccountNumberPath(history);
    return `${accountPath}${Routes.Condo}/${condoId}${location.search}`;
}

export const getBookRoutePath = (hotelId: number, selectedPackageId: string, history: H.History<H.LocationState>, isVacationRentals?: boolean, isStaticDetail?: boolean): string => {
    const accountPath = getAccountNumberPath(history);

    const staticDetailsParam = `${!isEmpty(location.search) ? `&` : '?'}${DETAIL_TYPE_PARAM_LABEL}=${DETAIL_TYPE_STATIC}`;
    const params = `${location.search}${isStaticDetail ? staticDetailsParam : ''}`;

    if (isVacationRentals) {
        return `${accountPath}${Routes.Book}/${hotelId}/${selectedPackageId}/${VACATIONS_PAGE}${params}`;
    }
    return `${accountPath}${Routes.Book}/${hotelId}/${selectedPackageId}${params}`;
}

export const getCarsBookRoutePath = (carId: string, history: H.History<H.LocationState>): string => {
    const accountPath = getAccountNumberPath(history);
    return `${accountPath}${Routes.CarsBook}/${carId}${location.search}`;
}