
import { FormattedMessage } from 'react-intl';
// @ts-ignore
import JotformEmbed from 'react-jotform-embed';
import CardImg from '@assets/images/inclusives/all-inclusives-submit-request-card-img.jpg';

import './style.scss';

const iFrameProps = {
  src: `https://form.jotform.com/220894480632156`,
  frameBorder: '0',
};

interface IProps {
  isCondoPage?: boolean;
}

export function SubmitRequestMain(props: IProps) {
  const { isCondoPage } = props;
  
  return (
    <div className="submit-request-main-wrapper">
      <div className="submit-request-main-wrapper__wrapper">
        <JotformEmbed {...iFrameProps} />

        {!isCondoPage ? (
          <div className="submit-request-main-wrapper__card">
            <img className="submit-request-main-wrapper__card-img" src={CardImg} />
            <div className="submit-request-main-wrapper__card-text">
              <h2 className="submit-request-main-wrapper__card-heading secondary-font">
                <FormattedMessage id="all.inclusives.submit.card.heading" />
              </h2>
              <p className="submit-request-main-wrapper__card-paragraph">
                <FormattedMessage id="all.inclusives.submit.card.paragraph" />
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
