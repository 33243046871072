import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Urls } from '@share/constants';
import { AppThunk, axiosInstance, getHeaders, getSelectedCurrency } from '@share/utils';
import { Moment } from 'moment';
import { ICard, IUser } from '../../components/pages/insurance/insurance-quote-modal';

// Interfaces for product details
export interface IProductDetails {
  productCode: string;
  productName: string;
  planCode: string;
  planDescription: string;
  price: number;
}

// Interface for policy holder's address
export interface IAddress {
  city: string;
  isoCountry: string;
  isoStateOrProvince: string;
  postalCode: string;
  street1: string;
  type: string;
}

// Interface for policy holder
export interface IPolicyHolder {
  insuredType: string;
  firstName: string;
  middleName: string;
  lastName: string;
  addresses: IAddress;
  email: string;
}

// Interface for checkout details
export interface ICheckoutResponse {
  effectiveDate: string;
  expirationDate: string;
  policyHolder: IPolicyHolder;
  policyLookup: string;
  policyNumber: string;
  policyStatus: string;
  productDetail: IProductDetails;
  totalPrice: number;
  loading: boolean;
  error: string;
}

// Interface for quote details
export interface IQuoteResponse {
  quoteId: string;
  productDetails: IProductDetails;
  loading: boolean;
  error: string;
}

// Final interface for the entire insurance quote state
export interface IQuoteCheckoutResponse {
  quote: IQuoteResponse;
  checkout: ICheckoutResponse;
}

export const insuranceQuoteInitialState: IQuoteCheckoutResponse = {
  quote: {
    quoteId: '',
    productDetails: {
      productCode: '',
      productName: '',
      planCode: '',
      planDescription: '',
      price: 0,
    },
    loading: false,
    error: '',
  },
  checkout: {
    effectiveDate: '',
    expirationDate: '',
    policyHolder: {
      insuredType: '',
      firstName: '',
      middleName: '',
      lastName: '',
      addresses: {
        city: '',
        isoCountry: '',
        isoStateOrProvince: '',
        postalCode: '',
        street1: '',
        type: '',
      },
      email: '',
    },
    policyLookup: '',
    policyNumber: '',
    policyStatus: '',
    productDetail: {
      productCode: '',
      productName: '',
      planCode: '',
      planDescription: '',
      price: 0,
    },
    totalPrice: 0,
    loading: false,
    error: '',
  },
};

const insuranceQuoteSlice = createSlice({
  name: 'insuranceQuote',
  initialState: insuranceQuoteInitialState,
  reducers: {
    setQuote: (
      state: IQuoteCheckoutResponse,
      { payload }: PayloadAction<{ quote: IQuoteResponse }>,
    ) => {
      state.quote = payload.quote;
    },
    setQuoteLoading: (
      state: IQuoteCheckoutResponse,
      { payload }: PayloadAction<{ loading: boolean }>,
    ) => {
      state.quote.loading = payload.loading;
    },
    setQuoteError: (
      state: IQuoteCheckoutResponse,
      { payload }: PayloadAction<{ error: string }>,
    ) => {
      state.quote.error = payload.error;
    },
    clearQuoteError: (state: IQuoteCheckoutResponse) => {
      state.quote.error = '';
    },
    setCheckoutLoading: (
      state: IQuoteCheckoutResponse,
      { payload }: PayloadAction<{ loading: boolean }>,
    ) => {
      state.checkout.loading = payload.loading;
    },
    setCheckoutError: (
      state: IQuoteCheckoutResponse,
      { payload }: PayloadAction<{ error: string }>,
    ) => {
      state.checkout.error = payload.error;
    },
    clearCheckoutError: (state: IQuoteCheckoutResponse) => {
      state.checkout.error = '';
    },
    setCheckout: (state: IQuoteCheckoutResponse, { payload }: PayloadAction<{ checkout: any }>) => {
      state.checkout = payload.checkout;
    },
    clearQuoteCheckout: (state: IQuoteCheckoutResponse) => {
      state.quote = insuranceQuoteInitialState.quote;
      state.checkout = insuranceQuoteInitialState.checkout;
    },
  },
});

export const insuranceQuoteActions = insuranceQuoteSlice.actions;

export const insuranceQuoteReducer = insuranceQuoteSlice.reducer;

export const PostInsuranceQuote = (
  total: number | null,
  dates: [Moment | null, Moment | null] | null,
  destinationIsoCountryCode: string | null,
  isoStateOrProvince: string | null,
  numberOfTravelers: number | null,
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      dispatch(insuranceQuoteActions.setQuoteLoading({ loading: true }));
      const { loginStore } = getState();
      const { account } = loginStore;
      const currency = getSelectedCurrency(account);
      const url = Urls.InsuranceGetQuote;
      const startDateTime = dates && dates[0] ? dates[0].format('YYYY-MM-DD') : null;
      const endDateTime = dates && dates[1] ? dates[1].format('YYYY-MM-DD') : null;

      const payload = {
        totalPrice: {
          isoCurrencyCode: currency,
          value: total,
        },
        booking: {
          startDateTime,
          endDateTime,
          destinationIsoCountryCode,
        },
        travelers: {
          numberOfTravelers,
          residency: {
            isoStateOrProvince,
            isoCountry: 'US',
          },
        },
      };

      const { data } = await axiosInstance.post(url, payload, { ...getHeaders() });
      const quote: IQuoteResponse = data;
      dispatch(insuranceQuoteActions.setQuote({ quote }));
      dispatch(insuranceQuoteActions.setQuoteLoading({ loading: false }));
    } catch (err: any) {
      const error = err.response ? JSON.parse(err?.response?.data)?.reason : 'Not Identified Error';
      dispatch(insuranceQuoteActions.setQuoteError({ error }));
      dispatch(insuranceQuoteActions.setQuoteLoading({ loading: false }));
    }
  };
};

export const PostInsuranceCheckout = (
  quoteId: string,
  PolicyBookingId: string,
  users: IUser[],
  card: ICard,
): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(insuranceQuoteActions.setCheckoutLoading({ loading: true }));
      const url = Urls.InsuranceCheckout;

      const travelers = users.map((u) => ({
        firstName: u?.firstName,
        middleName: u?.middleName,
        lastName: u?.lastName,
        addresses: [
          {
            type: 'Residency',
            street1: u?.street,
            city: u?.city,
            postalCode: u?.postalCode,
          },
        ],
        email: u?.email,
        phone: u?.phone,
      }));

      const paymentDetail = {
        cardDetails: {
          cardholderName: card?.holderName,
          cardholderAddress: {
            type: card?.type,
            isoStateOrProvince: card?.state,
            postalCode: card?.zipCode,
            isoCountry: card?.country,
          },
          cardType: card?.cardType,
          cardNumber: card?.cardNumber,
          expirationDate: card?.expireDate,
          securityCode: card?.cvv,
        },
      };

      const payload = {
        quoteId,
        PolicyBookingId,
        travelers,
        paymentDetail,
      };

      const { data } = await axiosInstance.post(url, payload, { ...getHeaders() });
      dispatch(insuranceQuoteActions.setCheckout({ checkout: data?.policyDetail }));
      dispatch(insuranceQuoteActions.setCheckoutLoading({ loading: false }));
    } catch (err: any) {
      const error = err.response ? JSON.parse(err?.response?.data)?.reason : 'Not Identified Error';
      dispatch(insuranceQuoteActions.setCheckoutError({ error }));
      dispatch(insuranceQuoteActions.setCheckoutLoading({ loading: false }));
    }
  };
};
