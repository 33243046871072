
import { RSIUpgradeEliteProfile } from "./rsi-upgrade-elite";
import { RSIUpgradeOldProfile, RSIUpgradeOldProfileHome } from "./rsi-upgrade-old";
import { RSIUpgradePrimeProfile } from "./rsi-upgrade-prime";
import { SportsIllustratesProfile } from "./sports-illustrates";

export const RSI_UPGRADE_ELITE_PROFILE = 'RSI_UPGRADE_ELITE';
export const RSI_UPGRADE_PRIME_PROFILE = 'RSI_UPGRADE_PRIME';
export const RSI_UPGRADE_OLD_PROFILE = 'RSI_UPGRADE_OLD';
export const SPORTS_ILLUSTRATES_PROFILE = 'SPORTS_ILLUSTRATES';

export enum IProfileEnum {
  SportsIllustrates = SPORTS_ILLUSTRATES_PROFILE,
  RSIUpgradeElite = RSI_UPGRADE_ELITE_PROFILE,
  RSIUpgradePrime = RSI_UPGRADE_PRIME_PROFILE,
  RSIUpgradeOld = RSI_UPGRADE_OLD_PROFILE
};

export enum IPageType {
  Home,
  Flow,
}

export const GetProfile = (profile: IProfileEnum, pageType?: IPageType) => {
  if (profile === IProfileEnum.SportsIllustrates) {
    return SportsIllustratesProfile;
  } else if (profile === IProfileEnum.RSIUpgradeElite) {
    return RSIUpgradeEliteProfile;
  } else if (profile === IProfileEnum.RSIUpgradePrime) {
    return RSIUpgradePrimeProfile;
  } else if (profile === IProfileEnum.RSIUpgradeOld) {
    if (pageType === IPageType.Home) {
      return RSIUpgradeOldProfileHome;
    }
    return RSIUpgradeOldProfile;
  }
  return null;
}