
import { connect } from 'react-redux';
import { Modal } from 'antd';

import { CondoWorldMap } from '@share/components';

import './style.scss';
import { useEffect, useState } from 'react';

interface IProps {
  visible: boolean;

  onCancel: () => void;
}

function CondoSearchInventoryModalComponent(props: IProps) {
  const { visible, onCancel } = props;

  const [key, setKey] = useState(new Date().getTime().toString());

  useEffect(() => {
    if (visible) {
      setKey(new Date().getTime().toString());
    }
  },[visible]);

  return (
    <Modal
      className="condo-all-inventory"
      open={visible}
      footer={null}
      onCancel={onCancel}
      wrapClassName="condo-all-inventory-wrapper"
    >
      <CondoWorldMap key={key} counterOnParent />
    </Modal>
  );
}

export const CondoSearchInventoryModal = connect()(CondoSearchInventoryModalComponent);
