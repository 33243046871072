import { connect, useSelector } from 'react-redux';

import { FormattedMessage, useIntl } from 'react-intl';

import './style.scss';
import { IQuoteCheckoutResponse } from '../../../../../store/slices/insurance-quote';
import { BookingConfirmedSvg } from '@assets';
import { ICard, IUser } from '../component';
import { FunctionalRootState } from '@share/utils';
import { ICondoStates, IRciCountriesCodes } from '@common-types';
import { useMemo } from 'react';

interface IProps {
  insuranceQuoteStore: IQuoteCheckoutResponse;
  card: ICard;
  users: IUser[];
}

function InsuranceConfirmedModalComponent(props: IProps) {
  const { insuranceQuoteStore, card, users } = props;
  const { condosCountriesCode }: any = useSelector(
    (state: FunctionalRootState) => state.condosStaticStore,
  );
  const state = useMemo(
    () =>
      condosCountriesCode.rciCountries
        .find((c: IRciCountriesCodes) => c.isoCode === 'US')
        .states.find((s: ICondoStates) => s.code === card.state),
    [condosCountriesCode, insuranceQuoteStore],
  );

  const intl = useIntl();

  const listUsers = users.map((u: any) =>
    `${u?.firstName} ${u?.middleName} ${u?.lastName} - ${u?.email} - ${u?.phone}`.trim(),
  );

  return (
    <>
      <div className="success-logo-search-container mb-3">
        <div className="col-md-3">
          <BookingConfirmedSvg />
        </div>
        <div className="col-md-8 ml-2" style={{ padding: '8px' }}>
          <p className="title-name">
            <FormattedMessage id="thank.you" />,{' '}
            {insuranceQuoteStore.checkout.policyHolder.firstName}{' '}
            {insuranceQuoteStore.checkout.policyHolder.lastName}!
          </p>
          <p className="title-confirmed">
            {insuranceQuoteStore.checkout.productDetail.planDescription}{' '}
            <FormattedMessage id="is.confirmed" />.
          </p>
        </div>
      </div>
      <h3>
        <strong>
          <FormattedMessage id="account.users.title" />
        </strong>
      </h3>
      {listUsers.map((u: string) => (
        <p className="mb-3 success-logo-search-container-modalText">{u}</p>
      ))}
      <h3>
        <strong>{intl.formatMessage({ id: 'billing.address' })}</strong>
      </h3>
      <p className="success-logo-search-container-modalText">
        {' '}
        <strong>{intl.formatMessage({ id: 'name' })}:</strong> {card.holderName}
      </p>
      <p className="success-logo-search-container-modalText">
        {' '}
        <strong>{intl.formatMessage({ id: 'city' })}:</strong> {card.city}
      </p>
      <p className="success-logo-search-container-modalText">
        <strong>{intl.formatMessage({ id: 'state' })}:</strong> {state ? state?.name : card.state}
      </p>
      <p className="success-logo-search-container-modalText">
        {' '}
        <strong>{intl.formatMessage({ id: 'cell.phone.type' })}:</strong> {card.type}
      </p>
      <p className="success-logo-search-container-modalText">
        {' '}
        <strong>{intl.formatMessage({ id: 'postal-code' })}:</strong> {card.zipCode}
      </p>
    </>
  );
}

export const InsuranceConfirmedModal = connect()(InsuranceConfirmedModalComponent);
