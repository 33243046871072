import React from 'react';

import Highlighter from 'react-highlight-words';
import debounce from 'lodash/debounce';

import { Action } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Select, Spin, AutoComplete } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import { RenderDOMFunc } from 'rc-select/lib/BaseSelect';

import { RootState } from '@share/utils';
import { ENTER_KEY } from '@share/constants';
import { filtersActions, getHotelsAutocomplete, IFiltersState, ILoginState } from '@share/store/slices';
import { Magnifier } from '@assets';
import { IHotelAutocompleteItem } from '@share/common-types';
import { NULL_VALUE, UNDEFINED_VALUE } from '@constants';

import { faBuilding, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './style.scss';

interface IMapStateToProps {
  filtersStore: IFiltersState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  getHotelsAutocomplete: (search: string) => void;
  selectAutocompleteItem: (item: IHotelAutocompleteItem) => void;
  setAutocompleteItems: (items: IHotelAutocompleteItem[]) => void;
  selectAutocompleteLabel: (label: string) => void;
  setAutocompleteLoading: (load: boolean) => void;
}

interface IState {
  isDropdownOpen: boolean;
  isFocused: boolean;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps {
  title: string;
  onSaveValue: () => void;
  className?: string;
  isMapView?: boolean;
  disabled?: boolean;
}

const MIN_SEARCH_LENGTH = 3;
const SEARCH_DEBOUNCE = 500;
const LIST_HEIGHT = 400;
const zero = 0;
const { Option, OptGroup } = Select;

class SearchPropertyNameComponent extends React.Component<IProps, IState> {
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  state: IState = {
    isFocused: false,
    isDropdownOpen: false,
  };
  lastSearch = '';
  lastSelectedValue;
  lastSelectedLocation: IHotelAutocompleteItem = UNDEFINED_VALUE;

  constructor(props: IProps) {
    super(props);

    this.lastSelectedValue = props.filtersStore.selectedAutocompleteLabel
      ? props.filtersStore.selectedAutocompleteLabel
      : undefined;
  }

  onChange = (value: SelectValue, option: any) => {
    const {
      setAutocompleteItems,
      selectAutocompleteItem,
      selectAutocompleteLabel,
      onSaveValue,
      filtersStore,
    } = this.props;
    const { autocompleteItems } = filtersStore;
    const location = autocompleteItems.find(
      ({ hotelName }) => hotelName === (option?.key as string),
    );
    const valueStr = (value || '').toString();

    this.setState({ isDropdownOpen: false });
    selectAutocompleteLabel((value as string) || '');
    setAutocompleteItems([]);
    selectAutocompleteItem(location || NULL_VALUE);
    this.lastSelectedLocation = location || NULL_VALUE;

    if ((!value || location) && this.lastSelectedValue !== valueStr) {
      this.lastSelectedValue = valueStr;
      onSaveValue();
    }
  };

  onClick = (event: React.MouseEvent<HTMLElement>) => {
    const { filtersStore, setAutocompleteItems } = this.props;
    const { selectedAutocompleteLabel } = filtersStore;
    const { isDropdownOpen } = this.state;
    if (
      selectedAutocompleteLabel &&
      !isDropdownOpen &&
      event.target &&
      (event.target as HTMLElement).classList.contains('ant-select-selection-search-input')
    ) {
      this.onSearch(selectedAutocompleteLabel);
    } else if (
      (event.target as HTMLElement).classList.contains('ant-select-item-option-selected') ||
      (event.target as HTMLElement).closest('.ant-select-item-option-selected')
    ) {
      this.setState({ isDropdownOpen: false });
      setAutocompleteItems([]);
    }
  };

  getNotFoundContent = (): React.ReactNode => {
    const { filtersStore } = this.props;
    const { selectedAutocompleteLabel, autocompleteLoading } = filtersStore;
    let message: React.ReactNode;

    if (selectedAutocompleteLabel?.length < MIN_SEARCH_LENGTH) {
      message = <FormattedMessage id="search.min.symbols" />;
    } else if (autocompleteLoading) {
      message = <FormattedMessage id="searching" />;
    } else {
      message = <FormattedMessage id="nothing.found" />;
    }

    return <div className="hotel-dropdown__nothing-found">{message}</div>;
  };

  onFocus = () => {
    this.setState({ isFocused: true });
    this.onSearch(this.props.filtersStore.selectedAutocompleteItem?.hotelName || '');
  };

  onBlur = () => {
    this.setState({ isFocused: false });
  };

  onSearch = (search: string) => {
    this.lastSearch = search;
    this.setState({ isDropdownOpen: !!search });

    if (search.length >= MIN_SEARCH_LENGTH) {
      this.debouncedSearch(search);
    } else {
      this.props.setAutocompleteItems([]);
    }
  };

  debouncedSearch = debounce((search: string) => {
    this.props.getHotelsAutocomplete(search);
  }, SEARCH_DEBOUNCE);

  handleClickOutside = (event: MouseEvent) => {
    if (
      this.wrapperRef &&
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target as Node)
    ) {
      this.setState({ isDropdownOpen: false });
      this.props.setAutocompleteItems([]);
      this.lastSearch = '';
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  getPopupContainer = (): HTMLElement => {
    const element = this.wrapperRef ? this.wrapperRef.current : document.body;
    return element as HTMLElement;
  };

  getOptions = (): React.ReactNode | React.ReactNode[] | null => {
    const { autocompleteItems } = this.props.filtersStore;

    return autocompleteItems?.length ? (
      <OptGroup label={<FormattedMessage id="hotels" />}>
        {autocompleteItems.map((item: IHotelAutocompleteItem) => {
          return (
            <Option key={item.hotelName} value={item.hotelName} className="hotel-dropdown__option">
              <div className="hotel-dropdown__option-icon">
                <FontAwesomeIcon icon={faBuilding} style={{ color: '#D2D2D2' }} />
              </div>
              <div className="hotel-dropdown__location-name">
                <Highlighter
                  highlightClassName="bold-text"
                  searchWords={[this.lastSearch.trim()]}
                  autoEscape={true}
                  textToHighlight={item.hotelName}
                />
                <div className="hotel-dropdown__option-address">{item.address}</div>
              </div>
            </Option>
          );
        })}
      </OptGroup>
    ) : null;
  };

  onKeyDown = (e: React.KeyboardEvent) => {
    const { isDropdownOpen, isFocused } = this.state;
    const {
      filtersStore,
      setAutocompleteItems,
      onSaveValue,
      selectAutocompleteItem,
      selectAutocompleteLabel,
    } = this.props;
    const { autocompleteItems } = filtersStore;

    if (
      e.code === ENTER_KEY &&
      isDropdownOpen &&
      isFocused &&
      autocompleteItems?.length &&
      !this.lastSelectedLocation
    ) {
      const item = autocompleteItems[zero];

      this.setState({ isDropdownOpen: false });
      selectAutocompleteLabel(item.hotelName || '');
      selectAutocompleteItem(item || null);
      setAutocompleteItems([]);
      onSaveValue();
    }
  };

  render(): React.ReactNode {
    const { isDropdownOpen, isFocused } = this.state;
    const { filtersStore, loginStore, title, className = '', isMapView = false, disabled = false } = this.props;
    const { autocompleteLoading, selectedAutocompleteLabel } = filtersStore;
    const { account } = loginStore;

    const generalBorderRadius: any = account?.generalBorderRadius ? account?.generalBorderRadius : null;

    return (
      <div className={`hotel-dropdown ${className}`} ref={this.wrapperRef}>
        <p className="hotel-dropdown__title secondary-font">
          <FormattedMessage id={title} />
        </p>
        <div style={{ position: 'relative' }}>
          <AutoComplete
            onKeyDown={this.onKeyDown}
            virtual={false}
            showSearch={true}
            className={!isEmpty(generalBorderRadius) ? `border-radius-${generalBorderRadius}` : ''}
            placeholder={
              <FormattedMessage
                id={isMapView ? 'map.search.placeholder' : 'location.input.placeholder'}
              />
            }
            disabled={disabled}
            onChange={this.onChange}
            onSearch={this.onSearch}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            showArrow={false}
            open={isDropdownOpen}
            defaultActiveFirstOption={false}
            filterOption={() => true}
            autoClearSearchValue={false}
            getPopupContainer={this.getPopupContainer as RenderDOMFunc}
            listHeight={LIST_HEIGHT}
            allowClear={true}
            value={selectedAutocompleteLabel}
            notFoundContent={this.getNotFoundContent()}
            onClick={this.onClick}
            clearIcon={
              <span className="hotel-dropdown__clear">
                {autocompleteLoading && isDropdownOpen ? <Spin /> : <FontAwesomeIcon icon={faCircleXmark} style={{ color: '#D2D2D2', fontSize: '18px' }} />}
              </span>
            }
          >
            {this.getOptions()}
          </AutoComplete>
          <div className={`hotel-dropdown__search-icon ${isFocused ? 'focused' : ''}`}>
            <Magnifier />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    filtersStore: state.filtersStore,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  getHotelsAutocomplete: (search: string) => {
    dispatch(getHotelsAutocomplete(search));
  },
  selectAutocompleteItem: (item: IHotelAutocompleteItem) => {
    dispatch(filtersActions.selectAutocompleteItem(item));
  },
  setAutocompleteItems: (items: IHotelAutocompleteItem[]) => {
    dispatch(filtersActions.setAutocompleteItems(items));
  },
  selectAutocompleteLabel: (label: string) => {
    dispatch(filtersActions.selectAutocompleteLabel(label));
  },
  setAutocompleteLoading: (load: boolean) => {
    dispatch(filtersActions.setAutocompleteLoading(load));
  },
});

export const SearchPropertyName = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchPropertyNameComponent);
