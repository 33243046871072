
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { FunctionalRootState } from '@share/utils';
import { Routes } from '@share/constants';
import { BlueButton } from '@share/components';
import { ILoginState, IMenuState } from '@share/store/slices';

import './style.scss';

export function AllInclusivesTell() {

  const menuStore: IMenuState = useSelector((state: FunctionalRootState) => state.navigationMenuStore);
  const { account }: ILoginState = useSelector((state: FunctionalRootState) => state.loginStore);

  const isVacationInspiration = menuStore?.items?.organizationId && account?.vacationInspirationsOrgIds?.includes(menuStore?.items?.organizationId);
  const isVPT = menuStore?.items?.organizationId && account?.vptOrgIds?.includes(menuStore?.items?.organizationId);
  
  if (isVacationInspiration || isVPT) {
    return null;
  }

  const stylesTell: any = {};
  if (account?.mainBackgroundColor) {
    stylesTell.background = account?.mainBackgroundColor;
  }

  return (
    <div className="inclusives__tell" style={stylesTell} >
      <div className="inclusives__container">
        <p className="inclusives__tell_title">
          <FormattedMessage id="inclusives.tell.title" />
        </p>
        <div className="inclusives__tell_info">
          <p className="inclusives__tell_text">
            <FormattedMessage id="inclusives.tell.text" />
          </p>
          <Link
            className="inclusives__tell_link"
            to={`/${account?.name}${Routes.AllInclusive}/submit-request`}
          >
            <BlueButton reverse>
              <FormattedMessage id="inclusives.tell.us" />
            </BlueButton>
          </Link>
        </div>
      </div>
    </div>
  );
}
