import { get, isEmpty } from "lodash";
import { Currencies, DEFAULT_CURRENCY_FIELD_KEY, IAccount } from "@share/common-types";
import { getPreferenceFromAccount } from '@share/utils';
import { SELECTED_CURRENCY, CHANGE_SELECTED_CURRENCY } from '@share/constants';

export const getCurrencyText = (currencyCode: string): string => {
  if (!isEmpty(currencyCode)) {
    const obj: any = get(Currencies, `${currencyCode}`, null);

    return `${!isEmpty(obj?.title) ? `${obj?.title}` : ''}${!isEmpty(obj?.symbol) ? obj?.symbol : ''} `;
  }

  return '$';
}

export const hasChangedCurrency = (): boolean => {
  const itemStr = localStorage.getItem(CHANGE_SELECTED_CURRENCY);

  if (!itemStr) {
    return false;
  }

  const value = JSON.parse(itemStr)
  //localStorage.removeItem(CHANGE_SELECTED_CURRENCY)

  return value;
}

export const removeChangedCurrency = () => {
  localStorage.removeItem(CHANGE_SELECTED_CURRENCY)
}

export const getSelectedCurrency = (account: IAccount | null | undefined, fromPreference?: boolean): string => {
  const selected = getLocalCurrency();

  if (selected) {
    return selected;
  }

  const currency = fromPreference ? getPreferenceFromAccount(account, DEFAULT_CURRENCY_FIELD_KEY) : account?.currency;

  return currency;
}

export const getLocalCurrency = () => {
  return localStorage.getItem(SELECTED_CURRENCY);
}

export const setLocalCurrency = (value: string) => {
  localStorage.setItem(SELECTED_CURRENCY, value);
  localStorage.setItem(CHANGE_SELECTED_CURRENCY, "true");
}