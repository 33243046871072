import React from 'react';

import { Modal, Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { ThunkDispatch } from 'redux-thunk';

import { Action } from '@reduxjs/toolkit';

import { RootState } from '@share/utils';
import { ICondoFlexibleDatePickerState, condoFlexibleDatePickerActions, condoStrictDatesActions, condoGuestsActions, ILoginState } from '@share/store/slices';
import { ICondosStaticState } from '@share/store/slices';
import { CondoDestination, DateSearchTypeEnum, ICondoLocation } from '@share/common-types';
import { USA, VICINITY } from '@share/constants';
import { ICondoGuestsState } from '@share/store/slices';
import { NULL_VALUE } from '@constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { CondoLocationPicker } from '../condo-location-picker';

import './style.scss';

const MODAL_WIDTH = 950;

interface IMapStateToProps {
  loginStore: ILoginState;
  condosStaticStore: ICondosStaticState;
}

interface IMapDispatchToProps {
  setGuestsState: (state: ICondoGuestsState) => void;
  setFlexibleState: (state: ICondoFlexibleDatePickerState) => void;
  setSearchType: (type: DateSearchTypeEnum) => void;
  setDates: (dates: { startDate: string; endDate: string }) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps {
  visible: boolean;

  onSelect: (location: ICondoLocation) => void;
  onQuick: () => void;
  onCancel: () => void;
}

interface IState {
  countrySelected: string;
}

class ModalCondoLocationDestinationsComponent extends React.Component<IProps, IState> {

  state: IState = {  countrySelected: NULL_VALUE };

  countriesWrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  statesWrapperRef: React.RefObject<HTMLDivElement> = React.createRef();

  componentDidMount(): void {
    this.setState({ countrySelected: NULL_VALUE });
  }

  handleSelectCountryChange = (countrySelected: string) => {
    this.setState({ countrySelected });
  }

  handleQuick = (location: ICondoLocation) => {
    this.props.onSelect(location);
    this.props.onQuick();
    this.props.onCancel();
    this.setState({ countrySelected: NULL_VALUE });
  }

  getPopupContainer = () => {
    return (this.countriesWrapperRef ? this.countriesWrapperRef.current : document.body) as HTMLElement;
  };

  getPopupContainerStates = () => {
    return (this.statesWrapperRef ? this.statesWrapperRef.current : document.body) as HTMLElement;
  };

  removeDuplicates = (list: any[]): CondoDestination[] => {
    return Object.values(list.reduce((r: any, o: any) => {
      if(!r[o.city] || o.totalAmount > r[o.city].totalAmount) {
        r[o.city] = o;
      }

      return r;
    }, {}))
  }

  render(): React.ReactNode {
    const { visible, condosStaticStore, loginStore, onCancel } = this.props;
    const { countrySelected } = this.state;
    const { allDestinations, allDestinationCountries } = condosStaticStore;
    const { account } = loginStore;

    let allDestinationsFiltered = [...allDestinations];
    let countryCount = 0;

    const isStateBased = [USA].includes(countrySelected);
    const isCountrySelected = !isEmpty(countrySelected);
    
    if (isCountrySelected) {
      if (!isStateBased) {
        allDestinationsFiltered = this.removeDuplicates(allDestinationsFiltered.filter(d => d.country?.toLowerCase() === countrySelected.toLowerCase() && d.placeType === 'City')
                                                                               .map(d => ({ ...d, city: d.name.replace(VICINITY, '').trim() })));
      } else {
        allDestinationsFiltered = this.removeDuplicates(allDestinationsFiltered.filter(d => d.country?.toLowerCase() === countrySelected.toLowerCase() && d.placeType === 'State')
                                                                               .map(d => ({ ...d, city: d.name.replace(VICINITY, '').trim() })));
      }

      countryCount = allDestinationsFiltered.reduce((total, item: any) => total + (item.totalAmount || 0), 0);
    }

    allDestinationsFiltered.sort((a, b) => a.name.localeCompare(b.name));

    const displayList = isCountrySelected;
    
    const fontFamily = account?.fontFamily;
    const secondaryFontFamily = account?.secondaryFontFamily;
    const generalBorderRadius = account?.generalBorderRadius;

    return (
      <Modal
        className={`modal-condo-location-destinations ${!isEmpty(generalBorderRadius) ? `border-radius-${generalBorderRadius}` : ''} ${fontFamily ? `${fontFamily}-font`: ''} ${secondaryFontFamily ? `secondary-${secondaryFontFamily}-font`: ''}`}
        open={visible}
        footer={null}
        onCancel={onCancel}
        width={MODAL_WIDTH}
        wrapClassName="modal-condo-location-destinations__wrapper"
      >
        <div>
        <h4 className="modal-condo-location-destinations__title secondary-font">
            <FormattedMessage id="see.all.destinations.title" />
          </h4>
          <h4 className="modal-condo-location-destinations__sub-title secondary-font">
            <FormattedMessage id="see.all.destinations.destination.by.country" />
          </h4>
          <div className="modal-condo-location-destinations__container">
            <div className="modal-condo-location-destinations__filters" ref={this.countriesWrapperRef}>
              <Select value={countrySelected} onChange={this.handleSelectCountryChange} placeholder={<FormattedMessage id="select.country" />} getPopupContainer={this.getPopupContainer}>
                {allDestinationCountries?.map((country, index) => <Select.Option key={`all_destinations_country_${index}`} value={country}>{country}</Select.Option>)}
              </Select>

              {isEmpty(countrySelected) ?
                  null : (
                  <FontAwesomeIcon icon={faTimesCircle} className="filters-search-icon filters-search-icon__clear" onClick={() => this.setState({ countrySelected: NULL_VALUE })} />)}
            </div>
          </div>

          <div className="modal-condo-location-destinations__destinations">
            <div className="container-fluid">
              {isCountrySelected && (
                <div className="row">
                  <label style={{ fontSize: 16, marginBottom: 10, fontWeight: 'bold' }}>{countrySelected} - {countryCount}</label>
                </div>)}

              <div className="row">
                {displayList ?
                  allDestinationsFiltered.map((dest, index) => (
                    <div key={`destinations-group_${index}`} className="col-lg-4">
                      <label style={{ display: 'flex', position: 'relative' }}>
                        <div>
                          <span className="modal-condo-location-destinations__destination-item" onClick={() => this.handleQuick(dest.location)}>{dest.name}</span> - {dest.totalAmount}
                        </div>
                      </label>
                    </div>)) : <FormattedMessage id="see.all.destinations.select_country" />}
              </div>

            </div>
          </div>

          <h4 className="modal-condo-location-destinations__sub-title" style={{ marginTop: '30px' }}>
            <FormattedMessage id="see.all.destinations.destination.by.name" />
          </h4>

          <CondoLocationPicker key="location-picker-modal" isSmall={true} isOnlyPicker={true} onSelect={() => this.props.onCancel()} />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    condosStaticStore: state.condosStaticStore
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  setGuestsState: (state: ICondoGuestsState) => {
    dispatch(condoGuestsActions.setState(state));
  },
  setFlexibleState: (state: ICondoFlexibleDatePickerState) => {
    dispatch(condoFlexibleDatePickerActions.setState(state));
  },
  setSearchType: (type: DateSearchTypeEnum) => {
    dispatch(condoStrictDatesActions.setSearchType(type));
  },
  setDates: (dates: { startDate: string; endDate: string }) => {
    dispatch(condoStrictDatesActions.setDates(dates));
  },
});

export const ModalCondoLocationDestinations = connect(mapStateToProps, mapDispatchToProps)(ModalCondoLocationDestinationsComponent);
