

import { ICondoAmenitiesFacilities, IGeoLocation, IImage } from '@share/common-types';

export interface IRoomsContent {
  amenities: string[];
  descriptions: string[];
  images: string[];
  roomKey: string;
  nbrOfBathrooms?: number;
  nbrOfBedrooms?: number;
  bedTypes?: string[];
  nonSmoking?: boolean;
  petPolicy?: string;
  policy?: string;
  extraInfo?: string;
  kitchenInfo?: IKitchenInfo[];
  squareFootage?: number;
  petsAllowed?: boolean;
}

export interface IHotelInfo {
  hotelDetails: IHotelDetails;
  packages: IPackage[];
  roomsContent: IRoomsContent[];
  trustYouData: ITrustYouData;
  adjustedPackageGroups: IAdjustedPackageGroup[];
  priceChange: number;
}

export interface IAdjustedPackageGroup {
  groupedPackages: IPackage[];
}

export interface GuestReviews {
  amenities: string;
  cleanliness: string;
  comfort: string;
  condition: string;
  count: number;
  neighborhood: string;
  overall: string;
  recommendationPercent: string;
  service: string;
}

export interface IHotelDetails {
  address: string;
  amenities: IAmenities[];
  amenityDescriptions: string[];
  checkInInformation: string[];
  checkOutInformation: string[];
  descriptions: string[];
  additionalDescriptions: string[];
  otherImportantInformation: string[];
  email: string;
  id: number;
  images: IImage[];
  location: IGeoLocation;
  locationDescriptions: string[];
  pets: string[];
  phone: number;
  roomsCount: number;
  starRating: number;
  title: string;
  city: string;
  countryCode: string;
  zipCode: string;
  state: string;
  reviews: number;
  reviewsCount: number;
  guestRatings: GuestReviews;
  category: string;
  whenBuild?: Date;
  recreationalActivities: string[];
  facilities: string;
  specialInstructions: string;
  other: string;
  isVacationRental: boolean;
  activities: ICondoAmenitiesFacilities[];
  importantInformation: string[];
  dealPrice: number;
  dealSavingPercentage: number;
  thumb: string;
  savedProperty: boolean;
}

export interface IAmenities {
  amenityId: number;
  categoryName: string;
  name: string;
}

export interface IPossibleStay {
  checkIn: string;
  checkOut: string;
  days: number;
  price: number;
  packageId: string;
}

export interface IPackage {
  hotelId: number;
  packageId: string;
  price: number;
  currency: string;
  refundability: RefundableEnum;
  refundableUntil: string;
  rooms: IPackageRoom[];
  priceDifference: number;
  cancellationPolicyText?: string;
  refundabilityText?: string;
  pricePerNight: number;
  isPackage: boolean;
  possibleStays: IPossibleStay[];
  maxWalletClientCash: number;
  savings: number;
}

export interface IPackageRoom {
  adultsCount: number;
  availability: string;
  bedType: string;
  id: string;
  kidsAges: number[];
  roomBasis: string;
  roomName: string;
  specialDeals: string[];
  targetRoomKey: string;
}

export interface ITrustYouData {
  id: string;
}

export interface IKitchenInfo {
  name: string;
  details: string[];
}

/*eslint-disable */
export enum RefundableEnum {
  Unknown = 'unknown',
  Refundable = 'refundable',
  Nonrefundable = 'nonrefundable',
}
/*eslint-enable */
