
import capitalize from 'lodash/capitalize';
import ReactGA from 'react-ga4';

import { connect, useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

import { UrlUtils, LoginType, FunctionalRootState } from '@share/utils';
import {
  StayForEnum,
  SearchTypeEnum,
  IAccount,
} from '@share/common-types';
import {
  SearchHandlerArgs,
} from '@common-types';
import {
  DateSearchTypeEnum,
} from '@share/common-types';
import {
  getCondos,
  condosActions,
  condoGuestsActions,
  condoStrictDatesActions,
  condosLocationPickerActions,
  condoFlexibleDatePickerActions,
} from '@share/store/slices';
import {
  ILoginState,
  condoFiltersActions,
} from '@share/store/slices';
import { CONDO_DATES_FLEXIBLE_LABEL, CONDO_DATES_LABEL, CONDO_FILTERS_LABEL, CONDO_RECENT_SEARCHES_LABEL, C_H_CLICK_RECENT_SEARCH, DEFAULT_PAGE_NUMBER } from '@share/constants';

import { CondoRecentSearches } from '../condo-recent-searches';

import './style.scss';
import { BlueButton, CondoSearchInventoryModal } from '@share/components';
import { useState } from 'react';

function CondoRecentSearchesWrapperComponent() {

  const [shomMapModal, setShomMapModal] = useState(false);

  const dispatch = useDispatch();
  
  const loginStore: ILoginState = useSelector((state: FunctionalRootState) => state.loginStore);

  const { loading, account } = loginStore;

  const onSearchHandler: SearchHandlerArgs = (
    condoLocation,
    searchType,
    stayDuration,
    guestsState,
    months,
    startDate,
    endDate,
  ) => {
    const modifiedSearchType = capitalize(searchType) as DateSearchTypeEnum;
    const modifiedStayFor = capitalize(stayDuration) as keyof typeof StayForEnum;


    dispatch(condosActions.setPageNumber(DEFAULT_PAGE_NUMBER));
    dispatch(condosActions.setIsSearch(true));

    dispatch(condoFiltersActions.resetFilters());

    UrlUtils.setUrl(CONDO_FILTERS_LABEL, null);

    dispatch(condosLocationPickerActions.selectLocationLabel(condoLocation.name));
    dispatch(condosLocationPickerActions.selectLocation(condoLocation));
    dispatch(condoStrictDatesActions.setSearchType(modifiedSearchType));

    if (modifiedSearchType === DateSearchTypeEnum.Flexible) {
      dispatch(condoFlexibleDatePickerActions.setMonths(months as string[]));
      UrlUtils.setUrl(CONDO_DATES_FLEXIBLE_LABEL, {
        selectedMonths: months,
        stayFor: StayForEnum[modifiedStayFor],
      });
      UrlUtils.removeFromUrl(CONDO_DATES_LABEL);
    }

    if (modifiedSearchType === DateSearchTypeEnum.Anytime) {
      dispatch(condoFlexibleDatePickerActions.setMonths([]));
      UrlUtils.setUrl(CONDO_DATES_FLEXIBLE_LABEL, {
        selectedMonths: [],
        stayFor: StayForEnum[modifiedStayFor],
      });
      UrlUtils.removeFromUrl(CONDO_DATES_LABEL);
    }

    if (modifiedSearchType === DateSearchTypeEnum.Strict) {
      dispatch(condoStrictDatesActions.setDates({ startDate: startDate as string, endDate: endDate as string }));
    }

    dispatch(condoGuestsActions.setState({ ...guestsState, bedroomsCount: !guestsState?.bedroomsCount ? 1 : guestsState?.bedroomsCount }));

    dispatch(getCondos(SearchTypeEnum.NewSearch, false));

    ReactGA.event({
      category: account?.name as string,
      action: `${C_H_CLICK_RECENT_SEARCH}_${account?.name.toUpperCase()}`,
      label: `User clicked recent search`,
      nonInteraction: false,
    });
  };

  const recentSearchesStorage = localStorage.getItem(CONDO_RECENT_SEARCHES_LABEL);
  let recentSearches = (recentSearchesStorage && !isEmpty(recentSearchesStorage)) ? JSON.parse(recentSearchesStorage) : [];
  if (recentSearches?.length) {
    recentSearches = recentSearches.slice(0, 4);
  }

  const hasPublicAccessToken = account?.hasPublicAccessToken;
  const hasPublicValidToken = account?.type !== LoginType.Public || !hasPublicAccessToken || !isEmpty(loginStore.accessToken);

  if (!hasPublicValidToken) {
    return null;
  }

  return (
    <div className="condo-recents">
      <div className="condo-recents__header-see-all-inventory">
        <BlueButton onClick={() => setShomMapModal(true)}>
          <label>Map of All Available Inventory</label>
          <small>(close this map box to begin Search)</small>
        </BlueButton>
      </div>

      {!!recentSearches.length && (
        <>
          <h2 className="condo-recents__title secondary-font">
            <FormattedMessage id="recents.title" />
          </h2>

          <div className="condo-recents__wrapper">
            {recentSearches?.map((item: any, index: number) => (
              <CondoRecentSearches
                data={item}
                account={account as IAccount}
                disabled={loading || !hasPublicValidToken}
                key={`${item.location.id} ${index}`}
                onSearchHandler={onSearchHandler}
              />
            ))}
          </div>
        </>)}

        <CondoSearchInventoryModal
          visible={shomMapModal}
          onCancel={() => setShomMapModal(false)}
        />
    </div>
  );
}

export const CondoRecentSearchesWrapper = connect()(CondoRecentSearchesWrapperComponent);
