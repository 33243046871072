import { useMemo } from 'react';
import Favicon from 'react-favicon';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import { isEmpty } from 'lodash';
import { RootState } from '@share/utils';
import { ILoginState, IMenuState } from '@share/store/slices';

import FaviconImage from '@assets/images/favicon.png';

const ORG_ID_TOEXCLUDE_CHAT = [644];

interface IMapStateToProps {
  loginStore: ILoginState;
  menuStore: IMenuState;
}

interface IProps extends IMapStateToProps, RouteComponentProps {}

function ApplicationHelmetComponent(props: IProps) {
  const { loginStore, menuStore } = props;
  const { account, user } = loginStore;
  const { items } = menuStore;
  
  const faviconPref = account?.faviconPref;
  const pageTitlePref = account?.pageTitlePref;
  const isRSITemplate = account?.isRSITemplate;
  const levartiOrgIds = account?.levartiOrgIds;

  const displayMarketingScriptFlag = import.meta.env.VITE_DISPLAY_MARKETING_SCRIP;

  const favicon = useMemo(() => !isEmpty(faviconPref) ? faviconPref : FaviconImage, [faviconPref]);
  const displayChat = useMemo(() => !items?.isMLM && isRSITemplate && items?.rsiId && !items?.isRemoveChat && !ORG_ID_TOEXCLUDE_CHAT.includes(items?.organizationId) && !levartiOrgIds?.includes(items?.organizationId), [isRSITemplate, items]);

  return (
    <>
      <Favicon url={favicon as string} />
      
      <Helmet>
        {!isEmpty(pageTitlePref) ? (
          <title>{pageTitlePref}</title>) : null}

        {(isRSITemplate && displayMarketingScriptFlag === 'true') ? (
          <script type="text/javascript">
            {`(function() {
                window.sib = {
                  equeue: [],
                  client_key: "jyhjbffcot7rzilng6zj030p"
                };

                /* OPTIONAL: email for identify request*/
                // window.sib.email_id = 'example@domain.com';
                window.sendinblue = {};
                for (var j = ['track', 'identify', 'trackLink', 'page'], i = 0; i < j.length; i++) {
                  (function(k) {
                    window.sendinblue[k] = function() {
                      var arg = Array.prototype.slice.call(arguments);
                      (window.sib[k] || function() {
                        var t = {};
                        t[k] = arg;
                        window.sib.equeue.push(t);
                      })(arg[0], arg[1], arg[2], arg[3]);
                    };
                  })(j[i]);
                }

                var n = document.createElement("script"),
                    i = document.getElementsByTagName("script")[0];
                n.type = "text/javascript", n.id = "sendinblue-js", n.async = !0, n.src = "https://sibautomation.com/sa.js?key=" + window.sib.client_key, i.parentNode.insertBefore(n, i), window.sendinblue.page();
              })();`}
          </script>) : null}

        {displayChat ? (
          <script type="text/javascript">
            {`var url_string = window.location.href;
              var url = new URL(url_string);
              var CA_DID = url.searchParams.get("ca_did");
              var CA_APIKEY = url.searchParams.get("apiKey");
              var CA_TITLE = url.searchParams.get("title");
              var CA_THEME_COLOR = url.searchParams.get("themeColor");`}
          </script>) : null}

        {displayChat ? (
          <script type="text/javascript">
            {`var chatConfig={
                "widgetConfig": {
                    "title": CA_TITLE,
                    "subtitle": "Chat",
                    "headerLogo": "https://ozonetel.com/wp-content/uploads/2022/03/O-Image.png.webp",
                    "showHeaderLogo": true,
                    "showAgentIcon": false,
                    "showAgentAvatar": false
                },
                "CA_SCRIPT": "https://crexendocx.com/ChatWidget/dist/caChatAPI.js",
                "clientInfo": {
                    "DID": "8284453857",
                    "API_KEY": "KK7edafced1c078adc0856ff36a7111116"
                },
                "custDetails": {
                    "custName": "${user?.firstName} ${user?.lastName}",
                    "custMail": "${user?.email}",
                    "custPhone": "${items?.phone}",
                    "custId": "${user?.keyid}"
                },
                "theme": {
                    "primary": "#"+CA_THEME_COLOR,
                    "appHeader": {
                        "backgroundColor": "#FFFFFF",
                        "textColor": "#2F324A"
                    },
                    "conversation": {
                        "body": "#F1F2F9",
                        "messagePreview": "#ffff",
                        "text": "#2F324A",
                        "bot": {
                            "backgroundColor": "#FFFFFF",
                            "textColor": "#2F324A"
                        },
                        "user": {
                            "backgroundColor": "rgba(59, 141, 247, 1)",
                            "textColor": "#FFFFFF"
                        },
                        "interactiveButton": {
                            "backgroundColor": "rgba(59, 141, 247, 1)",
                            "textColor": "#FFFFFF"
                        }
                    }
                },
                "launcher": {
                    "type": "text",
                    "text": "Need Help? Chat with Us"
                },
                "userForm": [
                    {
                        "name": "Name",
                        "required": true,
                        "hide": false
                    },
                    {
                        "name": "Email",
                        "required": true,
                        "hide": false
                    },
                    {
                        "name": "CountryCode",
                        "required": false,
                        "hide": true
                    },
                    {
                        "name": "Phone",
                        "required": false,
                        "hide": false
                    },
                    {
                        "name": "Address",
                        "required": false,
                        "hide": true
                    }
                ],
            }`}
          </script>) : null}
        {displayChat ? (
          <script type="text/javascript" src="https://crexendocx.com/ChatWidget/dist/chatWidget.js"></script>) : null}

      </Helmet>
    </>);
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    menuStore: state.navigationMenuStore
  };
};

const ApplicationComponentWithRouter = withRouter(ApplicationHelmetComponent);

export const ApplicationHelmet = connect(mapStateToProps)(ApplicationComponentWithRouter);
