import React from 'react';

import { connect } from 'react-redux';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { RootState } from '@share/utils';
import { IMenuState, getAllInclusiveDestinations } from '@share/store/slices';
import { DEFAULT_ALLINCLUSIVE_PAGE_SIZE, FUNJET_LINK, NULL_VALUE } from '@constants';
import { BlueButton, SearchWrapper } from '@share/components';
import { GetawayList, Membership, ModalMessage } from '@components';
import { inclusivesGalery, AllInclusivesCompanyLogos } from '@common-types';
import { ILoginState } from '@share/store/slices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faPhone } from '@fortawesome/free-solid-svg-icons';
import { GetawaysLocationEnum } from '@share/common-types';
import { IGetawaysState, SubCategory, getawaysActions } from '@store/slices';

import { GetawayListSkeleton } from '../getaway/getaway-list/getaway-list-skeleton';
import { AllInclusivesTell } from '../all-inclusives-tell';

import InclusivesBackground from '@assets/images/inclusives/bg-inclusives.png';
import ConciergeTeam from '@assets/images/concierge-team.png';
import Funjet from '@assets/images/funjet.svg';

import './style.scss';

interface IMapStateToProps {
  menuStore: IMenuState;
  loginStore: ILoginState;
  getawaysStore: IGetawaysState;
}

interface IMapDispatchToProps {
  getAllInclusiveDestinations: () => void;
  setLoading: (value: boolean, subCategory: SubCategory) => void;
  setActiveSubCategory: (subCategory: SubCategory) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {}

interface IState {
  isModalVisible: boolean;
}

class AllInclusivesWrapperComponent extends React.Component<IProps, IState> {
  state: IState = { isModalVisible: false };

  componentDidMount() {
    this.props.setLoading(true, GetawaysLocationEnum.AllInclusive);
    this.props.setActiveSubCategory(GetawaysLocationEnum.AllInclusive);
    this.props.getAllInclusiveDestinations();
  }

  render(): React.ReactNode {
    const { menuStore, loginStore, intl, getawaysStore } = this.props;
    const { account, user } = loginStore;
    const { items } = menuStore;
    const { loadingGetaways } = getawaysStore[GetawaysLocationEnum.AllInclusive];

    const phone = items?.phone;

    const stylesTell: any = {};
    const styles = { background: 'var(--lucky-point)', margin: '35px 0px', padding: '30px 0' };
    if (account?.mainBackgroundColor) {
      styles.background = account?.mainBackgroundColor;
      stylesTell.background = account?.mainBackgroundColor;
    }

    return (
      <div className="inclusives">
        <div
          className="inclusives__header"
          style={{ backgroundImage: `url(${InclusivesBackground})` }}
        >
          <div className="inclusives__container">
            <h1 className="inclusives__header_title secondary-font">
              <FormattedMessage id="inclusives.title" />
            </h1>

            <p className="inclusives__header_subtitle">
              <FormattedMessage id="inclusives.subtitle" />
            </p>

            <SearchWrapper
              isWidget={false}
              widgetBackgroundColor={'none'}
              hideTitle
              hideMessage
              openOnClick
            />
          </div>
        </div>

        {!user || loadingGetaways ? <GetawayListSkeleton isSlider className="inclusives__slider-skeleton" /> : null}

        <GetawayList
          getaway={GetawaysLocationEnum.AllInclusive}
          condoSubcategory={NULL_VALUE}
          isFromProperty={true}
          isInternal={true}
          isHome={true}
          isSlider={true}
          maxValues={DEFAULT_ALLINCLUSIVE_PAGE_SIZE}
          isAllInclusive
          disablePagination
          displayOriginal
          disableOnScroll
          sliderRotation
          hideFilter
        />

        <div
          className="inclusives__header"
          style={styles}
        >
          <div className="inclusives__container">
            <h1 className="inclusives__header_title secondary-font">
              <FormattedMessage id="inclusives.vacation.text.title" />
            </h1>

            <p className="inclusives__header_subtitle more-ways">
              <FormattedMessage id="inclusives.more.ways" />
            </p>

            <div className="inclusives__header_button">
              <div className="inclusives__header_button_container">
                <div className="inclusives__vacation_bottom header">
                  <a href={FUNJET_LINK} target="_blank" rel="noreferrer">
                    <BlueButton reverse>
                      <FormattedMessage id="book.online" />
                      <img alt="" src={Funjet} />
                    </BlueButton>
                  </a>
                </div>

                <p className="inclusives__header_subtitle code" style={{ marginTop: '15px' }}>
                  <FormattedMessage
                    id="inclusives.vacation.text.header"
                    values={{ code: intl.formatMessage({ id: 'inclusives.vacation.text.code' }) }}
                  />
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    onClick={() => this.setState({ isModalVisible: true })}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>

        {!user || loadingGetaways ? <GetawayListSkeleton isSlider={false} className="inclusives__main-skeleton" /> : null}

        <GetawayList
          getaway={GetawaysLocationEnum.AllInclusive}
          condoSubcategory={NULL_VALUE}
          isFromProperty={true}
          isInternal={true}
          isHome={true}
          isSlider={false}
          title={intl.formatMessage({ id: 'explore.more.all_inclusive' })}
          isAllInclusive
          disableOnScroll
        />

        <div className="inclusives__gallery">
          {inclusivesGalery.map((item, index) => (
            <img
              key={`inclusives galery ${index}`}
              className="inclusives__gallery_img"
              src={item.url}
              alt="gallery-img"
            />
          ))}
        </div>

        <div>
          <div className="inclusives__container inclusives__book">
            <div className="inclusives__book_info">
              <h2 className="inclusives__book secondary-font">
                <FormattedMessage id="inclusives.book.title" />
              </h2>
              <p className="inclusives__book_subtitle secondary-font">
                <FormattedMessage id="inclusives.book.subtitle" />
              </p>
              <p className="inclusives__book_text">
                <FormattedMessage id="inclusives.book.text" />
              </p>
              <p className="inclusives__all_subtitle" style={{ marginTop: '30px' }}>
                <FormattedMessage id="inclusives.all.subtitle" />
              </p>
              <p className="inclusives__all_text">
                <FormattedMessage id="inclusives.all.text" />
              </p>
            </div>
            <img className="inclusives__book_img" src={ConciergeTeam} alt="inclusives" />
          </div>
        </div>

        <div
          className="inclusives__header"
          style={{ backgroundImage: `url(${InclusivesBackground})`, margin: '0px', marginBottom: '30px' }}
        >
          <div className="inclusives__container">
            <div className="inclusives__header_button">
              <div className="inclusives__header_phone-container">
                <FontAwesomeIcon icon={faPhone} size="2x" />
                <div className="inclusives__header_phone-message">
                  <p className="inclusives__header_subtitle" style={{ marginTop: '0px' }}>
                    <FormattedMessage id="speak.specialist" />
                  </p>
                  <p className="inclusives__header_subtitle" style={{ marginTop: '5px' }}>
                    <FormattedMessage id="start.planning" />
                    &nbsp;&nbsp;
                    <a href={`tel:${items?.phone}`} target="_blank" rel="noreferrer">
                      {items?.phone}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <AllInclusivesTell />

        <div className="inclusives__all">
          <div className="inclusives__container">
            <h2 className="inclusives__all_title secondary-font">
              <FormattedMessage id="inclusives.all.title" />
            </h2>
            <p className="inclusives__all_subtitle">
              <FormattedMessage id="inclusives.all.subtitle.bottom" />
            </p>
            <p className="inclusives__all_text">
              <FormattedMessage id="inclusives.all.text.bottom" />
            </p>
            <p className="inclusives__all_link">
              <FormattedMessage id="inclusives.all.link" />
            </p>
          </div>
        </div>
        <div className="inclusives__partners">
          <div className="inclusives__container">
            <h2 className="inclusives__partners_title secondary-font">
              <FormattedMessage id="treat.yourself.book.with.our.team" />
              {': '}
              <a href={`tel:${phone}`}>{phone}</a>
            </h2>
            <p className="inclusives__partners_subtitle">
              <FormattedMessage id="your.award.winning.travel" />
            </p>
            <p className="inclusives__partners_text">
              <FormattedMessage id="travel.partners.and.cruise.lines" />
            </p>
            <div className="inclusives__partners_divider" />
            <p className="inclusives__partners_subtitle">
              <FormattedMessage id="travel.partners.and.cruise.partners" />
            </p>
            <div className="inclusives__partners_wrapper">
              {AllInclusivesCompanyLogos.map((item, index) => (
                <div key={`${index}-${item.name}`} className="inclusives__partners_wrapper_item">
                  <img
                    src={item.image}
                    key={`inclusives company galery ${item.name}`}
                    alt={`inclusives gallery-img ${item.name}`}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <Membership />

        <ModalMessage
          text="inclusives.code.message"
          title="inclusives.promo.code"
          visible={this.state.isModalVisible}
          onCancel={() => this.setState({ isModalVisible: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore,
    getawaysStore: state.getawaysStore,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  getAllInclusiveDestinations: () => {
    dispatch(getAllInclusiveDestinations());
  },
  setLoading: (value: boolean, subCategory: SubCategory) => {
    dispatch(getawaysActions.setLoadingGetaways({ loading: value, subCategory }));
  },
  setActiveSubCategory: (subCategory: SubCategory) => {
    dispatch(getawaysActions.setActiveGetawaySubCategory({ subCategory }));
  },
});

export const AllInclusivesWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AllInclusivesWrapperComponent));
