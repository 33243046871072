import React from 'react';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';
import differenceInDays from 'date-fns/differenceInDays';
import ReactGA from 'react-ga4';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { LabeledValue } from 'antd/lib/select';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  condosActions,
  getCondos,
  ICondosLocationPickerState,
  condosLocationPickerActions,
  ICondoGuestsState,
  ICondosState,
  resetCondosFull,
  ICondoStrictDatesState,
  ICondoFlexibleDatePickerState,
  ISavedPropertiesState,
} from '@share/store/slices';
import { ICondosStaticState } from '@share/store/slices';
import { ILoginState, IMenuState } from '@share/store/slices';
import { condoFiltersActions, ICondoFiltersState } from '@share/store/slices';
import { CondoRequestTypeEnum, IAccount, IClientCash, ICondo, SearchTypeEnum } from '@share/common-types';
import { GetHomeParams, RootState, getAccountUsernameFromPath } from '@share/utils';
import { CondoLocationsEnum, CondoMatchTypeEnum, DateSearchTypeEnum, IBounds, IBudgetRange, ICounterItem, SEARCH_BANNER_TYPE } from '@share/common-types';
import { CondoExpiredSessionMessage, WhiteButton, Banner, WalletSearchSlider } from '@components';
import { FilterSvg, MapSvg } from '@assets';
import { CloseSvg } from '@share/assets';
import { getCondoSortOptions, isCondoFiltersEmpty, getCondoFiltersCount, Map, HasPermission, ViewQuotesSharePermission, ViewCondoSupplierPermission } from '@utils';
import { UrlUtils, getBudgetRangeLabel } from '@share/utils';
import { APP_SELECTOR, NULL_VALUE } from '@constants';
import { CONDO_FILTERS_LABEL, C_R_FILTER_BUDGET, C_R_FILTER_CITIES, C_R_FILTER_DAY, C_R_FILTER_MONTH, C_R_FILTER_SUPPLIER, DEFAULT_PAGE_CONDO_SIZE, Routes, SortTypes, getCondoSortByCodeByValue } from '@share/constants';

import { NoAvailableCondosNotification, TravelDatesNotification, NoAvailableCondosFilterNotification } from '../notification';
import { CondoSkeletonResult } from '../condo-skeleton-result';
import { CondoHotelCard } from '../condo-hotel-card';
import { CondoCardsSkeleton } from '../condo-card-skeleton';
import { CondoMapWrapper } from '../condo-map';
import { CondoFiltersWrapper } from '../condo-filters-wrapper';
import { CondoSkeletonFilters } from '../condo-skeleton-filters';
import { CondoResultsEmpty } from '../condo-results-empty';
import { Aligment, CondoResultFilter } from './condo-result-filter';
import { ModalCompare } from '../condo-modal-compare';

import CondoMapPlaceholder from '@assets/images/condo-map-placeholder.jpg';
import MapMarker from '@assets/images/map-price-icon.png';
import MapMarkerSelected from '@assets/images/map-price-selected-icon.png';
import MapHomeMarker from '@assets/images/map-home-icon.svg';
import MapHomeMarkerSelected from '@assets/images/max-home-selected-icon.svg';
import MapMultipleDoubleMarker from '@assets/images/map-multiple-double-price-icon.png';
import MapMultipleDoubleMarkerSelected from '@assets/images/map-multiple-double-price-selected-icon.png';
import MapMultipleMarker from '@assets/images/map-multiple-price-icon.png';
import MapMultipleMarkerSelected from '@assets/images/map-multiple-price-selected-icon.png';

import './style.scss';
import { BlueButton } from '@share/components';

interface IMapStateToProps {
  condosStore: ICondosState;
  condosLocationPickerStore: ICondosLocationPickerState;
  condoFiltersStore: ICondoFiltersState;
  condoGuestsStore: ICondoGuestsState;
  condoStrictDatesStore: ICondoStrictDatesState;
  condoFlexibleDatePickerStore: ICondoFlexibleDatePickerState;
  condosStaticStore: ICondosStaticState;
  menuStore: IMenuState;
  loginStore: ILoginState;
  savedPropertiesStore: ISavedPropertiesState;
}

interface IMapDispatchToProps {
  setSortType: (sortType: SortTypes) => void;
  setPageNumber: (num: number) => void;
  getCondos: (searchType?: SearchTypeEnum) => void;
  setBounds: (bounds: IBounds) => void;
  setSelectedCondo: (condo: ICondo | null | undefined) => void;
  addMapCondo: (condo: ICondo) => void;
  setSelectedCompareCondos: (selectedCompareCondos: ICondo[]) => void;
  setSelectedCondoSearchClientCash: (selectedClientCash: IClientCash) => void;
  resetCondosFull: () => void;
  resetFilters: () => void;
  setBudgetRanges: (value: string[]) => void;
  setDays: (value: number[]) => void;
  setMonths: (value: number[]) => void;
  setSuppliers: (value: string[]) => void;
  setCities: (value: string[]) => void;
  resetLocations: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {
  matches?: boolean;
}

interface IState {
  visibleFilters: boolean;
  visibleMap: boolean;
  isNoAvailableCondosNotification: boolean;
  showCompareModal: boolean;
}

const debounceTime = 300;
const week = 7;
const scrollPixels = 150;
const scrollWindowPixels = 350;
const zero = 0;
const one = 1;
const topZIndex = 5;
const CHECK_WIDTH = 1020;
const minusOne = -1;
const maxCompareCondos = 5;

class CondoResultWrapperComponent extends React.Component<IProps, IState> {
  state: IState = {
    visibleFilters: false,
    visibleMap: false,
    isNoAvailableCondosNotification: false,
    showCompareModal: false,
  };
  contentWrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  root = document.querySelector(APP_SELECTOR) as HTMLDivElement;
  prevFilters: ICondoFiltersState | null = null;

  handleResize = debounce(() => {
    const footer = document.querySelector('.footer') as HTMLDivElement;
    this.showBodyScroll(!!(window.innerWidth <= CHECK_WIDTH && footer));
  }, debounceTime);

  showBodyScroll = (show: boolean, withScroll = true) => {
    const footer = document.querySelector('.footer') as HTMLDivElement;

    if (show) {
      document.body.style.overflow = 'auto';
      if (!isNull) {
        footer.style.display = 'block';
      }
    } else {
      if (withScroll) {
        window.scrollTo(zero, zero);
      }
      const values = UrlUtils.getValues();

      if (!isEmpty(values) && this.props.matches) {
        document.body.style.overflow = 'hidden';
      }

      if (!isNull) {
        footer.style.display = 'none';
      }
    }
  };

  toggleFilters = () => {
    this.setState({ visibleFilters: !this.state.visibleFilters }, () => {
      this.showBodyScroll(!this.state.visibleFilters && !this.state.visibleMap, false);
    });
  };

  showMap = () => {
    this.setState({ visibleMap: !this.state.visibleMap }, () => {
      this.showBodyScroll(!this.state.visibleMap, false);
    });
  };

  onWindowScroll = debounce(() => {
    const { condosLocationPickerStore, condosStore, setPageNumber, getCondos } = this.props;
    const { selectedLocation } = condosLocationPickerStore;
    const {
      isSessionExpired,
      condos,
      loadingMore,
      loading,
      pageNumber,
      isFinishedWithError,
      prevCondos,
    } = condosStore;
    const { current } = this.contentWrapperRef;

    if (!selectedLocation) {
      return;
    }

    if (
      (!prevCondos || prevCondos?.length > zero) &&
      !isFinishedWithError &&
      current &&
      !isSessionExpired &&
      condos &&
      condos.length &&
      condos.length % DEFAULT_PAGE_CONDO_SIZE === zero &&
      window.innerWidth <= CHECK_WIDTH &&
      !loadingMore &&
      !loading &&
      window.innerHeight + window.scrollY + scrollWindowPixels >= this.root.offsetHeight
    ) {
      setPageNumber(pageNumber + one);
      getCondos();
    }
  }, debounceTime);

  onScroll = debounce(() => {
    const { condosLocationPickerStore, condosStore, setPageNumber, getCondos } = this.props;
    const { selectedLocation } = condosLocationPickerStore;
    const {
      isSessionExpired,
      condos,
      loadingMore,
      loading,
      pageNumber,
      isFinishedWithError,
      prevCondos,
      cachedSelectedLocation,
    } = condosStore;
    const { current } = this.contentWrapperRef;

    if (!selectedLocation && !cachedSelectedLocation) {
      return;
    }

    if (
      (!prevCondos || prevCondos?.length > zero) &&
      !isFinishedWithError &&
      current &&
      !isSessionExpired &&
      condos &&
      condos.length &&
      condos.length % DEFAULT_PAGE_CONDO_SIZE === zero &&
      window.innerWidth > CHECK_WIDTH &&
      !loadingMore &&
      !loading &&
      current.scrollTop + current.clientHeight > current.scrollHeight - scrollPixels
    ) {
      setPageNumber(pageNumber + one);
      getCondos();
    }
  }, debounceTime);

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    this.contentWrapperRef?.current?.addEventListener('scroll', this.onScroll);
    window.addEventListener('scroll', this.onWindowScroll);

    const isNoAvailableCondosNotification = isCondoFiltersEmpty(this.props.condoFiltersStore);
    this.setState({ isNoAvailableCondosNotification });
  }

  componentWillUnmount() {
    const { setSelectedCondo } = this.props;
    window.removeEventListener('resize', this.handleResize);
    this.contentWrapperRef?.current?.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('scroll', this.onWindowScroll);
    setSelectedCondo(NULL_VALUE);
    setTimeout(() => {
      document.body.style.overflow = 'auto';
    }, 10);
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const { loading } = this.props.condosStore;

    if (prevProps.condosStore.loading !== loading && !loading) {
      const isNoAvailableCondosNotification = isCondoFiltersEmpty(this.props.condoFiltersStore);
      this.setState({ isNoAvailableCondosNotification });
    }
  }

  getSortOptions = (): LabeledValue[] => {
    const { condosStore, loginStore } = this.props;
    const { account } = loginStore;
    const hasSavedProperties = condosStore.condos?.some(x => x.savedProperty);

    const isB2C = account?.isB2C;
    const walletDisplayB2CSavings = account?.walletDisplayB2CSavings;
    
    return getCondoSortOptions(condosStore, isB2C, walletDisplayB2CSavings, hasSavedProperties);
  };

  onSortChange = (value: string[]): void => {
    const { account } = this.props.loginStore;

    this.props.setSortType(value[0] as SortTypes);
    this.props.setPageNumber(one);

    if (this.props.matches) {
      this.onFiltersOrSortChange();

      const valueStr = value[0] as string;
      const action = getCondoSortByCodeByValue(valueStr);
      if (action) {
        ReactGA.event({
          category: account?.name as string,
          action: `${action}_${account?.name.toUpperCase()}`,
          label: `User selected ${action} sort on result`,
          nonInteraction: false,
        });
      }
  
    }
  };

  updateBounds = (bounds: IBounds): void => {
    const { setBounds, getCondos } = this.props;

    setBounds(bounds);
    getCondos(SearchTypeEnum.MapSearch);
  };

  onMouseEnterCondoCard = (id: number): void => {
    if (this.props.condosStore.selectedCondo?.condoId !== id) {
      const marker = document.querySelector(`[data-item-id="${id}"]`) as HTMLDivElement;

      if (marker && !marker.dataset.itemNotChange) {
        marker.style.zIndex = `10`;

        if (this.props.condosStore.lastRequestType === CondoRequestTypeEnum.Anytime) {
          marker.style.background = `url(${MapHomeMarkerSelected})`;
        } else {
          const locationList = marker.getAttribute('data-item-location-id');
          const locationListSplit = locationList ? locationList.split(',') : [];

          if (locationListSplit?.length == 2) {
            marker.style.background = `url(${MapMultipleDoubleMarkerSelected})`;
          } else if (locationListSplit?.length > 2) {
            marker.style.background = `url(${MapMultipleMarkerSelected})`;
          } else {
            marker.style.background = `url(${MapMarkerSelected})`;
          }
        }
      } else {
        Map.refreshMarkers(id, true); 
      }
    }
  };

  onMouseLeaveCondoCard = (id: number): void => {
    if (this.props.condosStore.selectedCondo?.condoId !== id) {
      const marker = document.querySelector(`[data-item-id="${id}"]`) as HTMLDivElement;
      if (marker && !marker.dataset.itemNotChange) {
        marker.style.zIndex = `5`;

        if (this.props.condosStore.lastRequestType === CondoRequestTypeEnum.Anytime) {
          marker.style.background = `url(${MapHomeMarker})`;
        } else {
          const locationList = marker.getAttribute('data-item-location-id');
          const locationListSplit = locationList ? locationList.split(',') : [];

          if (locationListSplit?.length == 2) {
            marker.style.background = `url(${MapMultipleDoubleMarker})`;
          } else if (locationListSplit?.length > 2) {
            marker.style.background = `url(${MapMultipleMarker})`;
          } else {
            marker.style.background = `url(${MapMarker})`;
          }
        }
      } else {
        Map.refreshMarkers(this.props.condosStore.selectedCondo?.condoId, true);
      }
    }
  };

  selectCondo = (condo: ICondo) => {
    const { setSelectedCondo, condosStore, addMapCondo, matches } = this.props;
    const { lastRequestType, mapCondos } = condosStore;
    const markers = document.querySelectorAll('.price-marker');

    if (markers && markers.length) {
      markers.forEach((m) => {
        const marker = m as HTMLDivElement;
        if (marker.dataset.itemNotChange) {
          return;
        }

        marker.style.zIndex = `${one}`;

        if (lastRequestType === CondoRequestTypeEnum.Anytime) {
          marker.style.background = `url(${MapHomeMarker})`;
        } else {
          const locationList = marker.getAttribute('data-item-location-id');
          const locationListSplit = locationList ? locationList.split(',') : [];

          if (locationListSplit?.length == 2) {
            marker.style.background = `url(${MapMultipleDoubleMarker})`;
          } else if (locationListSplit?.length > 2) {
            marker.style.background = `url(${MapMultipleMarker})`;
          } else {
            marker.style.background = `url(${MapMarker})`;
          }
        }
      });
    }

    setSelectedCondo(condo);

    if (condo) {
      if (mapCondos?.every(({ condoId }) => condoId !== condo.condoId)) {
        addMapCondo(condo);
      }

      const selectedMarker = document.querySelector(`[data-item-id="${condo.condoId}"]`) as HTMLDivElement;

      if (selectedMarker && !selectedMarker.dataset.itemNotChange) {
        selectedMarker.style.zIndex = `${topZIndex}`;

        if (lastRequestType === CondoRequestTypeEnum.Anytime) {
          selectedMarker.style.background = `url(${MapHomeMarkerSelected})`;
        } else {
          const locationList = selectedMarker.getAttribute('data-item-location-id');
          const locationListSplit = locationList ? locationList.split(',') : [];

          if (locationListSplit?.length == 2) {
            selectedMarker.style.background = `url(${MapMultipleDoubleMarkerSelected})`;
          } else if (locationListSplit?.length > 2) {
            selectedMarker.style.background = `url(${MapMultipleMarkerSelected})`;
          } else {
            selectedMarker.style.background = `url(${MapMarkerSelected})`;
          }
        }
      }

      if (!matches && !this.state.visibleMap) {
        setTimeout(() => {
          this.showMap();
        });
      }
    }
  };

  handleFilterChanged = (key: string) => {
    this.onFiltersOrSortChange();

    const { loginStore } = this.props;
    const { account } = loginStore;

    ReactGA.event({
      category: account?.name as string,
      action: `${key}_${account?.name.toUpperCase()}`,
      label: `User selected filter on condo resuls`,
      nonInteraction: false,
    });
  }

  onFiltersOrSortChange = debounce(() => {
    const { condoFiltersStore, getCondos } = this.props;

    if (!isEqual(this.prevFilters, condoFiltersStore)) {
      UrlUtils.setUrl(CONDO_FILTERS_LABEL, condoFiltersStore);
      this.prevFilters = condoFiltersStore;
      this.props.setPageNumber(one);
      getCondos(SearchTypeEnum.SortsFilters);
      this.setState({ isNoAvailableCondosNotification: false });
    }
  }, debounceTime);

  resetFilters = () => {
    this.props.resetFilters();
    this.onFiltersOrSortChange();
  };

  onBudgetChange = (value: string[]) => {
    this.props.setBudgetRanges(value);
  };
  onDaysChange = (value: string[]) => {
    this.props.setDays(value.map(v => parseInt(v)));
  };
  onMonthsChange = (value: string[]) => {
    this.props.setMonths(value.map(v => parseInt(v)));
  };
  onSuppliersChange = (value: string[]) => {
    this.props.setSuppliers(value);
  };
  onCitiesChange = (value: string[]) => {
    this.props.setCities(value);
  };

  handleCompare = (condo: ICondo) => {
    const { condosStore } = this.props;
    const { selectedCompareCondos } = condosStore;
    const condoIndex = selectedCompareCondos.findIndex(h => h.condoId === condo.condoId);
    if (condoIndex !== -1) {
      const selectedCompareCondosFiltered = [...selectedCompareCondos];
      selectedCompareCondosFiltered.splice(condoIndex, 1);
      this.props.setSelectedCompareCondos([...selectedCompareCondosFiltered]);
    } else {
      this.props.setSelectedCompareCondos([...selectedCompareCondos, condo]);
    }
  }

  handleOnSelectClientCash = (selectedSearchClientCash: number) => {
    const { condosStore } = this.props;
    const { selectedCondoSearchClientCash } = condosStore;

    const clientCash = { ...selectedCondoSearchClientCash, selectedSearchClientCash };
    
    this.props.setSelectedCondoSearchClientCash(clientCash);
  }

  render(): React.ReactNode {
    const { condosStore, matches, condosStaticStore, condoFiltersStore, condoGuestsStore, menuStore, loginStore, condoFlexibleDatePickerStore, condoStrictDatesStore } = this.props;
    const {
      condos,
      loading,
      bounds,
      cachedSelectedLocation,
      isSessionExpired,
      loadingMore,
      counters,
      lastRequestType,
      cachedEndDate,
      cachedStartDate,
      selectedCondo,
      mapCondos,
      forceHideFilters,
      sessionKey,
      selectedCompareCondos,
      selectedCondoSearchClientCash,
    } = condosStore;
    const { sortType, budgetRanges, days, months, suppliers, cities } = condoFiltersStore;
    const { selectedMonths } = condoFlexibleDatePickerStore;
    const { searchType, startDate, endDate } = condoStrictDatesStore;
    const { account } = loginStore;

    const { visibleFilters, visibleMap, isNoAvailableCondosNotification, showCompareModal } = this.state;
    const isAnytime = lastRequestType === CondoRequestTypeEnum.Anytime;
    const isFlexible = lastRequestType === CondoRequestTypeEnum.Flexible;
    const isStrict = lastRequestType === CondoRequestTypeEnum.Strict;
    const nightsCount =
      isStrict && cachedStartDate && cachedEndDate
        ? differenceInDays(new Date(cachedEndDate), new Date(cachedStartDate))
        : zero;
    const isSpecificCondo =
      cachedSelectedLocation?.locationType === CondoLocationsEnum.GenericCondo;
    const exactMatches = condos?.filter(
      (condo: ICondo) => condo.matchType === CondoMatchTypeEnum.Exact,
    );
    const alternativesMatches = condos?.filter(
      (condo: ICondo) => condo.matchType === CondoMatchTypeEnum.Alternative,
    );
    const haveExacts = (exactMatches?.length ? exactMatches?.length : 0) > zero;
    const totalCondos = haveExacts
      ? counters?.totalFilteredExactCondos
      : counters?.totalFilteredCondos;

    const totalOptions = haveExacts
      ? counters?.totalFilteredExactOptions
      : counters?.totalFilteredOptions;
      
    const isFiltersEmpty = isCondoFiltersEmpty(condoFiltersStore);
    const accountName = getAccountUsernameFromPath(this.props.history);

    const walletHasSliderOnSearch = account?.walletHasSliderOnSearch;

    const isDisableHomes = loginStore?.account?.isDisableHomes;

    const showDays = counters?.days?.length ? counters.days?.some(({ count }) => count) : false;
    const showMonths = counters?.months?.length ? counters.months?.some(({ count }) => count) : false;
    const parentLocation = counters?.parentLocation;

    const showCities = counters?.cities?.length ? counters.cities?.some(({ count }) => count) : false;
    const HasViewQuotesSharePermission = HasPermission(loginStore?.user, ViewQuotesSharePermission);
    const HasViewCondoSupplierPermission = HasPermission(loginStore?.user, ViewCondoSupplierPermission);

    const displayCompareLength = HasViewQuotesSharePermission ? 0 : 1;

    const isStrictSearch = searchType === DateSearchTypeEnum.Strict;

    const footerLength = menuStore?.items?.footer?.length ? menuStore?.items?.footer?.length : 0;
    const hasFooterMenu = footerLength > 0;

    const suppliersList = condosStaticStore?.suppliers;

    const homeParams = GetHomeParams(account as IAccount);

    return (
      <div className="condo-result-wrapper">

        {selectedCompareCondos?.length ? (
          <>
            <div className="condo-result-wrapper__compare-message">
              {selectedCompareCondos.length > 1 ? (<FormattedMessage id="results.hotel.card.compare.message" values={{ selected: selectedCompareCondos.length, total: maxCompareCondos }} />) : null}
              {selectedCompareCondos.length === 1 ? (<FormattedMessage id="results.hotel.card.compare.message.select_more" />) : null}
              <div className="compare-buttons">
                {selectedCompareCondos.length ? (
                  <WhiteButton className="compare-clear-button" onClick={() => this.props.setSelectedCompareCondos([])}>
                    <FormattedMessage id="results.condos.card.compare.clear_selection" />
                  </WhiteButton>) : null}
                <BlueButton className="compare-button" disabled={selectedCompareCondos.length <= displayCompareLength} onClick={() => this.setState({ showCompareModal: true })}>
                  {HasViewQuotesSharePermission ? <FormattedMessage id="results.hotel.card.compare.share" /> : <FormattedMessage id="results.hotel.card.compare" /> }
                </BlueButton>
              </div>
            </div>

            <ModalCompare
              visible={showCompareModal}
              condos={selectedCompareCondos}
              adultsCount={condoGuestsStore.adultsCount}
              kidsCount={condoGuestsStore.kidsCount}
              bedroomsCount={condoGuestsStore.bedroomsCount}
              locationObject={cachedSelectedLocation}
              sessionKey={sessionKey}
              isFlexible={!isStrictSearch}
              months={selectedMonths}
              startDate={startDate}
              endDate={endDate}
              onCancel={() => this.setState({ showCompareModal: false })}
              onRemove={this.handleCompare}
            />
          </>) : null}

        <div className={`condo-result-wrapper__wrapper`}>
          <div
            ref={this.contentWrapperRef}
            className={`condo-result-wrapper__content ${account?.forceIframe ? 'force-iframe' : ''} ${visibleFilters ? 'filters-content' : ''} ${!hasFooterMenu ? 'no-footer' : ''}`}
          >
            <div
              className={`condo-result-wrapper__filters-wrapper ${visibleFilters ? 'show' : ''}`}
            >
              {visibleFilters ? (
                loading ? (
                  <CondoSkeletonFilters />
                ) : (
                  <CondoFiltersWrapper
                    onClose={this.toggleFilters}
                    onFiltersOrSortChange={this.onFiltersOrSortChange}
                    isDisabled={isSessionExpired}
                    matches={matches}
                  />
                )
              ) : null}
            </div>
            <div
              className={`condo-result-wrapper__hotels-wrapper ${!visibleFilters ? 'show' : ''} ${(selectedCompareCondos?.length && !hasFooterMenu) ? 'compare-open' : ''}`}
            >
              {(!loading && !isDisableHomes) ? (
                <div className="result-wrapper__back-link">
                  <div className="back-home" onClick={() => {
                    this.props.resetCondosFull();
                    this.props.history.push(`/${accountName}${Routes.CondoSearch}${homeParams}`);
                  }}>
                    <FormattedMessage id="back.home" />
                  </div>
                </div>) : null}

              {!matches && this.state.visibleMap && (
                <div className="condo-result-wrapper__map-top">
                  <div className="condo-result-wrapper__map-close" onClick={this.showMap}>
                    <CloseSvg />
                  </div>
                  <div className="condo-result-wrapper__map-location">
                    {cachedSelectedLocation?.name}
                  </div>
                  <div
                    className={`condo-result-wrapper__map-filters ${
                      isSessionExpired ? 'disabled' : ''
                    }`}
                    onClick={this.toggleFilters}
                  >
                    <FilterSvg />
                  </div>
                </div>
              )}
              {loading ? (
                <CondoSkeletonResult />
              ) : (
                <>
                  {isSessionExpired && (
                    <div className="condo-result-wrapper__session-expired">
                      <CondoExpiredSessionMessage onRefresh={() => window.location.reload()} />
                    </div>
                  )}
                  {matches && isAnytime ? <TravelDatesNotification /> : null}

                  {!forceHideFilters ? (
                    <div
                      className={`condo-result-wrapper__btn-view-wrapper ${isSessionExpired ? 'disabled' : ''} ${budgetRanges?.length ? 'selected' : ''}`}
                      style={{ position: 'relative' }}
                    >
                      <WhiteButton onClick={this.toggleFilters} disabled={isSessionExpired}>
                        {isFiltersEmpty ? (
                          <FilterSvg />
                        ) : (
                          <span className="condo-result-wrapper__filters-count">
                            {getCondoFiltersCount(condoFiltersStore)}
                          </span>
                        )}
                        <FormattedMessage id="all.filters" />
                      </WhiteButton>

                      <CondoResultFilter
                        display={!isAnytime && !isSessionExpired && (counters?.budgetRanges?.length ? counters?.budgetRanges?.length : 0) > 0}
                        title={'price'}
                        dropdownDisable={isSessionExpired || isAnytime}
                        dropdownTitle={'any.price'}
                        values={budgetRanges}
                        counters={counters?.budgetRanges}
                        counterConverter={(counter: IBudgetRange) => {
                          return {
                            count: minusOne,
                            value: `${counter.key.from}-${counter.key.to}`,
                            label: getBudgetRangeLabel(counter.key),
                          };
                        }}
                        onChange={this.onBudgetChange}
                        onDoneClick={() => this.handleFilterChanged(C_R_FILTER_BUDGET)}
                      />

                      <CondoResultFilter
                        display={(isAnytime || isFlexible) && !isSessionExpired && showDays}
                        title={'number.days'}
                        dropdownDisable={isSessionExpired}
                        dropdownTitle={'any.day'}
                        values={days?.map(d => d.toString())}
                        counters={counters?.days}
                        counterConverter={(counter: ICounterItem) => {
                          return {
                            count: counter.count,
                            value: `${counter.key}`,
                            label: <FormattedMessage id="days.count" values={{ count: counter.key }} />
                          };
                        }}
                        onChange={this.onDaysChange}
                        onDoneClick={() => this.handleFilterChanged(C_R_FILTER_DAY)}
                      />

                      <CondoResultFilter
                        display={(isAnytime || isFlexible) && !isSessionExpired && showMonths}
                        title={'number.of.months'}
                        dropdownDisable={isSessionExpired}
                        dropdownTitle={'any.month'}
                        values={months?.map(d => d.toString())}
                        counters={counters?.months}
                        counterConverter={(counter: ICounterItem) => {
                          return {
                            count: counter.count,
                            value: `${counter.key}`,
                            label: <FormattedMessage id={`months.${counter.key}`} />
                          };
                        }}
                        onChange={this.onMonthsChange}
                        onDoneClick={() => this.handleFilterChanged(C_R_FILTER_MONTH)}
                      />

                      <CondoResultFilter
                        display={(isAnytime || isFlexible) && !isSessionExpired && HasViewCondoSupplierPermission}
                        title={'supplier'}
                        dropdownDisable={isSessionExpired}
                        dropdownTitle={'supplier'}
                        values={suppliers?.map(d => d.toString())}
                        counters={suppliersList?.map(s => ({ count: 0, key: s }))}
                        counterConverter={(counter: ICounterItem) => {
                          return {
                            count: counter.count,
                            value: `${counter.key}`,
                            label: <FormattedMessage id={`suppliers.${counter.key}.name`} />
                          };
                        }}
                        onChange={this.onSuppliersChange}
                        displayZero={true}
                        onDoneClick={() => this.handleFilterChanged(C_R_FILTER_SUPPLIER)}
                      />

                      <CondoResultFilter
                        display={(isAnytime || isFlexible) && !isSessionExpired && showCities}
                        title={'cities'}
                        dropdownDisable={isSessionExpired}
                        dropdownTitle={'cities'}
                        values={cities?.map(d => d.toString())}
                        counters={counters?.cities}
                        counterConverter={(counter: ICounterItem) => {
                          return {
                            count: counter.count,
                            value: `${counter.key}`,
                            label: `${counter.key}`
                          };
                        }}
                        onChange={this.onCitiesChange}
                        onDoneClick={() => this.handleFilterChanged(C_R_FILTER_CITIES)}
                        inputFilter
                      />

                      {!isFiltersEmpty && (
                        <div className="condo-result-wrapper__reset-all" onClick={this.resetFilters}>
                          <FormattedMessage id="reset.all.filters" />
                        </div>
                      )}

                      {matches && (
                        <div className="condo-result-wrapper__condos-sort" style={{ position: 'absolute', right: '0' }}>
                          <CondoResultFilter
                            display={true}
                            title={'sort.by'}
                            dropdownDisable={isSessionExpired || (isAnytime && !isSpecificCondo)}
                            dropdownTitle={NULL_VALUE}
                            values={[sortType]}
                            counters={this.getSortOptions()}
                            counterConverter={(counter: any) => {
                              return {
                                count: -1,
                                value: `${counter.value}`,
                                label: counter.label
                              };
                            }}
                            onChange={this.onSortChange}
                            onDoneClick={NULL_VALUE}
                            aligment={Aligment.Right}
                            singleValue={true}
                          />
                        </div>)}

                    </div>) : null}

                  {!forceHideFilters ? (
                    <div
                      className={`condo-result-wrapper__btn-view-wrapper mobile ${
                        isSessionExpired ? 'disabled' : ''
                      }`}
                    >
                      <WhiteButton onClick={this.toggleFilters} disabled={isSessionExpired}>
                        <FilterSvg />
                        <FormattedMessage id="sort.filter" />
                      </WhiteButton>
                      <WhiteButton onClick={this.showMap} disabled={isSessionExpired}>
                        <MapSvg />
                        <FormattedMessage id="view.map" />
                      </WhiteButton>
                    </div>) : null}

                  {!matches && isAnytime && <TravelDatesNotification />}
                  {condos && !loading ? (
                    <>
                      {walletHasSliderOnSearch ? (
                        <div className="condo-result-wrapper__condos-top-section wallet">
                          <WalletSearchSlider
                            display={true}
                            selectedClientCash={selectedCondoSearchClientCash?.selectedSearchClientCash as number}
                            onSelectClientCash={this.handleOnSelectClientCash}
                          />
                        </div>) : null}

                      {parentLocation && (
                        <div className="condo-result-wrapper__condos-top-section" style={{ paddingBottom: '10px' }}>
                          <div className="condo-result-wrapper__condos-count">
                            {parentLocation?.name}
                            {': '}
                            <FormattedMessage
                              values={{ resortsCount: parentLocation.totalCondos, optionsCount: parentLocation.totalOptions }}
                              id="options.found.in"
                            />
                          </div>
                        </div>)}

                      {haveExacts && (
                        <div className="condo-result-wrapper__condos-top-section">
                          <div
                            className={`condo-result-wrapper__condos-count ${
                              isSessionExpired ? 'disabled' : ''
                            }`}
                          >
                            {cachedSelectedLocation?.name}
                            {': '}
                            {isSpecificCondo ? (
                              <FormattedMessage
                                values={{ resortsCount: totalCondos, optionsCount: totalOptions }}
                                id="options.found.in"
                              />
                            ) : (
                              <FormattedMessage
                                values={{ resortsCount: totalCondos, optionsCount: totalOptions }}
                                id="options.available.in"
                              />
                            )}
                          </div>
                        </div>
                      )}


                      {haveExacts ? (
                        exactMatches.map((condo: ICondo, index) => {
                          const isInCompareList = selectedCompareCondos?.map(c => c.condoId).includes(condo.condoId);
                          return (
                            <React.Fragment key={`${condo.condoId}-${index}-container`}>
                              <CondoHotelCard
                                onSelect={() => this.selectCondo(condo)}
                                disabled={isSessionExpired}
                                key={`${condo.condoId}-${index}`}
                                condo={condo}
                                lastRequestType={lastRequestType}
                                onMouseLeave={() => this.onMouseLeaveCondoCard(condo.condoId)}
                                onMouseEnter={() => this.onMouseEnterCondoCard(condo.condoId)}
                                matches={matches}

                                compared={isInCompareList}
                                disableCompare={!isInCompareList && selectedCompareCondos?.length >= maxCompareCondos}
                                displayCompare={true}
                                onCompareSelect={this.handleCompare}
                              />
                              {(index === 1 || exactMatches.length < 2) && (<Banner type={SEARCH_BANNER_TYPE}></Banner>)}
                            </React.Fragment>
                          );
                        })
                      ) : isFiltersEmpty && isNoAvailableCondosNotification ? 
                        condos?.length ? (
                          <NoAvailableCondosNotification
                            baseUrl={menuStore.items?.baseUrl as string}
                            resortsCount={counters?.totalAlternativeCondos}
                            optionsCount={counters?.totalAlternativeOptions}
                            isSpecificCondo={isSpecificCondo}
                          />
                        ) : (
                          <CondoResultsEmpty />
                      ) : (
                        <NoAvailableCondosFilterNotification
                          resetFilters={this.resetFilters}
                          altOptionsCount={alternativesMatches.length}
                        />
                      )}

                      {alternativesMatches?.length ? (
                        <>
                          <div className="condo-result-wrapper__condos-top-section">
                            <div
                              className={`condo-result-wrapper__condos-count alternative ${
                                isSessionExpired ? 'disabled' : ''
                              }`}
                            >
                              {isSpecificCondo ? (
                                <FormattedMessage
                                  values={{
                                    resortsCount: counters?.totalFilteredAlternativeCondos,
                                    optionsCount: counters?.totalFilteredAlternativeOptions
                                  }}
                                  id="and.options.available.in"
                                />
                              ) : haveExacts ? (
                                <FormattedMessage
                                  values={{
                                    resortsCount: counters?.totalFilteredAlternativeCondos,
                                    optionsCount: counters?.totalFilteredAlternativeOptions,
                                    location: cachedSelectedLocation?.name,
                                  }}
                                  id="alternative.condo.title"
                                />
                              ) : null}{' '}
                              {isStrict && nightsCount && nightsCount < week ? (
                                <FormattedMessage id="one.week.stay" />
                              ) : null}
                            </div>
                          </div>

                          {alternativesMatches.map((condo: ICondo, indexAlt: number) => {
                            const isInCompareList = selectedCompareCondos?.map(c => c.condoId).includes(condo.condoId);
                            return (
                              <React.Fragment key={`${condo.condoId}-${indexAlt}-container-mobile`}>
                                <CondoHotelCard
                                  onSelect={() => this.selectCondo(condo)}
                                  disabled={isSessionExpired}
                                  key={condo.condoId}
                                  condo={condo}
                                  lastRequestType={lastRequestType}
                                  onMouseLeave={() => this.onMouseLeaveCondoCard(condo.condoId)}
                                  onMouseEnter={() => this.onMouseEnterCondoCard(condo.condoId)}
                                  matches={matches}

                                  compared={isInCompareList}
                                  disableCompare={!isInCompareList && selectedCompareCondos?.length >= maxCompareCondos}
                                  displayCompare={true}
                                  onCompareSelect={this.handleCompare}
                                />
                                {(!haveExacts && (indexAlt === 1 || alternativesMatches.length < 2)) && (<Banner type={SEARCH_BANNER_TYPE}></Banner>)}
                              </React.Fragment>
                              
                            );
                          })}
                        </>) : null}

                      {loadingMore ? <CondoCardsSkeleton /> : null}
                    </>
                  ) : null}
                </>
              )}
            </div>
          </div>
          <div
            className={`condo-result-wrapper__map-content ${account?.forceIframe ? 'force-iframe' : ''} ${visibleMap ? 'mobile-show' : ''} ${!hasFooterMenu ? 'no-footer' : ''}`}
            style={{ backgroundImage: `url(${CondoMapPlaceholder})` }}
          >
            {cachedSelectedLocation &&
            condos &&
            !loading &&
            bounds &&
            mapCondos &&
            (this.state.visibleMap || matches) ? (
              <CondoMapWrapper
                selectCondo={this.selectCondo}
                selectedCondo={selectedCondo}
                lastRequestType={lastRequestType}
                disabled={isSessionExpired}
                locations={mapCondos.map(({ condoId, location, pricePerNight, matchType }) => ({
                  id: condoId,
                  location,
                  pricePerNight,
                  isExact: matchType === CondoMatchTypeEnum.Exact && isSpecificCondo,
                  isDisabled:
                    matchType === CondoMatchTypeEnum.Exact && isSpecificCondo && !pricePerNight,
                }))}
                bounds={bounds}
                condos={mapCondos}
                setBounds={this.updateBounds}
                mapOptions={{
                  zoomControl: true,
                  maxZoom: 17,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: false,
                  zoomControlOptions: {
                    position: google.maps.ControlPosition.LEFT_TOP,
                  },
                }}
                hasCompareSelected={selectedCompareCondos?.length > 0}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    condosStore: state.condosStore,
    condosLocationPickerStore: state.condosLocationPickerStore,
    condoFiltersStore: state.condoFiltersStore,
    condoGuestsStore: state.condoGuestsStore,
    condoStrictDatesStore: state.condoStrictDatesStore,
    condoFlexibleDatePickerStore: state.condoFlexibleDatePickerStore,
    condosStaticStore: state.condosStaticStore,
    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore,
    savedPropertiesStore: state.savedPropertiesStore
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  setSortType: (sortType: SortTypes) => {
    dispatch(condoFiltersActions.setSortType(sortType));
  },
  setBudgetRanges: (value: string[]) => {
    dispatch(condoFiltersActions.setBudgetRanges(value));
  },
  setDays: (value: number[]) => {
    dispatch(condoFiltersActions.setDays(value));
  },
  setMonths: (value: number[]) => {
    dispatch(condoFiltersActions.setMonths(value));
  },
  setSuppliers: (value: string[]) => {
    dispatch(condoFiltersActions.setSuppliers(value));
  },
  setCities: (value: string[]) => {
    dispatch(condoFiltersActions.setCities(value));
  },
  resetFilters: () => {
    dispatch(condoFiltersActions.resetFilters());
  },
  setPageNumber: (num: number) => {
    dispatch(condosActions.setPageNumber(num));
  },
  getCondos: (searchType: SearchTypeEnum = SearchTypeEnum.Pagination) => {
    dispatch(getCondos(searchType));
  },
  setBounds: (bounds: IBounds) => {
    dispatch(condosActions.setBounds(bounds));
  },
  setSelectedCondo: (condo: ICondo | null | undefined) => {
    dispatch(condosActions.setSelectedCondo(condo));
  },
  setSelectedCompareCondos: (condos: ICondo[]) => {
    dispatch(condosActions.setSelectedCompareCondos(condos));
  },
  resetCondosFull: () => {
    dispatch(resetCondosFull());
  },
  addMapCondo: (condo: ICondo) => {
    dispatch(condosActions.addMapCondo(condo));
  },
  resetLocations: () => {
    dispatch(condosLocationPickerActions.resetLocations());
  },
  setSelectedCondoSearchClientCash: (selectedClientCash: IClientCash) => {
    dispatch(condosActions.setSelectedCondoSearchClientCash(selectedClientCash));
  },
});

export const CondoResultWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CondoResultWrapperComponent));
