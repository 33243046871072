import React from 'react';
import { Checkbox, Input, Tooltip } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { IFilterOption } from '@common-types';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';

import './style.scss';

interface IProps extends WrappedComponentProps {
  title: string;
  titleInformation?: string;
  options: IFilterOption[];
  values: string[];
  onChange: (selectedValues: any[]) => void;
  disabled?: boolean;
  withShowMore?: boolean;
  displayZero?: boolean;
  inputFilter?: boolean;
}

interface IState {
  shownMore: boolean;
  inputValue: string;
}

const zero = 0;
const defaultOptionsCount = 5;

export class FilterCheckboxComponent extends React.Component<IProps, IState> {
  state: IState = {
    shownMore: false,
    inputValue: ''
  };

  toggleOptions = (): void => {
    this.setState((prevState: IState) => ({
      shownMore: !prevState.shownMore,
    }));
  };

  handleChangeInput = (inputValue: string) => {
    this.setState({ inputValue });
  }

  render(): React.ReactNode {
    const { shownMore, inputValue } = this.state;
    const { displayZero, title, titleInformation, options, values, onChange, disabled = false, withShowMore = false, inputFilter } = this.props;
    const showShowMore = withShowMore && options.length > defaultOptionsCount && !disabled;
    const resOptions = showShowMore
      ? shownMore
        ? options
        : options.slice(zero, defaultOptionsCount)
      : options;

    const style: any = {};
    if (inputFilter) {
      style.paddingBottom = '10px';
    }

    let resOptionsFiltered = resOptions;
    if (inputFilter && !isEmpty(inputValue)) {
      resOptionsFiltered = resOptions?.filter(o => o.label?.toString().toLowerCase().includes(inputValue));
    }

    return (
      <div className="filter filter-checkbox">
        {!isEmpty(title) ? (
          <p className="filter__title secondary-font" style={style}>
            <FormattedMessage id={title} />
            {!isEmpty(titleInformation) ? (
              <Tooltip title={this.props.intl.formatMessage({ id: titleInformation })} placement="top">
                <FontAwesomeIcon icon={faInfoCircle} />
              </Tooltip>) : null}
          </p>) : null}

        {inputFilter && (
          <div style={{ paddingBottom: '20px', position: 'relative' }}>
            <Input value={inputValue} onChange={e => this.handleChangeInput(e.target.value)} />
            {!isEmpty(inputValue) && (
              <span style={{ position: 'absolute', right: '5px', top: '2px', padding: '5px', cursor: 'pointer' }} onClick={() => this.setState({ inputValue: '' })}>
                <FontAwesomeIcon icon={faTimesCircle} />
              </span>)}
          </div>)}

        <Checkbox.Group value={values} onChange={onChange} disabled={disabled}>
          {resOptionsFiltered?.map((item: IFilterOption) => {
            if (!item.count && !displayZero) {
              return null;
            }

            const count = item.count ? item.count : 0;
            return (
              <div key={item.value}>
                <Checkbox value={item.value} disabled={disabled}>
                  <span className="filter__option-name">{item.label}</span>
                  {count > zero ? (
                    <span className="filter__option-quantity">{item.count}</span>
                  ) : null}
                </Checkbox>
              </div>
            );
          })}
        </Checkbox.Group>
        {showShowMore ? (
          <div className="filter__show-link" onClick={this.toggleOptions}>
            {shownMore ? <FormattedMessage id="show.less" /> : <FormattedMessage id="show.more" />}
          </div>
        ) : null}
      </div>
    );
  }
}


export const FilterCheckbox = connect()(injectIntl(FilterCheckboxComponent));
