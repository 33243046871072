import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { CondoDetailsWrapper, HotelDetailsWrapper } from '@components';
import { getTimeout, UrlUtils, scrollTop, getAccountUsernameFromPath, getNormalizedRoomsResponse, Responsive } from '@share/utils';
import { ILocation, IRoom, ISessionKey } from '@share/common-types';
import { CONDO_SESSION_KEY, Routes, SESSION_KEY_LABEL } from '@share/constants';
import { condosActions, condosLocationPickerActions, condoStrictDatesActions, datesActions, locationActions, roomsActions } from '@share/store/slices';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { NULL_VALUE } from '@constants';

const zero = 0;

interface IMapDispatchToProps {
  setRooms: (rooms: IRoom[]) => void;
  setDatesSelected: (dates: { startDate: string; endDate: string }) => void;
  setSelectLocation: (location: ILocation) => void;
  setSelectLocationLabel: (label: string) => void;
  applyDates: () => void;
  applyRooms: () => void;
  applyLocation: () => void;
  selectLocationLabel: (label: string) => void;
  setStrictDates: (dates: { startDate: string; endDate: string }) => void;
  setIsFocusDatePicker: (bool: boolean) => void;
}

interface IProps extends IMapDispatchToProps, RouteComponentProps {
  type: string;
}

function StaticDetailPageComponent(props: IProps) {
  const [loading, setLoading] = useState(true);
  const [isHotel, _setIsHotel] = useState<boolean>(props.type === 'hotel');

  useEffect(() => {
    scrollTop();
    const values = UrlUtils.getValues();
    const routeSearch = isHotel ? Routes.Search : Routes.CondoSearch;
    const sessionKey = isHotel ? SESSION_KEY_LABEL : CONDO_SESSION_KEY;

    const session: ISessionKey = values[sessionKey] as ISessionKey;
    if ((session && session.expireDate && getTimeout(session.expireDate) <= zero)) {
      const accountName = getAccountUsernameFromPath(props.history);
      history.replaceState(null, NULL_VALUE, `/${accountName}${routeSearch}${location.search}`);
      location.reload();
    }
    else if (isEmpty(values)) {
      const id = (props.match.params as any).id;
      let name = (props.match.params as any).name;
      const date = new Date();
      const startDate: any = moment(date.setDate(date.getDate() + 1)).format('yyyy-MM-DDT00:00:00');
      if (isHotel) {
        const locationObject: any = { "code": id, "name": name }
        props.setSelectLocationLabel(name);
        props.setSelectLocation(locationObject);

        const rooms: any[] = [{ adultsCount: 2, kids: [] }];
        props.setRooms(getNormalizedRoomsResponse(rooms));

        const endDate: any = moment(date.setDate(date.getDate() + 3)).format('yyyy-MM-DDT00:00:00');
        props.setDatesSelected({ startDate: startDate, endDate: endDate });

        props.applyDates();
        props.applyRooms();
        props.applyLocation();
      }
      else {
        const endDate: any = moment(date.setDate(date.getDate() + 7)).format('yyyy-MM-DDT00:00:00');
        props.setStrictDates({ startDate, endDate });
        props.selectLocationLabel(name);
        props.setIsFocusDatePicker(true);
      }
    }

    setLoading(false);
  }, []);

  return (
    <>
      {loading ? null :
        isHotel
          ? <div className="hotel-details-page"><HotelDetailsWrapper isVacationRentals={false} isStaticDetail={true} /></div>
          : <div className="condo-details-page"><Responsive><CondoDetailsWrapper isStaticDetail={true} /></Responsive></div>
      }
    </>
  );
}

const mapDispatchToProps: IMapDispatchToProps = {
  setRooms: roomsActions.setRooms,
  setDatesSelected: datesActions.setDatesSelected,
  setSelectLocationLabel: locationActions.setSelectLocationLabel,
  setSelectLocation: locationActions.setSelectLocation,
  applyDates: datesActions.applyDates,
  applyRooms: roomsActions.applyRooms,
  applyLocation: locationActions.applyLocation,
  selectLocationLabel: condosLocationPickerActions.selectLocationLabel,
  setStrictDates: condoStrictDatesActions.setDates,
  setIsFocusDatePicker: condosActions.setIsFocusDatePicker,
};

export const StaticDetailPage = connect(null, mapDispatchToProps)(withRouter(StaticDetailPageComponent));