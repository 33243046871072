
import isEqual from 'lodash/isEqual';

import { FormattedMessage } from 'react-intl';
import { LabeledValue } from 'antd/lib/select';
import {
  CondoLocationsEnum,
  GetawaysCondoSubCategoryEnum,
  GetawaysLocationEnum,
  IAccount,
  IBudgetRange,
  ICounterItem,
  IRange,
} from '@share/common-types';
import { CondoRequestTypeEnum } from '@share/common-types';
import {
  CarsFiltersInitialState,
  filtersB2CHotelsOnlyInitialState,
  filtersB2CInitialState,
  filtersB2CVacationsInitialFilteredState,
  filtersB2CVacationsInitialState,
  filtersInitialState,
  filtersInitialVacationsFilteredState,
  filtersInitialVacationsState,
  ICarsFiltersState,
  IFiltersState,
  VICINITY,
  filtersAllInclusiveInitialState
} from '@share/store/slices';
import { ICondosState } from '@share/store/slices';
import { TOP_AMENITIES } from '@constants';
import { SortTypes } from '@share/constants';
import {
  CHOICE_LIFESTYLE,
  CHOICE_LIFESTYLE_DESC,
  UNLIMITED_LIFESTYLE,
  UNLIMITED_LIFESTYLE_DESC,
  VALUE_LIFESTYLE,
  VALUE_LIFESTYLE_DESC,
} from './wallet';
import { IGateway } from '@store/slices';
import { isAllInclusiveFromQuery } from '@share/utils';

export const getAmenities = (allAmenities: ICounterItem[]): ICounterItem[] => {
  const res: ICounterItem[] = [];

  TOP_AMENITIES.forEach((amenity: string) => {
    const item = allAmenities.find(({ key }) => key.toLowerCase() === amenity.toLowerCase());

    if (item) {
      res.push(item);
    }
  });

  allAmenities.forEach((amenity: ICounterItem) => {
    if (res.every((item: ICounterItem) => item.key.toLowerCase() !== amenity.key.toLowerCase())) {
      res.push(amenity);
    }
  });

  return res;
};

export const isFiltersChanged = (
  prevFilters: IFiltersState,
  currentFilters: IFiltersState,
): boolean => {
  return !isEqual(prevFilters, currentFilters);
};

export const isCarsFiltersChanged = (
  prevFilters: ICarsFiltersState,
  currentFilters: ICarsFiltersState,
): boolean => {
  return !isEqual(prevFilters, currentFilters);
};

export const isFiltersEmpty = (currentFilters: IFiltersState, account: IAccount, isVacationRentals: boolean | null | undefined): boolean => {
  const isB2C = account?.isB2C;
  const walletWalletSavings = account?.walletWalletSavings;
  const forceDisableVacationRentals = account?.forceDisableVacationRentals;
  const restrictedVacationRentals = account?.restrictedVacationRentals;
  const restrictedVacationRentalsList = account?.restrictedVacationRentalsList;

  if (isAllInclusiveFromQuery()) {
    return isEqual(currentFilters, filtersAllInclusiveInitialState);
  }

  const initialState = (!isB2C || walletWalletSavings) ? 
                            isVacationRentals ?
                                restrictedVacationRentals ?
                                    restrictedVacationRentalsList?.length ? { ...filtersInitialVacationsFilteredState, accommodationType: [...restrictedVacationRentalsList] } : filtersInitialVacationsFilteredState :
                                    filtersInitialVacationsState :
                                filtersInitialState : 
                                    forceDisableVacationRentals ? 
                                        filtersB2CHotelsOnlyInitialState : 
                                        isVacationRentals ?
                                            restrictedVacationRentals ?
                                                restrictedVacationRentalsList?.length ? { ...filtersB2CVacationsInitialFilteredState, accommodationType: [...restrictedVacationRentalsList] } : filtersB2CVacationsInitialFilteredState :
                                                filtersB2CVacationsInitialState :
                                        filtersB2CInitialState;

  return isEqual(currentFilters, initialState);
};

export const isCarsFiltersEmpty = (currentFilters: ICarsFiltersState): boolean => {
  return isEqual(
    getCarsFilterStateExcluded(currentFilters),
    getCarsFilterStateExcluded(CarsFiltersInitialState),
  );
};

const getCarsFilterStateExcluded = (filters: ICarsFiltersState) => {
  const {
    simpleTypeQuick,
    rateDistanceQuick,
    rentalCompanyQuick,
    fuelQuick,
    ...filtersExluced
  } = filters;
  return filtersExluced;
};

const zero = 0;
const maxFilterCount = 6;

const getCounterKey = (counter: ICounterItem | IBudgetRange): string => {
  const key = counter?.key;
  if (typeof key === 'string') {
    return key;
  } else {
    const rangeKey = key as IRange;

    if (!rangeKey?.from && !rangeKey?.to) {
      return ``;
    }

    return `${rangeKey.from}-${rangeKey.to}`;
  }
};

export const getFilterListB = (
  counters: (ICounterItem | IBudgetRange)[],
  selected: string[],
  displayFull: boolean,
  exluceNonSelected?: boolean,
) => {
  let list = counters;
  if (counters?.length && selected?.length) {
    const countersToMoveTop = counters
      .filter((a) => {
        return selected.includes(getCounterKey(a));
      })
      .map((c) => ({ ...c }));
    if (exluceNonSelected) {
      list = [...countersToMoveTop];
    } else {
      const countersToKeep = counters
        .filter((a) => !selected.includes(getCounterKey(a)))
        .map((c) => ({ ...c }));
      list = [...countersToMoveTop, ...countersToKeep];
    }
  }

  return displayFull ? list : list.slice(zero, maxFilterCount);
};

export const getFilterList = (
  counters: ICounterItem[],
  selected: string[],
  displayFull: boolean,
) => {
  let list = counters;
  if (counters?.length && selected?.length) {
    const countersToMoveTop = counters.filter((a) => selected.includes(a.key));
    const countersToKeep = counters.filter((a) => !selected.includes(a.key));
    list = [...countersToMoveTop, ...countersToKeep];
  }

  return displayFull ? list : list.slice(zero, maxFilterCount);
};

export const getGetawaySortOptions = (): LabeledValue[] => {
  return [
    { value: SortTypes.PriceAsc, label: <FormattedMessage id="sort.low.to.price" /> },
    { value: SortTypes.PriceDesc, label: <FormattedMessage id="sort.high.to.price" /> },
  ];
};

export const getAllInclusiveSortOptions = (): LabeledValue[] => {
  return [
    { value: SortTypes.BiggestSavings, label: <FormattedMessage id="sort.biggest" /> },
    { value: SortTypes.BiggestSavingsPercentage, label: <FormattedMessage id="sort.biggest.percent" /> },
    { value: SortTypes.PriceAsc, label: <FormattedMessage id="sort.low.to.price" /> }
  ];
};

export const getGetawayOptionLabel = (location: any): string => {
  const countryIncludesVicinity = location.country?.includes(VICINITY);
  const cityIncludesVicinity = location.city?.includes(VICINITY);
  const countryStr = location.country ? location.country.replace(` ${VICINITY}`, '') : '';
  const stateStr = location.state ? location.state : '';
  const cityStr = location.city ? `${location.city}${(countryIncludesVicinity && !cityIncludesVicinity) ? ` ${VICINITY}` : ''}` : '';
  return `${cityStr}${location.state ? ', ' : ''}${stateStr}${location.country ? ', ' : ''}${countryStr}`;
};

//Quitar el default check si no tienen datos
export const filterDefaultCheckedValues = (
  defaultCheckedValues: string[],
  condosStore: IGateway,
  premiereStore: IGateway | null,
  valueStore: IGateway | null,
  staycationStore: IGateway,
  cruiseStore: IGateway,
  fantasyStore: IGateway,
) => {
  return defaultCheckedValues.filter((value) => {
    switch (value) {
      case GetawaysLocationEnum.NewCondos:
        return condosStore.getawaysTotalCount !== 0;
      case GetawaysCondoSubCategoryEnum.PremierEscapes:
        return premiereStore?.getawaysTotalCount !== 0;
      case GetawaysCondoSubCategoryEnum.ValueGetaways:
        return valueStore?.getawaysTotalCount !== 0;
      case GetawaysLocationEnum.Staycation:
        return staycationStore.getawaysTotalCount !== 0;
      case GetawaysLocationEnum.Cruise:
        return cruiseStore.getawaysTotalCount !== 0;
      case GetawaysLocationEnum.Fantasy:
        return fantasyStore.getawaysTotalCount !== 0;
      default:
        return true;
    }
  });
};

export const getSortOptions = (
  hasQuick: boolean,
  isB2C: boolean,
  walletWalletSavings: boolean,
  hasSavedProperties?: boolean
): LabeledValue[] => {
  const sorts = [];
  if (hasQuick) {
    sorts.push({ value: SortTypes.MostBooked, label: <FormattedMessage id="sort.most.booked" /> });
  }

  if (!isB2C || walletWalletSavings) {
    sorts.push({
      value: SortTypes.BiggestSavingsPercentage,
      label: <FormattedMessage id="sort.biggest.percent" />,
    });
    sorts.push({ value: SortTypes.BiggestSavings, label: <FormattedMessage id="sort.biggest" /> });
  }

  sorts.push(
    { value: SortTypes.GuestRating, label: <FormattedMessage id="sort.guest" /> },
    { value: SortTypes.PriceAsc, label: <FormattedMessage id="sort.lowest.price" /> },
    { value: SortTypes.PriceDesc, label: <FormattedMessage id="sort.highest.price" /> },
    { value: SortTypes.DistanceAsc, label: <FormattedMessage id="sort.nearest" /> },
  );

  if (hasSavedProperties) {
    sorts.push({ value: SortTypes.SavedProperties, label: <FormattedMessage id="sort.saved.properties" /> });
  }

  return sorts;
};

export const getCarsSortOptions = (
  hasQuick: boolean,
  isB2C: boolean,
  walletWalletSavings: boolean,
): LabeledValue[] => {
  const sorts = [];
  if (hasQuick) {
    sorts.push({ value: SortTypes.MostBooked, label: <FormattedMessage id="sort.most.booked" /> });
  }

  if (!isB2C || walletWalletSavings) {
    sorts.push({
      value: SortTypes.BiggestSavingsPercentage,
      label: <FormattedMessage id="sort.biggest.percent" />,
    });
    sorts.push({ value: SortTypes.BiggestSavings, label: <FormattedMessage id="sort.biggest" /> });
  }

  sorts.push(
    { value: SortTypes.BrandNames, label: <FormattedMessage id="sort.brand.names" /> },
    { value: SortTypes.PriceAsc, label: <FormattedMessage id="sort.lowest.price" /> },
    { value: SortTypes.PriceDesc, label: <FormattedMessage id="sort.highest.price" /> },
  );
  return sorts;
};

export const GetLifeStyleStores = () => {
  return [
    { value: VALUE_LIFESTYLE, label: VALUE_LIFESTYLE_DESC },
    { value: CHOICE_LIFESTYLE, label: CHOICE_LIFESTYLE_DESC },
    { value: UNLIMITED_LIFESTYLE, label: UNLIMITED_LIFESTYLE_DESC },
  ];
};

export const getCondoSortOptions = (
  condoState: ICondosState,
  isB2C: boolean | undefined,
  walletWalletSavings: boolean | undefined,
  hasSavedProperties?: boolean
): LabeledValue[] => {
  const { cachedSelectedLocation, lastRequestType } = condoState;
  const isSpecificCondo: boolean =
    cachedSelectedLocation?.locationType === CondoLocationsEnum.GenericCondo;
  const isAnytime: boolean = lastRequestType === CondoRequestTypeEnum.Anytime;

  const options = [];

  if (!isB2C || walletWalletSavings) {
    options.push({
      value: SortTypes.BiggestSavingsPercentage,
      label: <FormattedMessage id="sort.biggest.percent" />,
    });
    options.push({
      value: SortTypes.BiggestSavings,
      label: <FormattedMessage id="sort.biggest" />,
    });
  }

  options.push(
    { value: SortTypes.MostPopular, label: <FormattedMessage id="most.popular" /> },
    { value: SortTypes.PriceAsc, label: <FormattedMessage id="sort.lowest.price" /> },
    { value: SortTypes.PriceDesc, label: <FormattedMessage id="sort.highest.price" /> },
  );

  if (isSpecificCondo) {
    options.push({ value: SortTypes.Nearest, label: <FormattedMessage id="sort.nearest" /> });
  }

  if (!isAnytime) {
    options.push({
      value: SortTypes.ClosestDateRange,
      label: <FormattedMessage id="closest.date.range" />,
    });
  }

  if (lastRequestType === CondoRequestTypeEnum.Flexible) {
    options.push({
      value: SortTypes.FarthestDateRange,
      label: <FormattedMessage id="farthest.date.range" />,
    });
  }

  if (isSpecificCondo && isAnytime) {
    return [
      { value: SortTypes.MostPopular, label: <FormattedMessage id="most.popular" /> },
      { value: SortTypes.Nearest, label: <FormattedMessage id="sort.nearest" /> },
    ];
  }

  if (hasSavedProperties) {
    options.push({ value: SortTypes.SavedProperties, label: <FormattedMessage id="sort.saved.properties" /> });
  }

  return options;
};
