import React from 'react';
import addMonths from 'date-fns/addMonths';
import format from 'date-fns/format';

import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { enUS } from 'date-fns/esm/locale';

import { RootState } from '@share/utils';
import { MAX_SELECTED_MONTH_COUNT, DATE_FORMAT } from '@share/constants';
import { condoFlexibleDatePickerActions, ICondoFlexibleDatePickerState } from '@share/store/slices';
import { getStayForText, getSelectedMonthText } from '@share/utils';
import { ILoginState } from '@share/store/slices';

import { ArrowLeftSvg, ArrowRightSvg } from '@share/assets';

import { isEmpty } from 'lodash';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons/faLightbulb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

interface IMapStateToProps {
  condoFlexibleDatePickerStore: ICondoFlexibleDatePickerState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  resetState: () => void;
  selectMonths: (month: string) => void;
  selectMonthsExperiences: (month: string) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  isExperiences?: boolean;
  matches?: boolean;
  style?: React.CSSProperties;
}

interface IState {
  step: number;
}

const ZERO = 0;
const ONE = 1;
const MAX_MONTH_COUNT = 24;
const SHOW_MONTH_COUNT = 6;
const THREE_CHARACTERS = 3;

export class CondoFlexibleDatePickerComponent extends React.Component<IProps, IState> {
  state: IState = {
    step: ZERO,
  };

  generateMonthList = (): string[] => {
    const { loginStore } = this.props;
    const { account } = loginStore;
    // For now we for english
    const locale = enUS;//getAccountDateFnsLocale(account);

    const today = new Date();
    const maxDate = addMonths(today, MAX_MONTH_COUNT);
    const result: string[] = [];
    let i = today;

    while (i <= maxDate) {
      result.push(format(i, DATE_FORMAT, { locale }));
      i = addMonths(i, ONE);
    }

    return result;
  };

  next = (): void => {
    if (this.state.step <= MAX_MONTH_COUNT - SHOW_MONTH_COUNT) {
      this.setState((prevState: IState) => {
        return { step: prevState.step + ONE };
      });
    }
  };

  prev = (): void => {
    if (this.state.step > ZERO) {
      this.setState((prevState: IState) => {
        return { step: prevState.step - ONE };
      });
    }
  };

  getStayForText = (): React.ReactNode => {
    return getStayForText();
  };

  getSelectedMonthText = (): React.ReactNode => {
    const { condoFlexibleDatePickerStore } = this.props;
    const { selectedMonths } = condoFlexibleDatePickerStore;
    const text = getSelectedMonthText(selectedMonths, this.props.intl);

    return (
      <span>
        <FormattedMessage id="stay.for.a.week.in" /> <span className="bold">{text}</span>
      </span>
    );
  };

  getMonthList = (): React.ReactNode => {
    const { step } = this.state;
    const { isExperiences, condoFlexibleDatePickerStore, selectMonths, selectMonthsExperiences, matches, loginStore } = this.props;
    const { selectedMonths } = condoFlexibleDatePickerStore;
    const list = matches
      ? this.generateMonthList().slice(step, step + SHOW_MONTH_COUNT)
      : this.generateMonthList();
    const { account } = loginStore;
    const locale = account?.locale;
 
    const generalBorderRadius: any = account?.generalBorderRadius ? account?.generalBorderRadius : null;
    const style = !isEmpty(generalBorderRadius) ? { borderRadius: generalBorderRadius } : {};  

    return list.map((dateString) => {
      const date = new Date(dateString);
      const month = format(date, 'MMMM', { locale });
      const year = format(date, 'yyyy');
      const dateStringWithoutDay = dateString.toString().substr(THREE_CHARACTERS);
      const selectedMonthsWithoutDay = selectedMonths.map((item: any) => item.substr(THREE_CHARACTERS));

      return (
        <div
          key={dateString}
          className={`
            condo-flexible-date-picker__month-wrapper
            ${
              !selectedMonthsWithoutDay.includes(dateStringWithoutDay) &&
              selectedMonthsWithoutDay.length === MAX_SELECTED_MONTH_COUNT
                ? 'disabled'
                : ''
            }
            ${selectedMonthsWithoutDay.includes(dateStringWithoutDay) ? 'selected' : ''}
          `}
          style={style}
          onClick={() => isExperiences ? selectMonthsExperiences(dateString) : selectMonths(dateString)}
        >
          <div className="condo-flexible-date-picker__month">{month}</div>
          <div className="condo-flexible-date-picker__year">{year}</div>
          <div className="condo-flexible-date-picker__selected-badge">
            <FontAwesomeIcon icon={faCircleCheck} style={{ color: '#0d99d6', fontSize: '14px' }} />
          </div>
        </div>
      );
    });
  };

  render(): React.ReactNode {
    const { step } = this.state;
    const { condoFlexibleDatePickerStore, style = {} } = this.props;
    const { selectedMonths } = condoFlexibleDatePickerStore;

    return (
      <div className="condo-flexible-date-picker" style={style}>
        <div className="condo-flexible-date-picker__wrapper">
          <div className="condo-flexible-date-picker__label">
            {!selectedMonths.length ? (
              <>
                <FormattedMessage id="stay.for.a.week" />{' '}
                <span className="bold ttl">
                  <FormattedMessage id="anytime" />
                </span>
              </>
            ) : null}
            {selectedMonths.length ? this.getSelectedMonthText() : null}
          </div>
          <div className="condo-flexible-date-picker__months">
            {this.getMonthList()}
            {step > ZERO ? (
              <div className="condo-flexible-date-picker__months-prev" onClick={this.prev}>
                <ArrowLeftSvg />
              </div>
            ) : null}
            {step <= MAX_MONTH_COUNT - SHOW_MONTH_COUNT ? (
              <div className="condo-flexible-date-picker__months-next" onClick={this.next}>
                <ArrowRightSvg />
              </div>
            ) : null}
          </div>
        </div>
        <div className="condo-flexible-date-picker__stay-for-item">
          <FontAwesomeIcon icon={faLightbulb} style={{ color: '#D2D2D2', fontSize: '17px' }} />
          <FormattedMessage id="select.up.to.three.months" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    condoFlexibleDatePickerStore: state.condoFlexibleDatePickerStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  resetState: () => {
    dispatch(condoFlexibleDatePickerActions.resetState());
  },
  selectMonths: (month: string) => {
    dispatch(condoFlexibleDatePickerActions.selectMonths(month));
  },
  selectMonthsExperiences: (month: string) => {
    dispatch(condoFlexibleDatePickerActions.selectMonthsExperiences(month));
  },
});

export const CondoFlexibleDatePicker = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(CondoFlexibleDatePickerComponent));
