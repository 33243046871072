import React, { useEffect } from 'react';
import { Spin } from 'antd';
import './style.scss';

interface GlobalSpinnerProps {
  spinning: boolean;
}

const GlobalSpinner: React.FC<GlobalSpinnerProps> = ({ spinning }) => {
  // Añadir o quitar la clase que deshabilita el scroll
  useEffect(() => {
    if (spinning) {
      document.body.style.overflow = 'hidden'; // Desactiva el scroll
    } else {
      document.body.style.overflow = ''; // Restaura el scroll
    }
    return () => {
      document.body.style.overflow = ''; // Limpiar efecto cuando el componente se desmonta
    };
  }, [spinning]);

  if (!spinning) return null;

  return (
    <div className="global-spinner-overlay">
      <Spin size="large" className="large-spinner" />
    </div>
  );
};

export default GlobalSpinner;
