
import format from 'date-fns/format';
import addMonths from 'date-fns/addMonths';
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get, isUndefined } from 'lodash';

import {
  ISessionKey,
  ITrustYouReview,
} from '@share/common-types';
import {
  CONDO_SESSION_KEY,
  Routes,
  SESSION_EXPIRED_STATUS,
  SESSION_SEARCH_EXPIRED_MESSAGE,
  Urls,
} from '@share/constants';
import { getDateCheckInOut, getSelectedCurrency, getSessionObj, UrlUtils } from '@share/utils';
import { getHeaders, axiosInstance } from '@share/utils';
import { AppThunk } from '@share/utils';
import { SERVER_ERROR_STATUSES } from '@share/constants';

import { events, getCondoSearchBodyDetails, getEndDateNextMonth } from '@share/utils';
import {
  ICondoDetails,
  ICondoCheckinStatuses,
  CustomEvents,
  BookingErrorsEnum
} from '@share/common-types';

import { trustYouReviewActions } from './trust-you-review';
import { updateUnits, getUnitsDetails } from './condo-unit-search';
import { condosActions } from './condo-search';
import { NULL_VALUE } from '@constants';

export interface ICondoDetailsState {
  condo: ICondoDetails;
  loading: boolean;
  error: string;
  statuses: ICondoCheckinStatuses;
  isServerError: boolean;
  isCheckinStatusLoading: boolean;
  lastCheckinEndDate: string;
}

const initialState: ICondoDetailsState = {
  condo: NULL_VALUE,
  loading: true,
  error: '',
  statuses: NULL_VALUE,
  isServerError: false,
  isCheckinStatusLoading: false,
  lastCheckinEndDate: '',
};

const ZERO = 0;
const one = 1;
const two = 2;
const emptyDatePartDays = 'T00:00:00.000Z';
const emptyDatePart = '-01T00:00:00.000Z';

const condoDetailsSlice = createSlice({
  name: 'condo-details',
  initialState,
  reducers: {
    setLoading: (state: ICondoDetailsState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setLastCheckinEndDate: (state: ICondoDetailsState, { payload }: PayloadAction<string>) => {
      state.lastCheckinEndDate = payload;

      setTimeout(() => {
        events.emit(CustomEvents.LastCheckinEndDate, payload);
      });
    },
    setError: (state: ICondoDetailsState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setCondoDetails: (state: ICondoDetailsState, { payload }: PayloadAction<ICondoDetails | null | undefined>) => {
      state.condo = payload;
    },
    setUpdateUnitsType: (state: ICondoDetailsState, { payload }: PayloadAction<ICondoDetails>) => {
      state.condo = payload;
    },
    setIsCheckinStatusLoading: (state: ICondoDetailsState, { payload }: PayloadAction<boolean>) => {
      state.isCheckinStatusLoading = payload;
      events.emit(CustomEvents.CheckinStatusesLoading, payload);
    },
    setIsServerError: (state: ICondoDetailsState, { payload }: PayloadAction<boolean>) => {
      state.isServerError = payload;
    },
    setStatuses: (state: ICondoDetailsState, { payload }: PayloadAction<ICondoCheckinStatuses>) => {
      if (!payload) {
        state.statuses = payload;
      } else {
        if (!state.statuses) {
          state.statuses = payload;
        } else {
          const { availableDays, unavailableDays } = payload;

          Object.keys(availableDays).forEach((year) => {
            if (!state.statuses.availableDays[+year]) {
              state.statuses.availableDays[+year] = availableDays[+year];
            } else {
              Object.keys(availableDays[+year]).forEach((month) => {
                if (!state.statuses.availableDays[+year][+month]) {
                  state.statuses.availableDays[+year][+month] = availableDays[+year][+month];
                }
              });
            }
          });

          Object.keys(unavailableDays).forEach((year) => {
            if (!state.statuses.unavailableDays[+year]) {
              state.statuses.unavailableDays[+year] = unavailableDays[+year];
            } else {
              Object.keys(unavailableDays[+year]).forEach((month) => {
                if (!state.statuses.unavailableDays[+year][+month]) {
                  state.statuses.unavailableDays[+year][+month] = unavailableDays[+year][+month];
                }
              });
            }
          });
        }
      }

      setTimeout(() => {
        events.emit(CustomEvents.CheckinStatusesUpdated);
      });
    },
    resetState: () => {
      return initialState;
    },
  },
});

export const condoDetailsActions = condoDetailsSlice.actions;

export const condoDetailsReducer = condoDetailsSlice.reducer;

export const updateSavedPropertyDetailsCondos = (propertyId: number, added: boolean): AppThunk => {
  return async (dispatch, getState) => {

    const { condoDetailsStore } = getState();
    const { condo } = condoDetailsStore;
     
    if (condo) {
      const cloned = JSON.parse(JSON.stringify(condo));
      cloned.condoDetails.savedProperty = cloned.condoDetails.id == propertyId ? added : cloned.savedProperty;
      dispatch(condoDetailsActions.setCondoDetails(cloned));
    }
  };
};

export const getCondoDetails = (
  condoId: number,
  sessionKey: ISessionKey,
  isMobile = false,
  condoRequest?: any,
  dealId?: number,
  quote?: string
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(condoDetailsActions.setLoading(true));
    dispatch(condoDetailsActions.setStatuses(NULL_VALUE));
    dispatch(condoDetailsActions.setIsServerError(false));
    
    const { loginStore } = getState();
    const { account } = loginStore;

    const currency = getSelectedCurrency(account);

    try {  
      const res = await axiosInstance.post(
          Urls.CondoDetails,
          { 
            condoId,
            sessionKey,
            searchType: condoRequest?.requestType,
            ...getCondoSearchBodyDetails(condoRequest),
            dealId,
            quote,
            currency,
          },
          { ...getHeaders() },
        );

      dispatch(condoDetailsActions.setLoading(false));

      dispatch(condoDetailsActions.setCondoDetails(res.data));

      // @ts-ignore
      updateUnits(res.data.sessionKey?.expireDate, dispatch, getState);

      if (res.data?.sessionKey?.isInvalid) {
        dispatch(getUnitsDetails(condoId));
      }
      else {
        const values = UrlUtils.getValues();
        const hasSessionKey = getSessionObj(values[CONDO_SESSION_KEY] as ISessionKey, values[CONDO_SESSION_KEY] as ISessionKey);
        if (isUndefined(hasSessionKey)) {
          dispatch(condosActions.setCondoSessionKey(res.data.sessionKey));
        }
      }

      setTimeout(() => {
        const { unitsSearchStore } = getState();

        if (unitsSearchStore.startDate && unitsSearchStore.endDate) {
          const today = new Date();
          const start = getDateCheckInOut(unitsSearchStore.startDate);
          const startMonth = new Date(`${format(start, 'yyyy-MM')}${emptyDatePart}`);
          const end = subDays(addMonths(startMonth, two), one);
          const isSameMonth = today.getMonth() === start.getMonth();
          const isNextMonth = today.getMonth() === start.getMonth() - one;
          const tomorrow = addDays(today, one);
          let startDate;
          let endDate;

          if (isSameMonth || isNextMonth || isMobile) {
            startDate = `${format(tomorrow, 'yyyy-MM-dd')}${emptyDatePartDays}`;
          } else {
            startDate = `${format(start, 'yyyy-MM')}${emptyDatePart}`;
          }

          if (isMobile) {
            endDate = `${format(addMonths(end, one), 'yyyy-MM-dd')}${emptyDatePartDays}`;
          } else {
            endDate = `${format(end, 'yyyy-MM-dd')}${emptyDatePartDays}`;
          }

          dispatch(getCheckinStatus(startDate, endDate));
        } else {
          const currentDate = new Date();
          const currentDateFormat = `${format(currentDate, 'yyyy-MM-dd')}${emptyDatePartDays}`;
          const endDateNextMonthFormat = `${format(
            getEndDateNextMonth(),
            'yyyy-MM-dd',
          )}${emptyDatePartDays}`;

          dispatch(getCheckinStatus(currentDateFormat, endDateNextMonthFormat));
        }
      });
    } catch (error: any) {
      const errorObject = error?.response?.data;
      const errorCode = get(errorObject, 'code', error.toString());

      dispatch(condoDetailsActions.setError(errorCode));
      dispatch(condoDetailsActions.setLoading(false));

      if (
        error?.response?.status === SESSION_EXPIRED_STATUS &&
        error?.response?.data &&
        error?.response?.data[ZERO].errorMessage === SESSION_SEARCH_EXPIRED_MESSAGE
      ) {
        history.replaceState(null, NULL_VALUE, `/${account?.name}${Routes.CondoSearch}${location.search}`);
        location.reload();
      } else if (SERVER_ERROR_STATUSES.includes(error?.response?.status)) {
        dispatch(condoDetailsActions.setIsServerError(true));
      }

      if ([BookingErrorsEnum.RoomsUnavailable, BookingErrorsEnum.SoldOut].includes(errorCode)) {
        dispatch(condoDetailsActions.setError(errorCode));
      }
    }
  };
};

export const getTrustYouReview = (trustYouId: string): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const { loginStore } = getState();
      const { user } = loginStore;
      const res = await axiosInstance.get(`/condos/${trustYouId}/review`, {
        ...getHeaders(user?.accessToken),
      });

      dispatch(trustYouReviewActions.setReviewModule(res.data?.response as ITrustYouReview));
    } catch (error) {
      console.error(error);
    }
  };
};

export const getCheckinStatus = (startDate: string, endDate: string): AppThunk => {
  return async (dispatch, getState) => {
    try {
      dispatch(condoDetailsActions.setLastCheckinEndDate(endDate));
      dispatch(condoDetailsActions.setIsCheckinStatusLoading(true));
      const { condoDetailsStore, unitsSearchStore, loginStore } = getState();
      const { user } = loginStore;

      const { data } = await axiosInstance.post(
        Urls.CheckinStatus,
        {
          condoId: condoDetailsStore.condo.condoDetails.id,
          dateRange: {
            from: startDate,
            to: endDate,
          },
          adultsCount: unitsSearchStore.guests?.adultsCount,
          childrenCount: unitsSearchStore.guests?.kidsCount,
          bedroomsCount: unitsSearchStore.guests?.bedroomsCount,
        },
        {
          ...getHeaders(user?.accessToken),
        },
      );

      dispatch(condoDetailsActions.setStatuses(data));
      dispatch(condoDetailsActions.setIsCheckinStatusLoading(false));
    } catch (error) {
      dispatch(condoDetailsActions.setIsCheckinStatusLoading(false));
      console.error(error);
    }
  };
};
