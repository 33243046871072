import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { get } from 'lodash';

import {
  getHotelPopularDestinations,
  IPopularDestinationsState,
} from '@store/slices';
import {
  datesActions,
  ILoginState,
  locationActions,
} from '@share/store/slices';
import { RootState } from '@share/utils';
import { ILocation } from '@share/common-types';

import './style.scss';

interface IMapStateToProps {
  popularDestinationsStore: IPopularDestinationsState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  getHotelPopularDestinations: () => void;
  setSelectLocationLabel: (value: string) => void;
  setSelectLocation: (value: ILocation) => void;
  setIsFocused: (value: boolean) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps {}

const ONE = 1;
const TWO = 2;

function PopularDestinationsComponent(props: IProps) {
  
  const [filteredDestination, setFilteredDestination] = useState<any[]>([]);

  const { popularDestinationsStore, loginStore } = props;
  const { popularDestinations, loading } = popularDestinationsStore;
  const { account, user } = loginStore;

  const onClickPopularDestination = (location: ILocation) => {
    const { setIsFocused, setSelectLocationLabel, setSelectLocation } = props;

    setSelectLocationLabel(location.name);
    setSelectLocation(location);
    setIsFocused(true);
  };

  const handleFailedDestination = (destinationName: string) => {
    setFilteredDestination([...filteredDestination, destinationName]);
  }

  useEffect( () => {
    props.getHotelPopularDestinations();
  }, [user]);


  const destinationsList = get(popularDestinations, 'popularDestinations', []);

  //TODO to remove
  //-------------
  let destinations = destinationsList.slice(0, 5);
  if (account && account.name === 'rsi') {
    destinations = destinationsList.slice(destinationsList.length - 5, destinationsList.length);
  }else if (account && account.name === 'test1') {
    destinations = destinationsList.slice(0, 5);
  } else {
    destinations = destinationsList.slice(2, 5);
  }
  //-------------

  destinations = destinations.filter(d => !filteredDestination.includes(d.name));

  return destinations?.length > 0 && !loading ? (
    <div className="popular-destinations">
      <p className="popular-destinations__title secondary-font">
        <FormattedMessage id="destination.cities" />
      </p>
      <p className="popular-destinations__message secondary-font">
        <FormattedMessage id="destination.cities.message" />
      </p>
      <div className="popular-destinations__wrapper">
        {destinations.map((destination, index) => (
          <div
            key={index + destination.count}
            className={`popular-destinations__destination-wrapper
            ${
              index === destinations.length - ONE ||
              index === destinations.length - TWO
                ? 'small-wrapper'
                : ''
            }`}
            onClick={() => onClickPopularDestination(destination?.location as any)}
          >
            <div className="popular-destinations__destination-info">
              <p className="popular-destinations__destination-name">{destination.name}</p>
              <p className="popular-destinations__destination-count">
                {destination.count} <FormattedMessage id="hotels.available" />
              </p>
            </div>
            <div className="popular-destinations__image-wrapper">
              <img src={destination.imageUrl} alt={destination.name} onError={() => handleFailedDestination(destination.name)} />
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : null;
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    popularDestinationsStore: state.popularDestinationsStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getHotelPopularDestinations,
  setSelectLocationLabel: locationActions.setSelectLocationLabel,
  setSelectLocation: locationActions.setSelectLocation,
  setIsFocused: datesActions.setIsFocused,
};

export const PopularDestinations = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PopularDestinationsComponent);
