
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { CondoRequestTypeEnum, IClientCash, ICondo } from '@share/common-types';
import { IBounds, IGeoLocation } from '@share/common-types';
import { Routes } from '@share/constants';
import { ICondosState, ILoginState, IMenuState, condosActions } from '@share/store/slices';
import { RootState, Responsive, getSelectedCurrency } from '@share/utils';
import { Map, WalletPriceType, getWalletPrices } from '@utils';

import { CondoHotelCard } from '../condo-hotel-card';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
  menuStore: IMenuState;
  condosStore: ICondosState;
}

interface IMapDispatchToProps {
  setSelectedCondoSearchClientCash: (clientCash: IClientCash) => void;
}

interface IProps extends IMapDispatchToProps, RouteComponentProps, IMapStateToProps {
  bounds?: IBounds;
  locations: {
    location: IGeoLocation;
    id: number;
    isExact?: boolean;
    isDisabled?: boolean;
  }[];
  icon?: string;
  mapOptions?: google.maps.MapOptions;
  condos?: ICondo[];
  nights?: number;
  selectedCondo?: ICondo;
  disabled?: boolean;
  lastRequestType: CondoRequestTypeEnum;
  hasCompareSelected: boolean;

  setBounds: (bounds: IBounds) => void;
  selectCondo: (condo: ICondo) => void;
}

const padding = 20;
const debounceResizeTime = 400;
const debounceTime = 1000;

class CondoMapWrapperComponent extends React.Component<IProps> {
  mapRef: React.RefObject<HTMLDivElement> = React.createRef();
  map: google.maps.Map;

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (!isEqual(prevProps.bounds, this.props.bounds)) {
      this.fitMapToBounds();
    }

    if (!isEqual(prevProps.condos, this.props.condos)) {
      Map.removeAllMarkers();
      this.setMarkers();
    }
  }

  fitMapToBounds = () => {
    const bounds = this.map.getBounds();
    let mapBounds = null;

    if (bounds) {
      const sw = bounds.getSouthWest();
      const ne = bounds.getNorthEast();
      mapBounds = {
        northEast: {
          longitude: ne.lng(),
          latitude: ne.lat(),
        },
        southWest: {
          longitude: sw.lng(),
          latitude: sw.lat(),
        },
      };
    }

    if (!isEqual(this.props.bounds, mapBounds)) {
      const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds(
        Map.getGoogleLocation(this.props.bounds?.southWest),
        Map.getGoogleLocation(this.props.bounds?.northEast),
      );

      this.map.fitBounds(bounds, padding);
    }
  };

  selectCondo = (condoId: number): void => {
    const { condos = [] } = this.props;
    const selectedCondo = condos.find((condo: ICondo) => condo.condoId === condoId) || null;

    this.props.selectCondo(selectedCondo);
  };

  setMarkers = (): void => {
    const { locations, icon, selectedCondo, loginStore } = this.props;
    const { account } = loginStore;
    const currency = getSelectedCurrency(account);

    Map.addMarkers(this.map, locations.map(l => {
      const newLocation = { ...l };
      if (l.location?.latitude || l.location?.longitude) {
        const latitude = l.location?.latitude ? parseFloat(l.location?.latitude?.toFixed(3)) : 0;
        const longitude = l.location?.longitude ? parseFloat(l.location?.longitude?.toFixed(3)) : 0;
        newLocation.location = {
          latitude,
          longitude
        }
      }

      return newLocation;
    }), icon ? icon : '', currency, selectedCondo?.condoId, true, this.selectCondo);
  };

  componentDidMount(): void {
    const { mapOptions = {} } = this.props;

    if (this.mapRef && this.mapRef.current) {
      this.map = Map.initializeMap(this.mapRef.current, undefined, mapOptions);

      this.fitMapToBounds();
      this.setMarkers();

      this.map.addListener('click', () => {
        this.props.selectCondo(null);
        Map.refreshMarkers(null);
      });
    }

    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = debounce(() => {
    const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds(
      Map.getGoogleLocation(this.props.bounds?.southWest),
      Map.getGoogleLocation(this.props.bounds?.northEast),
    );

    this.map.fitBounds(bounds, padding);
  }, debounceResizeTime);

  onCenterChange = debounce(() => {
    const bounds = this.map.getBounds();
    const sw = bounds?.getSouthWest();
    const ne = bounds?.getNorthEast();

    this.props.setBounds({
      northEast: {
        longitude: ne?.lng(),
        latitude: ne?.lat(),
      },
      southWest: {
        longitude: sw?.lng(),
        latitude: sw?.lat(),
      },
    });
  }, debounceTime);

  onCondoPage = (id: number) => {
    const { loginStore, condosStore, selectedCondo } = this.props;
    const { account, userWalletData } = loginStore;
    const { selectedCondoSearchClientCash } = condosStore;

    const hotelPrices = getWalletPrices(account, userWalletData, selectedCondoSearchClientCash, selectedCondo?.price, selectedCondo?.maxWalletClientCash, WalletPriceType.Search);
    const clientCashApplied = hotelPrices?.clientCashApplied;

    this.props.setSelectedCondoSearchClientCash({ ...selectedCondoSearchClientCash, selectedPropertyClientCash: clientCashApplied, selectedPropertyReviewClientCash: null });

    this.props.history.push(`/${account?.name}${Routes.Condo}/${id}/${location.search}`);
  };

  render(): React.ReactNode {
    const { disabled = false, lastRequestType, selectedCondo, hasCompareSelected, menuStore } = this.props;

    const hasFooterMenu = menuStore?.items?.footer?.length > 0;

    return (
      <div className="condo-map-wrapper">
        {disabled && <div className="condo-map-wrapper__disabled-overlay" />}
        <div className="condo-map-wrapper__map">
          <div className="condo-map-wrapper__map-instance" ref={this.mapRef} />
        </div>
        {selectedCondo && (
          <div
            className={`condo-map-wrapper__hotel-detail ${(hasCompareSelected && !hasFooterMenu) ? 'compare-open' : ''}`}
            onClick={() => this.onCondoPage(selectedCondo.condoId)}
          >
            <Responsive>
              <CondoHotelCard
                condo={selectedCondo}
                disabled={disabled}
                lastRequestType={lastRequestType}
                isMap={true}
              />
            </Responsive>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    condosStore: state.condosStore,
    loginStore: state.loginStore,
    menuStore: state.navigationMenuStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setSelectedCondoSearchClientCash: condosActions.setSelectedCondoSearchClientCash
};

export const CondoMapWrapper = connect(mapStateToProps, mapDispatchToProps)(withRouter(CondoMapWrapperComponent));
