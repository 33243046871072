import { connect, useDispatch } from 'react-redux';
import { Button } from 'antd';
import { injectIntl, useIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  IWeeksDatesState,
  IWeekState,
  IHotelsState,
  IWeeksLocationsState,
} from '@share/store/slices';
import { RootState } from '@share/utils';
import { ICountriesCode } from '@share/common-types';
import { ILoginState } from '@share/store/slices';

import './style.scss';
import InsuranceCountryFilter from './insurance-country-filter/component';
import InsuranceDatesFilter from './insurance-dates-filter/component';
import InsuranceNumberFilter from './insurance-number-filter/component';
import PinMapImg from '@assets/images/insurance/pin-map.svg';
import GeoImg from '@assets/images/insurance/geo-alt.svg';
import CalendarWeekImg from '@assets/images/insurance/calendar-week.svg';
import PeopleImg from '@assets/images/insurance/people.svg';
import CurrencyImg from '@assets/images/insurance/currency-dollar.svg';
import FileRuledImg from '@assets/images/insurance/file-ruled.svg';
import { useEffect, useState } from 'react';
import InsuranceStateFilter from './insurance-state-filter/component';
import { isEmpty } from 'lodash';
import {
  insuranceQuoteActions,
  IQuoteCheckoutResponse,
  PostInsuranceQuote,
} from '../../../../store/slices/insurance-quote';
import { InsuranceQuoteModal } from '../insurance-quote-modal';
import { InsuranceFilter } from '../insurance-results-page';
import { Moment } from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface IMapStateToProps {
  hotelsStore: IHotelsState;

  weeksLocationsStore: IWeeksLocationsState;
  weeksDatesStore: IWeeksDatesState;
  weeksStore: IWeekState;

  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  postInsuranceQuote: (
    total: number | null,
    dates: [Moment | null, Moment | null] | null,
    destinationIsoCountryCode: string | null,
    isoStateOrProvince: string | null,
    numberOfTravelers: number | null,
  ) => void;
}

interface IProps
  extends IMapStateToProps,
    IMapDispatchToProps,
    RouteComponentProps,
    WrappedComponentProps {
  // country: IRciCountriesCodes[];
  setInsuranceFilter: React.Dispatch<React.SetStateAction<any>>;
  insuranceFilter: InsuranceFilter;
  states: any;
  insuranceQuoteStore: IQuoteCheckoutResponse;
  condosCountriesCode: ICountriesCode;
}

const InsuranceSearchComponent = (props: IProps) => {
  const [showQuoteModal, setShowQuoteModal] = useState<boolean>(false);
  const {
    insuranceFilter,
    setInsuranceFilter,
    condosCountriesCode,
    states,
    postInsuranceQuote,
    insuranceQuoteStore,
  } = props;

  const intl = useIntl();

  const dispatch = useDispatch();

  const hasMissingData = Object.values(insuranceFilter).some(
    (value) => value === null || value === '' || value === undefined,
  );

  const notifyError = (message: string) =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    postInsuranceQuote(
      insuranceFilter.tripCost,
      insuranceFilter.travelDates,
      insuranceFilter.destination,
      insuranceFilter.state,
      insuranceFilter.travelers,
    );
  };

  useEffect(() => {
    // Si hay un error y no es el mismo que el último mostrado, muestra el toast y limpia el error
    if (insuranceQuoteStore.quote.error) {
      notifyError(insuranceQuoteStore.quote.error);
      dispatch(insuranceQuoteActions.clearQuoteError());
      setShowQuoteModal(false);
    }
    // Si no hay error y no faltan datos, muestra el modal
    else if (
      !insuranceQuoteStore.quote.error &&
      !hasMissingData &&
      !isEmpty(insuranceQuoteStore.quote.quoteId)
    ) {
      setShowQuoteModal(true); // Muestra el modal si la solicitud es exitosa
    }
  }, [insuranceQuoteStore, hasMissingData, dispatch]);

  return (
    <form className="insurance-filters" onSubmit={onSubmit}>
      <InsuranceCountryFilter
        placeholder={intl.formatMessage({ id: 'insurance.destination.placeholder' })}
        label={intl.formatMessage({ id: 'insurance.destination.label' })}
        icon={PinMapImg}
        value={condosCountriesCode}
        setInsuranceFilter={setInsuranceFilter}
      />
      <InsuranceDatesFilter
        icon={CalendarWeekImg}
        label={intl.formatMessage({ id: 'insurance.travel.label' })}
        setInsuranceFilter={setInsuranceFilter}
      />
      <InsuranceNumberFilter
        icon={PeopleImg}
        placeholder={intl.formatMessage({ id: 'insurance.traveler.placeholder' })}
        label={intl.formatMessage({ id: 'insurance.traveler.label' })}
        setInsuranceFilter={setInsuranceFilter}
        type={'travelers'}
      />
      <InsuranceStateFilter
        placeholder={intl.formatMessage({ id: 'insurance.residence.placeholder' })}
        label={intl.formatMessage({ id: 'insurance.residence.label' })}
        icon={GeoImg}
        value={states}
        setInsuranceFilter={setInsuranceFilter}
        insuranceFilter={insuranceFilter}
      />
      <InsuranceNumberFilter
        icon={CurrencyImg}
        placeholder=""
        label={intl.formatMessage({ id: 'insurance.trip.label' })}
        setInsuranceFilter={setInsuranceFilter}
        type={'trip'}
      />
      <div className="insurance-filters-btn">
        <Button block htmlType="submit" disabled={hasMissingData}>
          <img src={FileRuledImg} className="search-container-icons-file me-1 mb-1" />
          {intl.formatMessage({ id: 'insurance.button' })}
        </Button>
      </div>

      <InsuranceQuoteModal
        visible={showQuoteModal}
        loading={insuranceQuoteStore?.quote.loading}
        error={''}
        onCancel={() => {
          dispatch(insuranceQuoteActions.clearQuoteCheckout());
          setShowQuoteModal(false);
        }}
        insuranceQuoteStore={insuranceQuoteStore}
      />
    </form>
  );
};

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    hotelsStore: state.hotelsStore,

    weeksLocationsStore: state.weeksLocationsStore,
    weeksDatesStore: state.weeksDatesStore,
    weeksStore: state.weeksStore,

    loginStore: state.loginStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  postInsuranceQuote: (
    total: number | null,
    dates: [Moment | null, Moment | null] | null,
    destinationIsoCountryCode: string | null,
    isoStateOrProvince: string | null,
    numberOfTravelers: number | null,
  ) =>
    PostInsuranceQuote(
      total,
      dates,
      destinationIsoCountryCode,
      isoStateOrProvince,
      numberOfTravelers,
    ),
};

export const InsuranceSearch = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withRouter(InsuranceSearchComponent)));
function dispatch(arg0: any) {
  throw new Error('Function not implemented.');
}
