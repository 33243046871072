import React from 'react';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { isUndefined } from 'lodash';

import { LocationMapSvg } from '@assets';
import { IHotelDetails } from '@common-types';
import { HotelStars, SaveProperty, SavePropertyTypes } from '@components';
import { getFullAddress } from '@utils';
import { ITrustYouReview } from '@share/common-types';
import { getRankingClass, renderNumber } from '@share/utils';
import { BlueButton } from '@share/components';

import './style.scss';

interface IProps {
  hotel: IHotelDetails;
  reviewModule: ITrustYouReview;
  roomsRef: React.RefObject<HTMLDivElement>;

  onClickScrollToRooms?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onClickScrollToReviews?: (e: React.MouseEvent<HTMLDivElement>) => void;
  showBigMap: () => void;
}

const zero = 0;

class HotelInfoComponent extends React.Component<IProps> {

  handleScrollToRooms = () => {
    const { roomsRef } = this.props;
    roomsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'end' });
  }

  render(): React.ReactNode {
    const { showBigMap, hotel } = this.props;
    const { id, title, thumb, images, address, starRating, city, countryCode, state, zipCode, descriptions, additionalDescriptions, reviews, reviewsCount, savedProperty } = hotel;
    const fullAddress = getFullAddress(address, city, state, zipCode, countryCode);
    const addressStr = fullAddress.join(', ');
    const hotelDescription: string[] = descriptions;

    return (
      <div className="hotel-info">
        <div className="hotel-info__item">
          <div className="hotel-info__item-content">
            <h4 className="hotel-info__title">{title} </h4>

            <div className="hotel-info__address-wrapper">
              <p className="hotel-info__address">
                {fullAddress.length !== zero ? addressStr : address}
                <a className="hotel-info__link-text" onClick={showBigMap}>
                  <LocationMapSvg />
                  <span className="hotel-card-wrapper__link-text">
                    <FormattedMessage id="view.map" />
                  </span>
                </a>
              </p>
            </div>

            <div className="hotel-info__stars">
              <HotelStars stars={starRating} />
            </div>
          </div>

          {(!hotelDescription.length &&
            !additionalDescriptions.length &&
            !isUndefined(reviewsCount) &&
            reviewsCount > 0) && (
            <div className="hotel-info__ranking hotel-details-wrapper__trust-you-info-wrapper padding-info">
              <div className="hotel-details-wrapper__trust-you-info">
                <p className="hotel-details-wrapper__trust-you-text">
                  <span className={`hotel-details-wrapper__bold ${getRankingClass(reviews)}`}>
                    {reviews.toFixed(1)}/5
                  </span>
                  <label>({renderNumber(reviewsCount)}{' '}<FormattedMessage id="reviews" />)</label>
                </p>
              </div>
            </div>)}

          <div className="hotel-info__item-button">
            <SaveProperty
              isSaved={savedProperty}
              id={id}
              title={title}
              thumb={thumb}
              images={images}
              starRating={starRating}
              reviewsCount={reviewsCount}
              fullAddress={getFullAddress(address, city, state, zipCode, countryCode).join(', ')}
              type={SavePropertyTypes.Hotel}
            />

            <BlueButton onClick={this.handleScrollToRooms}>
              <FormattedMessage id="reserve" />
            </BlueButton>
          </div>
        </div>
      </div>
    );
  }
}

export const HotelInfo = connect()(HotelInfoComponent);

