import React from 'react';
import parse from 'html-react-parser';
import ReactGA from 'react-ga4';

import { connect } from 'react-redux';
import { FormInstance } from 'antd/lib/form';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { v4 } from 'uuid';
import { Checkbox, Alert, Spin } from 'antd';
import { CheckboxChangeEventTarget } from 'antd/lib/checkbox/Checkbox';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { get, isEmpty } from 'lodash';

import { GetWalletNumber, RootState, getPreferenceFromAccount, getToken } from '@share/utils';
import {
  IReviewBookState,
  getBookPackageWithCard,
  setGuests,
  ICouponState,
  setPriceChangedError,
  setBooking,
  setBookingComplete,
  InsuranceSelection,
  IInsuranceState,
} from '@store/slices';
import {
  getNavigationMenu,
  IHotelsState,
  ILoginState,
} from '@share/store/slices';
import { BlueButton, Loading, LoadingSise } from '@share/components';
import { IBookingComplete, IBookPackageWithCard, IFormErrors, IGuestsBooking, ScrollBehaviorEnum } from '@common-types';
import { IAccount, ISessionKey, LOGOUT_FIELD_KEY } from '@share/common-types';
import { UrlUtils, getSessionObj, renderNumber, getHotelRoutePath, getHotelIdFromPath, getSelectedCurrency, LoginType } from '@share/utils';
import { DEFAULT_GUEST_DATA, NON_REFUNDABLE_TYPE } from '@constants';
import { ReservationProgress, BookingConfirmed, BookingFailed, ReviewBookPriceChangeModal, AvailabilityStatusModal, ExpiredSessionModal, ModalMessage, ThreeDSModal, ModalConfirmation, Insurance, InsuranceRequiredMessage } from '@components';
import { SESSION_KEY_LABEL_ROOMS, SESSION_KEY_LABEL, C_COMPLETE_BOOKING, C_PP, C_TERMS, USER_BASE_URL, QUOTE_LABEL, Routes, SESSION_KEY_TOKEN_NAME_PARAM, LIFE_STYLE_NAME_PARAM } from '@share/constants';
import { HOTEL_CONFIRMED_INFO, HOTEL_BOOKING_COMPLETE, HOTEL_PACKAGE_ID } from '@constants';
import { IMarginatorState, setLoginRedirect } from '@share/store/slices';

import { GuestInfo } from '../guest-info-wrapper/guest-info';
import { PaymentMethod } from '../payment-method';
import { ConfirmationEmail } from '../confirmation-email';
import { CancellationPolicies } from '../cancellation-policies';

import { GetPreferenceValue, MERCHANT_CHARGE_DESCRIPTION_FIELD_KEY } from '@utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

interface IMapStateToProps {
  reviewBookStore: IReviewBookState;
  redeemCodeStore: ICouponState;
  marginatorStore: IMarginatorState;
  hotelsStore: IHotelsState;
  loginStore: ILoginState;
  insuranceStore: IInsuranceState;
}

interface IMapDispatchToProps {
  getBookPackageWithCard: (bookWithCard: IBookPackageWithCard) => void;
  setGuests: (guests: IGuestsBooking[]) => void;
  setPriceChangedError: (priceChanged: boolean) => void;
  getNavigationMenu: () => void;
  setBooking: (data: IBookingComplete) => void;
  setBookingComplete: (item: boolean) => void;
  setLoginRedirect: (redirect: string) => void;
}

interface IProps
  extends IMapStateToProps,
    IMapDispatchToProps,
    WrappedComponentProps,
    RouteComponentProps {
  isVacationRentals: boolean;
}

interface IState {
  isAccept: boolean;
  isAcceptNoChangesAllowed: boolean;
  isAcceptBookingRemarks: boolean;
  isAcceptCancellationPolicy: boolean;
  isCheckMainGuest: boolean;
  isClientCash: boolean;
  isClientCashNotRefundable: boolean;
  isAcceptIsPackage: boolean;
  isAcceptIsInsurance: boolean;
  isSessionExpired: boolean;
  showNotRefundableMessage: boolean;
  showNotRefundableConfirmModal: boolean;
}

const ZERO = 0;
const TEN_SEC = 10000;

class ReviewBookComponent extends React.Component<IProps, IState> {
  state: IState = {
    isAccept: false,
    isAcceptBookingRemarks: false,
    isAcceptNoChangesAllowed: false,
    isAcceptCancellationPolicy: false,
    isCheckMainGuest: true,
    isClientCash: false,
    isClientCashNotRefundable: false,
    isAcceptIsPackage: false,
    isAcceptIsInsurance: false,
    isSessionExpired: false,
    showNotRefundableMessage: false,
    showNotRefundableConfirmModal: false,
  };

  formPaymentRef = React.createRef<FormInstance>();
  formGuestRef = React.createRef<FormInstance>();
  formEmailRef = React.createRef<FormInstance>();

  insuranceContainerRef = React.createRef<HTMLDivElement>();

  onChangeAccept = (e: { target: CheckboxChangeEventTarget }): void => {
    this.setState({ isAccept: e.target.checked });
  };

  onChangeAcceptBookingRemarks = (e: { target: CheckboxChangeEventTarget }): void => {
    this.setState({ isAcceptBookingRemarks: e.target.checked });
  };

  onChangeAcceptKnowBeforeYouGo = (e: { target: CheckboxChangeEventTarget }): void => {
    this.setState({ isAcceptNoChangesAllowed: e.target.checked });
  };

  onChangeAcceptCancellationPolicy = (e: { target: CheckboxChangeEventTarget }): void => {
    this.setState({ isAcceptCancellationPolicy: e.target.checked });
  };

  onChangeAcceptIsPackage = (e: { target: CheckboxChangeEventTarget }): void => {
    this.setState({ isAcceptIsPackage: e.target.checked });
  };

  onChangeAcceptIsInsurance = (e: { target: CheckboxChangeEventTarget }): void => {
    this.setState({ isAcceptIsInsurance: e.target.checked });
  };

  onChangeAcceptClientCash = (e: { target: CheckboxChangeEventTarget }): void => {
    this.setState({ isClientCash: e.target.checked });
  };

  onChangeAcceptClientCashNotRefundable = (e: { target: CheckboxChangeEventTarget }): void => {
    this.setState({ isClientCashNotRefundable: e.target.checked, showNotRefundableMessage: e.target.checked });
  };
  
  getGuests = () => {
    return this.props.reviewBookStore.bookingSummary.bookingCard.package.rooms
      .map((room, index) => {
        const guests = [...new Array(room.adultsCount)].map((_, indexGuest) => {
          return indexGuest === ZERO
            ? {
                ...DEFAULT_GUEST_DATA,
                id: v4(),
                isMainGuest: index === ZERO && indexGuest === ZERO,
                allocation: room.id,
                type: 'adult',
              }
            : {
                ...DEFAULT_GUEST_DATA,
                id: v4(),
                allocation: room.id,
                type: 'adult',
              };
        });

        const kids = room.kidsAges.map((kidAge) => {
          return {
            ...DEFAULT_GUEST_DATA,
            id: v4(),
            allocation: room.id,
            type: 'kid',
            age: kidAge,
          };
        });

        return [...guests, ...kids];
      })
      .flat();
  };

  handleOnCompleteBooking = () => {
    const { reviewBookStore, loginStore } = this.props;
    const { account, userWallet } = loginStore;
    const { bookingSummary, selectedHotelReviewClientCash, bookingComplete, booking } = reviewBookStore;
    const { refundability } = bookingSummary.bookingCard?.package;

    const hasClientCash = account?.hasClientCash;
    const walletIsExternal = account?.walletIsExternal;
    const isLogged = !!userWallet;

    const usedClientCash = (bookingComplete && booking) ? get(booking?.hotelSegments, '[0].clientCash', 0) : 0;

    const refundabilityFinal = (!walletIsExternal && hasClientCash && isLogged && (selectedHotelReviewClientCash > 0 || usedClientCash > 0)) ? NON_REFUNDABLE_TYPE : refundability;

    if (NON_REFUNDABLE_TYPE === refundabilityFinal) {
      this.setState({ showNotRefundableConfirmModal: true });
    } else {
      this.onCompleteBooking()
    }
  }

  onCompleteBooking = () => {
    const { reviewBookStore, hotelsStore, loginStore } = this.props;
    const { isAcceptBookingRemarks, isAccept, isAcceptCancellationPolicy, isAcceptNoChangesAllowed } = this.state;
    const { bookingSummary, card, guests, loadingBooking, isSoldOutError, selectedHotelReviewClientCash } = reviewBookStore;
    const { referenceNumber, agencyNumber } = hotelsStore;
    const { account, userWallet, userWalletData } = loginStore;
    const { balance } = bookingSummary.bookingCard;

    const convertionRate = userWalletData?.convertionRate ? userWalletData?.convertionRate : 1;
    const finalBalanceIntermediate = balance - (selectedHotelReviewClientCash ? selectedHotelReviewClientCash * convertionRate : 0);
    const finalBalance = Math.floor(finalBalanceIntermediate * 100) / 100;

    const token = getToken(account as IAccount);
    const isPrivateWithToken = account?.type === LoginType.PrivateWithToken;
    
    if (isPrivateWithToken && !token) {
      this.setState({ isSessionExpired: true }); 
      return;
    }

    if (loadingBooking || isSoldOutError || !isAccept || !isAcceptBookingRemarks || !isAcceptCancellationPolicy || !isAcceptNoChangesAllowed) {
      return;
    }

    const { coupon } = this.props.redeemCodeStore;
    const values = UrlUtils.getValues();
    const sessionKey = getSessionObj(
      values[SESSION_KEY_LABEL_ROOMS] as ISessionKey,
      values[SESSION_KEY_LABEL] as ISessionKey,
    );
    const getLeadPax = guests.find((guest) => guest.isMainGuest);

    if (getLeadPax === undefined) {
      this.setState({ isCheckMainGuest: false });
      window.scrollTo(ZERO, ZERO);
    }
    let quote;
    if (values[QUOTE_LABEL]) {
      quote = (values[QUOTE_LABEL] as string);
    }

    const data: any = {
      sessionKey,
      bookingPrice: bookingSummary?.bookingCard.bookingPrice.payNowPrice,
      hotelId: bookingSummary?.bookingCard.package.hotelId,
      leadPaxId: getLeadPax?.id,
      leadPaxRoomId: getLeadPax?.allocation,
      packageId: bookingSummary?.bookingCard.package.packageId,
      couponCode: coupon,
      marginator:
      {
        percentage: this.props.marginatorStore.percentage,
        commission: this.props.marginatorStore.commission,
        impersonator: {
          memberId: this.props.marginatorStore.impersonator.memberId
        }
      },
      guests: guests.map((guest) => {
        const type: string = guest?.type;

        return {
          ...guest,
          namePrefix: !isEmpty(guest?.namePrefix)? guest?.namePrefix : 'Mr',
          givenName: !isEmpty(guest?.givenName)? guest?.givenName : 'TBV',
          surname: !isEmpty(guest?.surname)? guest?.surname : 'TBV',        
          type,
        };
      }),
      referenceNumber,
      agencyNumber,
      currency: getSelectedCurrency(loginStore.account as IAccount),
      quote
    };

    const privateTokenFromUrl = account?.privateTokenFromUrl;
    if (isPrivateWithToken && privateTokenFromUrl) {
      const ssoSessionKey = UrlUtils.getSearchParam(SESSION_KEY_TOKEN_NAME_PARAM);
      data.ssoSessionKey = ssoSessionKey;
      data.lifeType = UrlUtils.getSearchParam(LIFE_STYLE_NAME_PARAM);
    }

    const hasClientCash = account?.hasClientCash;
    const isLogged = !!userWallet;

    const paymentMethod = { paymentMethodId: card?.id, CVC: card?.cvv };
    const creditCard = { ...card, phone: getLeadPax?.phone };

    if (hasClientCash && isLogged) {
      data.clientCashAmount = selectedHotelReviewClientCash ? selectedHotelReviewClientCash : 0;

      if (finalBalance > 0) {
        if (!isEmpty(card?.id)) {
          data.paymentMethod = paymentMethod;
        } else {
          data.card = creditCard;
        }
      }
    } else {
      if (!isEmpty(card?.id)) {
        data.paymentMethod = paymentMethod;
      } else {
        data.card = creditCard;
      }
    }

    this.setFieldsGuestsValue(guests);
    this.formEmailRef.current?.setFieldsValue({ email: this.mainGuestData('email') });
    this.formGuestRef.current?.setFieldsValue({
      mainGuestGivenName: this.mainGuestData('givenName'),
    });
    this.formGuestRef.current?.setFieldsValue({
      mainGuestPhone: this.mainGuestData('phone'),
    });
    this.formGuestRef.current?.setFieldsValue({ mainGuestSurname: this.mainGuestData('surname') });
    this.formPaymentRef.current?.setFieldsValue({ address: card.addressLine });
    this.formPaymentRef.current?.setFieldsValue({ city: card.city });
    this.formPaymentRef.current?.setFieldsValue({ state: card.state });
    this.formPaymentRef.current?.setFieldsValue({ 'postal-code': card.zipCode });

    const formPaymentRef = this.formPaymentRef.current?.validateFields();
    const formGuestRef = this.formGuestRef.current?.validateFields();
    const formEmailRef = this.formEmailRef.current?.validateFields();

    ReactGA.event({
      category: account?.name as string,
      action: `${C_COMPLETE_BOOKING}_${account?.name.toUpperCase()}`,
      label: `User clicked complete booking on book`,
      nonInteraction: false,
    });

    Promise.all([formPaymentRef, formGuestRef, formEmailRef])
      .then(() => {
        this.props.getBookPackageWithCard(data);
      })
      .catch((error: IFormErrors) => {
        const { errorFields } = error;
        const { name } = errorFields[ZERO];

        const { scrollToField: scrollToPaymentField } = (this.formPaymentRef.current as any);
        const { scrollToField: scrollToGuestField } = (this.formGuestRef.current as any);
        const { scrollToField: scrollToEmailField } = (this.formEmailRef.current as any);

        (() => {
          scrollToEmailField(name[ZERO], { behavior: ScrollBehaviorEnum.Smooth });
          scrollToPaymentField(name[ZERO], { behavior: ScrollBehaviorEnum.Smooth });
          scrollToGuestField(name[ZERO], { behavior: ScrollBehaviorEnum.Smooth });
        })();
      });
  };

  setFieldsGuestsValue = (guests: IGuestsBooking[]): void[] => {
    return guests.map((guest, index) => {
      if (guest.isMainGuest === true) {
        return;
      }

      this.formGuestRef.current?.setFieldsValue({ [`guests[${index}].givenName`]: guest.givenName });
      this.formGuestRef.current?.setFieldsValue({ [`guests[${index}].surname`]: guest.surname });
    });
  };

  handleRedirect = () => {
    const { loginStore } = this.props;
    const { account } = loginStore;

    const logoutUrlStorage = localStorage.getItem(USER_BASE_URL);
    const logoutPref = getPreferenceFromAccount(account as IAccount, LOGOUT_FIELD_KEY);
    const logoutUrl = !isEmpty(logoutUrlStorage) ? logoutUrlStorage : logoutPref;
    if (logoutUrl && !isEmpty(logoutUrl)) {
      window.location.replace(logoutUrl);
    }
  }

  mainGuestData = (item: string): string => {
    const guest = this.props.reviewBookStore.guests.find((item) => item.isMainGuest === true);

    return guest ? (guest[item] as string) : '';
  };

  titleBookingFailed = (): string => {
    return `${this.props.intl.formatMessage({ id: 'your.booking.in' })}
      ${this.props.reviewBookStore.bookingSummary.bookingCard.name}
      ${this.props.intl.formatMessage({ id: 'did.not.go.through' })}`;
  };

  redirectToHotelPage = (): void => {
    const hotelId = getHotelIdFromPath(this.props.history);
    const hotelPath = getHotelRoutePath(hotelId, this.props.history);
    this.props.history.push(hotelPath);
    this.props.setPriceChangedError(false);
  };

  getErrorMessages = () =>
    this.props.reviewBookStore.errorsBookingMessage.map((message, index) => (
      <p key={index}>{message.errorMessage}</p>
    ));

  componentDidMount() {
    this.props.setGuests(this.getGuests());

    if (localStorage.getItem(HOTEL_CONFIRMED_INFO) !== null) {
      const bookingInfo = localStorage.getItem(HOTEL_CONFIRMED_INFO);
      const bookingCompleteInfo = localStorage.getItem(HOTEL_BOOKING_COMPLETE);

      this.props.setBooking(bookingInfo ? JSON.parse(bookingInfo) : bookingInfo);
      this.props.setBookingComplete(bookingCompleteInfo ? JSON.parse(bookingCompleteInfo) : bookingCompleteInfo);
    }
  }

  
  componentDidUpdate(prevProps: Readonly<IProps>) {
    const { bookingComplete, errorBooking, bookingSummary } = this.props.reviewBookStore;
    const { bookingCard } = bookingSummary;

    if (
      (bookingComplete && bookingComplete !== prevProps.reviewBookStore.bookingComplete) ||
      (errorBooking && errorBooking !== prevProps.reviewBookStore.errorBooking)
    ) {
      localStorage.setItem(HOTEL_PACKAGE_ID, JSON.stringify(bookingCard.package.packageId));
      setTimeout(() => {
        this.props.getNavigationMenu();
      }, TEN_SEC);
    }
  }

  hangleGoToLogin = () => {
    const { loginStore, history } = this.props;
    const { account } = loginStore;

    const { pathname, search } = history.location;
    this.props.setLoginRedirect(`${pathname}${isEmpty(search) ? '' : search}`);

    this.props.history.push(`/${account?.name}${Routes.Login}`);
  }

  hangleGoToSignUp = () => {
    const { loginStore, history } = this.props;
    const { account } = loginStore;

    const { pathname, search } = history.location;
    this.props.setLoginRedirect(`${pathname}${isEmpty(search) ? '' : search}`);

    this.props.history.push(`/${account?.name}${Routes.SignUp}`);
  }

  render(): React.ReactNode {
    const { isSessionExpired, showNotRefundableMessage } = this.state;
    const { reviewBookStore, loginStore, insuranceStore, isVacationRentals, intl } = this.props;
    const isPackage = reviewBookStore.bookingSummary.bookingCard.package.isPackage;
    const {
      card,
      guests,
      loadingBooking,
      bookingComplete,
      bookingSummary,
      isPriceChangedError,
      isSoldOutError,
      errorsBookingMessage,
      isBookingInProgress,
      threeDSLoading,
      selectedHotelReviewClientCash,
    } = reviewBookStore;
    const { account, userWallet, userWalletData } = loginStore;
    const { selection, insurance } = insuranceStore;

    const isNotification =
      bookingComplete ||
      isPriceChangedError ||
      isSoldOutError ||
      isBookingInProgress;
    
    const currency = bookingSummary?.bookingCard?.package?.currency;
    const { balance, bookingPrice } = bookingSummary.bookingCard;
    const { maxWalletClientCash } = bookingPrice;

    const chargeDescription = GetPreferenceValue(account as IAccount, MERCHANT_CHARGE_DESCRIPTION_FIELD_KEY);
    const walletNoPassEarnings = account?.walletNoPassEarnings;
    const walletNoDecimals = account?.walletNoDecimals;
    const walletIsExternal = account?.walletIsExternal;
    
    const hasClientCash = account?.hasClientCash;
    const clientCashPercentage = account?.clientCashPercentage ? account?.clientCashPercentage : '';
    const enableInsurance = account?.enableInsurance;
    const isLogged = !!userWallet;

    const convertionRate = userWalletData?.convertionRate ? userWalletData?.convertionRate : 1;

    const balanceInt = balance;
    const maxWalletClientCashInt = maxWalletClientCash;

    const finalBalanceIntermediate = balanceInt - (selectedHotelReviewClientCash ? selectedHotelReviewClientCash : 0) * convertionRate;
    const finalBalance = Math.floor(finalBalanceIntermediate * 100) / 100;
    const eligibleClientCash = (finalBalance > 0 ? finalBalance : 0) * (!isEmpty(clientCashPercentage) ? parseInt(clientCashPercentage) : 0) / 100;
    const isRequired = !hasClientCash || finalBalance > 0;
    const balanceCalculated = balanceInt / convertionRate;

    const maxWalletClientCashCalculated = maxWalletClientCashInt ? walletNoDecimals ? Math.floor(maxWalletClientCashInt / convertionRate) : maxWalletClientCashInt / convertionRate : null;
    const userWalletDataBalanceCalculatedInt = (walletNoDecimals && userWalletData?.balance) ? Math.floor(userWalletData?.balance) : userWalletData?.balance;
    const userWalletDataBalanceCalculated = userWalletDataBalanceCalculatedInt ? userWalletDataBalanceCalculatedInt : 0;
    const maxWalletAmountIntermediated = walletNoPassEarnings ? 
                              maxWalletClientCashCalculated ?
                                (maxWalletClientCashCalculated && userWalletDataBalanceCalculated > maxWalletClientCashCalculated) ? 
                                  maxWalletClientCashCalculated : 
                                  userWalletDataBalanceCalculated : 
                                0 : 
                              userWalletDataBalanceCalculated > balanceCalculated ? 
                                balanceCalculated : 
                                userWalletDataBalanceCalculated;

    const maxWalletAmount = (maxWalletClientCashCalculated && maxWalletAmountIntermediated > maxWalletClientCashCalculated) ? maxWalletClientCashCalculated : maxWalletAmountIntermediated;

    const clientCash = selectedHotelReviewClientCash ? GetWalletNumber(selectedHotelReviewClientCash, account as IAccount) : Number(0).toFixed(walletNoDecimals ? 0 : 2);

    const insuranceBalance = (selection === InsuranceSelection.YES && !!insurance?.productDetails?.price) ? insurance?.productDetails?.price : 0;
    const isInsuranceSelected = (selection === InsuranceSelection.YES && !!insurance?.productDetails?.price);

    return (
      guests && (
        <>
          <div className={`review-book ${isNotification ? 'hide-block' : ''}`}>
            {errorsBookingMessage && errorsBookingMessage.length && (
              <Alert
                message={<FormattedMessage id="transaction.failed" />}
                description={this.getErrorMessages()}
                type="error"
                showIcon
              />
            )}

            <GuestInfo formRef={this.formGuestRef} isCheckMainGuest={this.state.isCheckMainGuest} />

            {bookingSummary.providerMessage &&
              <div>
                <h4 className="payment-method__title secondary-font" style={{ marginTop: '10px' }}>
                  <FormattedMessage id="important.information" />
                </h4>
                <span className="review-book__accept-text">
                  {parse(bookingSummary.providerMessage)}
                </span>
              </div>
            }

            <PaymentMethod
              formRef={this.formPaymentRef}
              display={finalBalance > 0}
              isRequired={isRequired}
              onChangeCountryState={() => this.setState({ isAcceptIsInsurance: false })}
            />
            
            {!card?.id ? (
              <div className="review-book__wrapper" ref={this.insuranceContainerRef}>
                <Insurance onChange={() => this.setState({ isAcceptIsInsurance: false })} />
              </div>) : null}

            <ConfirmationEmail formRef={this.formEmailRef} value={this.mainGuestData('email')} />
            
            <CancellationPolicies displayCheckbox={true} disable={loadingBooking} onChangeAcceptPolicy={this.onChangeAcceptCancellationPolicy} />
            
            <div className="review-book__wrapper">
              <Checkbox onChange={this.onChangeAccept} disabled={loadingBooking} />
              <p className="review-book__accept-text">
                <FormattedMessage
                  id="accept.you.will.be.charged"
                  values={{
                    price: renderNumber(finalBalance + insuranceBalance, 2),
                    type: <FormattedMessage id="hotel" />,
                    reservation_name: (!isEmpty(chargeDescription) && bookingSummary?.customProvider) ? chargeDescription : <FormattedMessage id="accept.you.will.be.charged.travcoding" />,
                    currency: currency
                  }}
                />
              </p>
            </div>

            <div className="review-book__wrapper">
              <Checkbox onChange={this.onChangeAcceptKnowBeforeYouGo} disabled={loadingBooking} />
              <p className="review-book__accept-text">
              <FormattedMessage id="accetp.no.changes.allow" />
              </p>
            </div>

            <div className="review-book__wrapper">
              <Checkbox onChange={this.onChangeAcceptBookingRemarks} disabled={loadingBooking} />
              <p className="review-book__accept-text">
                <FormattedMessage id="by.checking.this.box.you.acknowledge.that.you.have.read" />
              </p>
            </div>

            {isPackage && account?.isTravelAgentPro &&
              <div className="review-book__wrapper">
                <Checkbox onChange={this.onChangeAcceptIsPackage} disabled={loadingBooking} />
                <p className="review-book__accept-text">
                  <FormattedMessage id="checkout.package.message" />
                </p>
              </div>}

            {hasClientCash && isLogged &&
              <div className="review-book__wrapper">
                <Checkbox onChange={this.onChangeAcceptClientCash} disabled={loadingBooking} />
                <p className="review-book__accept-text">
                  {!walletIsExternal ? 
                    (<FormattedMessage id="wallet.client.cash.by.checking.acknowledge" values={{ clientCash, reward: walletNoDecimals ? Math.floor(eligibleClientCash).toFixed(0) : eligibleClientCash.toFixed(2), clientCashPercentage: clientCashPercentage, clientCashName: account?.walletClientCashName }} />) :
                    (<FormattedMessage id="wallet.client.cash.by.checking.acknowledge.external" values={{ clientCash, clientCashName: account?.walletClientCashName }} />)}
                </p>
              </div>}

            {!walletIsExternal && hasClientCash && isLogged && selectedHotelReviewClientCash > 0 &&
              <div className="review-book__wrapper">
                <Checkbox onChange={this.onChangeAcceptClientCashNotRefundable} disabled={loadingBooking} />
                <p className="review-book__accept-text">
                  <FormattedMessage id="wallet.client.not_refundable.acknowledge" values={{ clientCash, clientCashName: account?.walletClientCashName }} />
                </p>

                <ModalMessage text={intl.formatMessage({ id: 'wallet.client.not_refundable.modal.acknowledge'}, { clientCashName: account?.walletClientCashName })} disableTextLocalization disableTitleLocalization={true} visible={showNotRefundableMessage} onCancel={() => this.setState({ showNotRefundableMessage: false })} />
              </div>}

            {isInsuranceSelected ? (
              <div className="review-book__wrapper">
                <Checkbox onChange={this.onChangeAcceptIsInsurance} disabled={loadingBooking} />
                <p className="review-book__accept-text">
                  <FormattedMessage id="insurance.policy.disclaimer" />
                </p>
              </div>) : null}

            {hasClientCash && !isLogged &&
              <div className="review-book__wrapper client-cash-message">
                <p className="review-book__accept-text">
                  <FontAwesomeIcon icon={faTriangleExclamation} />{' '}<FormattedMessage id="wallet.client.not_logged.acknowledge" values={{ reward: eligibleClientCash.toFixed(2), currency: currency, clientCashPercentage: clientCashPercentage, clientCashName: account?.walletClientCashName }} />
                </p>
                <p className="booking-confirmed__contact-property" style={{ fontSize: '18px', marginTop: '10px', textAlign: 'right' }}>
                  <BlueButton onClick={this.hangleGoToLogin}><FormattedMessage id="sign.in" /></BlueButton> <FormattedMessage id="or" /> <BlueButton onClick={this.hangleGoToSignUp}><FormattedMessage id="sign.up" /></BlueButton>
                </p>
              </div>}

            <div className="review-book__wrapper">
              <p className="review-book__agree-navigation-text">
                {bookingSummary?.showExtraPolicies && (<FormattedMessage 
                  id="agree.booking.expp"
                  values={{
                    pp: (msg: string) => {
                      return (
                        <a target="_blank" rel="noreferrer" href="https://www.travcoding.com/privacy-policy/" onClick={() => {
                          ReactGA.event({
                            category: account?.name as string,
                            action: `${C_PP}_${account?.name.toUpperCase()}`,
                            label: `User clicked privacy policy on book`,
                            nonInteraction: false,
                          });
                        }}>
                          {msg}
                        </a>);
                    },
                    tu: (msg: string) => {
                      return (
                        <a target="_blank" rel="noreferrer" href="https://www.travcoding.com/terms-of-use/" onClick={() => {
                          ReactGA.event({
                            category: account?.name as string,
                            action: `${C_TERMS}_${account?.name.toUpperCase()}`,
                            label: `User clicked terms of use on book`,
                            nonInteraction: false,
                          });
                        }}>
                          {msg}
                        </a>);
                    },
                    b: (msg: string) => (
                      <a target="_blank" rel="noreferrer" href="https://developer.expediapartnersolutions.com/terms/en">
                        {msg}
                      </a>
                    ),
                    c: (msg: string) => (
                      <a target="_blank" rel="noreferrer" href="https://developer.expediapartnersolutions.com/terms/agent/en/">
                        {msg}
                      </a>
                    ),
                  }}
                
                />)}
                {!bookingSummary?.showExtraPolicies && (<FormattedMessage 
                  id="agree.booking"
                  values={{
                    pp: (msg: string) => (
                      <a target="_blank" rel="noreferrer" href="https://www.travcoding.com/privacy-policy/" onClick={() => {
                        ReactGA.event({
                          category: account?.name as string,
                          action: `${C_PP}_${account?.name.toUpperCase()}`,
                          label: `User clicked privacy policy on book`,
                          nonInteraction: false,
                        });
                      }}>
                        {msg}
                      </a>
                    ),
                    tu: (msg: string) => (
                      <a target="_blank" rel="noreferrer" href="https://www.travcoding.com/terms-of-use/" onClick={() => {
                        ReactGA.event({
                          category: account?.name as string,
                          action: `${C_TERMS}_${account?.name.toUpperCase()}`,
                          label: `User clicked terms of use on book`,
                          nonInteraction: false,
                        });
                      }}>
                        {msg}
                      </a>
                    )
                  }}
                />)}
              </p>

              <div className="review-book__complete-btn">
                <BlueButton
                  onClick={this.handleOnCompleteBooking}
                  disabled={
                    isSoldOutError || 
                    (isPackage && account?.isTravelAgentPro && !this.state.isAcceptIsPackage) || 
                    (!card?.id && enableInsurance && insurance && (selection === null || selection === undefined)) ||
                    (isInsuranceSelected && !this.state.isAcceptIsInsurance) || 
                    loadingBooking || 
                    !this.state.isAccept || 
                    !this.state.isAcceptBookingRemarks || 
                    !this.state.isAcceptCancellationPolicy || 
                    !this.state.isAcceptNoChangesAllowed || 
                    (hasClientCash && isLogged && (!this.state.isClientCash)) || 
                    (hasClientCash && isLogged && !this.state.isClientCashNotRefundable && selectedHotelReviewClientCash > 0 && !walletIsExternal) || 
                    (hasClientCash && isLogged && selectedHotelReviewClientCash > maxWalletAmount) || 
                    (hasClientCash && isLogged && !!selectedHotelReviewClientCash && selectedHotelReviewClientCash <= maxWalletAmount && walletNoDecimals && Math.floor(selectedHotelReviewClientCash) != selectedHotelReviewClientCash)
                  }
                >
                  <FormattedMessage id="complete.booking" />
                  {loadingBooking && <Spin />}
                </BlueButton>
              </div>
            </div>

            <InsuranceRequiredMessage
              display={(!card?.id && enableInsurance && insurance && (selection === null || selection === undefined)) as boolean}
              insuranceContainerRef={this.insuranceContainerRef}
              classNameContainer="review-book__wrapper"
            />

            {/*(enableInsurance && (selection === null || selection === undefined)) && (
              <div className="review-book__wrapper" style={{ paddingTop: '6px' }}>
                <label className="insurance-missing"><FormattedMessage id="please.select.insurance" values={{
                  anchor: (msg: string) => (
                    <div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => {
                      this.insuranceContainerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }}>
                      {msg}
                    </div>
                  ),
                }} /></label>
              </div>)*/}
          </div>
          
          {isBookingInProgress && <ReservationProgress isBookingInProgress={isBookingInProgress} />}
          
          <BookingConfirmed
            isBookingComplete={bookingComplete}
            isVacationRentals={isVacationRentals}
            packageData={reviewBookStore}
          />

          <ModalConfirmation
            message="non.refundable.reservation.message"
            title="non.refundable.reservation"
            yesMessage="yes"
            noMessage="no"
            visible={this.state.showNotRefundableConfirmModal}
            onCancel={() => this.setState({ showNotRefundableConfirmModal: false })}
            onConfirm={() => this.setState({ showNotRefundableConfirmModal: false }, this.onCompleteBooking)}
          />

          {isPriceChangedError && (
            <BookingFailed
              title={intl.formatMessage({ id: 'price.changed' })}
              description={intl.formatMessage({ id: 'unable.to.confirm.booking' })}
              buttonTitle={intl.formatMessage({ id: 'book.another.room' })}
              onClick={this.redirectToHotelPage}
              isPriceChangedError={isPriceChangedError}
              isVacationRentals={isVacationRentals}
            />)}

          {isSoldOutError && (
            <BookingFailed
              title={this.titleBookingFailed()}
              isVacationRentals={isVacationRentals}
              description={intl.formatMessage({ id: 'sorry.we.were.unable.to.confirm' })}
              buttonTitle={intl.formatMessage({ id: 'start.searching' })}
              onClick={this.redirectToHotelPage}
            />)}

          <ReviewBookPriceChangeModal isVacationRentals={isVacationRentals} />
          <AvailabilityStatusModal isVacationRentals={isVacationRentals} />
          <ThreeDSModal />
          
          {threeDSLoading ? (
            <div className="review-book__loading-container">
              <Loading size={LoadingSise.Small} />
            </div>) : null}

          {isSessionExpired && (
            <div className="result-wrapper__expired-message">
              <ExpiredSessionModal visible={isSessionExpired} hasRedirect={true} onHandleRedirect={this.handleRedirect} />
            </div>)}

        </>
      )
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    reviewBookStore: state.reviewBookStore,
    redeemCodeStore: state.redeemCodeStore,
    marginatorStore: state.marginatorStore,
    hotelsStore: state.hotelsStore,
    loginStore: state.loginStore,
    insuranceStore: state.insuranceStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getBookPackageWithCard,
  setGuests,
  setPriceChangedError,
  getNavigationMenu,
  setBooking,
  setBookingComplete,
  setLoginRedirect,
};

export const ReviewBook = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(injectIntl(ReviewBookComponent)));

