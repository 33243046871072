
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { FunctionalRootState } from '@share/utils';
import { IMenuState } from '@share/store/slices';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

interface IProps {
  isDrawer?: boolean;
}

export function GiftCard(props: IProps) {
  const { isDrawer } = props;

  const { items }: IMenuState = useSelector((state: FunctionalRootState) => state.navigationMenuStore);

  if (items?.isGiftCard && items?.leisureCredits && Number(items?.leisureCredits) > 0) {
    return (
      <div className={`header__gift-card ${isDrawer ? 'drawer-card' : ''}`}>
        <div className="crown">
          <FontAwesomeIcon icon={faCrown} />
        </div>
        <div className="rewards">
          <FormattedMessage id={!isDrawer ? 'rewards' : 'my.rewards'} />: ${items?.leisureCredits}
        </div>
      </div>
    );
  } 

  return null;
}
