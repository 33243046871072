import { useState } from 'react';
import './style.scss';
import { InputNumber } from 'antd';
import { FormattedMessage } from 'react-intl';

interface IProps {
  placeholder: string;
  label: string;
  icon: any;
  setInsuranceFilter: React.Dispatch<React.SetStateAction<any>>;
  type: string;
}

export default function InsuranceNumberFilter(props: IProps) {
  const { placeholder, label, icon, setInsuranceFilter, type } = props;
  const onChange = (value: number | null) => {
    if (type === 'travelers') {
      setInsuranceFilter((prevState: any) => ({
        ...prevState,
        travelers: value,
      }));
    } else {
      setInsuranceFilter((prevState: any) => ({
        ...prevState,
        tripCost: value,
      }));
    }
  };
  return (
    <div className="insurance-filters-containers">
      <div className="insurance-filters-containers-icons">
        <img src={icon} className="search-container-icons" />
      </div>
      <div className="insurance-filters-containers-inputs">
        <div className="insurance-filters-containers-inputs-title">
          <span>{label}</span>
        </div>
        {type === 'travelers' ? (
          <InputNumber
            className="insurance-filters-containers-inputs-controls"
            placeholder={placeholder}
            min={1}
            max={50}
            type="number"
            onChange={(value) => {
              // Aseguramos que el valor se limite entre 1 y 50
              if (value !== undefined && value >= 1 && value <= 50) {
                onChange(value);
              }
            }}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              // Solo permitimos números dentro del rango 1-50 y evitamos que el valor supere 50
              const inputValue = e.target.value;

              // Filtra cualquier valor que no sea numérico o superior a 50
              if (!/^\d*$/.test(inputValue) || (inputValue && parseInt(inputValue) > 50)) {
                e.preventDefault(); // Bloquea la entrada de valores superiores a 50
              }
            }}
          />
        ) : (
          <InputNumber
            className="insurance-filters-containers-inputs-controls"
            placeholder={placeholder}
            type="number"
            min={1}
            onChange={onChange}
          />
        )}
        {type === 'travelers' && (
          <span style={{ fontSize: '11px', color: 'gray' }}>
            <FormattedMessage id="insurance.maximum.travelers" />
          </span>
        )}
      </div>
    </div>
  );
}
