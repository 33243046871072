import './style.scss';
import { DatePicker } from 'antd';
import moment from 'moment';
import { Moment } from 'moment';

interface IProps {
  label: string;
  icon: any;
  setInsuranceFilter: React.Dispatch<React.SetStateAction<any>>;
}

export default function InsuranceDatesFilter(props: IProps) {
  const { label, icon, setInsuranceFilter } = props;
  const { RangePicker } = DatePicker;

  const onChange = (dates: [Moment | null, Moment | null] | null) => {
    if (dates) {
      setInsuranceFilter((prevState: any) => ({
        ...prevState,
        travelDates: dates,
      }));
    }
  };

  const disablePastDates = (current: moment.Moment) => {
    // Disabled the day before tomorrow
    return current ? current.isBefore(moment().add(1, 'day').startOf('day')) : false;
  };


  return (
    <div className="insurance-filters-containers">
      <div className="insurance-filters-containers-icons">
        <img src={icon} className="search-container-icons" />
      </div>
      <div className="insurance-filters-containers-inputs">
        <div className="insurance-filters-containers-inputs-title">
          <span>{label}</span>
        </div>
        <RangePicker
          className="insurance-filters-containers-inputs-controls"
          style={{ background: 'rgb(245,245,246)', border: 'none', boxShadow: 'none' }}
          onChange={onChange}
          popupClassName="custom-insurance-dates-dropdown"
          disabledDate={disablePastDates}
        />
      </div>
    </div>
  );
}
